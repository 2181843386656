import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Select, BlockStack } from '@shopify/polaris';
import { useUrl, handleSaveWithoutLines } from '../../shared/util/hanldesave';
import { getCurrenctDatetimeYYYMMDD, alphabetize, getRequest } from '../../shared/components/functions.jsx';
import { useLookupStore } from '../../context/useStore.js';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet.jsx';
/*
  FIXME:
  - in access ther is a field Machine, what is this?  Not able to find in object
  - same for TaalID not to find in montagebrief
*/

export default function MontageBonPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const docTypeID = 2;

  const montageBonInterface = {
    Montagebriefnummer: null,
    Firmanaam: url.get('Firmanaam') || null,
    adres: url.get('adres') || null,
    stad: url.get('stad') || null,
    Land: url.get('Land') || null,
    BTW: url.get('BTW') || null,
    datum: getCurrenctDatetimeYYYMMDD(),
    Datum1: getCurrenctDatetimeYYYMMDD(),
    Merk: url.get('Merk') || null,
    Type: url.get('Type') || null,
    Serie: url.get('Serie') || null,
    Monteur: url.get('Monteur') || null,
    Opmerkingen: url.get('Opmerkingen') || null,
    FIRNR: url.get('FIRNR') || null,
    Montagebonnummer: url.get('Montagebonnummer') || null,
    Postcode: url.get('Postcode') || null,
    LandID: url.get('LandID') || 300,
    Verstuurd: url.get('Verstuurd') || false
  };

  const [initalBon, setInitialBon] = useState('');
  const [montageBon, setMontageBons] = useState('');
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [company, setCompany] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const { countries, languages } = useLookupStore();
  const pageTitle = getPageTitle(montageBon.Montagebriefnummer, 'Montage bon');

  const handleChange = (value, id) => {
    setMontageBons(
      (montageBon) => ({
        ...montageBon,
        [id]: value
      }),
      setIsDirty(true)
    );
  };

  const handleFormSubmit = async () => {
    await handleSaveWithoutLines(
      id,
      montageBon,
      'montage_bons',
      montageBon.Montagebriefnummer,
      'Montagebriefnummer',
      setMontageBons,
      setInitialBon,
      setIsDirty,
      setErrorToast,
      setToast,
      setFormSubmitting,
      navigate
    );
  };

  const handleDiscard = () => {
    setMontageBons(initalBon);
    setIsDirty(false);
  };

  useEffect(() => {
    async function fetchData() {
      //if url contains /new then set credit note in to its interface
      if (id === 'new') {
        setMontageBons(montageBonInterface);
        setInitialBon(montageBonInterface);
      } else {
        //else get the credit note in resource via api get request
        const data = await getRequest(`/api/montage_bons/${id}`);
        if (!data) {
          setMontageBons(montageBonInterface);
          setInitialBon(montageBonInterface);
        }
        setMontageBons(data);
        setInitialBon(data);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (montageBon.FIRNR) {
        const data3 = await getRequest(`/api/companies/${montageBon.FIRNR}`);
        setCompany(data3);
      }
    }
    fetchData();
  }, [montageBon.FIRNR]);

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} toastContent={'Montage Bon saved'} />
      <TheSaveBar isDirty={isDirty} handleSave={handleFormSubmit} handleDiscard={handleDiscard} savingResult={formSubmitting} />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={() => handleFormSubmit()}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="Kontraktnummer" disabled label="Number" value={montageBon.Montagebonnummer ? String(montageBon.Montagebonnummer) : 'created automatically'} />
                  <TextField id="Firnr" label="Company" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${montageBon.FIRNR}`) }} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="datum" type="date" label="Date" value={String(montageBon.datum).substring(0, 10)} onChange={handleChange} disabled={formSubmitting} />
                  <TextField id="periode" maxLength={60} type="number" disabled autoComplete="off" label="Machine: FIXME" value={String(montageBon.periode)} onChange={handleChange} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="Datum1" type="date" label="Tussenkomst" value={String(montageBon.Datum1).substring(0, 10)} onChange={handleChange} disabled={formSubmitting} />
                  <TextField id="Merk" maxLength={60} autoComplete="off" label="Merk" value={montageBon.Merk || ''} onChange={handleChange} disabled={formSubmitting} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="Firmanaam" maxLength={100} autoComplete="off" label="Firmanaam" value={montageBon.Firmanaam || ''} onChange={handleChange} disabled={formSubmitting} />
                  <TextField id="Type" label="Type" value={montageBon.Type || ''} onChange={handleChange} disabled={formSubmitting} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="adres" maxLength={60} autoComplete="off" label="Address" value={montageBon.adres || ''} onChange={handleChange} disabled={formSubmitting} />
                  <TextField id="Serie" label="Serie" value={montageBon.Serie || ''} onChange={handleChange} disabled={formSubmitting} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <BlockStack>
                    <Checkbox id="Verstuurd" label="PDF document emailed" checked={montageBon.Verstuurd} onChange={handleChange} disabled={formSubmitting} />
                  </BlockStack>
                  <Select
                    id="LandID"
                    label="Country"
                    placeholder="Select country"
                    value={parseInt(montageBon.LandID) || ''}
                    options={countries.map((count) => {
                      return { value: count.LandId, label: count.Land };
                    })}
                    onChange={handleChange}
                    disabled={formSubmitting}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    disabled
                    id="TaalID"
                    label="FIXME: Language"
                    value={parseInt(company.TaalID) || ''}
                    options={languages
                      .sort((a, b) => alphabetize(a, b, 'Omschrijving'))
                      .map((lan) => {
                        return { value: lan.TaalID, label: lan.Omschrijving };
                      })}
                    onChange={handleChange}
                  />
                  <TextField id="Monteur" maxLength={60} autoComplete="off" label="Monteurs" value={montageBon.Monteur || ''} onChange={handleChange} disabled={formSubmitting} />
                </FormLayout.Group>
                <TextField id="Opmerkingen" multiline={5} label="Note" value={montageBon.Opmerkingen || ''} onChange={handleChange} disabled={formSubmitting} />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <DocumentCard
            resource={'INSTALLATION_NOTE'}
            id={id}
            title="Montage bon document"
            company={company}
            docNumber={montageBon.Firnr}
            isDirty={isDirty}
            generateDocument={false}
            docTypeID={docTypeID}
            setResource={setMontageBons}
            docLang={company.TaalID}
            documentButtonLoading={formSubmitting}
            setDocumentButtonLoading={setFormSubmitting}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
