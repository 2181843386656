import React, { useState, useEffect } from 'react';
import { Card, Select, FormLayout, Popover, Button, TextField, Text, InlineStack } from '@shopify/polaris';
import { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { formatDate, formatToCurrency } from '../../../../shared/components/functions';
import { useToggle } from '../../../../shared/useToogle';
import { CardHeading } from '../../../../shared/components/cards/CardHeading';

export default function MetricMonthYearLine() {
  const [data, setData] = useState([]);
  const [groupBy, setGroupBy] = useState('YEAR');
  const [popoverActive, setPopoverActive] = useToggle();
  const [startDate, setStartDate] = useState(formatDate('1996-01-01'));
  const [endDate, setEndDate] = useState(formatDate(new Date()));

  const activator = (
    <Button onClick={setPopoverActive} disclosure>
      Customize
    </Button>
  );

  const options = [
    { label: 'Month', value: 'MONTH' },
    { label: 'Year', value: 'YEAR' },
    { label: 'Day', value: 'DAY' }
  ];

  async function fetchData() {
    const response = await fetch(`/api/metrics/financial?groupBy=${groupBy}&startDate=${startDate}&endDate=${endDate}`);
    const data = await response.json();
    setData(
      data.map((point) => {
        return {
          name: point.year,
          ...point
        };
      })
    );
  }

  useEffect(() => {
    fetchData();
  }, [groupBy, startDate, endDate]);

  const customize_graph = (
    <Popover active={popoverActive} activator={activator} onClose={setPopoverActive} ariaHaspopup={false} sectioned>
      <FormLayout>
        <Select label="Group by" options={options} onChange={setGroupBy} value={groupBy} />
        <TextField id="start_date" maxLength={60} type="date" autoComplete="off" label="Start Date" value={startDate} onChange={setStartDate} />
        <TextField id="end_date" maxLength={60} type="date" autoComplete="off" label="End Date" value={endDate} onChange={setEndDate} />
      </FormLayout>
    </Popover>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Card>
          <Text className="label">{`Date : ${label}`}</Text>
          <p className="label">{`Invoiced out tax incl : ${formatToCurrency(payload[0] ? payload[0].value : 0)}`}</p>
          <p className="label">{`Invoiced in tax incl : ${formatToCurrency(payload[1] ? payload[1].value : 0)}`}</p>
          <p className="label">{`Credited out tax incl : ${formatToCurrency(payload[2] ? payload[2].value : 0)}`}</p>
          <p className="label">{`Credited in tax incl : ${formatToCurrency(payload[3] ? payload[3].value : 0)}`}</p>
        </Card>
      );
    }
    return null;
  };

  return (
    <Card>
      <InlineStack align="space-between">
        <CardHeading title="Financial metrics taxes inclusive" />
        {customize_graph}
      </InlineStack>

      <div style={{ width: '100%', height: '650px', marginTop: '20px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={1500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <Legend />
            <CartesianGrid strokeDasharray="1 5" />
            <XAxis dataKey="period" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="invoiced_out_tax_incl" stroke="#64BE7B" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="invoiced_in_tax_incl" stroke="#AD0002" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="credited_out_tax_incl" stroke="#FA8471" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="credited_in_tax_incl" stroke="#81DC8C" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}
