import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsIndustriesPage() {
  const [sectors, setSectors] = useLookupStore((state) => [state.sectors, state.setSectors]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    SectorCode: { type: 'text', label: 'Industry code', max: '10', value: '' },
    Sector: { type: 'text', label: 'Description', max: '150', multi: true, value: '' }
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/sectors';
  const title = 'Industries';

  const rows = sectors.map((item) => [item.SectorCode, item.Sector, getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.SectorID)]);

  return (
    <Page
      title={title}
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add industry', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setSectors}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'numeric']}
          headings={[<b>Industry code</b>, <b>Description</b>, <b>Edit</b>]}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
