import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, DataTable, Button, Banner, Select, InlineGrid, Box } from '@shopify/polaris';
import { MaximizeIcon } from '@shopify/polaris-icons';
import { getTableFooter, formatToCurrency, formatDate, generateArrayOfYears, getRequest } from '../../shared/components/functions.jsx';
import CashbookModal from './cashbookModal.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { useUrl } from '../../shared/util/hanldesave';

export default function CashbookPage() {
  let url = useUrl();
  const navigate = useNavigate();

  const [year, setYear] = useState(url.get('year') || '2022');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState('new');
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const fetchTransactions = async () => {
    const data = await getRequest(`api/cashbook?page=${page}&limit=100&year=${year}`);
    setItems(data);
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const totals =
    items.length > 0 ? ['', '', '', '', formatToCurrency(items.reduce((p, c) => p + c['bedrag inkomst'], 0)), formatToCurrency(items.reduce((p, c) => p + c['bedrag uitgaven'], 0))] : null;

  function handleTotals(totals) {
    let income = totals[4];
    income = parseFloat(income.split('€')[1].replace('.', '').replace(',', '.'));
    let expense = totals[5];
    expense = parseFloat(expense.split('€')[1].replace('.', '').replace(',', '.'));
    return formatToCurrency(income - expense);
  }

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          item.Kasboeknummer,
          <div style={{ margin: '-4px 0' }}>
            <Button
              size="micro"
              onClick={() => {
                setModalOpen(true);
                setSelectedId(item.boekingsnummer);
              }}
              icon={MaximizeIcon}
            />
          </div>,
          formatDate(item.datum),
          item.omschrijving,
          formatToCurrency(item['bedrag inkomst']),
          formatToCurrency(item['bedrag uitgaven'])
        ];
      })
    : [];

  const options = generateArrayOfYears();
  const handleSelectChange = useCallback(
    (value) => {
      setYear(value);
      navigate(
        {
          pathname: window.location.pathname,
          search: `?year=${value}`
        },
        { replace: true }
      );
    },
    [navigate]
  );

  return (
    <Page
      title="Cashbook"
      fullWidth
      primaryAction={{
        content: 'New cashbook entry',
        onAction: () => {
          setModalOpen(true);
          setSelectedId('new');
        }
      }}
    >
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} toastContent={'Transaction saved'} />
      <CashbookModal modalOpen={modalOpen} setModalOpen={setModalOpen} id={selectedId} setId={setSelectedId} setToast={setToast} setErrorToast={setErrorToast} fetchTransactions={fetchTransactions} />
      <Card>
        <Box paddingBlockEnd="400">
          <Banner>
            <InlineGrid gap="800" columns={2}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Total income:</b>
                    </td>
                    <td>{totals ? totals[4] : null}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total expenses:</b>
                    </td>
                    <td>{totals ? totals[5] : null}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total:</b>
                    </td>
                    <td>{totals ? handleTotals(totals) : null}</td>
                  </tr>
                </tbody>
              </table>
              <Select label={<b>{'Filter on year'}</b>} options={options} onChange={handleSelectChange} value={parseInt(year) || ''} />
            </InlineGrid>
          </Banner>
        </Box>
        <DataTable
          verticalAlign="middle"
          stickyHeader={true}
          increasedTableDensity={true}
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'numeric']}
          headings={[<b>{'Code'}</b>, <b>{'Edit'}</b>, <b>{'Date'}</b>, <b>{'Description'}</b>, <b>{'Income'}</b>, <b>{'Expense'}</b>]}
          showTotalsInFooter
          rows={rows}
          totals={totals}
          footerContent={getTableFooter(rows, page, setPage, 'cashbook transactions')}
        />
      </Card>
    </Page>
  );
}
