import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsPurchaseTypes() {
  const [purchasetypes, setPurchasetypes] = useLookupStore((state) => [state.purchasetypes, state.setPurchasetypes]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({ BestellingType: { type: 'text', label: 'Purchase type', max: '50', multi: false, value: '' } });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/purchasetypes';
  const title = 'Purchase types';

  const rows = purchasetypes.map((item) => [item.BestellingType, getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.BestellingTypeID)]);

  return (
    <Page
      title={title}
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add purchase order type', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setPurchasetypes}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable stickyHeader increasedTableDensity verticalAlign="middle" columnContentTypes={['text', 'numeric']} headings={[<b>Purchase type</b>, <b>Edit</b>]} rows={rows} />
      </Card>
    </Page>
  );
}
