import { Badge, Tooltip } from '@shopify/polaris';
import { useLookupStore } from '../../../context/useStore';

export const ApprovalBadges = ({ approvals, resource }) => {
  const users = useLookupStore((state) => state.users);

  return approvals ? (
    <div>
      {approvals.map((approval, idx) => {
        const user = users.filter((user) => user.GebruikerID === approval[resource === 'invoice' ? 'GoedgekeurdDoorID' : 'GoedgekeurdDoorGebruikersID'])[0];
        return (
          <Tooltip key={idx} content={approval.Goedgekeurd ? `${user?.Gebruikernaam} approved` : `${user?.Gebruikernaam} did not yet approve this invoice`}>
            <Badge tone={approval.Goedgekeurd ? 'success' : 'error'}>{user?.Afkorting}</Badge>
          </Tooltip>
        );
      })}
    </div>
  ) : null;
};
