import React, { useState, useEffect } from 'react';
import { Modal, DataTable, Badge, InlineStack } from '@shopify/polaris';
import { formatToCurrency, getRequest } from '../../../shared/components/functions';

export default function TotalsModal({ modalOpen, setModalOpen }) {
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    async function getTotals() {
      //get totals from api
      const data = await getRequest('/api/invoices/all/totals');

      setTotals(
        Array.isArray(data)
          ? data.map((total) => {
              return [
                total.Vervallen ? (
                  <div style={{ height: '10px', display: 'flex', alignItems: 'center' }}>
                    <InlineStack wrap={false} gap="200">
                      {total.name}
                      <Badge tone="warning">Vervallen</Badge>
                    </InlineStack>
                  </div>
                ) : (
                  <div style={{ height: '10px', display: 'flex', alignItems: 'center' }}>
                    <InlineStack wrap={false} gap="200">
                      {total.name}
                      <Badge>Niet vervallen</Badge>
                    </InlineStack>
                  </div>
                ),
                total.amount,
                formatToCurrency(total.valueOpen),
                formatToCurrency(total.valueTotal)
              ];
            })
          : []
      );
    }
    if (modalOpen) getTotals();
  }, [modalOpen]);

  return (
    <Modal size="large" open={modalOpen} onClose={() => setModalOpen(false)} title="Totals">
      <Modal.Section>
        <DataTable
          verticalAlign="middle"
          stickyHeader={true}
          increasedTableDensity={true}
          columnContentTypes={['text', 'numeric', 'numeric', 'numeric']}
          headings={['Status invoice', 'Number of invoices', 'Value open', 'Value total']}
          rows={totals}
        />
      </Modal.Section>
    </Modal>
  );
}
