import { Link, Badge, DataTable, Filters, Tooltip, Button, Box } from '@shopify/polaris';
import { formatToCurrency, getDocPdfLinkForListViewIncoming, getTableFooter, truncate } from '../../../shared/components/functions';
import { addFilters, applyFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../../shared/components/listFilter';
import { CreditCardIcon, CreditCardPercentIcon } from '@shopify/polaris-icons';
import { useAuthStore } from '../../../context/useStore';
import { ApprovalBadges } from '../../../shared/components/badges/approvalBadges';

export default function InvoicesInTable({ items, page, setPage, filters, setFilters, navigate, setInvoiceCollectionModalOpen, setCollectionIvoice, setFullInvoiceCollection, filterLoading }) {
  const { user } = useAuthStore();

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const displayButtons = item.S3ObjectKeys && item.S3ObjectKeys.length > 0 ? item.processed && !item.status : !item.status;
        return [
          <div style={{ display: 'flex', marginLeft: '-10px', gap: '5px' }}>
            {!item.processed && item.S3ObjectKeys ? '🔔' : null}
            <Link removeUnderline url={'/invoices_in/' + item.faktuurinvolgnummer}>
              {item.FactuurInNummer}
            </Link>
          </div>,
          getDocPdfLinkForListViewIncoming(item, item.supplier_number, item.FactuurInNummer, user, 'Factuur In', 'FI'),
          <Link removeUnderline url={'/companies/' + item.LeverancierNummer}>
            {item.supplier_name?.substring(0, 30)}
          </Link>,
          item.datumin ? String(item.datumin)?.substring(0, 10) : '',
          item.datumbetaling ? String(item.datumbetaling)?.substring(0, 10) : '',
          item.datum ? String(item.datum)?.substring(0, 10) : '',
          item.faktuurref,
          formatToCurrency(item.Bedragfaktuur),
          formatToCurrency(item.bedragopen),
          item.datumbetalingout ? String(item.datumbetalingout)?.substring(0, 10) : '',
          truncate(item.memo, 25, false),
          <ApprovalBadges approvals={JSON.parse(item.approvals)} resource="invoice" />,
          item.Afkorting ? <Badge>{item.Afkorting}</Badge> : '',
          item.status ? <Badge tone="success">Paid</Badge> : <Badge tone="warning">Not paid</Badge>,
          displayButtons ? (
            <div style={{ margin: '-4px 0' }}>
              <Tooltip content="Mark as full paid">
                <Button
                  size="slim"
                  icon={CreditCardIcon}
                  onClick={() => {
                    setInvoiceCollectionModalOpen(true);
                    setCollectionIvoice(item);
                    setFullInvoiceCollection(true);
                  }}
                />
              </Tooltip>
              <Tooltip content="Mark as partially paid">
                <Button
                  size="slim"
                  icon={CreditCardPercentIcon}
                  onClick={() => {
                    setInvoiceCollectionModalOpen(true);
                    setCollectionIvoice(item);
                    setFullInvoiceCollection(false);
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            ''
          )
        ];
      })
    : [];

  const totals =
    items.length > 0
      ? ['', '', '', '', '', '', '', formatToCurrency(items.reduce((p, c) => p + c.Bedragfaktuur, 0)), formatToCurrency(items.reduce((p, c) => p + c.bedragopen, 0)), '', '', '', '', '', '']
      : null;

  return (
    <Box>
      <Filters
        queryValue={filters.q.value}
        filters={addFilters(filters, setFilters, navigate, setPage)}
        appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
        loading={filterLoading}
        onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
        onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
        autoComplete="off"
        queryPlaceholder="Filter invoices on supplier name, number, value and reference"
        onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
      />
      <DataTable
        verticalAlign="middle"
        stickyHeader
        increasedTableDensity
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'text', 'text', 'text', 'text', 'text', 'numeric']}
        totals={totals}
        showTotalsInFooter
        headings={[
          <b>Number</b>,
          <b>PDF</b>,
          <b>Supplier</b>,
          <b>Date in</b>,
          <b>Date expire</b>,
          <b>Date invoice</b>,
          <b>Reference</b>,
          <b>Value</b>,
          <b>Open</b>,
          <b>Date payment</b>,
          <b>Note</b>,
          <b>Approvals</b>,
          <b>Owner</b>,
          <b>Paid</b>,
          <b>Payment</b>
        ]}
        rows={rows}
        footerContent={getTableFooter(rows, page, setPage, 'incoming invoices')}
      />
    </Box>
  );
}
