import { Box, Button, ButtonGroup, InlineStack, Text } from '@shopify/polaris';

export const CardHeading = ({ title, actions }) => {
  return (
    <Box paddingBlockEnd="300">
      <InlineStack align="space-between">
        <Text variant="headingMd" as="h2">
          {title}
        </Text>
        {actions ? (
          <ButtonGroup>
            {actions.map((action, idx) => {
              return (
                <Button key={idx} variant="plain" onClick={action.onAction}>
                  {action.content}
                </Button>
              );
            })}
          </ButtonGroup>
        ) : null}
      </InlineStack>
    </Box>
  );
};
