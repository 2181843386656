import React, { useState, useEffect } from 'react';
import { Spinner, DropZone as PolarisDropzone, Button } from '@shopify/polaris';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import { setChonkyDefaults, ChonkyActions } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { FileBrowser, FileToolbar, FileList } from 'chonky';
import getSharePointUrlForFirnrDocumentTypeIdDocName from '../../../utils/sharepoint';
import { convertDocumentTZ, getRequest } from '../functions';
import { useLookupStore } from '../../../context/useStore';

export default function DetailFileBrowser({ FIRNR, Klantnummer, resourceId, docTypeId, docNumber, docType, heightPxs, parentRefetchDocuments = console.log }) {
  setChonkyDefaults({ iconComponent: ChonkyIconFA });

  const documenttypes = useLookupStore((state) => state.documenttypes);
  const [docs, setDocs] = useState();
  const [loading, setLoading] = useState(false);

  //handles files actions
  async function handleAction(data) {
    if (data.id === ChonkyActions.OpenFiles.id) {
      const { docTypeId, name, isDir } = data.payload.targetFile;
      // Open the files or folders
      if (isDir) {
        //open folder
      } else {
        const url = getSharePointUrlForFirnrDocumentTypeIdDocName(Klantnummer, docTypeId, docNumber, name, documenttypes);
        window.open(url, '_newtab');
      }
    } else if (data.id === ChonkyActions.DeleteFiles.id) {
      setLoading(true);

      //loop over selected files and delete them one by one
      for (let i = 0; i < data.state.selectedFiles.length; i++) {
        const { id, name } = data.state.selectedFiles[i];

        const payload = {
          id: id,
          name: name,
          customerNumber: Klantnummer,
          docType: docType,
          docNumber: docNumber
        };

        await fetch(`/api/docgen`, { method: 'DELETE', headers: { 'Content-type': 'application/json; charset=UTF-8' }, body: JSON.stringify(payload) });
      }
      fetchDocuments();
      parentRefetchDocuments();
      setLoading(false);
    }
  }

  async function fetchDocuments() {
    if (resourceId !== 'new') {
      let data = [];

      if (docTypeId !== 28 && docTypeId !== 22) {
        data = await getRequest(`/api/documents/id/typeid?id=${resourceId}&typeID=${docTypeId}`);
      } else {
        //fetching documents for company
        data = await getRequest(`/api/documents/id/typeid?firnr=${FIRNR}&typeID=${docTypeId}`);
      }

      if (Array.isArray(data)) {
        const files = data.map((doc) => ({
          id: String(doc.DocumentID),
          name: doc.Filename,
          isDir: false,
          modDate: convertDocumentTZ(doc.Datum),
          docTypeId: doc.DocumentTypeID,
          docNumber: doc.docNumber
        }));
        setDocs(files);
      }
    }
  }

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId, FIRNR]);

  const handleDropZoneDrop = async (files) => {
    //if a filename has a hashtag the upload will fail, so do not allow uploads of files with hashtags in the name
    if (files.some((file) => file.name.includes('#'))) return alert('File name(s) cannot contain a #');

    setLoading(true);

    //loop over the files and upload them one by one
    for (let i = 0; i < files.length; i++) {
      var formData = new FormData();
      formData.append('file', files[i]);
      formData.append('customerNumber', Klantnummer);
      formData.append('docType', docType);
      formData.append('docNumber', docNumber);
      formData.append('FIRNR', FIRNR);
      formData.append('resourceId', resourceId);
      formData.append('docTypeId', docTypeId);
      formData.append('isSystemDoc', '0');

      await fetch(`/api/docgen/uploadfile`, { method: 'POST', body: formData });
    }

    await fetchDocuments();
    await parentRefetchDocuments();
    setLoading(false);
  };

  const myFileActions = [ChonkyActions.DeleteFiles];

  const actionsToDisable = [ChonkyActions.SelectAllFiles.id];

  const fileUpload = (
    <div style={{ padding: '1rem' }}>
      <Button>ADD PICS OR FILES</Button>
    </div>
  );

  return (
    <div>
      {loading ? (
        <div style={{ marginTop: '20px', fontWeight: '500', marginBottom: '15px' }}>
          <span style={{ paddingRight: '5px' }}>
            <Spinner accessibilityLabel="Spinner example" size="small" />
          </span>
          SYNCING WITH SHAREPOINT
          <span style={{ paddingLeft: '5px' }}>
            <Spinner accessibilityLabel="Spinner example" size="small" />
          </span>
        </div>
      ) : null}

      <div style={{ marginBottom: '15px' }}>
        <div style={{ paddingBottom: '10px', display: isMobile ? 'inline' : 'none' }}>
          <PolarisDropzone onDrop={handleDropZoneDrop} variableHeight>
            {fileUpload}
          </PolarisDropzone>
        </div>
        <Dropzone onDrop={handleDropZoneDrop} allowMultiple={false} disabled={loading}>
          {({ getRootProps }) => (
            <div {...getRootProps()} style={{ resize: 'vertical', overflow: 'auto', height: heightPxs || '250px' }}>
              <FileBrowser
                {...getRootProps()}
                darkMode={true}
                files={docs}
                defaultFileViewActionId={ChonkyActions.EnableListView.id}
                defaultSortActionId={!ChonkyActions.SortFilesByDate.id}
                onFileAction={handleAction}
                enableDragAndDrop={true}
                disableDefaultFileActions={actionsToDisable}
                fileActions={myFileActions}
                disableSelection={loading}
              >
                <FileToolbar />
                <FileList />
              </FileBrowser>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
}
