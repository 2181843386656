import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import { useLookupStore } from '../../../../context/useStore.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsMachineTypesPage() {
  const [machinetypes, setMachinetypes] = useLookupStore((state) => [state.machinetypes, state.setMachinetypes]);
  const machinebrands = useLookupStore((state) => state.machinebrands);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    Type: { type: 'text', label: 'Type', max: '200', value: '' },
    IsDeleted: { type: 'active', label: 'Is deleted', value: false },
    MerkId: { type: 'select', label: 'Machine brand', value: '' }
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/machinetypes';
  const title = 'Machine types';

  const rows = machinetypes.map((item) => {
    const machinebrandArr = machinebrands.filter((brand) => brand.counter === item.MerkId);
    const machinebrand = machinebrandArr[0].merk;
    return [item.Type, machinebrand, getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.ID)];
  });

  return (
    <Page
      title={title}
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add machine type', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setMachinetypes}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable stickyHeader increasedTableDensity verticalAlign="middle" columnContentTypes={['text', 'text', 'numeric']} headings={[<b>Machine type</b>, <b>Brand</b>, <b>Edit</b>]} rows={rows} />
      </Card>
    </Page>
  );
}
