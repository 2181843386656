import { useState, useCallback, useEffect } from 'react';
import { FormLayout, Modal, TextField } from '@shopify/polaris';
import { postRequest } from '../functions';
import { useAuthStore, useLookupStore } from '../../../context/useStore';
import getSharePointUrlForFirnrDocumentTypeIdDocName from '../../../utils/sharepoint';
import ProjectBriefSelect from '../projectBriefSelect';
import ContactBriefSelect from '../contactBriefSelect';

export default function WordExporterModal({ company, active, setActive, setToastError }) {
  const { user } = useAuthStore();

  const [selectedContact, setSelectedContact] = useState([]);
  const [referenceInput, setReferenceInput] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [sysDoc, setSysDoc] = useState(null);
  const [documentGenerated, setDocumentGenerated] = useState(false);
  const [isInvalid, setIsInvalid] = useState('');
  const documenttypes = useLookupStore((state) => state.documenttypes);

  const handleModalClose = useCallback(() => setActive(!active), [active, setActive]);

  const generateWord = async () => {
    let ourRef, concern;
    function decideOnLang(company) {
      switch (company.TaalID) {
        case 1:
          ourRef = 'Onze ref.:';
          concern = 'Betreft:';
          break;
        case 4:
          ourRef = 'Unsere Ref.:';
          concern = 'Betrifft:';
          break;
        case 2:
          ourRef = 'Notre ref.:';
          concern = 'Concerner:';
          break;
        default:
          ourRef = 'Our Ref.:';
          concern = 'Concerning:';
      }
    }

    decideOnLang(company);

    const document = {
      companyID: company.FIRNR,
      companyNumber: company.Klantnummer,
      companyName: company.NAAM,
      contactName: selectedContact || '',
      street: company.STRAAT_NR !== null || company.STRAAT_NR !== undefined ? `${company.STRAAT_NR}` : '',
      postcode: company.POSTNR !== null ? `${company.POSTNR} ` : '',
      city: company.WOONPL !== null ? `${company.WOONPL}` : '',
      projectName: selectedProject ? `${selectedProject.label}` : '',
      ourRef: ourRef,
      concern: concern,
      reference: referenceInput,
      user: user,
      ParentID: selectedProject ? selectedProject.value : null
    };

    const response = await postRequest(`/api/docgen_word/`, document, console.log, setToastError);
    return response;
  };

  const submitModal = async () => {
    setIsLoading(true);
    let docname = await generateWord();
    if (docname && docname?.docName) {
      setSysDoc(docname);
      window.open(getSharePointUrlForFirnrDocumentTypeIdDocName(company.Klantnummer, 22, null, docname.docName, documenttypes), '_blank');
      setDocumentGenerated(true);
      handleModalClose();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (sysDoc) window.location.reload(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentGenerated]);

  const handleReferenceChange = async (value) => {
    setReferenceInput(value);
    if (value.length < 5) return setIsDisabled(true);
    setIsDisabled(false);
    setIsInvalid('');
  };

  const handleOnBlur = async () => {
    if (referenceInput.length < 5) return setIsInvalid('Reference needs to be at least 5 characters long');
    setIsInvalid('');
  };

  return (
    <Modal
      height="auto"
      open={active}
      onClose={handleModalClose}
      title="Select details:"
      primaryAction={{ content: 'New brief', onAction: submitModal, disabled: isDisabled, loading: isLoading }}
      loading={isLoading}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            onBlur={handleOnBlur}
            error={isInvalid}
            id="reference"
            label="Reference:"
            value={referenceInput || ''}
            onChange={handleReferenceChange}
            maxLength={35}
            autoComplete="off"
            showCharacterCount
          />
          <ContactBriefSelect label="Contact:" FIRNR={company.FIRNR} onChange={(value) => setSelectedContact(value)} />
          <ProjectBriefSelect label="Project:" FIRNR={company.FIRNR} onChange={(value) => setSelectedProject(value)} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
