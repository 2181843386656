import { useEffect, useState } from 'react';
import { Modal, TextField, Button, Badge, Icon, Scrollable, InlineGrid } from '@shopify/polaris';
import { truncate, companyWebRequest } from '../../../shared/components/functions';
import { PlusCircleIcon } from '@shopify/polaris-icons';
import { getSchema } from '../../../shared/formSchemas';
import { Table } from '../../../shared/table';

export function AddCompanyModal({ active, setActive, setCompany, setIsDirty }) {
  const [companyList, setCompanyList] = useState([]);
  const [input, setInput] = useState({ companyName: '', zip: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const companySchema = getSchema('company');

  function matchLanguage(preferredLang) {
    return preferredLang.IsEnabled ? (preferredLang.Value.UsesDutch ? 1 : preferredLang.UsesFrench ? 2 : preferredLang.UsesGerman ? 4 : 3) : 3;
  }

  const handleChange = async (value, id) => {
    setInput((input) => ({
      ...input,
      [id]: value
    }));
  };

  useEffect(() => {
    async function fetchSearchResults() {
      const list = await companyWebRequest(input, false);
      if (list && list?.SearchCompaniesResult?.SearchResponseList !== null) {
        setCompanyList(list.SearchCompaniesResult.SearchResponseList.SearchResponseItem);
        setNoResults(false);
      } else {
        setCompanyList(null);
        setNoResults(true);
      }
    }
    if (input.companyName.length > 2) fetchSearchResults();
  }, [input]);

  const handleSubmit = async (selected) => {
    setIsLoading(true);
    const list = await companyWebRequest(selected, true);
    if (list) {
      const { CompanyName, LegalForm, Address, TelephoneNumber, Score, CreditLimit, Balances, PreferredLanguages } = list.GetCompanyByVatResult.CompanyResponse;
      let newCompanySchema = companySchema.default();
      const closeDate = Balances.Value ? Balances.Value.Balans[0].BalansData.BalansData.filter((b) => b.Key === 'CloseDate')[0].Value : null;
      const workforce = Balances.Value ? Balances.Value.Balans[0].BalansData.BalansData.filter((b) => b.Key === 'Rub9087')[0].Value : null;
      setCompany({
        ...newCompanySchema,
        NAAM: CompanyName.Value + ' ' + LegalForm.Value.Abbreviation,
        STRAAT_NR: Address.Value.Line1,
        WOONPL: Address.Value.City,
        POSTNR: Address.Value.PostalCode, //string
        LandID: 300,
        TEL: TelephoneNumber.Value || null,
        BTW: `BE${selected}`,
        TaalID: matchLanguage(PreferredLanguages),
        BTWPlichtig: 1,
        personeelsbestand: Number(workforce).toFixed(0),
        //Article
        //new:
        scoreInfo: Score.Value.Info,
        scoreInt: Score.Value.ScoreAsInt,
        creditLimit: CreditLimit.Value.Limit,
        creditCode: CreditLimit.Value.Code,
        closingDate: closeDate
      });
    }
    setIsLoading(false);
    setActive(false);
    setIsDirty(true);
  };

  const columns = [
    {
      Header: 'Select',
      accessor: '',
      maxWidth: '40px',
      textAlign: 'center',
      Cell: ({ row }) => {
        return (
          <div style={{ marginTop: '-10px', marginBottom: '-15px' }}>
            <Button variant="plain" loading={isLoading} size="slim" onClick={() => handleSubmit(row.values.VatNumber)}>
              <Icon tone="primary" source={PlusCircleIcon} />
            </Button>
          </div>
        );
      }
    },
    {
      Header: 'Name',
      accessor: 'CompanyName',
      Cell: ({ row }) => {
        return truncate(row.values.CompanyName, 32, false);
      }
    },
    {
      Header: 'Street',
      accessor: 'Street',
      maxWidth: '130px',
      Cell: ({ row }) => {
        return truncate(row.values.Street, 20, false);
      }
    },
    {
      Header: 'City',
      accessor: 'City',
      maxWidth: '130px',
      Cell: ({ row }) => {
        return truncate(row.values.City, 25, false);
      }
    },
    {
      Header: 'VAT',
      accessor: 'VatNumber',
      maxWidth: '80px',
      Cell: ({ row }) => {
        return `BE${row.values.VatNumber.toString()}`;
      }
    },
    {
      Header: 'Status',
      accessor: 'IsActive',
      maxWidth: '50px',
      textAlign: 'center',
      Cell: ({ row }) => {
        return row.values.IsActive ? <Badge tone="success">Active</Badge> : <Badge tone="critical">Inactive</Badge>;
      }
    }
  ];

  return (
    <Modal
      open={active}
      size="large"
      onClose={() => setActive(false)}
      title="Do you want to add a belgian company?"
      secondaryActions={[
        {
          content: 'Go to blank form',
          onAction: () => setActive(false)
        }
      ]}
    >
      <Modal.Section>
        If you are adding a belgian company, prefill the form by selecting the company here or continue with a blank form. <br></br>
      </Modal.Section>
      <Modal.Section>
        <InlineGrid columns={2} gap="200">
          <TextField label="Company name" id={'companyName'} value={input.companyName} onChange={handleChange} autoComplete="off" />
          <TextField label="Zip code" id={'zip'} value={input.zip} onChange={handleChange} autoComplete="off" placeholder={'optional'} />
        </InlineGrid>
      </Modal.Section>
      <Modal.Section>
        {companyList && companyList.length > 0 ? (
          <Scrollable shadow style={{ height: '500px', margin: 'auto -20px -20px -20px' }} horizontal={false}>
            <Table tableColumns={columns} tableData={companyList} border padding={'5px'} />
          </Scrollable>
        ) : noResults ? (
          'No results found.'
        ) : null}
      </Modal.Section>
    </Modal>
  );
}
