import { Button, Tooltip, FormLayout, Combobox, Listbox } from '@shopify/polaris';
import { useState } from 'react';
import { useLookupStore } from '../../../context/useStore';
import { BelgiumZipModal, USZipModal } from './zipModal';
import { QuestionCircleIcon } from '@shopify/polaris-icons';

export const CityZipSelect = ({ company, setCompany, savingResult, setIsDirty }) => {
  const zipcodes = useLookupStore((state) => state.zipcodes);
  const [selected, setSelected] = useState();
  const [cityZipOptions, setCityZipOptions] = useState([]);
  const [zipCodeModalActive, setZipCodeModalActive] = useState(false);
  const [usZipCodeModalActive, setUSZipCodeModalActive] = useState(false);

  const handleChange = (value, id) => {
    if (company.LandID === 300) {
      let filter = id === 'WOONPL' ? 'Plaatsnaam' : 'Postcode';
      let label = id === 'WOONPL' ? 'Postcode' : 'Plaatsnaam';

      const filteredZips = zipcodes.filter((zipcode) => String(zipcode[filter]).toLocaleLowerCase() === String(value).toLocaleLowerCase());
      setCityZipOptions(filteredZips.map((f) => ({ value: f[label], label: f[label] })));
    }
    setCompany((company) => ({ ...company, [id]: value }), setIsDirty(true));
  };

  const handleSelect = (value, id) => {
    setSelected(value);
    setCompany((company) => ({ ...company, [id]: value }), setIsDirty(true));
  };

  const cityActivator = <Combobox.TextField maxLength={50} showCharacterCount={company.WOONPL} id="WOONPL" label="City" value={company.WOONPL || ''} onChange={handleChange} disabled={savingResult} />;

  const zipActivator = (
    <Combobox.TextField
      maxLength={20}
      showCharacterCount={company.POSTNR}
      id="POSTNR"
      label="ZIP"
      value={company.POSTNR || ''}
      onChange={handleChange}
      width="20%"
      disabled={savingResult}
      connectedRight={
        Number(company.LandID) === 300 ? (
          <Tooltip content="Display all Belgian ZIP codes">
            <Button size="large" onClick={() => setZipCodeModalActive(true)}>
              ZIPS
            </Button>
          </Tooltip>
        ) : Number(company.LandID) === 468 ? (
          <Tooltip content="Information on US ZIP codes">
            <Button size="large" icon={QuestionCircleIcon} onClick={() => setUSZipCodeModalActive(true)} />
          </Tooltip>
        ) : null
      }
    />
  );

  const listBoxMarkup = cityZipOptions.map((o, idx) => (
    <Listbox.Option key={idx} value={o.value} selected={selected === o.value}>
      {o.label}
    </Listbox.Option>
  ));

  return (
    <>
      {zipCodeModalActive && <BelgiumZipModal active={zipCodeModalActive} setActive={setZipCodeModalActive} />}
      {usZipCodeModalActive && <USZipModal active={usZipCodeModalActive} setActive={setUSZipCodeModalActive} />}
      <FormLayout.Group condensed>
        <Combobox activator={cityActivator}>
          <Listbox onSelect={(e) => handleSelect(e, 'POSTNR')}>{listBoxMarkup}</Listbox>
        </Combobox>
        <Combobox activator={zipActivator}>
          <Listbox onSelect={(e) => handleSelect(e, 'WOONPL')}>{listBoxMarkup}</Listbox>
        </Combobox>
      </FormLayout.Group>
    </>
  );
};
