import { useState } from 'react';
import { openAddModal } from '../../../../shared/components/settingsFunctions';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import Toasts from '../../../../shared/components/toasts/toasts';
import { Card, DataTable, Page } from '@shopify/polaris';
import { useLookupStore } from '../../../../context/useStore';
import { useToggle } from '../../../../shared/useToogle';

export const SettingsCompanyTagsPage = () => {
  const [companytags, setCompanytags] = useLookupStore((state) => [state.companytags, state.setCompanytags]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({ omschrijving: { type: 'text', label: 'Description', max: '50', multi: false, value: '' } });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/companytags';
  const title = 'Company tags';

  const rows = companytags.map((item) => [item.omschrijving, getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.id)]);

  return (
    <Page
      title={title}
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add company tag', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setCompanytags}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable stickyHeader increasedTableDensity verticalAlign="middle" columnContentTypes={['text', 'numeric']} headings={[<b>Description</b>, <b>Edit</b>]} rows={rows} />
      </Card>
    </Page>
  );
};
