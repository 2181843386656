import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getDocPdfLinkForListViewIncoming, getRequest } from '../../../shared/components/functions';
import { useAuthStore } from '../../../context/useStore';
import { ApprovalBadges } from '../../../shared/components/badges/approvalBadges';

export default function CreditnotesInCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);
  const { user } = useAuthStore();

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/creditnotes_in?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var date_payment = item.datumbetalingout ? String(item.datumbetalingout).substring(0, 10) : null;
        var remarks = item.memo ? String(item.memo).substring(0, 20) : null;
        var status = item.bedragopen === 0 ? <Badge>Paid</Badge> : <Badge tone="warning">Not Paid</Badge>;
        return [
          <Link removeUnderline url={'/creditnotes_in/' + item.CreditnotaINvolgnummer}>
            {item.CreditnotaInNummer}
          </Link>,
          getDocPdfLinkForListViewIncoming(item, item.supplier_number, item.CreditnotaInNummer, user, 'Creditnota In', 'KI'),
          String(item.datumin).substring(0, 10),
          String(item.datumbetaling).substring(0, 10),
          String(item.datum).substring(0, 10),
          item.faktuurref,
          formatToCurrency(item.Bedragfaktuur),
          formatToCurrency(item.bedragopen),
          date_payment,
          remarks,
          status,
          <ApprovalBadges approvals={JSON.parse(item.approvals)} resource="creditnote" />
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'text', 'text', 'text', 'text']}
      headings={[
        <b>Number</b>,
        <b>PDF</b>,
        <b>Date in</b>,
        <b>Date expire</b>,
        <b>Date invoice</b>,
        <b>Reference</b>,
        <b>Value invoice</b>,
        <b>Value open</b>,
        <b>Date payment</b>,
        <b>Remarks</b>,
        <b>Status</b>,
        <b>Approvals</b>
      ]}
      rows={rows}
    />
  ) : (
    'No incoming credit notes'
  );
}
