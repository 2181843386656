import { Badge, Banner, DataTable, Link, Modal } from '@shopify/polaris';
import { CardHeading } from '../../../shared/components/cards/CardHeading';
import { formatToCurrency } from '../../../shared/components/functions';

export const DoublesModal = ({ modalOpen, setModalOpen, doubles, handleDiscard, handleSave }) => {
  return modalOpen ? (
    <Modal
      size="large"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Possible duplicates are found!"
      primaryAction={{
        content: 'Continue with saving the product',
        onAction: () => {
          setModalOpen(false);
          handleSave();
        }
      }}
      secondaryActions={[
        {
          content: 'Discard product',
          onAction: () => {
            setModalOpen(false);
            handleDiscard();
          }
        },
        {
          content: 'Go back to form and edit product',
          onAction: () => setModalOpen(false)
        }
      ]}
    >
      <Modal.Section>
        <CardHeading title="Products from the same supplier with the same or similar reference" />
        {doubles.lvlOne.length > 0 ? <DoublesTable items={doubles.lvlOne} /> : <Banner tone="success">No matches</Banner>}
      </Modal.Section>

      <Modal.Section>
        <CardHeading title="Products with the same reference" />
        {doubles.lvlTwo.length > 0 ? <DoublesTable items={doubles.lvlTwo} /> : <Banner tone="success">No matches</Banner>}
      </Modal.Section>

      <Modal.Section>
        <CardHeading title="Products with a similar reference" />
        {doubles.lvlThree.length > 0 ? <DoublesTable items={doubles.lvlThree} /> : <Banner tone="success">No matches</Banner>}
      </Modal.Section>
    </Modal>
  ) : null;
};

const DoublesTable = ({ items }) => {
  const getRows = (rows) => {
    return Array.isArray(rows)
      ? rows.map((item) => {
          return [
            <Link removeUnderline target={'_blank'} url={`/products/${item.ProductID}`}>
              {item.ProductNameNL}
            </Link>,
            item.Productref,
            <Link removeUnderline target={'_blank'} url={`/companies/${item.supplierID}`}>
              {item.supplierName}
            </Link>,
            item.Discontinued ? <Badge tone="critical">Discontinued</Badge> : <Badge tone="success">Active</Badge>,
            formatToCurrency(item.Eenheidsaankoopprijs),
            formatToCurrency(item['Eenheids verkoopprijs'])
          ];
        })
      : [];
  };

  return (
    <DataTable
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'numeric']}
      headings={[<b>Name</b>, <b>Ref</b>, <b>Supplier</b>, <b>Status</b>, <b>Buy</b>, <b>Sell</b>]}
      rows={getRows(items)}
    />
  );
};
