import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, DataTable } from '@shopify/polaris';
import { getRequest, formatDate, formatToCurrency, truncate } from '../functions';

export default function QuickViewModal({ modalOpen, setModalOpen, id, source, type, title }) {
  const navigate = useNavigate();
  const [lines, setLines] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      const response = await getRequest(`/api/${source}/${id}/lines`);
      setLines(response);
      setIsLoading(false);
    }
    if (modalOpen) fetchData();
  }, [id, source, modalOpen]);

  const content = {
    'non-financial': {
      columnContentTypes: ['number', 'text', 'number', 'number'],
      headings: [<b>Code</b>, <b>Description</b>, <b>Ordered</b>, <b>Delivered</b>],
      rows: lines.map((line) => {
        return [line.produktnr, line.tekstlijn, line.aantalbesteld, line.aantalgeleverd];
      })
    },
    'financial-simple': {
      columnContentTypes: ['number', 'number', 'text', 'currency', 'number'],
      headings: [<b>Qty</b>, <b>Code</b>, <b>Description</b>, <b>VP</b>, <b>VAT</b>],
      rows: lines.map((line) => {
        return [line.aantal, line.produktnr, line.tekstlijn, line.VP ? formatToCurrency(line.VP) : null, line.BTW ? line.BTW + '%' : null];
      })
    },
    default: {
      columnContentTypes: ['number', 'number', 'text', 'currency', 'date', 'currency', 'date', 'number'],
      headings: [<b>Qty</b>, <b>Code</b>, <b>Description</b>, <b>AP</b>, <b>Date AP</b>, <b>VP</b>, <b>Date VP</b>, <b>VAT</b>],
      rows: lines.map((line) => {
        return [
          line.aantal,
          line.produktnr,
          truncate(line.tekstlijn, 25, false),
          line.AP ? formatToCurrency(line.AP) : null,
          line['Datum bijw_AP'] ? formatDate(line['Datum bijw_AP']) : null,
          line.VP ? formatToCurrency(line.VP) : null,
          line['Datum  bijw_ VP'] ? formatDate(line['Datum  bijw_ VP']) : null,
          line.BTW ? line.BTW + '%' : null
        ];
      })
    }
  };

  return (
    <Modal
      size="large"
      loading={isLoading}
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title={title}
      primaryAction={{
        content: 'Open details',
        onAction: () => navigate(`/${source}/${id}`)
      }}
    >
      <DataTable
        stickyHeader={true}
        increasedTableDensity={true}
        verticalAlign="middle"
        columnContentTypes={content[type].columnContentTypes}
        headings={content[type].headings}
        rows={content[type].rows}
      />
    </Modal>
  );
}
