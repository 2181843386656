import React, { useState, useEffect } from 'react';
import { Badge, DataTable, Text, Card } from '@shopify/polaris';
import { CardHeading } from '../../../shared/components/cards/CardHeading';

export default function HolidaysComponent() {
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    fetch('/api/holidays/upcoming')
      .then((response) => response.json())
      .then((data) => {
        setHolidays(data);
      });
  }, []);

  const rows_holiday = holidays.map((item) => {
    var holiday_type =
      item.Brugdag === false && item.RecupFDDag === false ? <Badge>Normal</Badge> : item.Brugdag === true ? <Badge tone="info">Arbeidsduurverkorting</Badge> : <Badge tone="warning">RecupFD</Badge>;
    return [String(item.Datum).substring(0, 10), item.Omschrijving, holiday_type];
  });

  return (
    <Card>
      <CardHeading title="Upcoming official holidays" />
      <Text tone="subdued">Listed holidays are automatically added to the technicians application, so please keep it up to date by adding them at the beginning of the year.</Text>
      <DataTable stickyHeader={true} increasedTableDensity={true} columnContentTypes={['text', 'text', 'text']} headings={['Date', 'Holiday', 'Type']} rows={rows_holiday} />
    </Card>
  );
}
