import { Popover, Button } from '@shopify/polaris';
import React, { useState, useCallback } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
import ProductSelect from './productSelect';

export default function ProductSearcherPopover({ value, handleChange, disabled }) {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const activator = <Button size="large" disabled={disabled} icon={SearchIcon} onClick={togglePopoverActive} disclosure />;

  return (
    <Popover active={popoverActive} onClose={togglePopoverActive} sectioned activator={activator} preferredPosition="above" preferredAlignment="left">
      <div style={{ width: '370px' }}>
        <ProductSelect value={value} onChange={handleChange} isDisabled={disabled} />
      </div>
    </Popover>
  );
}
