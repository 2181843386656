import { TextField, Select, Button, Tooltip } from '@shopify/polaris';
import { getCountryCodeForRegionCode, parsePhoneNumber, getNumberFrom } from 'awesome-phonenumber';
import React, { useEffect, useState } from 'react';
import { useLookupStore } from '../../../context/useStore';
import PhoneFormattedExamplesComponent from './phoneFormattedExamplesComponent';

export default function CustomPhoneInput({ value, id, onChange, label, countryIso2 = 'BE', disabled }) {
  const { countries } = useLookupStore();
  /*
        Custom phone input that validates the input for a given country      
        
        FIXME:
        - country switcher is disabled as we dont have persistance fields in the database, when this is the case, we can enable the country switcher again
  */
  const [selectedCountry, setSelectedCountry] = useState(countryIso2);
  const [phoneExamplesModalActive, setPhoneExamplesModalActive] = useState(false);

  useEffect(() => {
    setSelectedCountry(countryIso2);
  }, [countryIso2]);

  const code = getCountryCodeForRegionCode(selectedCountry);

  let helptext = null;
  let error = null;

  const validate_number = (phone) => {
    if (code !== 0 && phone && String(phone).length > 0 && phone !== null) {
      let pn = parsePhoneNumber(String(phone), { regionCode: String(selectedCountry) });
      if (pn.valid) {
        helptext = `Valid ${selectedCountry} ${pn.type} (${getNumberFrom(pn, 'international').number})`;
      } else {
        error = `Not a valid ${selectedCountry} number`;
      }
    } else {
      helptext = null;
    }
  };

  validate_number(value);

  const formatNumber = () => {
    const pn = parsePhoneNumber(String(value), { regionCode: String(selectedCountry) });
    if (pn.valid) {
      const formatted = getNumberFrom(pn, 'international');
      if (formatted.valid) onChange(formatted.number, id);
    }
  };

  return (
    <>
      <PhoneFormattedExamplesComponent active={phoneExamplesModalActive} country={selectedCountry} toggleModal={setPhoneExamplesModalActive} />
      <TextField
        id={id}
        labelAction={
          code === 0
            ? null
            : {
                content: `Formatted ${selectedCountry} examples`,
                onAction: () => setPhoneExamplesModalActive(true)
              }
        }
        error={value ? error : ''}
        maxLength={30}
        showCharacterCount
        type="tel"
        helpText={helptext}
        autoComplete="off"
        label={label}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        connectedLeft={
          <Select
            value={selectedCountry}
            disabled
            label="Country"
            onChange={(e) => setSelectedCountry(e)}
            labelHidden
            options={countries.map((country) => ({ value: country.ISO2, key: country.LandId, label: country.ISO2 }))}
          />
        }
        connectedRight={
          helptext ? (
            <Tooltip content={`Format number to internationally callable number`}>
              <Button size="large" disabled={disabled} onClick={formatNumber}>
                {'✔️'}
              </Button>
            </Tooltip>
          ) : null
        }
      />
    </>
  );
}
