import { Button, DataTable, Modal, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { getUserOrg, postRequest, putRequest } from '../../../shared/components/functions';
import { useAuthStore, useLookupStore } from '../../../context/useStore';

export const UpdateStockModal = ({ lines, savingResult, deliverynoteNumber, setToast, setToastError }) => {
  const { user } = useAuthStore();
  const organisations = useLookupStore((state) => state.organisations);
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const [active, setActive] = useState(false);
  const [stockChanges, setStockChanges] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (active) setStockChanges(lines.filter((l) => l.ProductID > 49).map((l) => ({ id: l.ProductID, value: l.aantalbesteld })));
  }, [active]);

  const activator = (
    <Button disabled={savingResult} onClick={() => setActive(true)}>
      Update product inventories
    </Button>
  );

  const handleChange = (id, value) => {
    const itemToUpdate = stockChanges.findIndex((s) => s.id == id);
    const stockChangesCopy = [...stockChanges];
    stockChangesCopy[itemToUpdate] = { id: id, value: value || '' };
    setStockChanges(stockChangesCopy);
  };

  const rows = Array.isArray(lines)
    ? lines
        .filter((l) => l.ProductID > 49)
        .map((l) => [
          l.ProductID,
          l.tekstlijn,
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div style={{ width: '130px' }}>
              <TextField
                label=""
                type="number"
                value={stockChanges.find((s) => s.id === l.ProductID)?.value}
                onChange={(e) => handleChange(l.ProductID, e)}
                autoComplete="off"
                error={stockChanges.find((s) => s.id === l.ProductID)?.value < 0 ? 'Must be positive' : ''}
              />
            </div>
          </div>
        ])
    : [];

  const handleSave = async () => {
    if (stockChanges.filter((s) => parseFloat(s.value) < 0).length > 0) return;

    setLoading(true);
    const stockChangesFiltered = stockChanges.filter((s) => parseFloat(s.value) !== 0);

    for (let i = 0; i < stockChangesFiltered.length; i++) {
      const line = stockChangesFiltered[i];
      await putRequest(`/api/inventory/update/${line.id}/1`, { substraction: line.value }, setToast, setToastError);

      if (userOrg === 2) {
        const payload = {
          productId: line.id,
          change: -Math.abs(line.value),
          date: new Date(),
          source: `Delivery note #${deliverynoteNumber}`,
          stockchangeTypeId: 8
        };
        await postRequest(`/api/stockchanges`, payload);
      }
    }
    setLoading(false);
    setActive(false);
  };

  return (
    <Modal
      size="large"
      activator={activator}
      open={active}
      onClose={() => setActive(false)}
      title="Update stock"
      primaryAction={{ content: 'Save', onAction: handleSave, loading: loading }}
      secondaryActions={[{ content: 'Close', onAction: () => setActive(false) }]}
    >
      <Modal.Section>
        <DataTable verticalAlign="middle" increasedTableDensity columnContentTypes={['text', 'text', 'numeric']} headings={['Product', 'Description', 'Amount to substract']} rows={rows} />
      </Modal.Section>
    </Modal>
  );
};
