export function PageError({ error }) {
  return (
    <div style={{ minHeight: '100vh', paddingTop: '30vh', fontSize: '30px', textAlign: 'center', backgroundColor: '#213440' }}>
      <p style={{ width: '100%', fontSize: '90px', paddingBottom: '10rem', fontWeight: 800, color: 'white' }}>Oops!</p>
      <p style={{ width: '100%', fontSize: '60px', paddingBottom: '2rem', fontWeight: 600, color: '#DBB646', lineHeight: '60px' }}>Something went wrong.</p>
      <p style={{ width: '100%', fontSize: '21px', paddingBottom: '10rem', fontWeight: 600, color: 'gray', lineHeight: '27px' }}>Please let us know how you broke it by submitting the crash report.</p>
      <a style={{ width: '100%', fontSize: '40px', paddingBottom: '10rem', fontWeight: 600, color: 'gray' }} href="/home">
        Go Home 🏠
      </a>
    </div>
  );
}
