import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Banner, Button, Box, BlockStack } from '@shopify/polaris';
import { alphabetize, getRequest } from '../../shared/components/functions.jsx';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import { useLookupStore } from '../../context/useStore.js';
import { useUrl, handleSaveWithoutLines } from '../../shared/util/hanldesave.js';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import ProjectSelect from '../../shared/components/projectSelect.jsx';
import CompanySelect from '../../shared/components/companySelect.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';

export default function MachinePage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const { machinebrands, agreementtypes } = useLookupStore();
  const [savingResult, setSavingResult] = useState(false);
  const [initialMachine, setInitialMachine] = useState('');
  const [machine, setMachine] = useState('');
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const pageTitle = getPageTitle(machine.counter, 'Machine');
  const machineSchema = getSchema('machine', url);

  const handleChange = (value, id) => {
    if (id === 'indienst' && (!value || value === '')) value = null;
    if (id === 'uitdienst' && (!value || value === '')) value = null;
    setMachine(
      (machine) => ({
        ...machine,
        [id]: value
      }),
      setIsDirty(true)
    );
  };

  const handleFormSubmit = async () => {
    setSavingResult(true);
    const errorInSchema = await validateSchema(machineSchema, machine);
    if (!errorInSchema) {
      setSavingResult(true);
      await handleSaveWithoutLines(
        id,
        machineSchema.cast(machine),
        'machines',
        machine.counter,
        'counter',
        setMachine,
        setInitialMachine,
        setIsDirty,
        setErrorToast,
        setToast,
        setSavingResult,
        navigate
      );
    } else {
      alert(errorInSchema);
    }
    setSavingResult(false);
  };

  const handleDiscard = () => {
    setMachine(initialMachine);
    setIsDirty(false);
  };

  useEffect(() => {
    async function fetchData() {
      //if url contains /new then set machine to its interface
      if (id === 'new') {
        setMachine(machineSchema.default());
      } else {
        //else get the machine resource via api get request
        const machine = await getRequest(`/api/machines/${id}`);
        if (!machine) return setMachine(machineSchema.default());
        setMachine(machine);
        setInitialMachine(machine);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  var discontinued_banner = machine.discontinued ? (
    <Box paddingBlockEnd={'400'}>
      <Banner tone="critical" title="This machine is discontinued!" />
    </Box>
  ) : null;

  return (
    <Page title={pageTitle} fullWidth primaryAction={{ content: 'New machine', url: '/machines/new' }}>
      <TabTitle title={pageTitle} />
      <TheSaveBar isDirty={isDirty} savingResult={savingResult} handleSave={handleFormSubmit} handleDiscard={handleDiscard} />
      {discontinued_banner}
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} toastContent={'Machine saved'} />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={() => handleFormSubmit()}>
              <FormLayout>
                <CompanySelect isClearable={false} label="Company" id="Firmaid" value={machine.Firmaid} onChange={handleChange} isDisabled={savingResult} />
                <Select
                  id="machinemerk"
                  label="Brand"
                  value={parseInt(machine.machinemerk) || ''}
                  options={machinebrands
                    .sort((a, b) => alphabetize(a, b, 'merk'))
                    .map((brand) => {
                      return { label: brand.merk, value: brand.counter };
                    })}
                  onChange={handleChange}
                  placeholder="Select a brand"
                  disabled={savingResult}
                />
                <TextField id="Machinetype" maxLength={50} showCharacterCount autoComplete="off" label="Type" value={machine.Machinetype || ''} onChange={handleChange} disabled={savingResult} />
                <TextField
                  id="Serienummer"
                  maxLength={50}
                  showCharacterCount
                  autoComplete="off"
                  label="Serial Number"
                  value={machine.Serienummer || ''}
                  onChange={handleChange}
                  disabled={savingResult}
                />
                <FormLayout.Group>
                  <TextField id="bouwjaar" maxLength={60} type="number" autoComplete="off" label="Construction year" value={String(machine.bouwjaar)} onChange={handleChange} disabled={savingResult} />
                  <TextField
                    id="indienst"
                    maxLength={60}
                    type="date"
                    autoComplete="off"
                    label="In service"
                    value={String(machine.indienst).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                  <TextField
                    id="uitdienst"
                    maxLength={60}
                    disabled={savingResult || !machine.discontinued}
                    type="date"
                    autoComplete="off"
                    label="Out of service"
                    value={String(machine.uitdienst).substring(0, 10)}
                    onChange={handleChange}
                  />
                </FormLayout.Group>
                <Select
                  id="OvereenkomstTypeID"
                  label="Agreement type"
                  value={parseInt(machine.OvereenkomstTypeID) || ''}
                  options={agreementtypes.map((type) => {
                    return { label: type.OvereenkomstType, value: type.OvereenkomstTypeID };
                  })}
                  onChange={handleChange}
                  disabled={savingResult}
                />
                <ProjectSelect value={machine.ProjectID} onChange={handleChange} id="ProjectID" label="Project" isDisabled={savingResult} />
                <TextField id="opmerking" label="Note" value={machine.opmerking || ''} multiline={10} onChange={handleChange} disabled={savingResult} />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Status" />
              <Form>
                <FormLayout>
                  <Checkbox id="discontinued" label="Machine discontinued" checked={machine.discontinued} onChange={handleChange} disabled={savingResult} />
                </FormLayout>
              </Form>
            </Card>
            {id !== 'new' && (
              <Card>
                <CardHeading title="Maintenance contract" />
                <Button
                  id="MaintenanceContractButton"
                  disabled={savingResult}
                  onClick={() => {
                    navigate(
                      `/maintenance_contracts/new?Firnr=${machine.Firmaid}&Machinemerk=${machine.machinemerk}${machine.Machinetype ? `&Type=${machine.Machinetype}` : ''}&isDirty=true${
                        machine.Serienummer ? `&serienummer=${machine.Serienummer}` : ''
                      }`
                    );
                  }}
                >
                  Make maintenance contract for this machine
                </Button>
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
