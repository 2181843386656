import React from 'react';
import { combinedSchema } from './schemas';
import { Formik, Form } from 'formik';
import { Container, Stack, Heading, Box, useDisclosure, Flex, Spacer, Collapse, Button } from '@chakra-ui/react';
import { languagesEN, titlesEN } from '../../../shared/components/functions';
import { useLookupStore } from '../../../context/useStore';
import { TextInput, SelectInput, TextareaInput, CheckboxInput } from './forms';

export default function CombinedForm({ SubmitButton, handleSubmit }) {
  const { contacttitels, countries, languages } = useLookupStore();
  const langEN = languagesEN(languages);
  const titles = titlesEN(contacttitels);

  const { isOpen, onToggle } = useDisclosure();

  const allValues = {
    companyNAAM: '',
    STRAAT_NR: '',
    WOONPL: '',
    POSTNR: '',
    companyLandID: window.location.href.includes('perfectmoose') ? '468' : '300',
    companyTaalID: '3',
    personeelsbestand: '',
    companyGSM: '',
    companyTEL: '',
    'web-site': '',
    companyEmail: '',
    companyOPMERKING: '',
    KlantStatusID: '5',
    NAAM: '',
    DEPT: '',
    AanspreektitelID: '1',
    TaalID: '3',
    'E-mail': '',
    GSM: '',
    TEL: '',
    OPMERKING: '',
    KLANR: '',
    interestedIn: []
  };

  return (
    <Formik initialValues={allValues} validationSchema={combinedSchema} onSubmit={(values) => handleSubmit(values)}>
      {(formikProps) => (
        <Form>
          <Container py={{ base: '4', md: '8' }} px={{ base: '0', md: '0' }}>
            <Stack spacing="5">
              <Heading size="xl">General information</Heading>
            </Stack>

            <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between">
              <Box bg="bg-surface" borderRadius="lg" flex="1">
                <Stack spacing="5" py={{ base: '5', md: '6' }}>
                  <TextInput isRequired aria-required="true" autoFocus label="Your name (first and last name)" name="NAAM" type="text" />
                  <TextInput isRequired aria-required="true" label="E-Mail" name="E-mail" type="text" />
                  <SelectInput
                    isRequired
                    aria-required="true"
                    label="Country"
                    name="companyLandID"
                    as="select"
                    options={countries.map((count) => {
                      <option key={count.LandId} value={count.LandId}>
                        {count.Land}
                      </option>;
                    })}
                  />
                  <TextInput label="Website" name="web-site" type="text" />

                  <CheckboxInput
                    label="Interested in"
                    name="interestedIn"
                    handleChange={(e) => {
                      if (e.target.checked) {
                        formikProps.setFieldValue('interestedIn', [...formikProps.values.interestedIn, e.target.id]);
                      } else {
                        formikProps.setFieldValue(
                          'interestedIn',
                          formikProps.values.interestedIn.filter((i) => i !== e.target.id)
                        );
                      }
                    }}
                  />

                  <TextareaInput label="Note" name="OPMERKING" type="text" />
                </Stack>
              </Box>
            </Stack>
          </Container>

          <Container py={{ base: '4', md: '12' }} px={{ base: '0', md: '0' }}>
            <Flex spacing="5">
              <Heading size="xl">{isOpen ? '' : 'Some more information'}</Heading>
              <Spacer />
              <Button onClick={onToggle} color="black">
                <div style={{ transform: `rotate(${isOpen ? '270' : '90'}deg)` }}>▶️</div>
              </Button>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
              <Container py={{ base: '4', md: '8' }} px={{ base: '0', md: '0' }}>
                <Stack spacing="5">
                  <Heading>Some information about your company</Heading>
                </Stack>

                <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between">
                  <Box bg="bg-surface" borderRadius="lg" flex="1">
                    <Stack spacing="5" py={{ base: '5', md: '6' }}>
                      <TextInput label="Street and number" name="STRAAT_NR" type="text" />
                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <TextInput label="City" name="WOONPL" type="text" />
                        <TextInput label="ZIP" name="POSTNR" type="text" />
                      </Stack>

                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <TextInput label="Workforce" name="personeelsbestand" type="text" />
                      </Stack>
                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <TextInput label="Phone" name="companyGSM" type="text" />
                        <TextInput label="Mobile phone" name="companyTEL" type="text" />
                      </Stack>

                      <Stack>
                        <TextInput label="E-Mail" name="companyEmail" type="text" />
                      </Stack>
                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <TextareaInput label="Note (please let us know your interests or questions)" name="companyOPMERKING" type="text" />
                      </Stack>
                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}></Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Container>

              <Container py={{ base: '4', md: '8' }} px={{ base: '0', md: '0' }}>
                <Stack spacing="5">
                  <Heading>Some information about you</Heading>
                </Stack>

                <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between">
                  <Box bg="bg-surface" borderRadius="lg" flex="1">
                    <Stack spacing="5" py={{ base: '5', md: '6' }}>
                      <TextInput label="Function" name="DEPT" type="text" />
                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <SelectInput label="Title" name="AanspreektitelID" as="select" options={titles} />
                        <SelectInput label="Language" name="TaalID" as="select" options={langEN} />
                      </Stack>

                      <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <TextInput label="Phone" name="GSM" type="text" />
                        <TextInput label="Mobile phone" name="TEL" type="text" />
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Container>
            </Collapse>
          </Container>

          {SubmitButton}
        </Form>
      )}
    </Formik>
  );
}
