import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, DataTable, Page, Card, Link, Badge } from '@shopify/polaris';
import { getTableFooter, truncate, fetchTableData } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function MontageBonsPage() {
  const url = useUrl();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({ q: { type: 'text', name: 'Q', value: url.get('q') || '' } });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/montage_bons', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => [
        <Link removeUnderline url={'/montage_bons/' + item.Montagebriefnummer}>
          {item.Montagebriefnummer}
        </Link>,
        <Link removeUnderline url={'/companies/' + item.FIRNR}>
          {truncate(item.Firmanaam, 25, false)}
        </Link>,
        String(item.datum).substring(0, 10),
        item.Datum1 ? String(item.Datum1).substring(0, 10) : '',
        item.Merk,
        item.Type,
        item.Serie,
        item.Monteur,
        truncate(String(item.Opmerkingen).substring(0, 50), 20, false),
        item.Verstuurd ? <Badge tone="success">Sent</Badge> : <Badge tone="critical">Not sent</Badge>
      ])
    : [];

  return (
    <Page title="Montagebons" fullWidth>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter montagebons on brand, type, serie or technician"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        />
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={[<b>Number</b>, <b>Customer</b>, <b>Date</b>, <b>Tussenkomst</b>, <b>Brand</b>, <b>Type</b>, <b>Serie</b>, <b>Technician</b>, <b>Notes</b>, <b>PDF mailed</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'montage_bons')}
        />
      </Card>
    </Page>
  );
}
