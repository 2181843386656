import { Box, InlineStack } from '@shopify/polaris';
import { useAuthStore, useLookupStore } from '../../context/useStore';
import Select from 'react-select';
import { getUserOrg } from './functions';
import { getUserOptions } from './listFilter';

export default function OwnerSelect({ id, value, handleChange, disabled, label }) {
  const users = useLookupStore((state) => state.users);
  const organisations = useLookupStore((state) => state.organisations);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  return (
    <Box width="100%">
      <Box paddingBlockEnd="100">
        <InlineStack align="space-between">
          {label && (
            <Box>
              <p style={{ color: 'rgba(181, 181, 181, 1)' }}>{label}</p>
            </Box>
          )}
        </InlineStack>
      </Box>

      <Select
        id={id}
        placeholder=""
        options={filteredUsers}
        onChange={(e) => handleChange(e.value, id)}
        value={filteredUsers.find((c) => parseInt(c.value) === parseInt(value)) || ''}
        isDisabled={true || disabled}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          // control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: disabled ? '#ebebeb' : '#919191' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#ebebeb' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
      />
    </Box>
  );
}
