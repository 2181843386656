import { Pagination, Button, BlockStack } from '@shopify/polaris';
import { getSharepointSystemDocUrl } from '../../utils/sharepoint';
import { contactSchema, companySchema } from '../../pages/fairs/helpers/schemas';
import { MaximizeIcon } from '@shopify/polaris-icons';

function getUserOrg(organisations, userOrgName) {
  return organisations?.find((o) => o.Naam.toLowerCase().includes(userOrgName.toLowerCase()))?.VennootschapID;
}

function sum(a, b) {
  return a + b;
}

var currencyFormatter = new Intl.NumberFormat('nl-BE', { style: 'currency', currency: 'EUR' });

function formatToCurrency(value) {
  return currencyFormatter.format(value);
}

var percentageFormatter = new Intl.NumberFormat('nl-BE', { style: 'percent' });

function formatToPercent(value) {
  return percentageFormatter.format(value);
}

function roundToTwoDecimalPlaces(number) {
  return parseFloat((Math.round(number * 100) / 100).toFixed(2));
}

//ROUNDING WEIGHT
var weightFormatter = new Intl.NumberFormat('nl-BE', { style: 'unit', unit: 'kilogram', minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const formatRoundedWeight = (weight) => {
  if (!weight && weight === null) weight = 0;
  return weightFormatter.format(weight);
};

function formatDate(date) {
  //formats date to YYYY-MM-DD
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

function formatDateTime(d) {
  //formats date to YYYY-MM-DD HH:MM:SS:00
  return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join('-') + ' ' + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(':');
}

function formatBriefDate(date) {
  //formats date to DD/MM/YYYY
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

function getCurrenctDatetimeYYYMMDD() {
  const yourDate = new Date();
  let date = yourDate.toISOString().split('T')[0];
  date = date += ` ${yourDate.toLocaleTimeString()}`; //sets the date to locale time of the browser

  return date;
}

function filtersToApiUrl(filters) {
  let filterKeys = Object.keys(filters);
  return filterKeys
    .map((key) => {
      const { value, type } = filters[key];
      if (type === 'status') {
        return String(value) === 'all' ? '' : `${key}=${value}`;
      } else if (type === 'multiple' && value !== null && value.length > 0) {
        return `${key}=${JSON.stringify(value)}`;
      } else if (type === 'range' && value !== null && (Number(value[0]) || value[0] === 0) && Number(value[1])) {
        return `${key}=[${value}]`;
      } else if (type === 'date' && value !== '') {
        return `${key}=${value}`;
      } else if (type === 'text' && value !== '') {
        return `${key}=${encodeURIComponent(value).replace(/_/g, '[_]')}`;
      } else if (type === 'none' && value !== null) {
        return `${key}=${value}`;
      }
      return '';
    })
    .filter(Boolean)
    .join('&');
}

const fetchTableData = async (page, baseurl, setItems, filters, abortController, setFilterLoading, limit = '100') => {
  setFilterLoading(true);
  try {
    const response = await fetch(`${baseurl}?page=${page}&limit=${limit}&${filtersToApiUrl(filters)}`, { signal: abortController.signal });
    const newData = await response.json();
    setItems(newData);
  } catch (error) {
    if (error.name === 'AbortError') return;
  }
  setFilterLoading(false);
};

function getTableFooter(rows, page, setPage, pluralResourceName) {
  if (rows.length > 0) {
    return (
      <>
        <div style={{ marginBottom: '10px' }}>
          Displaying {rows.length} {pluralResourceName}
        </div>
        <BlockStack inlineAlign="center">
          <Pagination
            hasPrevious={page > 1}
            onPrevious={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            hasNext={rows.length > 99}
            onNext={() => {
              setPage(page + 1);
            }}
          />
        </BlockStack>
      </>
    );
  } else {
    return `No ${pluralResourceName} found`;
  }
}
function stripHtml(html) {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

function formatHtml(html) {
  return stripHtml(
    html
      .replace(/<br\s*\/?>/gi, '\n')
      .replace(/<\s*\/?\s*p\s*>/gi, '</p>\n')
      .replace('&#65279;', '\n')
  );
}

async function deleteRequest(url) {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    return data;
  }
}

async function putRequest(url, payload, setToast = console.log, setErrorToast = console.log) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast(true);
    return data;
  } else {
    return setErrorToast(true);
  }
}

async function postRequest(url, payload, setToast = console.log, setErrorToast = console.log) {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast(true);
    return data;
  } else {
    return setErrorToast(true);
  }
}

async function simplePostRequest(url, payload) {
  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  });
}

async function getRequest(url) {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(`Error at getRequest fetching ${url}`, err);
    return;
  }
}

function truncate(str, n, useWordBoundary) {
  if (str == null) return '';
  if (str.length <= n) return str;
  const subString = str.substr(0, n - 1); // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const countryCodeToFlag = (isoCode) => {
  return isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
};

const TAV = {
  1: 'Tav. de boekhouding',
  2: 'Attn. de la comptabilité',
  3: 'Attn. accounting department',
  4: 'z.Hd. der Buchhaltung'
};

const getEndOfMonthDays = (date, days) => {
  //renturns the date for the last day of the month
  //for a given days that needs to be added to the date
  let result = new Date(date);
  let datePlusDays = new Date(date.setDate(date.getDate() + days));
  //console.log('datePlusDays:: ', datePlusDays);

  result = new Date(datePlusDays.getFullYear(), datePlusDays.getMonth() + 1, 0, 10, 0, 0);
  //console.log('result:: ', result);
  return result;
};

const getExpiryDate = (paymentID, date) => {
  //hardcoded function that is taken straight out of VBA access
  //this calculates an expiry date for a given payment ID and a given start date

  const dateStart = new Date(date);
  let result = new Date(dateStart);
  result = new Date(dateStart.setDate(dateStart.getDate()));

  switch (parseInt(paymentID)) {
    case 0:
      result = new Date(dateStart.setDate(dateStart.getDate()));
      break;
    case 1:
    case 11:
    case 15:
    case 16:
    case 17:
    case 18:
      //contant
      result = new Date(dateStart.setDate(dateStart.getDate() + 7));
      break;
    case 2:
    case 7:
    case 14:
      //30 days invoice date
      result = new Date(dateStart.setDate(dateStart.getDate() + 30));
      break;
    case 3:
      //30 days end of month
      result = getEndOfMonthDays(dateStart, 30); //fix
      break;
    case 4:
      //60 days end of month
      result = getEndOfMonthDays(dateStart, 60); //fix
      break;
    case 5:
      //60 days invoice date
      result = new Date(dateStart.setDate(dateStart.getDate() + 60));
      break;
    case 6:
      //contant before delivery
      result = new Date(dateStart.setDate(dateStart.getDate() + 7));
      break;
    case 8:
      //90 days invoice date
      result = new Date(dateStart.setDate(dateStart.getDate() + 90));
      break;
    case 12:
      //90 days end of month
      result = getEndOfMonthDays(dateStart, 90); //fix
      break;
    case 19:
      //14 days invoice date
      result = new Date(dateStart.setDate(dateStart.getDate() + 14));
      break;
    default:
      return undefined;
  }

  return result.toISOString().split('T')[0];
};

const getBTWVerlegdID = (CountryID, inEU) => {
  //hardcoded function that follows logic out of VBA access
  return CountryID !== null ? (CountryID !== 300 ? (inEU ? 4 : 2) : null) : null;
};

const alphabetize = (a, b, key) => {
  return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
};

const getDocPdfLinkForListView = (item, docType, companyNumber, itemNumber) => {
  return item.pdfCount > 0 ? (
    <Button size="slim" variant="plain" onClick={() => window.open(getSharepointSystemDocUrl(companyNumber, docType, itemNumber, item.TaalID))}>
      PDF
    </Button>
  ) : (
    ''
  );
};

const getDocPdfLinkForListViewIncoming = (item, companyNumber, itemNumber, user, doctype, abbr) => {
  return item.pdfCount > 0 ? (
    <Button
      variant="plain"
      size="micro"
      onClick={() =>
        window.open(
          `https://schuilenburg.sharepoint.com/${user.SHAREPOINT_DOCUMENT_LIBRARY_NAME}/${pad_with_zeroes(companyNumber, 5)}/${doctype}/${itemNumber}/${
            user.ACCOUNTANT_FILES_PREFIX
          }-${abbr}${itemNumber}.pdf`
        )
      }
    >
      PDF
    </Button>
  ) : (
    ''
  );
};

export function pad_with_zeroes(number, length) {
  var my_string = '' + number;
  while (my_string.length < length) {
    my_string = '0' + my_string;
  }
  return my_string;
}

//things for the fairs app
const addContact = async (values, pubs, setIsLoading, toast, setIsDisabled, onOpen, location) => {
  setIsLoading(true);

  if (values.interestedIn.length > 0) {
    const addToNote = `${values.OPMERKING.length > 0 ? '\n----\n' : ''}Customer interested in: ${values.interestedIn.join(', ')}`;
    values.OPMERKING = values.OPMERKING.concat(addToNote);
  }

  const response = await fetch(`/api/contacts`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',

    body: JSON.stringify({
      ...contactSchema,
      NAAM: values.NAAM,
      DEPT: values.DEPT,
      AanspreektitelID: values.AanspreektitelID,
      TaalID: values.TaalID,
      'E-mail': values['E-mail'],
      GSM: values.GSM,
      TEL: values.TEL,
      OPMERKING: values.OPMERKING,
      KLANR: values.KLANR
    })
  });
  if (response.status >= 200 && response.status <= 399) {
    let resultJson = await response.json();
    var pubResponse = await fetch(`/api/contacts/${resultJson.CONNR}/pubs`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(pubs.map((pub) => ({ value: pub })))
    });
    if (pubResponse.status >= 200 && pubResponse.status <= 399) {
      console.log('all good', pubResponse);
    } else {
      console.log('pub error', pubResponse);

      toast({
        title: 'Error',
        description: 'Please try again',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
    setIsLoading(false);
    setIsDisabled(true);
    onOpen();
    setTimeout(() => {
      window.location.href = `/fairs/form${location.search}`;
    }, 7000);
  } else if (!response.ok) {
    console.log('contact error', response);
    toast({
      title: 'Error',
      description: 'Please try again',
      status: 'error',
      duration: 9000,
      isClosable: true
    });
  }
};

const addCompany = async (values, pubs, setIsLoading, toast, setIsDisabled, onOpen, location) => {
  setIsLoading(true);

  const companyResult = await fetch(`/api/companies`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({
      ...companySchema,
      NAAM: values.companyNAAM,
      STRAAT_NR: values.STRAAT_NR,
      WOONPL: values.WOONPL,
      POSTNR: values.POSTNR,
      LandID: values.companyLandID,
      TaalID: values.companyTaalID,
      personeelsbestand: values.personeelsbestand,
      GSM: values.companyGSM,
      TEL: values.companyTEL,
      'web-site': values['web-site'],
      'E-mail': values.companyEmail,
      OPMERKING: values.companyOPMERKING,
      KlantStatusID: 5,
      Klant: true,
      betalingID: 1
    })
  });
  if (companyResult.status >= 200 && companyResult.status <= 399) {
    let resultJson = await companyResult.json();
    addContact({ ...values, KLANR: resultJson.FIRNR }, pubs, setIsLoading, toast, setIsDisabled, onOpen, location);
  } else {
    toast({
      title: 'Error',
      description: 'Please try again',
      status: 'error',
      duration: 9000,
      isClosable: true
    });
    console.log('company error', companyResult);
  }
};

function pubsToApiUrl(pubs) {
  let pubKeys = Object.keys(pubs);
  let pubsUrl = pubKeys.map((key) => pubs[key].value);
  return `pubcodes=${JSON.stringify(pubsUrl)}`;
}

function languagesEN(langs) {
  const langEN = langs.map((lang) => {
    switch (lang.TaalID) {
      case 1:
        return (
          <option key="1" value="1">
            Dutch
          </option>
        );
      case 2:
        return (
          <option key="2" value="2">
            French
          </option>
        );
      case 3:
        return (
          <option key="3" value="3">
            English
          </option>
        );
      case 4:
        return (
          <option key="4" value="4">
            German
          </option>
        );
      default:
        return null;
    }
  });

  return langEN;
}

function titlesEN(titles) {
  const selectedTitlesEN = titles
    .filter((title) => title.AanspreektitelUK !== 'Doctor' && title.AanspreektitelUK !== 'Engineer')
    .map((title) => (
      <option key={title.AanspreektitelID} value={title.AanspreektitelID}>
        {title.AanspreektitelUK}
      </option>
    ));
  return selectedTitlesEN;
}

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 14;
  var years = [];
  for (var i = max; i >= min; i--) {
    years.push({ label: i.toString(), value: i });
  }
  return years;
}

function calcDateDiff(date1, date2) {
  let d1 = new Date(date1).getTime();
  let d2 = new Date(date2).getTime();
  return Math.floor((d2 - d1) / (24 * 3600 * 1000));
}

function getModalButton(itemID, itemNumber, setModalOpen, setSelectedItem) {
  return (
    <div style={{ margin: '-4px 0' }}>
      <Button
        size="micro"
        onClick={() => {
          setModalOpen(true);
          setSelectedItem({ id: itemID, number: itemNumber });
        }}
        icon={MaximizeIcon}
      />
    </div>
  );
}

async function companyWebRequest(input, details) {
  const url = details ? 'companyweb_details' : 'companyweb';
  const body = details ? { VatNumber: input } : { SearchTerm: input.companyName, FilterPostalCode: input.zip };
  const response = await fetch(`/api/companies/${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
  let data = '';
  if (response.ok) {
    const text = await response.text();
    data = JSON.parse(text);
  }
  return data;
}

function getScoreImg(info, score) {
  //info: "stop", "failliet", "new", "score" or "blanco"
  let png;
  if (info === 'score') {
    let scorePng = (score / 10).toFixed(1).replace('.', '');
    scorePng === '0' ? (png = 'failliet') : scorePng[0] === '-' ? (png = `neg${scorePng}`) : (png = `pos-${scorePng}`);
    if (png === 'pos-00') png = 'zero';
  } else if (info === 'bankrupt') {
    png = 'failliet';
  } else {
    png = `${info}`;
  }
  return png;
}

function convertDocumentTZ(inputTimeString) {
  const inputDate = new Date(inputTimeString);
  inputDate.setUTCHours(inputDate.getUTCHours() - 1);
  return inputDate.toISOString();
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function isObjectEmpty(object) {
  return Object.keys(object).length === 0;
}

const calculateTimeDifference = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  let difference = end - start;
  let hours = Math.floor(difference / (1000 * 60 * 60));
  let minutes = Math.floor((difference / (1000 * 60)) % 60);
  let result = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
  return result;
};

const getTimeSum = (timeArr) => {
  let totalMinutes = timeArr.reduce((acc, time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return acc + hours * 60 + minutes;
  }, 0);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export {
  alphabetize,
  sum,
  formatDate,
  formatDateTime,
  formatBriefDate,
  capitalizeFirstLetter,
  formatToCurrency,
  formatToPercent,
  getCurrenctDatetimeYYYMMDD,
  filtersToApiUrl,
  getTableFooter,
  stripHtml,
  formatHtml,
  deleteRequest,
  putRequest,
  postRequest,
  simplePostRequest,
  truncate,
  countryCodeToFlag,
  TAV,
  getExpiryDate,
  getBTWVerlegdID,
  getRequest,
  getDocPdfLinkForListView,
  addCompany,
  pubsToApiUrl,
  addContact,
  languagesEN,
  titlesEN,
  generateArrayOfYears,
  calcDateDiff,
  getModalButton,
  companyWebRequest,
  getScoreImg,
  getUserOrg,
  convertDocumentTZ,
  getDocPdfLinkForListViewIncoming,
  addDays,
  isObjectEmpty,
  fetchTableData,
  calculateTimeDifference,
  getTimeSum,
  roundToTwoDecimalPlaces
};
