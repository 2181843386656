export const selectStyles = {
  valueContainer: (base) => ({ ...base, height: '31px', borderRadius: '8px 0 0 8px' }),
  indicatorSeperator: (base) => ({ ...base, height: '32px' }),
  indicatorsContainer: (base) => ({ ...base, height: '32px', borderRadius: '0 8px 8px 0' }),
  input: (base) => ({ ...base }), //'&:hover': { backgroundColor: 'rgba(247, 247, 247, 1)' }
  menu: (base) => ({ ...base, zIndex: 9999 }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menuList: (base) => ({ ...base, zIndex: 9999 }),
  control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' })
};
