import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import { truncate } from '../../../../shared/components/functions.jsx';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsVatShiftsPage() {
  const [vatshifts, setVatshifts] = useLookupStore((state) => [state.vatshifts, state.setVatshifts]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    Omschrijving: { type: 'text', label: 'Description', max: '100', multi: true, value: '' },
    Info: { type: 'text', label: 'Info', max: '500', multi: true, value: '' },
    OmschrijvingOLD: { type: '', label: 'Old description', max: '500', multi: true, value: '' },
    OmschrijvingFR: { type: 'text', label: 'FR', max: '100', multi: true, value: '' },
    OmschrijvingDE: { type: 'text', label: 'DE', max: '100', multi: true, value: '' },
    OmschrijvingUK: { type: 'text', label: 'EN', max: '100', multi: true, value: '' }
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/vatshifts';
  const title = 'VAT shifts';

  const rows = vatshifts.map((item) => {
    return [
      truncate(item.Omschrijving, 30, false),
      truncate(item.OmschrijvingOLD, 30, false),
      truncate(item.Info, 30, false),
      getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.BTWVerlegdID)
    ];
  });

  return (
    <Page
      title={title}
      fullWidth
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add VAT shift', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setVatshifts}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'numeric']}
          headings={[<b>Description NL</b>, <b>Old description</b>, <b>Info</b>, <b>Edit</b>]}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
