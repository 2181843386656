import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { getRequest } from '../shared/components/functions';

export const useAuthStore = create((set) => ({
  user: null,
  setUser: (user) => set({ user }),
  errorMessage: null,
  isLoading: false,
  logout: async () => {
    set({ isLoading: true });
    try {
      let response = await fetch('/api/logout');
      if (response.ok) {
        set({ user: null });
        set({ isLoading: false });
        localStorage.clear();
        return;
      } else {
        console.log('Logout failed.');
      }
    } catch (err) {
      set({ isLoading: false });
      console.log('Logout error: ', err);
    }
  }
}));

export const useLookupStore = create(
  persist(
    (set) => ({
      agreementtypes: [],
      setAgreementtypes: async () => set({ agreementtypes: await getRequest(`/api/agreement_types`) }),
      companystatuses: [],
      setCompanystatuses: async () => set({ companystatuses: await getRequest(`/api/companystatuses`) }),
      companytags: [],
      setCompanytags: async () => set({ companytags: await getRequest(`/api/companytags`) }),
      contacttitels: [],
      setContacttitels: async () => set({ contacttitels: await getRequest(`/api/contacttitels`) }),
      countries: [],
      setCountries: async () => set({ countries: await getRequest(`/api/countries`) }),
      currencies: [],
      setCurrencies: async () => set({ currencies: await getRequest(`/api/currencies`) }),
      documenttypes: [],
      setDocumenttypes: async () => set({ documenttypes: await getRequest(`/api/documenttypes`) }),
      emailformats: [],
      setEmailformats: async () => set({ emailformats: await getRequest(`/api/emailformats`) }),
      incasseren: [],
      setIncasseren: async () => set({ incasseren: await getRequest(`/api/incasseren`) }),
      invoicestatuses: [],
      setInvoicestatuses: async () => set({ invoicestatuses: await getRequest(`/api/invoicestatuses`) }),
      languages: [],
      setLanguages: async () => set({ languages: await getRequest(`/api/languages`) }),
      machinebrands: [],
      setMachinebrands: async () => set({ machinebrands: await getRequest(`/api/machinebrands`) }),
      machinetypes: [],
      setMachinetypes: async () => set({ machinetypes: await getRequest(`/api/machinetypes`) }),
      organisations: [],
      setOrganisations: async () => set({ organisations: await getRequest(`/api/organisations`) }),
      paymentconditions: [],
      setPaymentconditions: async () => set({ paymentconditions: await getRequest(`/api/paymentconditions`) }),
      pickingplaces: [],
      setPickingplaces: async () => set({ pickingplaces: await getRequest(`/api/pickingplaces`) }),
      projectstatuses: [],
      setProjectstatuses: async () => set({ projectstatuses: await getRequest(`/api/projectstatuses`) }),
      pubcodes: [],
      setPubcodes: async () => set({ pubcodes: await getRequest(`/api/pubcodes`) }),
      purchasetypes: [],
      setPurchasetypes: async () => set({ purchasetypes: await getRequest(`/api/purchasetypes`) }),
      quotationstatuses: [],
      setQuotationstatuses: async () => set({ quotationstatuses: await getRequest(`/api/quotationstatuses`) }),
      rappeltexts: [],
      setRappeltexts: async () => set({ rappeltexts: await getRequest(`/api/rappeltexts`) }),
      sectors: [],
      setSectors: async () => set({ sectors: await getRequest(`/api/sectors`) }),
      servicecontracttypes: [],
      setServicecontracttypes: async () => set({ servicecontracttypes: await getRequest(`/api/service_contracttypes`) }),
      units: [],
      setUnits: async () => set({ units: await getRequest(`/api/units`) }),
      users: [],
      setUsers: async () => set({ users: await getRequest(`/api/users`) }),
      usertypes: [],
      setUsertypes: async () => set({ usertypes: await getRequest(`/api/usertypes`) }),
      vatshifts: [],
      setVatshifts: async () => set({ vatshifts: await getRequest(`/api/vatshifts`) }),
      zipcodes: [],
      setZipcodes: async () => set({ zipcodes: await getRequest(`/api/zipcodes`) })
    }),
    {
      name: 'lookup-storage'
    }
  )
);
