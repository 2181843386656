import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, DataTable, Page, Card, Link, Badge } from '@shopify/polaris';
import { useUrl } from '../../shared/util/hanldesave';
import { getDocPdfLinkForListView, getTableFooter, getModalButton, fetchTableData, getUserOrg } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../shared/components/listFilter.jsx';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { emailSentBadge } from '../../shared/components/badges/badges.jsx';
import QuickViewModal from '../../shared/components/modals/quickViewModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';

export default function DeliveriesPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    dateFrom: { type: 'date', name: 'Date from', value: url.get('dateFrom') || '' },
    dateTo: { type: 'date', name: 'Date to', value: url.get('dateTo') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    refCustomer: { type: 'text', name: 'Reference customer', value: url.get('refCustomer') || '' },
    mail: { type: 'status', name: 'PDF emailed', value: url.get('mail') || 'all' },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/delivery_notes', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => [
        <Link removeUnderline url={'/delivery_notes/' + item.Bonnr}>
          {item.LeveringsbonNummer}
        </Link>,
        getModalButton(item.Bonnr, item.LeveringsbonNummer, setModalOpen, setSelectedItem),
        getDocPdfLinkForListView(item, 'delivery_note', item.company_number, item.LeveringsbonNummer),
        String(item.datum).substring(0, 10),
        <Link removeUnderline url={'/companies/' + item.klantnummer}>
          {item.klantnaam}
        </Link>,
        item.refklant,
        item.refklantdatum ? String(item.refklantdatum).substring(0, 10) : '',
        item.Afkorting ? <Badge>{item.Afkorting}</Badge> : '',
        emailSentBadge(item.Verstuurd)
      ])
    : [];

  return (
    <Page title="Delivery notes" fullWidth>
      <QuickViewModal modalOpen={modalOpen} setModalOpen={setModalOpen} id={selectedItem.id} type="non-financial" source="delivery_notes" title={`Delivery Note #${selectedItem.number}`} />
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter deliveries on number, customer name, customer number, adress, date, reference and ZIP"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={[<b>Number</b>, <b>View</b>, <b>PDF</b>, <b>Date</b>, <b>Customer</b>, <b>Reference customer</b>, <b>Date customer</b>, <b>Owner</b>, <b>PDF emailed</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'deliveries')}
        />
      </Card>
    </Page>
  );
}
