import React from 'react';
import { Modal } from '@shopify/polaris';

import HolidaysComponent from '../../../pages/technicians/components/holidays';

export default function HolidayModal(props) {
  const handleChange = () => props.toggle(false);
  return (
    <Modal open={props.activated} onClose={handleChange} title="Holidays">
      <HolidaysComponent></HolidaysComponent>
    </Modal>
  );
}
