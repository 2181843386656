import { Button, TextField, Tooltip, Form, Box } from '@shopify/polaris';
import React from 'react';
import { useTable, usePagination } from 'react-table';
import { ChevronUpIcon, PlusCircleIcon, DeleteIcon, ExternalSmallIcon, PlusIcon } from '@shopify/polaris-icons';
import ProductSearcherPopover from './productSearcherPopover';
import { addEmptyLine, addLineByIndex, removeLineByIndex, shiftLineUpIndex } from '../util/hanldesave';

export default function LinesTable({ id, data, updateMyData, skipPageReset, type, onSubmit, setLines, setLinesDirty, lineInterface, resource, company, disabled, setIsBlurred }) {
  const columns = React.useMemo(() => {
    let cols =
      type === 'non-financial'
        ? [
            {
              Header: 'Code',
              accessor: 'produktnr',
              type: 'number',
              prefix: '#',
              weight: 8
            },
            {
              Header: 'Description',
              accessor: 'tekstlijn',
              type: 'text',
              weight: 55
            },
            {
              Header: 'Ordered',
              accessor: 'aantalbesteld',
              type: 'number',
              prefix: '#',
              weight: 8
            },
            {
              Header: 'Delivered',
              accessor: 'aantalgeleverd',
              type: 'number',
              prefix: '#',
              weight: 8
            }
          ]
        : type === 'financial-simple'
        ? [
            {
              Header: 'Qty',
              accessor: 'aantal',
              type: 'number',
              weight: 5
            },
            {
              Header: 'Code',
              accessor: 'produktnr',
              type: 'number',
              prefix: '#',
              weight: 10
            },
            {
              Header: 'Description',
              accessor: 'tekstlijn',
              type: 'text',
              weight: 31
            },
            {
              Header: 'VP',
              accessor: 'VP',
              type: 'currency',
              prefix: '€',
              weight: 8
            },
            {
              Header: 'VAT',
              accessor: 'BTW',
              type: 'number',
              prefix: '%',
              weight: 8
            }
          ]
        : [
            {
              Header: 'Qty',
              accessor: 'aantal',
              type: 'number',
              weight: 8
            },
            {
              Header: 'Code',
              accessor: 'produktnr',
              type: 'number',
              prefix: '#',
              weight: 10
            },
            {
              Header: 'Description',
              accessor: 'tekstlijn',
              type: 'text',
              weight: 25
            },
            {
              Header: 'AP',
              accessor: 'AP',
              type: 'currency',
              prefix: '€',
              weight: 8
            },
            {
              Header: 'Date AP',
              accessor: 'Datum bijw_AP',
              type: 'date',
              weight: 4
            },
            {
              Header: 'VP',
              accessor: 'VP',
              type: 'currency',
              prefix: '€',
              weight: 8
            },
            {
              Header: 'Date VP',
              accessor: 'Datum  bijw_ VP',
              type: 'date',
              weight: 4
            },
            {
              Header: 'VAT',
              accessor: 'BTW',
              type: 'number',
              prefix: '%',
              weight: 4
            }
          ];

    return cols;
  }, [type]);

  // Create an editable cell renderer
  const EditableCell = ({
    value: initialValue,
    row,
    column: { id, type, prefix },
    updateMyData, // This is a custom function that we supplied to our table instance
    resource,
    company,
    disabled
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      setIsBlurred(false);
      setLinesDirty(true);
      if (id === 'produktnr') {
        e.match(/^[0-9]*$/) //produktnr can only accept numbers or blank
          ? setValue(parseInt(e))
          : null;
      } else {
        setValue(e);
      }
      if (id !== 'produktnr') {
        updateMyData(row.index, id, e, setLines, setLinesDirty, resource, company, setIsBlurred);
      }
    };

    const onChangePopover = (e) => {
      setValue(e);
      updateMyData(row.index, id, e, setLines, setLinesDirty, resource, company, setIsBlurred);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(row.index, id, value, setLines, setLinesDirty, resource, company, setIsBlurred);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const detectError = () => {
      if (row.original?.notExisting) return 'Product does not exist';
      if (row.original?.Discontinued) return 'Product discontinued';
      return false;
    };
    const textFieldError = detectError();

    return (
      <TextField
        id="line"
        type={type === 'number' || type === 'currency' ? 'currency' : 'text'}
        value={value ? (type === 'date' ? String(value).substring(0, 10) : String(value)) : ''}
        onChange={onChange}
        onBlur={onBlur}
        prefix={prefix}
        disabled={resource.Verstuurd ? true : type === 'date' ? true : disabled ? true : false}
        error={id === 'produktnr' ? textFieldError : false}
        autoComplete="off"
        connectedRight={id === 'produktnr' ? <ProductSearcherPopover value={value} handleChange={onChangePopover} disabled={disabled || resource.Verstuurd} /> : null}
        maxLength={100} //restrict tesklijn values to be longer than 100 chars
      />
    );
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell
  };

  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      // use the skipPageReset option to disable page resetting temporarily
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      resource,
      company,
      disabled
    },
    usePagination
  );

  return (
    <Form onSubmit={onSubmit} id="linesTable">
      <table {...getTableProps()} style={{ width: '100%', marginTop: '0px' }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{ width: column.weight + '%', textAlign: 'left', fontWeight: 400, fontSize: 15, paddingBottom: '5px' }}>
                  {column.render('Header')}
                </th>
              ))}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '5%' }}></th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr id="lineRow" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} style={{ verticalAlign: 'top' }}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}

                <td style={{ verticalAlign: 'top' }}>
                  <Tooltip content="Open part in new tab">
                    <Button
                      size="large"
                      icon={ExternalSmallIcon}
                      disabled={disabled}
                      style={{ margin: '-5px' }}
                      onClick={() => window.open(`/products/${row.cells.filter((cell) => cell.column.id === 'produktnr')[0].value}`, '_blank').focus()}
                    />
                  </Tooltip>
                </td>

                <td style={{ verticalAlign: 'top' }}>
                  {id === 'new' && i > 0 && (
                    <Tooltip content="Move line up">
                      <Button size="large" disabled={disabled} icon={ChevronUpIcon} onClick={() => shiftLineUpIndex(setLines, setLinesDirty, i)} />
                    </Tooltip>
                  )}
                  {id === 'new' && (
                    <Tooltip content="Add line above this line.">
                      <Button size="large" disabled={disabled} icon={PlusIcon} onClick={() => addLineByIndex(setLines, setLinesDirty, lineInterface, i)} />
                    </Tooltip>
                  )}
                  <Tooltip content="Remove line">
                    <Button size="large" disabled={disabled} onClick={() => removeLineByIndex(setLines, setLinesDirty, i, setIsBlurred)} icon={DeleteIcon} />
                  </Tooltip>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Box paddingBlockStart="300">
        <Tooltip content="Add a line to the table">
          <Button size="large" disabled={disabled} id="addLine" onClick={() => addEmptyLine(setLines, setLinesDirty, lineInterface)} icon={PlusCircleIcon} />
        </Tooltip>
      </Box>
    </Form>
  );
}
