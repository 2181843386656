import * as PolarisIcons from '@shopify/polaris-icons';
import userRoles from '../utils/userRoles';
import { Banner, Box, Button, Form, FormLayout, Grid, Icon, InlineStack, Modal, Navigation, Popover, Text, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { deleteRequest, getRequest, postRequest, putRequest } from '../shared/components/functions';
import { useNavigate } from 'react-router-dom';

const getSidebarNavigation = (user) => {
  const sidebarNavigation = [
    { url: '/home', label: 'Home', icon: PolarisIcons.HomeIcon },
    {
      url: '/companies',
      label: 'Companies',
      icon: PolarisIcons.InventoryIcon,
      subNavigationItems: [
        { url: '/companies', disabled: false, label: 'Companies' },
        { url: '/contacts', disabled: false, label: 'Contacts' }
      ],
      secondaryAction: { url: '/companies/new', accessibilityLabel: 'Add a company', icon: PolarisIcons.PlusCircleIcon }
    },
    {
      url: '/quotations',
      label: 'Price requests',
      icon: PolarisIcons.WalletIcon,
      subNavigationItems: [
        { url: '/quotations', disabled: false, label: 'Quotations' },
        { url: '/inquiries', disabled: false, label: 'Inquiries' }
      ]
    },
    {
      url: '/sales',
      label: 'Orders',
      icon: PolarisIcons.ConfettiIcon,
      subNavigationItems: [
        { url: '/sales', disabled: false, label: 'Sales orders' },
        { url: '/purchases', disabled: false, label: 'Purchase orders' },
        { url: '/delivery_notes', disabled: false, label: 'Delivery notes' }
      ]
    },
    {
      url: '/invoices',
      label: 'Invoices',
      icon: PolarisIcons.CashEuroIcon,

      subNavigationItems: [
        { url: '/invoices', disabled: false, label: 'Invoices OUT' },
        {
          url: '/invoices_in',
          disabled: false,
          label: 'Invoices IN',
          secondaryAction: { url: '/invoices_in/new', accessibilityLabel: 'Add a new incoming invoice', icon: PolarisIcons.PlusCircleIcon }
        },
        { url: '/creditnotes', disabled: false, label: 'Credit notes OUT' },
        {
          url: '/creditnotes_in',
          disabled: false,
          label: 'Credit notes IN',
          secondaryAction: { url: '/creditnotes_in/new', accessibilityLabel: 'Add a new incoming creditnote', icon: PolarisIcons.PlusCircleIcon }
        }
      ]
    },
    {
      url: '/products',
      label: 'Products',
      icon: PolarisIcons.ProductIcon,
      secondaryAction: { url: '/products/new', accessibilityLabel: 'Add a product', icon: PolarisIcons.PlusCircleIcon }
    },
    {
      url: '/projects',
      label: 'Projects',
      icon: PolarisIcons.ThemeIcon,
      secondaryAction: { url: '/projects/new', accessibilityLabel: 'Add a project', icon: PolarisIcons.PlusCircleIcon }
    },
    {
      url: '/jobs',
      label: 'Jobs',
      icon: PolarisIcons.WrenchIcon,

      subNavigationItems: [
        { url: '/jobs', disabled: false, label: 'Jobs' },
        { url: '/montage_bons', disabled: false, label: 'Montagebons' }
      ],
      secondaryAction: {
        onClick: () => (window.location.href = `https://tech.schuilenburg.be/Jobscherm`),
        accessibilityLabel: 'Add a job',
        icon: PolarisIcons.PlusCircleIcon
      }
    },
    {
      url: '/machines',
      label: 'Machines',
      icon: PolarisIcons.WrenchIcon,

      subNavigationItems: [
        { url: '/machines', disabled: false, label: 'Machines' },
        { url: '/maintenance_contracts', disabled: false, label: 'Maintenance contracts' }
      ],
      secondaryAction: { url: '/machines/new', accessibilityLabel: 'Add a machine', icon: PolarisIcons.PlusCircleIcon }
    }
  ];

  if (user?.role === userRoles.AdminFull || user?.role === userRoles.AdminData) {
    sidebarNavigation.push(
      { url: '/expenses', label: 'Expenses', icon: PolarisIcons.ReceiptEuroIcon },
      { url: '/cashbook', label: 'Cashbook', icon: PolarisIcons.ReceiptEuroIcon },
      {
        url: '/technicians',
        label: 'HR',
        icon: PolarisIcons.ShieldCheckMarkIcon,
        subNavigationItems: [
          { url: '/technicians', disabled: false, label: 'Technicians payroll' },
          { url: '/holidays', disabled: false, label: 'Holiday admin' }
        ]
      }
    );
  }

  if (user?.role === userRoles.AdminFull) sidebarNavigation.push({ url: '/analytics', label: 'Analytics', icon: PolarisIcons.FlagIcon });

  return sidebarNavigation;
};

export const userMenuActions = (handleLogoutAction, setHolidayModalActivated, holidaymodalactivated, setShotcutmodalactivated, shotcutmodalactivated) => {
  return [
    {
      items: [
        {
          content: 'Log out',
          icon: PolarisIcons.ExitIcon,
          onAction: handleLogoutAction
        },
        {
          content: 'Account',
          url: '/account',
          icon: PolarisIcons.SettingsIcon
        },
        {
          //to load holidays card component in modal
          content: 'Official Holidays',
          icon: PolarisIcons.GiftCardIcon,
          onAction: () => setHolidayModalActivated(!holidaymodalactivated)
        }
        // {
        //   //to load holidays card component in modal
        //   content: 'Hotkeys',
        //   icon: HideKeyboardMajor,
        //   onAction: () => {
        //     setShotcutmodalactivated(!shotcutmodalactivated);
        //   }
        // }
      ]
    }
  ];
};

const SavedLinksSidebarComponent = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [userLinks, setUserLinks] = useState([]);
  const [link, setLink] = useState({ id: null, linkUrl: window.location.href, linkName: '', icon: 'ConfettiIcon' });
  const [selectedIcon, setSelectedIcon] = useState(<Icon source={PolarisIcons.ConfettiIcon} />);
  const [isLoading, setIsLoading] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);

  useEffect(() => {
    async function fetchUserLinks() {
      const data = await getRequest(`/api/userlinks/user`);
      if (data) setUserLinks(data);
    }
    fetchUserLinks();
  }, [modalOpen]);

  const openLink = (url) => {
    if (url.startsWith(window.location.origin)) {
      if (url.includes(window.location.pathname)) return (window.location = url.replace(window.location.origin, ''));
      return navigate(url.replace(window.location.origin, ''));
    }
    return (window.location.href = url);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const res = link.id ? await putRequest(`/api/userlinks/${link.id}`, link) : await postRequest('/api/userlinks', link);
    setIsLoading(false);
    if (res) handleClose();
  };

  const handleDelete = async () => {
    setIsLoading(true);
    const res = await deleteRequest(`/api/userlinks/${link.id}`);
    setIsLoading(false);
    if (res) handleClose();
  };

  const handleOpen = () => {
    setModalOpen(true);
    setLink({ id: null, linkUrl: window.location.href, linkName: '', icon: 'ConfettiIcon' });
  };

  const handleClose = () => {
    setModalOpen(false);
    setLink({ id: null, linkUrl: '', linkName: '', icon: '' });
  };

  const activator = (
    <InlineStack gap="400" blockAlign="stretch">
      <Button onClick={() => setPopoverActive(true)} disclosure>
        Select icon
      </Button>
    </InlineStack>
  );

  return (
    <Box paddingInlineStart="300" paddingBlockEnd="200" width="222px">
      <Box paddingInlineStart="200">
        <InlineStack align="space-between" blockAlign="center">
          <Text variant="headingXs" as="h6" fontWeight="bold" tone="subdued">
            Saved links
          </Text>
          <Button size="micro" icon={PolarisIcons.PlusCircleIcon} variant="tertiary" onClick={handleOpen} />
        </InlineStack>
      </Box>

      {Array.isArray(userLinks) && userLinks.length > 0
        ? userLinks.map((u, idx) => (
            <InlineStack key={idx} align="space-between" blockAlign="center">
              <Box width="100px">
                <Button variant="tertiary" textAlign="left" icon={PolarisIcons[u.icon]} onClick={() => openLink(u.linkUrl)}>
                  <p style={{ width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{u.linkName}</p>
                </Button>
              </Box>

              <Button
                onClick={() => {
                  setModalOpen(true);
                  setLink({ id: u.id, linkUrl: u.linkUrl, linkName: u.linkName, icon: PolarisIcons[u.icon || 'ConfettiIcon'] });
                  setSelectedIcon(<Icon source={PolarisIcons[u.icon || 'ConfettiIcon']} />);
                }}
                icon={PolarisIcons.EditIcon}
                size="micro"
                variant="tertiary"
              />
            </InlineStack>
          ))
        : null}

      <Modal
        title={link.id ? `Edit link #${link.id}` : 'Add link'}
        open={modalOpen}
        onClose={handleClose}
        primaryAction={{ content: 'Save link', onAction: handleSave, disabled: isLoading, loading: isLoading }}
        secondaryActions={[
          { content: 'Close', onAction: handleClose },
          { content: 'Delete', destructive: true, onAction: handleDelete }
        ]}
      >
        <Modal.Section>
          <Form>
            <FormLayout>
              <InlineStack blockAlign="center" gap="400">
                <Popover active={popoverActive} activator={activator} autofocusTarget="first-node" onClose={() => setPopoverActive(false)}>
                  <Box padding="200">
                    <Grid>
                      {Object.entries(PolarisIcons).map(([iconName, IconComponent], idx) => (
                        <Button
                          key={idx}
                          icon={IconComponent}
                          onClick={() => {
                            setLink({ ...link, icon: iconName });
                            setSelectedIcon(<Icon source={IconComponent} />);
                            setPopoverActive(false);
                          }}
                        />
                      ))}
                    </Grid>
                  </Box>
                </Popover>
                <Box>{selectedIcon}</Box>
              </InlineStack>
              <TextField id="linkName" label="Name:" value={link.linkName || ''} onChange={(e) => setLink({ ...link, linkName: e })} maxLength={255} autoComplete="off" showCharacterCount />
              <TextField id="linkUrl" label="URL:" value={link.linkUrl || ''} onChange={(e) => setLink({ ...link, linkUrl: e })} maxLength={255} autoComplete="off" showCharacterCount />
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </Box>
  );
};

export const navigationMarkup = (location, user, birthdays) => (
  <Navigation location={location.pathname}>
    <Navigation.Section items={getSidebarNavigation(user)} />

    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
      <SavedLinksSidebarComponent />

      {Array.isArray(birthdays) && birthdays.length > 0 ? (
        <Box paddingInlineStart="300" paddingBlockEnd="200">
          <Box paddingInlineStart="200" paddingBlockEnd="200">
            <Text variant="headingXs" as="h6" fontWeight="bold" tone="subdued">
              Happy birthday
            </Text>
          </Box>
          {birthdays.map((b, idx) => (
            <Banner key={idx} icon={PolarisIcons.ConfettiIcon}>
              {b.Gebruikernaam}
            </Banner>
          ))}
        </Box>
      ) : null}
    </div>

    {user?.role === userRoles.AdminFull || user?.role === userRoles.AdminData ? (
      <Navigation.Section separator items={[{ url: '/settings', label: 'Settings', icon: PolarisIcons.SettingsIcon }]} />
    ) : null}
  </Navigation>
);

// !!!ATTENTION:
// We have to rebuild the navbar from scratch once the frame is removed from polaris (its already deprecated), use this to start:
// {
//   /* <ui-nav-menu style={{ padding: '10px', width: '240px', backgroundColor: 'rgba(235, 235, 235, 1)' }}>
// {user?.role === userRoles.AdminFull || user?.role === userRoles.AdminData ? <NavLink name="Settings" url="/settings" icon={SettingsIcon} /> : null}
// </ui-nav-menu> */

// // const NavLink = ({ name, url, icon }) => {
// //   return (
// //     <Link monochrome removeUnderline url={url}>
// //       <div id="NavLinkContainer">
// //         <InlineStack gap="200">
// //           <div>
// //             <Icon source={icon} />
// //           </div>

// //           <Text as="p" fontWeight="bold">
// //             {name}
// //           </Text>
// //         </InlineStack>
// //       </div>
// //     </Link>
// //   );
// // };
// }
