import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Page, Card, Link } from '@shopify/polaris';
import { useUrl } from '../../shared/util/hanldesave';
import { formatToCurrency, getTableFooter, truncate, getDocPdfLinkForListView, getModalButton, fetchTableData, getUserOrg } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../shared/components/listFilter.jsx';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { emailSentBadge } from '../../shared/components/badges/badges.jsx';
import QuickViewModal from '../../shared/components/modals/quickViewModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function QuotationsPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);
  const quotationstatuses = useLookupStore((state) => state.quotationstatuses);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    dateFrom: { type: 'date', name: 'Date from', value: url.get('dateFrom') || '' },
    dateTo: { type: 'date', name: 'Date to', value: url.get('dateTo') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    refCustomer: { type: 'text', name: 'Reference customer', value: url.get('refCustomer') || '' },
    supplier: { type: 'text', name: 'Supplier', value: url.get('supplier') || '' },
    mail: { type: 'status', name: 'PDF emailed', value: url.get('mail') || 'all' },
    status: {
      type: 'multiple',
      name: 'Status',
      value: url.get('status') ? JSON.parse(url.get('status')) : null,
      choices: quotationstatuses.map((status) => ({ label: status.offertestatustekst, value: status.offertestatusnr }))
    },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/quotations', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const colorCodes = {
    0: 'warning',
    1: 'default',
    2: 'critical',
    3: 'default',
    4: 'success',
    5: 'default',
    6: 'default'
  };

  const rows = Array.isArray(items)
    ? items.map((item) => [
        <Link removeUnderline url={'/quotations/' + item.offertenr}>
          {item.Offertenummer}
        </Link>,
        getModalButton(item.offertenr, item.Offertenummer, setModalOpen, setSelectedItem),
        getDocPdfLinkForListView(item, 'quotation', item.customer_number, item.Offertenummer),
        String(item.datum).substring(0, 10),
        <Link removeUnderline url={'/companies/' + item.FIRNR}>
          {truncate(item.customer_name, 30, false)}
        </Link>,
        truncate(item.refklant, 25, false),
        truncate(item.supplier_name, 30),
        formatToCurrency(item.price),
        item.Afkorting ? <Badge>{item.Afkorting}</Badge> : '',
        <Badge tone={colorCodes[item.OfferteStatusID]}>{quotationstatuses.find((status) => status.offertestatusnr == item.OfferteStatusID)?.offertestatustekst}</Badge>, //eslint-disable-line
        emailSentBadge(item.Verstuurd)
      ])
    : [];

  return (
    <Page title="Quotations" fullWidth>
      <QuickViewModal modalOpen={modalOpen} setModalOpen={setModalOpen} id={selectedItem.id} type="default" source="quotations" title={`Quotation #${selectedItem.number}`} />
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter quotations on number, customer name, status text, supplier name and reference"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'text', 'text', 'text']}
          headings={[<b>Number</b>, <b>View</b>, <b>PDF</b>, <b>Date</b>, <b>Customer</b>, <b>Reference customer</b>, <b>Supplier</b>, <b>Price</b>, <b>Owner</b>, <b>Status</b>, <b>PDF emailed</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'quotations')}
        />
      </Card>
    </Page>
  );
}
