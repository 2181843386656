import { object, string, date, number, boolean } from 'yup';
import { getCurrenctDatetimeYYYMMDD } from '../../../shared/components/functions';

export const contactSchema = object({
  NAAM: string().default(undefined).required('Name is required'),
  TITEL: string().default(undefined),
  STATUS: string().default(undefined).nullable(),
  TELNR: string().default(undefined).nullable(),
  OPMERKING: string().default(undefined).nullable(),
  TAAL: string().default(undefined).nullable(),
  DEPT: string().default(undefined).nullable(),
  PUB: string().default(undefined).nullable(),
  CONNR: string().default(undefined),
  KLANR: string().default(undefined),
  KLANROLD: string().default(undefined).nullable(),
  DISCONTINUED: boolean().default(false),
  'Last modif': date().default(undefined).nullable(),
  'E-mail': string().default(undefined).required('E-mail is required'),
  s_GUID: string().default(undefined).nullable(),
  Factuur: boolean().default(false),
  TaalID: number().default(undefined).required('Language is required'),
  AanspreektitelID: number().default(undefined).required('Title is required'),
  TELFormatID: number().default(undefined).nullable(),
  TEL: string().default(undefined).nullable(),
  GSM: string().default(undefined).nullable(),
  GSMFormatID: number().default(undefined).nullable(),
  FAXFormatID: number().default(undefined).nullable(),
  FAX: string().default(undefined).nullable(),
  SendMail: boolean().default(false),
  Montagebon: boolean().default(false)
});

export const companySchema = object({
  NAAM: string().default(undefined).required('Name is required'),
  STRAAT_NR: string().default(undefined).required('Street is required'),
  WOONPL: string().default(undefined).required('City is required'),
  POSTNR: string().default(undefined).required('ZIP Code is required'),
  TEL: string().default(undefined).nullable(),
  FAX: string().default(undefined).nullable(),
  TYPE: string().default(undefined).nullable(),
  OPMERKING: string().default(undefined).nullable(),
  GEBIED: string().default(undefined).nullable(),
  BTW: string()
    .default(undefined)
    .nullable()
    .when('BTWPlichtig', {
      is: true,
      then: string().default(undefined).typeError('The VAT number is required').min(7, 'The VAT number must be 7 characters long').required('The VAT number is required')
    }),
  FIRNR: string().default(undefined).nullable(),
  FIRNROLD: string().default(undefined).nullable(),
  DISCONTINUED: boolean().default(false),
  personeelsbestand: number().default(undefined).nullable(),
  'E-mail': string().default(undefined).nullable(),
  select: boolean().default(false).nullable(),
  'web-site': string().default(undefined).nullable(),
  Land: string().default(undefined).nullable(),
  Voertaal: string().default(undefined).nullable(),
  Klant: boolean().default(true),
  Leverancier: boolean().default(false),
  'Betalingsvw aankoop': string().default(undefined).nullable(),
  Artikel: string().default(undefined).nullable(),
  Status: string().default(undefined).nullable(),
  'Last modif': date().default(getCurrenctDatetimeYYYMMDD()).nullable(),
  betalingID: number().default(1).nullable(),
  http: string().default(undefined).nullable(),
  Kontrakt: boolean().default(false),
  s_GUID: string().default(undefined).nullable(),
  Opmerkingen1: string().default(undefined).nullable(),
  Opmerkingen2: string().default(undefined).nullable(),
  Gesperd: boolean().default(false),
  FirNrFactuur: number().default(undefined).nullable(),
  LevBetalingID: number()
    .default(undefined)
    .nullable()
    .when('Leverancier', {
      is: true,
      then: number().default(undefined).typeError('The payment condition is required for the supplier').required('The payment condition is required for the supplier')
    }),
  LandID: number()
    .default(window.location.href.includes('perfectmoose') ? 468 : 300)
    .required('Country is required'),
  TaalID: number().default(undefined).required('Language is required'),
  Klantnummer: string().default(undefined),
  PostcodeID: number().default(undefined).nullable(),
  BTWFormatID: number().default(undefined).nullable(),
  GSM: string().default(undefined).nullable(),
  GSMFormatID: number().default(undefined).nullable(),
  TELFormatID: number().default(undefined).nullable(),
  FaxFormatID: number().default(undefined).nullable(),
  SectorID: number().default(undefined).nullable(),
  Particulier: boolean().default(false),
  BTWPlichtig: boolean().default(false),
  POSTNRFormatID: number().default(undefined).nullable(),
  KlantStatusID: number().default(5),
  PartBetalingID: number()
    .default(undefined)
    .nullable()
    .when('Particulier', {
      is: true,
      then: number().default(undefined).typeError('The payment condition is required for the particulier').required('The payment condition is required for the particulier')
    }),
  StatusReden: string().default(undefined).nullable()
});

export const combinedSchema = object({
  companyNAAM: string().default(undefined),
  STRAAT_NR: string().default(undefined),
  WOONPL: string().default(undefined),
  POSTNR: string().default(undefined),
  companyLandID: number()
    .default(window.location.href.includes('perfectmoose') ? 468 : 300)
    .required('Country is required'),
  companyTaalID: number().default(3),
  personeelsbestand: number().default(undefined).nullable(),
  companyGSM: string().default(undefined).nullable(),
  companyTEL: string().default(undefined).nullable(),
  'web-site': string().default(undefined),
  companyEmail: string().default(undefined).nullable(),
  companyOPMERKING: string().default(undefined).nullable(),
  KlantStatusID: number().default(5),
  NAAM: string().default(undefined).required('Name is required'),
  DEPT: string().default(undefined).nullable(),
  AanspreektitelID: number().default(1),
  TaalID: number().default(3),
  'E-mail': string().default(undefined).required('E-mail is required'),
  GSM: string().default(undefined).nullable(),
  TEL: string().default(undefined).nullable(),
  OPMERKING: string().default(undefined).nullable(),
  KLANR: string().default(undefined)
});
