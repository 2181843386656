import { DataTable } from '@shopify/polaris';
import { formatToCurrency, getRequest } from '../../../shared/components/functions';
import { useEffect, useState } from 'react';

export const LinkedInvoicesOverviewTable = ({ id, lines, invoices, saleInclTax, creditSum }) => {
  const saleExclTax = lines.reduce((prev, curr, index) => prev + (lines[index].aantal || 0) * (lines[index].VP || 0), 0);
  const invoicesInclTax = invoices.reduce((prev, curr) => prev + curr.totaalbedrag, 0);

  const [invoicesExclTax, setInvoicesExclTax] = useState(0);

  useEffect(() => {
    let cancelled = false;

    async function fetchInvoiceLines() {
      try {
        const fetches = invoices.map((invoice) => getRequest(`/api/invoices/${invoice.faktuurnr}/lines`));

        const results = await Promise.all(fetches);
        if (cancelled) return;

        const invoiceLinesValue = results.reduce((total, linesData) => {
          const filteredLines = linesData.filter((d) => d.aantal && d.VP);
          const lineValue = filteredLines.reduce((sum, line) => sum + (line.aantal || 0) * (line.VP || 0), 0);
          return total + lineValue;
        }, 0);

        setInvoicesExclTax(invoiceLinesValue);
      } catch (error) {
        if (cancelled) return;
        console.error('Fetch error:', error);
      }
    }

    if (Array.isArray(invoices) && invoices.length > 0) {
      fetchInvoiceLines();
    } else {
      setInvoicesExclTax(0);
    }

    return () => {
      cancelled = true;
    };
  }, [invoices, id]);

  return (
    <DataTable
      stickyHeader
      increasedTableDensity
      verticalAlign="middle"
      columnContentTypes={['text', 'numeric', 'numeric']}
      headings={['', 'Excl tax', 'Incl tax']}
      rows={[
        ['Value to be invoiced', formatToCurrency(saleExclTax), formatToCurrency(saleInclTax)],
        ['Value already invoiced', '-' + formatToCurrency(invoicesExclTax), '-' + formatToCurrency(invoicesInclTax)],
        ['Value still to be invoiced', formatToCurrency(saleExclTax - invoicesExclTax), formatToCurrency(saleInclTax - invoicesInclTax)],
        creditSum ? ['Credited value incl tax', '', '-' + formatToCurrency(creditSum)] : [],
        creditSum ? ['Total remaining value incl tax', '', formatToCurrency(saleInclTax - invoicesInclTax + creditSum)] : []
      ]}
    />
  );
};
