import { Card, DataTable } from '@shopify/polaris';
import { formatToCurrency } from '../functions';
import { CardHeading } from './CardHeading';

export default function LinesTotalCard({ summary, lines, creditnotes }) {
  const totalSaleValue = lines.reduce((prev, curr, index) => prev + (lines[index].aantal || 0) * (lines[index].VP || 0) * (1 + lines[index].BTW / 100), 0);

  let rows = [
    ['Value excl tax', formatToCurrency(lines.reduce((prev, curr, index) => prev + (lines[index].aantal || 0) * (lines[index].VP || 0), 0))],
    ['Taxes value', '+' + formatToCurrency(lines.reduce((prev, curr, index) => prev + (lines[index].aantal || 0) * (lines[index].VP || 0) * (lines[index].BTW / 100), 0))],
    [`Total ${summary} value incl tax`, <b>{formatToCurrency(totalSaleValue)}</b>]
  ];

  if (creditnotes) {
    let totalCreditNotes = creditnotes
      .map((c) => {
        return c.BedragTotaal;
      })
      .reduce((p, c) => p + c, 0);
    totalCreditNotes = Math.round(totalCreditNotes * 100) / 100;
    let totalAfterCreditNote = totalSaleValue - totalCreditNotes;
    rows.push(['Credited value', '-' + formatToCurrency(totalCreditNotes)], [<b>Total value</b>, +totalAfterCreditNote === 0 ? '€ 0,00' : formatToCurrency(totalAfterCreditNote)]);
  }

  return (
    <Card>
      <CardHeading title="Summary" />
      <DataTable stickyHeader={true} increasedTableDensity={true} verticalAlign="middle" columnContentTypes={['text', 'numeric']} headings={[]} rows={rows} />
    </Card>
  );
}
