import { Banner, Card, ChoiceList, Spinner, Text } from '@shopify/polaris';
import { useEffect, useState } from 'react';

export const PdfCard = ({ keys, selectedS3Key, setSelectedS3Key, setIsDirty, resource }) => {
  const [urlsLoaded, setUrlsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfLink, setPdfLink] = useState();
  const [error, setError] = useState(false);

  if (!keys) return null;

  useEffect(() => {
    async function fetchDocument() {
      if (selectedS3Key) {
        setLoading(true);
        const response = await fetch(`/api/${resource}/download?fileKey=${encodeURIComponent(selectedS3Key)}`);
        if (!response.ok) {
          setLoading(false);
          return setError(true);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        setPdfLink(url);
        setLoading(false);
      }
      setSelectedS3Key(selectedS3Key || keys[0]);
      setUrlsLoaded(true);
    }
    fetchDocument();
  }, [selectedS3Key]);

  return (
    urlsLoaded && (
      <Card sectioned>
        {keys.length > 1 ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginBottom: '10px' }}>
            <Banner status="warning">Multiple PDFs are connected to this invoice! Please select the relevant file.</Banner>
            <ChoiceList
              title="Available files"
              titleHidden
              choices={keys.map((key) => {
                const pathArr = key.split('/');
                return {
                  value: key,
                  label: pathArr[pathArr.length - 1]
                };
              })}
              selected={[selectedS3Key]}
              onChange={(value) => {
                setIsDirty(true);
                setSelectedS3Key(value[0]);
              }}
            />
          </div>
        ) : null}

        <div style={{ height: '800px', cursor: 'help' }}>
          {loading ? <Spinner /> : null}
          {error ? <Text>File could not be downloaded.</Text> : null}
          {pdfLink ? <iframe title="PDF Viewer" style={{ width: '100%', height: '100%' }} src={pdfLink + '#toolbar=0'} /> : null}
        </div>
      </Card>
    )
  );
};
