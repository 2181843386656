import { Text, Modal, DescriptionList } from '@shopify/polaris';
import { getCountryCodeForRegionCode, getExample } from 'awesome-phonenumber';

export default function PhoneFormattedExamplesComponent(props) {
  const handleChange = () => props.toggleModal(false);

  const validTest = getCountryCodeForRegionCode(String(props.country));

  if (validTest === 0) return;

  const mobile_example = getExample(String(props.country), 'mobile');
  const landline_example = getExample(String(props.country), 'fixed-line');

  return (
    <div>
      <Modal open={props.active} onClose={handleChange} title={`Formatted Phone Examples for ${props.country}`}>
        <Modal.Section>
          <Text>
            Examples are given and validated in the international E.164 standard, learn{' '}
            <a className="Polaris-Link" rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/E.164">
              more
            </a>
          </Text>
          <br />
          <DescriptionList
            items={[
              {
                term: 'Mobile Number',
                description: mobile_example.number.international
              },
              {
                term: 'Land Fixed Number',
                description: landline_example.number.international
              }
            ]}
          />
        </Modal.Section>
      </Modal>
    </div>
  );
}
