import { InlineStack, Link } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from './functions';

export default function ProjectSelect({ value, id, onChange, label, isDisabled, rowIndex, index }) {
  /*
        value: initial id of the selected company, component will search the label for the given value id
        id: id is the property name of the object
        onChange: callback function to be called when the selected company changes
        returns: a react component that renders a select box with the list of companies that is loaded aysncronously
    */

  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (value) {
        const data = await getRequest(`/api/projects/${value}`);
        if (data) {
          //FIXME: ugly hack to make it work
          setSelectValue({ value: data[0].ID, label: ` ${data[0].Projectnummer} | ${data[0].omschrijving}` });
        }
      }
    }
    fetchData();
  }, [value]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      //calls the api companies for this inputvalue
      const data = await getRequest(`/api/projects/simple/search?omschrijving=${inputValue}`);
      const options = data.map((project) => {
        return {
          value: project.ID,
          label: ` ${project.Projectnummer} | ${project.omschrijving} [${project.NAAM}] `
        };
      });
      callback(options);
    }
  };

  return (
    <>
      {label ? (
        <div style={{ marginBottom: '4px' }}>
          <InlineStack align="space-between">
            <div style={{ color: isDisabled ? 'rgba(181, 181, 181, 1)' : 'black' }}>{label}</div>
            {value && (
              <Link removeUnderline url={`/projects/${value}`}>
                open
              </Link>
            )}
          </InlineStack>
        </div>
      ) : null}

      <AsyncSelect
        isDisabled={isDisabled}
        key={`asyncselect-${index}`}
        placeholder="Search on number, company or description"
        width="200px !important"
        value={value ? selectValue : null}
        loadOptions={loadOptions}
        isClearable
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: isDisabled ? '#ebebeb' : '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
        defaultOptions
        onChange={(props) => {
          if (props) {
            const { value, label } = props;
            setSelectValue({ value: value, label: label });
            onChange(value, id, rowIndex);
          } else {
            setSelectValue({ value: null, label: null });
            onChange(null, id, rowIndex);
          }
        }}
      />
    </>
  );
}
