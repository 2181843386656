import { useState, useCallback } from 'react';
import { Page, Layout, Card, Form, FormLayout, TextField, Toast } from '@shopify/polaris';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { useAuthStore } from '../../context/useStore.js';
import { putRequest } from '../../shared/components/functions.jsx';
import { flattenEntry } from '../../shared/components/settingsFunctions.js';
import { CardHeading } from '../../shared/components/cards/CardHeading';

export default function AccountPage() {
  const { user } = useAuthStore();
  const [initialUser, setInitialUser] = useState(user);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const toggleToast = useCallback(() => setIsSuccess((isSuccess) => !isSuccess), []);
  const toastMarkup = isSuccess ? <Toast content="Changes saved" onDismiss={toggleToast} /> : null;

  const url = `/api/users/${user.id}`;

  const handleChange = (value, id) => {
    setInitialUser((initialUser) => ({ ...initialUser, [id]: value }), setIsDirty(true));
  };

  async function handleSave() {
    setIsSaving(true);
    let payload = flattenEntry({
      Gebruikernaam: { value: initialUser.full_name },
      Email: { value: initialUser.email },
      Birthday: { value: initialUser.birthday }
    });
    await putRequest(url, payload);
    localStorage.setItem('currentUser', JSON.stringify(initialUser));
    setIsSaving(false);
    setIsDirty(false);
    toggleToast();
  }

  async function handleDiscard() {
    const prevUser = await JSON.parse(localStorage.getItem('currentUser'));
    setInitialUser(prevUser);
    setIsDirty(false);
  }

  return (
    <Page title="Account settings">
      {toastMarkup}
      <TheSaveBar isDirty={isDirty} savingResult={isSaving} handleDiscard={handleDiscard} handleSave={handleSave} />
      <Layout>
        <Layout.Section>
          <Card>
            <CardHeading title="Edit account information" />
            <Form onSubmit={handleSave}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="username" label="Username" value={initialUser.username} onChange={handleChange} maxLength={10} showCharacterCount={initialUser.username} disabled autoComplete="off" />
                  <TextField id="full_name" label="Name" value={initialUser.full_name} onChange={handleChange} maxLength={50} showCharacterCount={initialUser.full_name} autoComplete="off" />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="email" label="E-Mail" value={initialUser.email} onChange={handleChange} maxLength={50} showCharacterCount={initialUser.email} autoComplete="off" />
                  <TextField type="date" id="birthday" label="Birthday" value={initialUser.birthday} onChange={handleChange} autoComplete="off" />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
