import { useState, useEffect } from 'react';
import { Modal, Form, FormLayout, TextField } from '@shopify/polaris';
import { getCurrenctDatetimeYYYMMDD, getRequest } from '../../shared/components/functions';
import { object, string, number, addMethod } from 'yup';
import { validateSchema } from '../../helpers/schemas/schemasHelpers';
import invariant from 'invariant';
import { without } from 'lodash';

export default function CashbookModal({ modalOpen, setModalOpen, id, setId, setToast, setErrorToast, fetchTransactions }) {
  const [isLoading, setIsLoading] = useState(true);
  const [transaction, setTransaction] = useState({});

  addMethod(object, 'atLeastOneRequired', function atLeastOneRequired(list, message) {
    invariant(
      list.every((field) => this.fields[field]),
      'All required fields should be defined before calling atLeastOneRequired'
    );
    return this.shape(
      list.reduce(
        (acc, field) => ({
          ...acc,
          [field]: this.fields[field].when(without(list, field), {
            is: (...values) => !values.some((item) => item),
            then: this.fields[field].required(message)
          })
        }),
        {}
      ),
      list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map((i) => [item, i])], [])
    );
  });

  const cashbookSchema = object({
    omschrijving: string().nullable().required('Description is required').max(50, 'Description is too long'),
    'bedrag inkomst': number().nullable(),
    'bedrag uitgaven': number().nullable(),
    datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required')
  }).atLeastOneRequired(['bedrag inkomst', 'bedrag uitgaven'], 'Income or expense is required');

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setTransaction(cashbookSchema.default());
      } else {
        const response = await getRequest(`/api/cashbook/${id}`);
        setTransaction(response);
      }
    }
    if (modalOpen) fetchData();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, modalOpen]);

  const handleChange = (value, id) => {
    if (id === 'bedrag inkomst' || id === 'bedrag uitgaven') {
      value = value.replace(',', '.');
      if (value === '') value = null;
    }
    setTransaction((transaction) => ({
      ...transaction,
      [id]: value
    }));
  };

  const handleSave = async () => {
    const errorInSchema = await validateSchema(cashbookSchema, transaction);
    if (!errorInSchema) {
      setIsLoading(true);
      const url = id === 'new' ? `/api/cashbook` : `/api/cashbook/${id}`;
      const method = id === 'new' ? 'POST' : 'PUT';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transaction)
      });
      if (response.status >= 200 && response.status <= 399) {
        setModalOpen(false);
        await fetchTransactions();
        setIsLoading(false);
        setToast(true);
      } else {
        console.log('Error, Response:', response);
        setErrorToast(true);
      }
    } else {
      alert(errorInSchema);
    }
  };

  return (
    <Modal
      open={modalOpen}
      loading={isLoading}
      onClose={() => {
        setModalOpen(false);
        setId('new');
      }}
      title={id === 'new' ? 'New cashbook transaction' : `Cashbook transaction #${transaction.Kasboeknummer}`}
      primaryAction={{
        content: id === 'new' ? 'Save' : 'Edit',
        onAction: () => handleSave(),
        loading: isLoading
      }}
    >
      <Modal.Section>
        <Form onSubmit={handleSave}>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField id="datum" label="Date" value={String(transaction.datum).substring(0, 10)} type="date" onChange={handleChange} />
              <TextField
                id="bedrag inkomst"
                label="Income"
                value={transaction['bedrag inkomst'] ? String(transaction['bedrag inkomst']) : 0}
                type="text"
                autoComplete="off"
                prefix="€"
                onChange={handleChange}
                disabled={transaction['bedrag uitgaven'] ? true : false}
              />
              <TextField
                id="bedrag uitgaven"
                label="Expense"
                value={transaction['bedrag uitgaven'] ? String(transaction['bedrag uitgaven']) : 0}
                type="text"
                autoComplete="off"
                prefix="€"
                onChange={handleChange}
                disabled={transaction['bedrag inkomst'] ? true : false}
              />
            </FormLayout.Group>
            <TextField id="omschrijving" label="Description" value={transaction.omschrijving} type="text" onChange={handleChange} maxLength={50} showCharacterCount={transaction.omschrijving} />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
}
