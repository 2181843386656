import React from 'react';
import { getCurrenctDatetimeYYYMMDD } from '../../../shared/components/functions';

function parseDate(str) {
  const date = str.substring(0, 10);
  var mdy = date.split('-');
  return new Date(mdy[0], mdy[1] - 1, mdy[2]);
}

function datediff(first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.]
  return Math.round((parseDate(second) - parseDate(first)) / (1000 * 60 * 60 * 24));
}

export default function ProjectTimeLine({ displaySimple, project }) {
  const projectMap = {
    0: {
      name: 'Aangevraagd',
      color: 'orange',
      text: 'Customer is waiting on our reply...'
    },
    1: {
      name: 'Bij Klant',
      color: '#787878',
      text: 'We are waiting for the reply of the customer...'
    },
    2: {
      name: 'Verloren',
      color: '#EE3623',
      text: 'Project is lost'
    },
    3: {
      name: 'Vervangen',
      color: 'gray',
      text: 'We replaced the project with another project'
    },
    4: {
      name: 'Besteld',
      color: '#AEE9D1',
      text: 'Project is ordered'
    },
    5: {
      name: 'Geen investering',
      color: 'gray',
      text: 'Customer did not invest'
    },
    6: {
      name: 'Other reason [inactive]',
      color: 'gray',
      text: 'Divers'
    }
  };

  let markup = '';
  const timelinHeight = displaySimple ? '25px' : '50px';
  const timelineWidth = displaySimple ? '200px' : '100%';
  const shadow = displaySimple ? '0px' : '4px 4px 4px lightgray';
  const fonsize = displaySimple ? '15px' : '20px';
  const fontsizeDates = '15px';
  const borderradius = '5px';
  const color = displaySimple ? 'white' : 'white';

  const replyTimeSchuilenburg = project['aanvraag offerte'] && project['offerte'] ? datediff(project['aanvraag offerte'], project['offerte']) : '?';
  const replyTimeCustomer = project['Beslissing'] && project['offerte'] ? datediff(project['offerte'], project['Beslissing']) : '?';

  //this is the time that goes between the decision of the custoemr and the delivey, is not always set
  //const timeToStart = project['aanvraag offerte'] && project['offerte'] ? datediff(project['aanvraag offerte'], project['offerte']) : '?';

  //calculate if a delivery time is set
  const deliveryTime = project['Leverings'] ? project['Leverings'].substring(0, 10) : null;

  //calculate if we are before delivery date
  let weAreBeforeDelivery = false;

  if (project.Leverings && datediff(getCurrenctDatetimeYYYMMDD(), project.Leverings) > 0) {
    weAreBeforeDelivery = true;
  }

  //calculate if we are after the end date
  let weAreAfterEndDate = false;

  if (project.Einde && datediff(getCurrenctDatetimeYYYMMDD(), project.Einde) < 0) {
    weAreAfterEndDate = true;
  }

  /*
            Futher dates that need to be set:
            - first time that an incoming invoice is created (this marks the start of the project)
            - last outgoing invoice, this is the end of the project

            Further more we need to do calcualtions on how much is inovoiced for the project 

            Project can be a normal project or it can be one on comission, there is not yet a field created for this is in the database
            price calculations need to be done on the basis of the project type and the connected timeslots etc

            Maybe add a status switcher to the banner also so all the data is centralized there for clear overview. 

            This banner can also be made for invoices. So the status is directly clear for the viewer? 

            - Should the days also be moved to the table display component?

            We need to do some cleaning up of this component. 
      */

  switch (project.status) {
    case 0:
      //Aangevraagd
      markup = (
        <>
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '700', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                padding: displaySimple ? '0px' : '14px',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? projectMap[project.status].text : null}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
            </div>
          )}
        </>
      );
      break;
    case 1:
      //Bij Klant
      var percentage = 10;
      markup = (
        <>
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '700', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                padding: displaySimple ? '0px' : '14px',
                width: '100%',
                position: 'absolute',
                height: timelinHeight,
                paddingLeft: percentage + 2 + '%',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? projectMap[project.status].text : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage + '%',
                height: '100%',
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                position: 'absolute',
                backgroundColor: 'lightgray',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeSchuilenburg + ' days' : null}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage + '%' }}
              >
                <div>We reply</div>
                <div>{project['offerte'] ? project['offerte'].substring(0, 10) : '?'}</div>
              </div>
            </div>
          )}
        </>
      );
      break;
    case 2:
      //Verloren
      var percentage1 = 10;
      var percentage2 = 25;
      markup = (
        <>
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '600', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                padding: displaySimple ? '0px' : '14px',
                top: '0',
                left: '0',
                paddingLeft: percentage2 + 2 + '%',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? projectMap[project.status].text : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage2 + '%',
                height: timelinHeight,
                paddinTop: '14px',
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                paddingLeft: percentage1 + 2 + '%',
                position: 'absolute',
                top: '0',
                left: '0',
                backgroundColor: 'red',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeCustomer + ' days' : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage1 + '%',
                height: timelinHeight,
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                position: 'absolute',
                top: '0',
                left: '0',
                backgroundColor: '#A10112',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeSchuilenburg + ' days' : null}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage1 + '%' }}
              >
                <div>We reply</div>
                <div>{project['offerte'] ? project['offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage2 + '%' }}
              >
                <div>Customer decision</div>
                <div>{project['Beslissing'] ? project['Beslissing'].substring(0, 10) : '?'}</div>
              </div>
            </div>
          )}
        </>
      );
      break;
    case 3:
      //Vervangen
      percentage = 10;
      markup = (
        <>
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '700', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                padding: displaySimple ? '0px' : '14px',
                width: '100%',
                position: 'absolute',
                height: timelinHeight,
                paddingLeft: percentage + 2 + '%',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? projectMap[project.status].text : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage + '%',
                height: '100%',
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                position: 'absolute',
                backgroundColor: 'lightgray',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeSchuilenburg + ' days' : null}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage + '%' }}
              >
                <div>We reply</div>
                <div>{project['offerte'] ? project['offerte'].substring(0, 10) : '?'}</div>
              </div>
            </div>
          )}
        </>
      );
      break;
    case 4:
      //Besteld
      percentage1 = 10;
      percentage2 = 25;
      var percentage3 = 60;
      var percentage4 = 80;

      markup = (
        <>
          {!displaySimple ? (
            <div style={{ fontSize: '18px', marginBottom: '8px' }}>
              {weAreBeforeDelivery
                ? 'This project has been ordered and waiting to be delivered.'
                : weAreAfterEndDate
                ? 'This project has been ordered and has ended'
                : 'This project has been ordered but did not end yet'}
            </div>
          ) : null}
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '600', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                padding: displaySimple ? '0px' : '14px',
                top: '0',
                left: '0',
                paddingLeft: percentage2 + 2 + '%',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple
                ? weAreBeforeDelivery
                  ? `${projectMap[project.status].text}, ${datediff(getCurrenctDatetimeYYYMMDD(), project.Leverings)} days until project delivery date`
                  : weAreAfterEndDate
                  ? `${projectMap[project.status].text} and after the end date`
                  : project.Leverings
                  ? `${projectMap[project.status].text} and is being delivered at the moment`
                  : 'Project is ordered, no delivery date is set'
                : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage1 + '%',
                height: '100%',
                padding: displaySimple ? '0px' : '14px',
                paddingLeft: displaySimple ? '0px' : '14px',
                margin: '0',
                position: 'absolute',
                backgroundColor: '#53BD7F',
                zIndex: '10',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeSchuilenburg + ' days' : ''}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage2 + '%',
                height: '100%',
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                paddingLeft: percentage1 + 2 + '%',
                position: 'absolute',
                top: '0',
                left: '0',
                backgroundColor: '#81DC8C',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeCustomer + ' days' : ''}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage1 + '%' }}
              >
                <div>We reply</div>
                <div>{project['offerte'] ? project['offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage2 + '%' }}
              >
                <div>Customer decision</div>
                <div>{project['Beslissing'] ? project['Beslissing'].substring(0, 10) : '?'}</div>
              </div>
              {weAreBeforeDelivery && (
                <>
                  <div
                    className="ProjectContainer"
                    style={{
                      color: 'darkgray',
                      fontSize: fontsizeDates,
                      paddingTop: '10px',
                      paddingLeft: '3px',
                      borderLeft: '1px solid lightgray',
                      position: 'absolute',
                      marginLeft: percentage3 + '%'
                    }}
                  >
                    <div>Today</div>
                    <div>{getCurrenctDatetimeYYYMMDD().substring(0, 10)}</div>
                  </div>
                  <div
                    className="ProjectContainer"
                    style={{
                      color: 'darkgray',
                      fontSize: fontsizeDates,
                      paddingTop: '10px',
                      paddingLeft: '3px',
                      borderLeft: '1px solid lightgray',
                      position: 'absolute',
                      marginLeft: percentage4 + '%'
                    }}
                  >
                    <div>Delivery Date</div>
                    <div>{project['Leverings'] ? deliveryTime : '?'}</div>
                  </div>
                </>
              )}
              {project.Einde && (
                <>
                  <div
                    className="ProjectContainer"
                    style={{
                      color: 'darkgray',
                      fontSize: fontsizeDates,
                      paddingTop: '10px',
                      paddingLeft: '3px',
                      borderRight: '1px solid lightgray',
                      position: 'absolute',
                      width: '100%',
                      textAlign: 'right',
                      paddingRight: '5px'
                    }}
                  >
                    <div>End date</div>
                    <div>{project.Einde ? project.Einde.substring(0, 10) : '?'}</div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      );
      break;
    case 5:
      //Geen investering
      percentage = 10;
      markup = (
        <>
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '700', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                padding: displaySimple ? '0px' : '14px',
                width: '100%',
                position: 'absolute',
                height: timelinHeight,
                paddingLeft: percentage + 2 + '%',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? projectMap[project.status].text : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage + '%',
                height: '100%',
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                position: 'absolute',
                backgroundColor: 'lightgray',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeSchuilenburg + ' days' : null}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage + '%' }}
              >
                <div>We reply</div>
                <div>{project['offerte'] ? project['offerte'].substring(0, 10) : '?'}</div>
              </div>
            </div>
          )}
        </>
      );
      break;
    case 6:
      //Divers
      percentage = 10;
      markup = (
        <>
          <div
            className="ProjectContainer"
            style={{ boxShadow: shadow, width: timelineWidth, height: timelinHeight, position: 'relative', borderRadius: borderradius, color: color, fontWeight: '700', fontSize: fonsize }}
          >
            <div
              className="box"
              style={{
                padding: displaySimple ? '0px' : '14px',
                width: '100%',
                position: 'absolute',
                height: timelinHeight,
                paddingLeft: percentage + 2 + '%',
                backgroundColor: projectMap[project.status].color,
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? projectMap[project.status].text : null}
            </div>
            <div
              className="box stack-top"
              style={{
                width: percentage + '%',
                height: '100%',
                padding: displaySimple ? '0px' : '14px',
                margin: '0',
                position: 'absolute',
                backgroundColor: 'lightgray',
                zIndex: '9',
                borderRadius: borderradius
              }}
            >
              {!displaySimple ? replyTimeSchuilenburg + ' days' : null}
            </div>
          </div>
          {displaySimple ? null : (
            <div className="ProjectContainer" style={{ position: 'relative', height: timelinHeight }}>
              <div className="ProjectContainer" style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute' }}>
                <div>Received</div>
                <div>{project['aanvraag offerte'] ? project['aanvraag offerte'].substring(0, 10) : '?'}</div>
              </div>
              <div
                className="ProjectContainer"
                style={{ color: 'darkgray', fontSize: fontsizeDates, paddingTop: '10px', paddingLeft: '3px', borderLeft: '1px solid lightgray', position: 'absolute', marginLeft: percentage + '%' }}
              >
                <div>We reply</div>
                <div>{project['offerte'] ? project['offerte'].substring(0, 10) : '?'}</div>
              </div>
            </div>
          )}
        </>
      );
      break;
    default:
      markup = null;
      break;
  }
  return markup;
}
