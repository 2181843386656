import { Badge, Card, DataTable, Link, Page } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { formatToCurrency, getRequest, getTableFooter } from '../../shared/components/functions';

export const ExpensesPage = () => {
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  const fetchData = useCallback(async () => {
    const data = await getRequest(`/api/expenses/users?page=${page}`);
    setItems(data);
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={'/expenses/' + item.GebruikerID}>
            {item.user_name}
          </Link>,
          <Badge tone={item.awaitingApprovalQty > 0 ? 'warning' : 'success'}>
            <Link monochrome removeUnderline url={`/expenses/${item.GebruikerID}?tab=1`}>{`${item.awaitingApprovalQty}`}</Link>
          </Badge>,
          <Badge tone={item.awaitingApprovalValue > 0 ? 'warning' : 'success'}>
            <Link monochrome removeUnderline url={`/expenses/${item.GebruikerID}?tab=1`}>
              {formatToCurrency(item.awaitingApprovalValue)}
            </Link>
          </Badge>,
          <Badge tone={item.awaitingPaymentValue > 0 ? 'warning' : 'success'}>
            <Link monochrome removeUnderline url={`/expenses/${item.GebruikerID}?tab=0`}>
              {formatToCurrency(item.awaitingPaymentValue)}
            </Link>
          </Badge>
        ];
      })
    : [];

  const totals =
    items.length > 0
      ? [
          '',
          items.reduce((p, c) => p + c.awaitingApprovalQty, 0),
          formatToCurrency(items.reduce((p, c) => p + c.awaitingApprovalValue, 0)),
          formatToCurrency(items.reduce((p, c) => p + c.awaitingPaymentValue, 0))
        ]
      : null;

  return (
    <Page title="Expenses" fullWidth>
      <Card>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'numeric', 'numeric', 'numeric']}
          totals={totals}
          showTotalsInFooter
          headings={[<b>Username</b>, <b>Receipts awaiting approval</b>, <b>Total value awaiting approval</b>, <b>Approved, waiting for payment</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'users')}
        />
      </Card>
    </Page>
  );
};
