import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Banner, Box, InlineStack, Link, Text, BlockStack } from '@shopify/polaris';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import { getRequest, alphabetize, simplePostRequest } from '../../shared/components/functions.jsx';
import { handleSaveWithoutLines, useUrl } from '../../shared/util/hanldesave';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import { ReactSelect } from '../../shared/components/ReactSelect';
import Select from 'react-select';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';

/*
  FIXME:
  - Factuurdatum is not a date field in the database, needs to be fixed
  - Onderhoudscontracten are not linked to machines on a database level, this need to be fixed
       --> THIS is really bad because all machine info is duplicated and not synced, maintenance contract need to have a pointer to the machine or the way around
  - Couple of things need to still happen here:
    - Possible link with invoice: set also date of that invoice (?why)
    - To do on update events, auto setting of next dates etc. , in code, this is the file: 'Form Menu_Onderhoudscontract.cls'
*/

export default function MaintenanceContractPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const docTypeID = 8;
  const docType = 'maintenance_contract';

  const [initialMaintenanceContract, setInitialMaintenanceContract] = useState('');
  const [maintenanceContract, setMaintenanceContract] = useState('');
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [company, setCompany] = useState({});
  const [savingResult, setSavingResult] = useState(false);
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [companyInvoices, setCompanyInvoices] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { machinebrands, machinetypes, servicecontracttypes, countries, languages } = useLookupStore();
  const { user } = useAuthStore();
  const pageTitle = getPageTitle(maintenanceContract.Onderhoudscontractnummer, 'Maintenance contract');
  const maintenanceContractSchema = getSchema('maintenance_contract', url, null, user);
  const canGenerateMaintenanceContract =
    maintenanceContract.Machinemerk === 1 || maintenanceContract.Machinemerk === 2 || maintenanceContract.Machinemerk === 10 || maintenanceContract.Machinemerk === 16;

  const invoiceOptions = companyInvoices.map((invoice) => {
    return { label: invoice.Factuurnummer, value: invoice.faktuurnr, date: invoice.datum };
  });

  const handleChange = (value, id) => {
    if (id === 'Faktuurnummer') {
      return setMaintenanceContract((maintenanceContract) => ({ ...maintenanceContract, Faktuurnummer: value.value, Faktuurdatum: value.date }), setIsDirty(true));
    }

    if (id === 'Bezoeken') value = value.replace(/[^0-9]/g, '');

    setMaintenanceContract(
      (maintenanceContract) => ({
        ...maintenanceContract,
        [id]: value
      }),
      setIsDirty(true)
    );
  };

  //Handling saving the form
  const handleSave = async () => {
    const errorInSchema = await validateSchema(maintenanceContractSchema, maintenanceContract);

    if (!errorInSchema) {
      handleSaveWithoutLines(
        id,
        maintenanceContract,
        'maintenance_contracts',
        maintenanceContract.Kontraktnummer,
        'Kontraktnummer',
        setMaintenanceContract,
        setInitialMaintenanceContract,
        setIsDirty,
        setToastError,
        setToast,
        setSavingResult,
        navigate
      );
      if (id && id !== 'new' && canGenerateMaintenanceContract) await generateDocument();
    } else {
      alert(errorInSchema);
    }
  };

  const handleDiscard = () => {
    setMaintenanceContract(initialMaintenanceContract);
    setIsDirty(false);
  };

  useEffect(() => {
    async function fetchData() {
      //if url contains /new then set credit note in to its interface
      if (id === 'new') {
        setMaintenanceContract(maintenanceContractSchema.default());
        setInitialMaintenanceContract(maintenanceContractSchema.default());
      } else {
        //else get the credit note in resource via api get request
        const data = await getRequest(`/api/maintenance_contracts/${id}`);
        if (!data || data.length === 0) {
          setMaintenanceContract(maintenanceContractSchema.default());
          setInitialMaintenanceContract(maintenanceContractSchema.default());
          return;
        }
        setMaintenanceContract(data[0]);
        setInitialMaintenanceContract(data[0]);
      }
    }
    fetchData();
    setDataLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (maintenanceContract.Firnr) {
        setCompanyInvoices(await getRequest(`/api/invoices?limit=10000&FIRNR=${maintenanceContract.Firnr}`));
        setCompany(await getRequest(`/api/companies/${maintenanceContract.Firnr}`));
      }
    }
    fetchData();
  }, [maintenanceContract.Firnr]);

  useEffect(() => {
    const { laatste, periode } = maintenanceContract;

    if (periode && laatste && dataLoaded) {
      const date = new Date(laatste.valueOf());
      const nextVisit = new Date(date.setDate(date.getDate() + parseInt(periode))).toISOString();

      //update the next visit
      setMaintenanceContract((maintenanceContract) => ({
        ...maintenanceContract,
        Volgende: nextVisit
      }));
      if (periode && periode !== '0') {
        setMaintenanceContract((maintenanceContract) => ({
          ...maintenanceContract,
          Bezoeken: parseInt(365 / periode)
        }));
      }
    }
    //eslint-disable-next-line
  }, [maintenanceContract.periode, maintenanceContract.laatste]);

  useEffect(() => {
    async function getEmailStatus() {
      setMaintenanceContract((maintenanceContract) => ({
        ...maintenanceContract,
        Verstuurd: emailSent
      }));
    }
    getEmailStatus();
  }, [emailSent]);

  var discontinued_banner = maintenanceContract.DISCONTINUED ? (
    <Box paddingBlockEnd={'400'}>
      <Banner tone="critical" title="This maintenance contract is discontinued!" />
    </Box>
  ) : null;

  const generateDocument = async () => {
    const translation = {
      1: 'Werken',
      2: 'WerkenFR',
      3: 'WerkenUK',
      4: 'WerkenDE'
    };

    const taskTranslation = machinebrands.find((m) => m.counter === maintenanceContract.Machinemerk)[translation[company.TaalID]];
    const language = company.TaalID ? languages.find((l) => l.TaalID === parseInt(company.TaalID))?.Taal : 'N';

    const document = {
      addressee: {
        FIRNR: company.FIRNR,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      eu: false,
      language: language === 'F' ? 'F' : 'N',
      type: docType,
      id: maintenanceContract.Kontraktnummer,
      typeID: docTypeID,
      number: maintenanceContract.Onderhoudscontractnummer,
      date: String(maintenanceContract.aanvangsdatum).substring(0, 10),
      contract_type: servicecontracttypes.find((c) => c.OnderhoudscontractTypeID === maintenanceContract.OnderhoudscontractTypeID).OnderhoudscontractType,
      machine_type: maintenanceContract.Type,
      machine_brand: machinebrands.find((m) => m.counter === maintenanceContract.Machinemerk).merk,
      machine_brand_id: maintenanceContract.Machinemerk,
      serial_number: maintenanceContract.serienummer,
      tasks: taskTranslation || machinebrands.find((m) => m.counter === maintenanceContract.Machinemerk).Werken
    };
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <TheSaveBar isDirty={isDirty} handleSave={handleSave} handleDiscard={handleDiscard} savingResult={savingResult} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent={'Maintenance contract saved'} />
      {discontinued_banner}
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={handleSave}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="Kontraktnummer" disabled label="Number" value={maintenanceContract.Kontraktnummer ? String(maintenanceContract.Kontraktnummer) : 'created automatically'} />
                  <TextField id="Firnr" label="Company" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${maintenanceContract.Firnr}`) }} />
                  <ReactSelect
                    id="OnderhoudscontractTypeID"
                    label="Type"
                    placeholder="Select maintenance contract type"
                    savingResult={savingResult}
                    handleChange={handleChange}
                    value={maintenanceContract.OnderhoudscontractTypeID}
                    options={servicecontracttypes
                      .sort((a, b) => alphabetize(a, b, 'OnderhoudscontractType'))
                      .map((cont) => {
                        return { value: cont.OnderhoudscontractTypeID, label: cont.OnderhoudscontractType };
                      })}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <ReactSelect
                    id="Type"
                    label="Machine type"
                    placeholder="Select machine type"
                    savingResult={savingResult}
                    handleChange={handleChange}
                    value={maintenanceContract.Type}
                    options={machinetypes.map((type, idx) => {
                      return { key: idx, label: type.Type, value: type.Type };
                    })}
                  />
                  <TextField
                    id="periode"
                    maxLength={60}
                    type="number"
                    autoComplete="off"
                    label="Period"
                    value={maintenanceContract.periode ? String(maintenanceContract.periode) : '0'}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                  <TextField id="Bezoeken" value={maintenanceContract.Bezoeken} onChange={handleChange} autoComplete="off" label="Number of visits" disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <ReactSelect
                    id="Machinemerk"
                    label="Machine brand"
                    placeholder="Select machine brand"
                    savingResult={savingResult}
                    handleChange={handleChange}
                    value={maintenanceContract.Machinemerk}
                    options={machinebrands
                      .sort((a, b) => alphabetize(a, b, 'merk'))
                      .map((brand) => {
                        return { label: brand.merk, value: brand.counter };
                      })}
                  />
                  <TextField id="aanvangsdatum" type="date" label="Start" value={String(maintenanceContract.aanvangsdatum).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                  <Box>
                    <Box paddingBlockEnd="100">
                      <InlineStack align="space-between">
                        <Box>
                          <Text>Invoice</Text>
                        </Box>
                        <Link removeUnderline onClick={() => navigate(`/invoices/${maintenanceContract.Faktuurnummer}`)}>
                          open
                        </Link>
                      </InlineStack>
                    </Box>
                    <Select
                      id="Faktuurnummer"
                      placeholder="Select invoice"
                      options={invoiceOptions}
                      value={invoiceOptions.find((o) => o.value === Number(maintenanceContract.Faktuurnummer))}
                      onChange={(e) => handleChange(e, 'Faktuurnummer')}
                      isDisabled={savingResult}
                      menuPortalTarget={document.body}
                      styles={{
                        valueContainer: (base) => ({ ...base, height: '32px' }),
                        indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                        indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                        control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                      }}
                    />
                  </Box>
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField disabled id="serienummer" maxLength={60} autoComplete="off" label="Serial number" value={maintenanceContract.serienummer || ''} onChange={handleChange} />
                  <TextField id="laatste" type="date" label="Last visit" value={String(maintenanceContract.laatste).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                  <TextField
                    disabled
                    id="Faktuurdatum"
                    autoComplete="off"
                    label="Invoice date"
                    value={maintenanceContract.Faktuurdatum ? String(maintenanceContract.Faktuurdatum).substring(0, 10) : ''}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <BlockStack>
                    <Checkbox id="Verstuurd" label="PDF Document emailed" checked={maintenanceContract.Verstuurd} onChange={handleChange} disabled={savingResult} />
                    <Checkbox id="DISCONTINUED" label="Discontinued" checked={maintenanceContract.DISCONTINUED} onChange={handleChange} disabled={savingResult} />
                  </BlockStack>
                  <OwnerSelect id="GebruikerID" label="Owner" value={maintenanceContract.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                  <TextField
                    id="Volgende"
                    type="date"
                    label="Next visit"
                    value={maintenanceContract.Volgende ? String(maintenanceContract.Volgende).substring(0, 10) : ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <TextField id="memo1" label="Note" value={maintenanceContract.memo1 || ''} multiline={6} onChange={handleChange} disabled={savingResult} />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <DocumentCard
            resource={'MAINTENANCE_CONTRACT'}
            id={id}
            title="Maintenance contract document"
            docNumber={maintenanceContract.Onderhoudscontractnummer}
            isDirty={isDirty}
            generateDocument={canGenerateMaintenanceContract ? generateDocument : false}
            docTypeID={docTypeID}
            docType={docType}
            company={company}
            setResource={setMaintenanceContract}
            setEmailSent={setEmailSent}
            docLang={company.TaalID}
            documentButtonLoading={savingResult || documentInProgress}
            setDocumentButtonLoading={setSavingResult}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
