import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { getRequest, stripHtml } from '../../../shared/components/functions';

export default function JobsCompany({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/jobs?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var status = item.Closed ? <Badge>Closed</Badge> : <Badge tone="success">Open</Badge>;
        var invoiced = item.Invoiced ? <Badge>Yes</Badge> : <Badge tone="warning">No</Badge>;

        return [
          <Link removeUnderline url={`/jobs/${item.JobID}`}>
            {item.JobNumber}
          </Link>,
          String(item.OrderDate).substring(0, 10),
          <Badge>{item.job_type}</Badge>,
          stripHtml(item.Description).substring(0, 50),
          item.brand,
          String(item.ChangedOn).substring(0, 10),
          item.ChangedBy,
          invoiced,
          status,
          'Actions'
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
      headings={[
        <b>{'Number'}</b>,
        <b>{'Date'}</b>,
        <b>{'Type'}</b>,
        <b>{'Description'}</b>,
        <b>{'Brand'}</b>,
        <b>{'Changed on'}</b>,
        <b>{'Changed by'}</b>,
        <b>{'Invoiced'}</b>,
        <b>{'Status'}</b>,
        <b>{'Actions'}</b>
      ]}
      rows={rows}
    />
  ) : (
    'No jobs'
  );
}
