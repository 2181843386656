import { useState } from 'react';
import { DataTable, Page, Card, Badge } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import { useAuthStore, useLookupStore } from '../../../../context/useStore.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsUsersPage() {
  const { user } = useAuthStore();
  const [users, setUsers] = useLookupStore((state) => [state.users, state.setUsers]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    Gebruiker: { type: 'text', label: 'Username', max: '10', value: '', disabled: false, required: true },
    //Gebruikertype: { type: 'text', label: 'User Type', max: '', value: '' },
    Gebruikernaam: { type: 'text', label: 'Name', max: '50', value: '', disabled: false, required: true },
    Afkorting: { type: 'text', label: 'Abbreviation', max: '3', value: '', disabled: false, required: true },
    //Vennootschap: { type: 'text', label: 'Organisation', max: '', value: '' },
    Email: { type: 'text', label: 'Email', max: '50', value: '', disabled: false, required: true },
    DropboxPath: { type: 'text', label: 'DropBox', max: '500', value: '' },
    TaalID: { type: 'select', label: 'language', value: '' },
    UserType: { type: 'select', label: 'User type', value: '', required: true },
    Inactief: { type: 'active', label: 'Is inactive', value: false },
    InPlanning: { type: 'active', label: 'Is in planning', value: false },
    Birthday: { type: 'date', label: 'Birthday', value: '' }
    // ITTarief
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/users';
  const title = 'Users';

  const rows = users.map((user) => {
    return [
      user.Gebruiker,
      user.Inactief ? <Badge tone="critical">Inactive</Badge> : user.Gebruikertype,
      user.Gebruikernaam,
      user.Afkorting,
      user.Email,
      getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, user, user.GebruikerID)
    ];
  });

  return (
    <Page
      title="Users"
      fullWidth
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add user', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId), disabled: user.role !== 1 && user.role !== 6 }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setUsers}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric']}
          headings={[<b>User</b>, <b>User type</b>, <b>User name</b>, <b>Abbrev</b>, <b>Email</b>, <b>Edit</b>]}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
