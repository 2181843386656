import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsMaintenanceContractsPage() {
  const [servicecontracttypes, setServicecontracttypes] = useLookupStore((state) => [state.servicecontracttypes, state.setServicecontracttypes]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    OnderhoudscontractType: { type: 'text', label: 'NL', max: '20', value: '' },
    OnderhoudscontractTypeFR: { type: 'text', label: 'FR', max: '20', value: '' },
    OnderhoudscontractTypeDE: { type: 'text', label: 'DE', max: '20', value: '' },
    OnderhoudscontractTypeUK: { type: 'text', label: 'UK', max: '20', value: '' },
    Actief: { type: 'active', label: 'Active', value: true }
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/service_contracttypes';
  const title = 'Maintenance contract types';

  const rows = servicecontracttypes.map((item) => {
    return [
      item.OnderhoudscontractType,
      item.OnderhoudscontractTypeFR,
      item.OnderhoudscontractTypeDE,
      item.OnderhoudscontractTypeUK,
      getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.OnderhoudscontractTypeID)
    ];
  });

  return (
    <Page
      title={title}
      fullWidth
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add maintenance contract type', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setServicecontracttypes}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={[<b>NL</b>, <b>FR</b>, <b>DE</b>, <b>EN</b>, <b>Edit</b>]}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
