import React from 'react';
import { Card, Button, Icon, TextField, Form, Text } from '@shopify/polaris';
import { useLookupStore } from '../../../context/useStore.js';
import { DeleteIcon } from '@shopify/polaris-icons';
import { CardHeading } from '../../../shared/components/cards/CardHeading.jsx';
import ReactSelect from 'react-select';

/*
  FIXME:
  - in approvals, project is never set to, so this is always a null value in the database
  - It is not possible to reject an approval, so the reject button is never shown
*/

export default function InventoryCard({ handleFormSubmit, id, product, inventories, setInventories, setInventoriesDirty }) {
  const { pickingplaces } = useLookupStore();

  const inventoryInterface = {
    Productid: product.id,
    Stockplaats: null,
    aantal: 0,
    counter: null,
    Rack: 0,
    Legplank: 0,
    pickingplace: null
  };

  const addEmptyLine = () => {
    setInventories((old) => [...old, inventoryInterface]);
    setInventoriesDirty(true);
  };

  const removeLineByIndex = (rowIndex) => {
    setInventories((old) => old.filter((row, index) => index !== rowIndex));
    setInventoriesDirty(true);
  };

  const lineUpdate = (value, columnId, rowIndex) => {
    let lineValue = value;
    if (columnId === 'Stockplaats') lineValue = value.value;

    setInventories((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: lineValue,
            lineIsDirty: true //will be used to process in API
          };
        }
        return row;
      })
    );
    setInventoriesDirty(true);
  };

  if (id === 'new')
    return (
      <Card>
        <CardHeading title="Product inventories" />
        <Text>Inventory is available after saving.</Text>
      </Card>
    );

  const pickingplaceOptions = pickingplaces.map((place) => {
    return { value: place.counter, label: place.pickingplace };
  });

  return (
    <Card>
      <CardHeading title="Product inventories" />
      <Form onSubmit={handleFormSubmit}>
        {inventories.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Place</th>
                <th style={{ textAlign: 'left' }}>Qty</th>
                <th style={{ textAlign: 'left' }}>Rack</th>
                <th style={{ textAlign: 'left' }}>Shelf</th>
                <th style={{ textAlign: 'left' }}>Remove</th>
              </tr>
            </thead>
            <tbody>
              {inventories.map((inventory, index) => {
                return (
                  <tr key={index}>
                    <td style={{ width: '25%', textAlign: 'left' }}>
                      <ReactSelect
                        id="Stock"
                        isDisabled={inventory.Stockplaats === 1}
                        options={pickingplaceOptions.filter((p) => !inventories.map((i) => i.Stockplaats).includes(p.value))}
                        onChange={(value) => lineUpdate(value, 'Stockplaats', index)}
                        value={pickingplaceOptions.find((pl) => pl.value === parseInt(inventory.Stockplaats))}
                        menuPortalTarget={document.body}
                        styles={{
                          valueContainer: (base) => ({ ...base, height: '32px' }),
                          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 })
                        }}
                      />
                    </td>
                    <td style={{ width: '25%', textAlign: 'left' }}>
                      <TextField id="Quantity" onChange={(value) => lineUpdate(value, 'aantal', index)} type="number" value={String(inventory.aantal)} />
                    </td>
                    <td style={{ width: '20%', textAlign: 'right' }}>
                      <TextField id="Rack" onChange={(value) => lineUpdate(value, 'Rack', index)} type="number" value={String(inventory.Rack)} />
                    </td>
                    <td style={{ width: '20%', textAlign: 'right' }}>
                      <TextField id="Shelf" onChange={(value) => lineUpdate(value, 'Legplank', index)} type="number" value={String(inventory.Legplank)} />
                    </td>
                    <td style={{ width: '10%' }}>
                      <Button onClick={() => removeLineByIndex(index)} disabled={inventory.Stockplaats === 1}>
                        <Icon source={DeleteIcon} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
        <div style={{ marginTop: '15px' }}>
          <Button id="AddInventory" disabled={id === 'new'} onClick={addEmptyLine}>
            Add inventory place
          </Button>
        </div>
      </Form>
    </Card>
  );
}
