import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import { truncate } from '../../../../shared/components/functions.jsx';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsEmailFormatsPage() {
  const [emailformats, setEmailformats] = useLookupStore((state) => [state.emailformats, state.setEmailformats]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    EmailSubjectNL: { type: 'text', label: 'Subject NL', max: '50', value: '' },
    EmailBodyStartNL: { type: 'text', label: 'Body Start NL', max: '100', multi: true, value: '' },
    EmailBodyNL: { type: 'text', label: 'Body NL', max: '4000', multi: true, value: '' },
    EmailSubjectFR: { type: 'text', label: 'Subject FR', max: '50', value: '' },
    EmailBodyStartFR: { type: 'text', label: 'Body Start FR', max: '100', multi: true, value: '' },
    EmailBodyFR: { type: 'text', label: 'Body FR', max: '4000', multi: true, value: '' },
    EmailSubjectDE: { type: 'text', label: 'Subject DE', max: '50', value: '' },
    EmailBodyStartDE: { type: 'text', label: 'Body Start DE', max: '100', multi: true, value: '' },
    EmailBodyDE: { type: 'text', label: 'Body DE', max: '4000', multi: true, value: '' },
    EmailSubjectUK: { type: 'text', label: 'Subject UK', max: '50', value: '' },
    EmailBodyStartUK: { type: 'text', label: 'Body Start UK', max: '100', multi: true, value: '' },
    EmailBodyUK: { type: 'text', label: 'Body UK', max: '4000', multi: true, value: '' },
    DocumentTypeID: { type: 'select', label: 'Document type', value: '' }
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/emailformats';
  const title = 'Email formats';

  const rows = emailformats.map((item) => {
    return [
      truncate(item.EmailSubjectNL, 15),
      truncate(item.EmailBodyNL, 15),
      truncate(item.EmailSubjectFR, 15),
      truncate(item.EmailBodyFR, 15),
      truncate(item.EmailSubjectDE, 15),
      truncate(item.EmailBodyDE, 15),
      truncate(item.EmailSubjectUK, 15),
      truncate(item.EmailBodyUK, 15),
      getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.EmailFormatID)
    ];
  });

  return (
    <Page
      title={title}
      fullWidth
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add email format', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setEmailformats}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric']}
          headings={[<b>NL Subject</b>, <b>Body</b>, <b>FR Subject</b>, <b>Body</b>, <b>DE Subject</b>, <b>Body</b>, <b>EN Subject</b>, <b>Body</b>, <b>Edit</b>]}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
