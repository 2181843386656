import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsPaymentCollectionsPage() {
  const [incasseren, setIncasseren] = useLookupStore((state) => [state.incasseren, state.setIncasseren]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({ Incasseren: { type: 'text', label: 'Payment collection types', max: '50', value: '' } });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/incasseren';
  const title = 'Payment collection types';

  const rows = incasseren.map((item) => [item.Incasseren, getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.IncasserenID)]);

  return (
    <Page
      title={title}
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add payment collection type', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setIncasseren}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable stickyHeader increasedTableDensity verticalAlign="middle" columnContentTypes={['text', 'numeric']} headings={[<b>Payment collection types</b>, <b>Edit</b>]} rows={rows} />
      </Card>
    </Page>
  );
}
