import { TextField, ChoiceList, InlineStack } from '@shopify/polaris';
import { alphabetize, filtersToApiUrl } from './functions';
import Select from 'react-select';

//EXAMPLE OBJECT:
// const [filters, setFilters] = useState({
//   q: { type: 'text', name: 'Q', value: url.get('q') || '' },
//   status: { type: 'status', name: 'Status', value: url.get('status') || 'all' },
//   text: { type: 'text', name: 'Text', value: url.get('text') || '' },
//   date: { type: 'date', name: 'Date', value: url.get('date') || '' },
//   priceRange: {
//     type: 'range',
//     name: 'Price range',
//     value: url.get('priceRange') ? JSON.parse(url.get('priceRange')) : null,
//     rangeBottom: 0,
//     rangeTop: 100000,
//     prefix: '€'
//   },
//   percentRange: {
//     type: 'range',
//     name: 'Percentage Range',
//     value: url.get('percentRange') ? JSON.parse(url.get('percentRange')) : null,
//     rangeBottom: 0,
//     rangeTop: 100,
//     prefix: '%'
//   },
//   mulitple: {
//     type: 'multiple',
//     name: 'Multiple',
//     value: url.get('mulitple') ? JSON.parse(url.get('multiple')) : null,
//     choices: choicesToMap.map((choice) => {
//       return { label: choice.labeltext, value: choice.id };
//     })
//   }
// });

const intialValuesFilters = {
  text: '',
  multiple: null,
  status: 'all',
  range: null,
  date: ''
};

// ADD FILTERS (making them available)
function addFilters(filters, setFilters, navigate, setPage) {
  const filterKeys = Object.keys(filters);
  const filterMethods = filterKeys
    .filter((fil) => fil !== 'q')
    .filter((fil) => filters[fil].type !== 'none')
    .map((filterKey) => {
      switch (filters[filterKey].type) {
        case 'status':
          return addStatusFilter(filterKey, filters, setFilters, navigate, setPage);
        case 'multiple':
          return addMultipleSelect(filterKey, filters, setFilters, navigate, setPage);
        case 'range':
          return addRangeSlider(filterKey, filters, setFilters, navigate, setPage);
        case 'date':
          return addDateFilter(filterKey, filters, setFilters, navigate, setPage);
        default:
          return addTextFilter(filterKey, filters, setFilters, navigate, setPage);
      }
    });
  return filterMethods;
}

function addTextFilter(key, filters, setFilters, navigate, setPage) {
  let filterInput = {
    key: key,
    label: filters[key].name,
    filter: <TextField autoComplete="off" label={`${filters[key].name}`} value={filters[key].value} onChange={(e) => handleFilterChange(e, filters, setFilters, key, navigate, setPage)} labelHidden />,
    shortcut: true
  };
  return filterInput;
}

function addStatusFilter(key, filters, setFilters, navigate, setPage) {
  let filterInput = {
    key: key,
    label: filters[key].name,
    filter: (
      <ChoiceList
        title={filters[key].name}
        titleHidden
        choices={[
          { label: 'All', value: 'all' },
          { label: key === 'status' ? 'Active' : 'Yes', value: '0' },
          { label: key === 'status' ? 'Discontinued' : 'No', value: '1' }
        ]}
        selected={filters[key].value}
        onChange={(e) => handleFilterChange(e, filters, setFilters, key, navigate, setPage)}
      />
    ),
    shortcut: true
  };
  return filterInput;
}

function addMultipleSelect(key, filters, setFilters, navigate, setPage) {
  const filterChoices = filters[key].choices;
  const filterValue = filters[key].value;
  const filterName = filters[key].name;
  const values = Array.isArray(filterValue) ? filterValue.map((v) => filterChoices.find((c) => c.value == v)) : [];

  let filterInput = {
    key: key,
    label: filterName,
    filter: (
      <Select
        // closeMenuOnSelect={false} this plus removing menuPortalTarget makes the select stay open but popover is too small...
        isMulti
        options={filterChoices}
        value={values || []}
        menuPortalTarget={document.body}
        onChange={(e) => {
          let v = e.map((x) => x.value || x);
          handleFilterChange(v, filters, setFilters, key, navigate, setPage);
        }}
        styles={{
          indicatorSeperator: (base) => ({ ...base, minHeight: '30px' }),
          indicatorsContainer: (base) => ({ ...base, minHeight: '30px' }),
          control: (base) => ({ ...base, minHeight: '30px', borderRadius: '8px', borderColor: '#919191', width: '100%' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
      />
    ),
    shortcut: true
  };

  return filterInput;
}

function addRangeSlider(key, filters, setFilters, navigate, setPage) {
  let filterInput = {
    key: key,
    label: filters[key].name,
    filter: (
      <InlineStack wrap={false} gap="200" blockAlign="center">
        <TextField
          autoComplete="off"
          value={Array.isArray(filters[key].value) ? filters[key].value[0] : ''}
          onChange={(e) => {
            if (e && e !== '0' && !Number(e)) return;
            const from = e ? parseFloat(e) : '';
            const to = Array.isArray(filters[key].value) ? filters[key].value[1] : filters[key].rangeTop;
            handleFilterChange([from, to], filters, setFilters, key, navigate, setPage);
          }}
          labelHidden
          suffix={filters[key].prefix}
        />
        <div>-</div>
        <TextField
          autoComplete="off"
          value={Array.isArray(filters[key].value) ? filters[key].value[1] : ''}
          onChange={(e) => {
            if (e && e !== '0' && !Number(e)) return;
            const from = Array.isArray(filters[key].value) ? filters[key].value[0] : filters[key].rangeBottom;
            const to = e ? parseFloat(e) : '';
            handleFilterChange([from, to], filters, setFilters, key, navigate, setPage);
          }}
          labelHidden
          suffix={filters[key].prefix}
        />
      </InlineStack>
    )
  };
  return filterInput;
}

function addDateFilter(key, filters, setFilters, navigate, setPage) {
  let filterInput = {
    key: key,
    label: filters[key].name,
    filter: <TextField type="date" label={`${filters[key].name}`} value={filters[key].value} onChange={(e) => handleFilterChange(e, filters, setFilters, key, navigate, setPage)} labelHidden />,
    shortcut: true
  };
  return filterInput;
}

export function handleFilterChange(e, filters, setFilters, key, navigate, setPage) {
  let oldFilter = filters[key];
  let newFilter = {
    type: oldFilter?.type,
    name: oldFilter?.name,
    value: e,
    choices: oldFilter?.choices,
    prefix: oldFilter?.prefix,
    rangeBottom: oldFilter?.rangeBottom,
    rangeTop: oldFilter?.rangeTop
  };

  if (oldFilter.type === 'date' && e.length > 10) return;

  setFilters((filters) => ({ ...filters, [key]: newFilter }), navigate({ pathname: window.location.pathname, search: `${filtersToApiUrl({ ...filters, [key]: newFilter })}` }, { replace: true }));
  setPage(1);
}

// APPLY FILTERS (selecting them for the list)
function applyFilters(filters, setfilter, navigate, setPage) {
  return Object.keys(filters)
    .filter((fil) => fil !== 'q')
    .filter((fil) => filters[fil].type !== 'none')
    .map((filterKey) => applyFilter(filterKey, filters, setfilter, navigate, setPage))
    .filter((filterItem) => filterItem?.key);
}

function applyFilter(key, filters, setfilter, navigate, setPage) {
  const filter = filters[key];
  if (!isEmpty(filter.value) && filter.type !== 'none') {
    return {
      key,
      label: disambiguateLabel(key, filter.type, filter.name, filter.value, filter?.choices, filter.prefix),
      onRemove: () => removeFilter(key, filters, setfilter, navigate, setPage)
    };
  }
}

// REMOVE APPLIED FILTERS
export function removeFilter(key, filters, setFilters, navigate, setPage) {
  handleFilterChange(intialValuesFilters[filters[key]?.type], filters, setFilters, key, navigate, setPage);
}

// REMOVE ALL FILTERS
function handleFiltersClearAll(filters, setFilters, navigate, setPage) {
  Object.keys(filters).forEach((fk) => removeFilter(fk, filters, setFilters, navigate, setPage));
  navigate({ pathname: window.location.pathname, search: '' }, { replace: true });
}

// detect if the filter is applied
function isEmpty(value) {
  if (Array.isArray(value)) return value.length === 0;
  return value === '' || value == null || value === 'all';
}

// create tag when applied
function disambiguateLabel(key, type, name, value, choices, prefix) {
  switch (type) {
    case 'status':
      if (name === 'Status') {
        if (String(value).localeCompare('1')) return "Status 'Active'";
        return "Status 'Discontinued'";
      } else if (key === 'mail') {
        if (String(value).localeCompare('1')) return 'Mail is sent';
        return 'Mail is not sent';
      } else if (key === 'invoiced') {
        if (String(value).localeCompare('1')) return 'Only invoiced jobs';
        return 'Only not invoiced jobs';
      } else if (key === 'jobsClosed') {
        if (String(value).localeCompare('1')) return 'Only closed jobs';
        return 'Only open jobs';
      } else if (key === 'paid') {
        if (String(value).localeCompare('1')) return 'Paid';
        return 'Not paid';
      } else {
        if (String(value).localeCompare('1')) return 'In service';
        return 'Out of service';
      }
    case 'multiple':
      let details = [];
      details = choices.filter((choice) => value?.includes(choice.value));
      return `${name}: ` + details.map((detail) => `${detail.label}`).join(', ');
    case 'range':
      return `${name} between ${value[0]}${prefix} and ${value[1]}${prefix}`;
    case 'date':
      return `${name} ${value}`;
    default:
      return `${name} contains '${value}'`;
  }
}

const getUserOptions = (users, userOrg) => {
  return users
    .filter((item) => item.UserType !== 4 && item.UserType !== 7 && item.Vennootschap === userOrg && !item.Inactief)
    .sort((a, b) => alphabetize(a, b, 'Afkorting'))
    .map((item) => ({ value: item.GebruikerID, label: item.Afkorting }));
};

export { addFilters, applyFilters, handleFiltersClearAll, getUserOptions };
