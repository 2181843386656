import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { getRequest } from '../../../shared/components/functions';

export default function MachinesCompany({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/machines?limit=10000&FIRNR=${FIRNR}&page=1`);
      if (data && data.length > 0) {
        let sorted = data.sort((a, b) => {
          return Number(a.discontinued) - Number(b.discontinued) || a.brand?.localeCompare(b.brand) || a.Machinetype?.localeCompare(b.Machinetype);
        });
        setItems(sorted);
      }
    };
    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var out_of_service = item.uitdienst ? String(item.uitdienst).substring(0, 10) : <Badge>Still in service</Badge>;
        var discontinued = item.discontinued ? <Badge tone="critical">Discontinued</Badge> : out_of_service;
        var machine_brand = item.brand ? <Badge tone="info">{item.brand}</Badge> : null;
        return [
          <Link removeUnderline url={'/machines/' + item.counter}>
            {item.counter}
          </Link>,
          machine_brand,
          item.Machinetype,
          item.Serienummer,
          String(item.indienst).substring(0, 10),
          discontinued
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text']}
      headings={[<b>{'Number'}</b>, <b>{'Brand'}</b>, <b>{'Type'}</b>, <b>{'Serial number'}</b>, <b>{'In service'}</b>, <b>{'Out of service'}</b>]}
      rows={rows}
    />
  ) : (
    'No machines'
  );
}
