import { Box, FormControl, FormLabel, Stack, useColorModeValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getRequest } from '../../../shared/components/functions';

export default function CompanySelect({ setCompany, setNewCompany, setNewContact, isClearable }) {
  const [selectCompany, setSelectCompany] = useState(null);

  useEffect(() => {
    async function fetchCompanies() {
      if (selectCompany?.value) {
        const data = await getRequest(`/api/companies/${selectCompany.value}`);

        if (data) {
          setCompany(data);
        }
      } else {
        setCompany((company) => ({
          ...company,
          NAAM: selectCompany?.label
        }));
      }
    }
    fetchCompanies();
  }, [selectCompany, setCompany]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      //calls the api companies for this inputvalue
      const data = await getRequest(`/api/companies/simple/search?q=NAAM:${inputValue}`);

      const options = data.map((company) => {
        return {
          value: company.FIRNR,
          label: company.NAAM
        };
      });
      callback(options);
    }
  };

  return (
    <Stack
      direction={{
        base: 'column',
        lg: 'row'
      }}
      spacing={{
        base: '5',
        lg: '8'
      }}
      justify="space-between"
    >
      <Box as="form" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')} flex="1" borderRadius="lg" pt="7">
        <Stack
          spacing="5"
          py={{
            base: '5',
            md: '6'
          }}
        >
          <FormControl id="company">
            <FormLabel>Your company</FormLabel>
            <div style={{ color: 'black' }}>
              <AsyncCreatableSelect
                value={selectCompany}
                loadOptions={loadOptions}
                isClearable={isClearable}
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 })
                }}
                defaultOptions
                onChange={(props) => {
                  if (props) {
                    const { value, label, __isNew__ } = props;
                    if (__isNew__) {
                      //create event, the company does not yet exist in our database
                      setSelectCompany({ value: null, label: label });
                      setNewCompany(true);
                      setNewContact(true);
                    } else {
                      setSelectCompany({ value: value, label: label });
                      setNewCompany(false);
                      setNewContact(true);
                    }
                  } else {
                    //the select is cleared
                    setNewCompany(false);
                    setNewContact(false);
                    setSelectCompany({ value: null, label: null });
                  }
                }}
              />
            </div>
          </FormControl>
        </Stack>
      </Box>
    </Stack>
  );
}
