import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getDocPdfLinkForListView, getRequest } from '../../../shared/components/functions';

export default function InvoicesCompany({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/invoices?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = items.map((item, idx) => {
    var date_payment = '';
    var fact_status = '';
    if (item.datumbetaling != null) {
      date_payment = String(item.datumbetaling).substring(0, 10);
    } else {
      date_payment = '';
    }
    if (item.status === 0) {
      //Invoice is open
      fact_status = <Badge size="small">{item.FactuurStatus}</Badge>;
    } else if (item.status === 9) {
      //1ste aanmaning
      fact_status = (
        <Badge tone="success" size="small">
          {item.FactuurStatus}
        </Badge>
      );
    } else if (item.status === 1) {
      //1ste aanmaning
      fact_status = (
        <Badge tone="attention" size="small">
          {item.FactuurStatus}
        </Badge>
      );
    } else if (item.status === 2) {
      //2ste aanmaning
      fact_status = (
        <Badge tone="warning" size="small">
          {item.FactuurStatus}
        </Badge>
      );
    } else {
      //Faling en de rest
      fact_status = (
        <Badge tone="critical" size="small">
          {item.FactuurStatus}
        </Badge>
      );
    }

    return [
      <Link removeUnderline url={'/invoices/' + item.faktuurnr}>
        {item.Factuurnummer}
      </Link>,
      getDocPdfLinkForListView(item, 'invoice', item.companyNumber, item.Factuurnummer),
      item.refklant,
      String(item.datum).substring(0, 10),
      String(item.Vervaldatum).substring(0, 10),
      String(item.laatsteaanmaning).substring(0, 10),
      formatToCurrency(item.totaalbedrag),
      formatToCurrency(item.bedragopen),
      date_payment,
      item.Vervallen ? <Badge>True</Badge> : <Badge>False</Badge>,
      fact_status
    ];
  });

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'text', 'text', 'text']}
      headings={[
        <b>{'Number'}</b>,
        <b>{'PDF'}</b>,
        <b>{'Reference'}</b>,
        <b>{'Date'}</b>,
        <b>{'Date expire'}</b>,
        <b>{'Last notice'}</b>,
        <b>{'Total'}</b>,
        <b>{'Open'}</b>,
        <b>{'Payment date'}</b>,
        <b>{'Vervallen'}</b>,
        <b>{'Status'}</b>
      ]}
      rows={rows}
    />
  ) : (
    'No  invoices'
  );
}
