import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { getRequest } from '../../../shared/components/functions';

export default function ContactsCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/contacts?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={'/contacts/' + item.CONNR}>
            {item.CONNR}
          </Link>,
          item.NAAM,
          item['E-mail'],
          item.DEPT,
          item.TEL,
          item.DISCONTINUED ? <Badge tone="critical">Discontinued</Badge> : <Badge>Active</Badge>
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text']}
      headings={[<b>{'Contact number'}</b>, <b>{'Contact name'}</b>, <b>{'Email'}</b>, <b>{'Function'}</b>, <b>{'Tel'}</b>, <b>{'Status'}</b>]}
      rows={rows}
    />
  ) : (
    'No contacts'
  );
}
