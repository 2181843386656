import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getRequest, getDocPdfLinkForListView } from '../../../shared/components/functions';
import { useLookupStore } from '../../../context/useStore';

export default function SalesCompanyPage({ FIRNR }) {
  const { users } = useLookupStore();
  const [sales, setSales] = useState([]);

  useEffect(() => {
    const fetchParts = async () => {
      const data = await getRequest(`/api/sales?limit=10000&FIRNR=${FIRNR}`);
      setSales(data);
    };

    fetchParts();
  }, [FIRNR]);

  const rows = Array.isArray(sales)
    ? sales.map((item) => {
        return [
          <Link removeUnderline url={'/sales/' + item.OrderbevestigingID}>
            {item.Orderbevestigingnummer}
          </Link>,
          getDocPdfLinkForListView(item, 'order_confirmation', item.Klantnummer, item.Orderbevestigingnummer),
          String(item.Datum).substring(0, 10),
          item.RefKlant,
          String(item.RefKlantDatum).substring(0, 10),
          formatToCurrency(item.to_invoice_tax_incl),
          item.GebruikerID ? <Badge>{users.filter((user) => user.GebruikerID === item?.GebruikerID)[0].Afkorting}</Badge> : ''
        ];
      })
    : [];

  return sales.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric', 'text']}
      headings={[<b>{'Number'}</b>, <b>{'PDF'}</b>, <b>{'Date'}</b>, <b>{'Reference customer'}</b>, <b>{'Date customer'}</b>, <b>{'Value (tax incl.)'}</b>, <b>{'Owner'}</b>]}
      rows={rows}
    />
  ) : (
    'No sales'
  );
}
