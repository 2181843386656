import { useMemo, useState } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import userRoles from '../../utils/userRoles';
import { useAuthStore } from '../../context/useStore';

const ProtectedRoute = ({ roles = [...Object.values(userRoles)] }) => {
  const location = useLocation();
  const { user, setUser } = useAuthStore();
  const isAuthorized = roles.includes(user?.role);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useMemo(() => {
    const checkAuth = async () => {
      const response = await fetch('/api/check-auth');
      const data = await response.json();
      if (response.ok) {
        setAuthenticated(data.message === 'Authenticated.');
      } else {
        setUser(null);
        setAuthenticated(false);
        localStorage.removeItem('currentUser');
      }
      setLoading(false);
    };
    checkAuth();
  }, [location]);

  if (isLoading) return null;

  if (!isAuthenticated && !isLoading) {
    localStorage.setItem('prevLocation', `${location.pathname}${location.search}`);
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
