import { performTracking } from '../../../analytics/segment';
import { putRequest } from '../../../shared/components/functions';

const toggleJobClosed = async (jobID, closed) => {
  const data = await putRequest(`/api/jobs/${jobID}`, { Closed: closed });
  performTracking('toggle_job_closed', { jobID: jobID, closed: closed });
  return data;
};

const toggleJobInvoiced = async (jobID, invoiced) => {
  const data = await putRequest(`/api/jobs/${jobID}`, { Invoiced: invoiced });
  performTracking('toggle_job_invoiced', { jobID: jobID, invoiced: invoiced });
  return data;
};

const toggleJobFeedback = async (jobID, feedbackProcessed) => {
  const data = await putRequest(`/api/jobs/${jobID}`, { ToDoOfficeProcessed: feedbackProcessed });
  performTracking('toggle_job_feedback_processed', { jobID: jobID, ToDoOfficeProcessed: feedbackProcessed });
  return data;
};

export { toggleJobClosed, toggleJobInvoiced, toggleJobFeedback };
