import { getRequest, putRequest } from '../components/functions';

export const setVerstuurd = async (resourceName, resourceID, docTypeID) => {
  if (!resourceName || !resourceID) return false;

  // check first if resource is not already verstuurd
  if (docTypeID !== 15) {
    const resource = await getRequest(`/api/${resourceName}/${resourceID}`);
    if (resource.Verstuurd) return resource;
  }

  const data = await putRequest(`/api/${resourceName}/${resourceID}`, { Verstuurd: true });
  return data;
};
