import { TextField, Modal, Select as PSelect, Banner, ChoiceList, Link, Toast, Text, FormLayout, Box } from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useAuthStore, useLookupStore } from '../../../context/useStore.js';
import getSharePointUrlForFirnrDocumentTypeIdDocName, { getSharePointPathForFirnrDocumentTypeIdDocName, GENERAL_CONDITIONS_DOC_NAMES, languageMap } from '../../../utils/sharepoint.js';
import { setVerstuurd } from '../../requests/setVerstuurd.js';
import { alphabetize, getRequest } from '../functions.jsx';
import { performTracking } from '../../../analytics/segment';

//FIXME: in the react-selects it is possible to create new options, but these need to be email addresses, needs to be checked if
//this is the case when it is created, we can use a library for this

export default function DocumentCardModal({ resource, resourceId, modalActive, setModalActive, company, docTypeID, docNumber, sysDoc, documents, setResource, docLang, reminderInvoices }) {
  const { user } = useAuthStore();
  const { emailformats, languages, documenttypes } = useLookupStore();

  const [mailTo, setMailTo] = useState(null);
  const [mailCcs, setMailCcs] = useState([]);
  const [mailBccs, setMailBccs] = useState([]);
  const [language, setLanguage] = useState(company.TaalID || '3');
  const [contacts, setContacts] = useState([]);
  const [mailSubject, setMailSubject] = useState('');
  const [mailContent, setMailContent] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [sendMailButtonLoading, setSendMailButtonLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [companyState, setCompanyState] = useState({});
  const [ccOptions, setCcOptions] = useState([]);
  const documentsToAttach = documents.filter((d) => d.DocumentTypeID !== 15);

  //TOAST STATES
  const [toastOkay, setToastOkay] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastAttachementsTooBig, setToastAttachementsTooBig] = useState(false);
  const [toastDocumentSent, setToastDocumentSent] = useState(false);

  const toastOkayMarkup = toastOkay ? <Toast content="👍 Email is on its way! " onDismiss={() => setToastOkay(false)} /> : null;
  const toastErrorMarkup = toastError ? <Toast error content="💣  Error sending email! " onDismiss={() => setToastError(false)} /> : null;
  const toastAttachementsTooBigMarkup = toastAttachementsTooBig ? <Toast error content="💣  Attachments are too big! " onDismiss={() => setToastAttachementsTooBig(false)} /> : null;
  const toastDocumentSentMarkup = toastDocumentSent ? <Toast content="✔️  Document marked as sent! " onDismiss={() => setToastDocumentSent(false)} /> : null;

  const taalIdToKey = { 1: 'NL', 2: 'FR', 3: 'UK', 4: 'DE' };

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/contacts?limit=10000&FIRNR=${company.FIRNR}`);
      if (data && Array.isArray(data)) {
        setContacts(data);
        setCcOptions([
          //might be relevant in the futur to add the company contacts also here... Not done for now
          // ...users
          //   .filter((usr) => !usr.Inactief && usr['Email'] !== null)
          //   .sort((a, b) => (a.Afkorting > b.Afkorting ? 1 : a.Afkorting < b.Afkorting ? -1 : 0))
          //   .map((user) => {
          //     return { value: user.GebruikerID, label: `${user.Gebruikernaam} (${user.Email})`, email: user.Email };
          //   }),
          ...data
            .filter((con) => !con.DISCONTINUED && con['E-mail'] !== null)
            .sort((a, b) => alphabetize(a, b, 'Afkorting'))
            .map((con) => ({ value: con.CONNR, label: `${con.NAAM} (${con['E-mail']})`, TaalID: con.TaalID, email: con['E-mail'] }))
        ]);
      }
      setDataLoaded(true);
    };

    if (typeof company.FIRNR !== 'undefined' && company.FIRNR !== null) fetchItems();
    setCompanyState(company);
    //eslint-disable-next-line
  }, [company.FIRNR]);

  useEffect(() => {
    if (emailformats && emailformats.length > 0) {
      const emailFormat = emailformats.find((em) => em.DocumentTypeID === docTypeID);
      const startKey = 'EmailBodyStart' + taalIdToKey[language];
      const bodyKey = 'EmailBody' + taalIdToKey[language];
      setMailContent(`${emailFormat[startKey]}\r\r${emailFormat[bodyKey]}\r${user?.full_name}`);

      const subjectKey = 'EmailSubject' + taalIdToKey[language];
      setMailSubject(`${emailFormat[subjectKey]} ${docNumber}`);
    }
    //eslint-disable-next-line
  }, [language, docNumber, companyState, emailformats]);

  useEffect(() => {
    if (dataLoaded && contacts.map((con) => con['E-mail']).includes(mailTo.email)) setLanguage(mailTo.TaalID);
    //eslint-disable-next-line
  }, [mailTo]);

  const handlePrepareEmail = async () => {
    setSendMailButtonLoading(true);
    const sharepointUploadPath = getSharePointPathForFirnrDocumentTypeIdDocName(company.Klantnummer, docTypeID, docNumber, false, documenttypes);
    /*
      Sharepoint docs is an array of 3 categories of documents that will be send as attachements in the email
      1: The systen document (i.e. invoice, sales order etc..)
      2: Link to general conditions file (in function of the language)
      3: All the other uploaded files, the user can choose which ones to send in this modal
    */

    let sharepointDocs = [{ sharepointDocName: sysDoc.Filename, sharepointPath: sharepointUploadPath }];

    if (docTypeID === 15) sharepointDocs = sharepointDocs.concat(reminderInvoices);

    const getFilenumberFromName = (filename) => filename.match(/\d+/)[0];

    sharepointDocs = sharepointDocs.concat([
      ...documents
        .filter((document) => selectedDocuments.includes(document.DocumentID)) //only the ones that are selected by the user
        .map((document) => ({
          sharepointDocName: document.Filename,
          sharepointPath: getSharePointPathForFirnrDocumentTypeIdDocName(document.klantnummer, document.DocumentTypeID, getFilenumberFromName(document.Filename), false, documenttypes)
        }))
    ]);

    if (docTypeID !== 13) sharepointDocs.push({ sharepointDocName: GENERAL_CONDITIONS_DOC_NAMES[languageMap[docLang]], sharepointPath: null });

    const payload = {
      emailSubject: mailSubject,
      emailBody: mailContent,
      replyTo: user.email,
      toAddress: mailTo.email,
      cc: [user.email, ...mailCcs.map((cc) => cc.email)],
      bcc: mailBccs.map((bcc) => bcc.email),
      docTypeID: docTypeID,
      docNumber: docNumber,
      sharepointDocs,
      resource,
      resourceId,
      docLang: languageMap[language]
    };

    const response = await fetch('/api/email/systemdoc', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) });
    // json response is needed either way for the toasts:
    const data = await response.json();

    if (data.error) {
      const { error } = data;
      if (error === 'attachements_too_big') {
        setToastAttachementsTooBig(true);
        setSendMailButtonLoading(false);
      } else {
        setToastError(true);
        setSendMailButtonLoading(false);
      }
    } else {
      setToastOkay(true);
      const pathNameArray = window.location.pathname.split('/');
      const resourceName = docTypeID === 15 ? 'payment_reminders/mark_as_sent' : pathNameArray[1];
      const resourceID = docTypeID === 15 ? resourceId : pathNameArray[2];
      const resource = await setVerstuurd(resourceName, resourceID, docTypeID);

      //analytics
      performTracking('email_systemdoc', payload);

      if (resource) setResource(resource);
      setToastDocumentSent(true);
      setSendMailButtonLoading(false);
      setModalActive(false);
    }
  };

  return (
    <Modal
      size="large"
      open={modalActive}
      onClose={() => setModalActive(false)}
      title="Email configuration"
      primaryAction={{ content: 'SEND EMAIL', disabled: !mailTo, loading: sendMailButtonLoading, onAction: handlePrepareEmail }}
      secondaryActions={{
        content: 'Cancel',
        onAction: () => {
          setModalActive(false);
          setSendMailButtonLoading(false);
        }
      }}
    >
      {toastOkayMarkup}
      {toastErrorMarkup}
      {toastAttachementsTooBigMarkup}
      {toastDocumentSentMarkup}
      <Modal.Section>
        <Box paddingBlockEnd="400">
          <Banner tone="info" title="The email will be automatically sent to you in CC, you do not need to add yourself as CC. The system document will be automatically send as attachment." />
        </Box>

        <FormLayout>
          <Box>
            <div style={{ marginBottom: '4px' }}>
              <Text>Send email to</Text>
            </div>
            <CreatableSelect
              isClearable
              onChange={(props, actionMeta) => {
                if (actionMeta.action === 'create-option') return setMailTo({ ...props, email: props.value });
                setMailTo({ ...props });
              }}
              options={contacts
                ?.filter((con) => !con.DISCONTINUED && con['E-mail'] !== null)
                .sort((a, b) => alphabetize(a, b, 'NAAM'))
                .map((con) => ({ value: con.CONNR, label: `${con.NAAM} (${con['E-mail']})`, TaalID: con.TaalID, email: con['E-mail'] }))}
              menuPortalTarget={document.body}
              styles={{
                valueContainer: (base) => ({ ...base, height: '32px' }),
                indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 })
              }}
            />
          </Box>

          <FormLayout.Group>
            <Box>
              <div style={{ marginBottom: '4px' }}>
                <Text>CC</Text>
              </div>
              <CreatableSelect
                options={ccOptions}
                onChange={(props) => setMailCcs([...props])}
                isMulti
                onCreateOption={(value) => setMailCcs([...mailCcs, { label: value, email: value }])}
                value={mailCcs}
                menuPortalTarget={document.body}
                styles={{
                  valueContainer: (base) => ({ ...base, height: '32px' }),
                  indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                  indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                  control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
              />
            </Box>

            <Box>
              <div style={{ marginBottom: '4px' }}>
                <Text>BCC</Text>
              </div>
              <CreatableSelect
                options={ccOptions}
                onChange={(props) => setMailBccs([...props])}
                isMulti
                onCreateOption={(value) => setMailBccs([...mailBccs, { label: value, email: value }])}
                value={mailBccs}
                menuPortalTarget={document.body}
                styles={{
                  valueContainer: (base) => ({ ...base, height: '32px' }),
                  indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                  indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                  control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
              />
            </Box>
          </FormLayout.Group>

          <PSelect
            label="Language"
            options={languages?.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
            value={parseInt(language)}
            onChange={(value) => setLanguage(value)}
          />
          <TextField label="Mail Subject" value={mailSubject} onChange={(props) => setMailSubject(props)} />
          <TextField label="Mail content" value={mailContent} onChange={(val) => setMailContent(val)} multiline={10} autoComplete="off" />

          {documentsToAttach.length > 0 && (
            <>
              <Box paddingBlockEnd="400" paddingBlockStart="400">
                <Banner tone="warning" title="Some extra uploaded documents are detected. Which one(s) would you like to include as attachment (Total max of 7MB)?" />
              </Box>
              <ChoiceList
                allowMultiple
                choices={documentsToAttach.map((doc, idx) => ({
                  key: idx,
                  label: doc.Filename,
                  value: doc.DocumentID,
                  helpText: (
                    <Link key={idx} url={getSharePointUrlForFirnrDocumentTypeIdDocName(company.Klantnummer, docTypeID, docNumber, doc.Filename, documenttypes)}>
                      open file
                    </Link>
                  )
                }))}
                selected={selectedDocuments}
                onChange={setSelectedDocuments}
              />
            </>
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
