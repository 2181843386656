import { Box, Button, ButtonGroup, Link, Text } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLookupStore } from '../../../context/useStore';
import { formatDate, getRequest, putRequest, simplePostRequest } from '../functions';
import getSharePointUrlForFirnrDocumentTypeIdDocName from '../../../utils/sharepoint';
import DocumentCardModal from './DocumentCardModal';
import { CheckCircleIcon } from '@shopify/polaris-icons';
import { CardHeading } from './CardHeading';

export const ReminderCardSection = ({ disabled, invoice, company }) => {
  const countries = useLookupStore((state) => state.countries);
  const languages = useLookupStore((state) => state.languages);
  const documenttypes = useLookupStore((state) => state.documenttypes);

  const docTypeID = 15;

  const [reminders, setReminders] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [includedInvoices, setIncludedInvoices] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState({ disabled: false, new: false, regenerate: false, mail: false });
  const [cardLoaded, setCardLoaded] = useState(false);
  const [reminderToSend, setReminderToSend] = useState();

  const fetchReminder = useCallback(async () => {
    const data = await getRequest(`/api/payment_reminders?invoice=${invoice.faktuurnr}`);
    if (data) setReminders(data);
    return true;
  }, []);

  const fetchDocuments = useCallback(async () => {
    const documentsData = await getRequest(`/api/documents/id/typeid?id=${invoice.faktuurnr}&typeID=${15}`);
    if (documentsData) setDocuments(documentsData);
    return true;
  }, []);

  useEffect(() => {
    async function fetchData() {
      const r = await fetchReminder();
      const d = await fetchDocuments();
      if (r && d) setCardLoaded(true);
    }
    fetchData();
  }, [openModal]);

  const handleDocumentGenerationButtonClick = async (rem, loading) => {
    setLoading({ ...loading, disabled: true, [loading]: rem?.rappelnr || true });
    const confirmMessage = rem?.Verstuurd
      ? '❗ Reminder has been sent to the customer! Do you want to regenerate? ❗'
      : 'Do you want to create a payment reminder for this invoice and all outstanding invoices for this customer?';
    if (confirm(confirmMessage)) {
      const document = {
        addressee: {
          FIRNR: invoice.klantnummer,
          name: invoice.klantnaam,
          number: company.Klantnummer,
          address: invoice.adres,
          city: invoice.woonplaats,
          zip: invoice.postkode,
          country: countries.find((c) => c.LandId === parseInt(invoice.LandID))?.Land,
          countryId: invoice.LandID,
          vat: invoice.btwnummer,
          postal_code: invoice.postkode,
          EU: countries.find((country) => country.LandId === company.LandID).EU,
          private: company.Particulier
        },
        language: invoice.TaalID ? languages.filter((l) => l.TaalID === invoice.TaalID)[0].Taal : 'N',
        type: 'reminder',
        typeID: docTypeID,
        id: invoice.faktuurnr,
        reference: invoice.refklant,
        reference_date: invoice.refklantdatum ? String(invoice.refklantdatum).substring(0, 10) : '',
        invoice_status: invoice.status,
        number: rem?.Rappelnummer
      };
      const res = await simplePostRequest(`/api/payment_reminders`, document);
      if (res.ok) {
        fetchReminder();
        fetchDocuments();
      }
    }
    setLoading({ disabled: false, new: false, regenerate: false, mail: false });
  };

  const handleMail = async (rem, loading) => {
    setLoading({ ...loading, disabled: true, [loading]: rem?.rappelnr || true });
    setReminderToSend(rem);
    const pathObjects = await getRequest(`/api/payment_reminders/related_invoices?rappelnummer=${rem.Rappelnummer}&DocumentTypeID=1`);
    setIncludedInvoices(pathObjects);
    setOpenModal(true);
    setLoading({ disabled: false, new: false, regenerate: false, mail: false });
  };

  const markAsSent = async (rem) => {
    await putRequest(`/api/payment_reminders/mark_as_sent/${rem.rappelnr}`, { Verstuurd: !rem.Verstuurd });
    fetchReminder();
  };

  return (
    <Box paddingBlockStart="400">
      <CardHeading title="Reminders" />
      {openModal && reminderToSend ? (
        <DocumentCardModal
          resource={'REMINDER'}
          resourceId={reminderToSend.rappelnr}
          modalActive={openModal}
          setModalActive={setOpenModal}
          company={company}
          docTypeID={docTypeID}
          docNumber={reminderToSend.Rappelnummer}
          sysDoc={documents.find((d) => d.Filename.includes(reminderToSend.Rappelnummer))}
          documents={documents}
          setResource={setReminderToSend}
          docLang={invoice.TaalID}
          reminderInvoices={includedInvoices}
        />
      ) : null}
      {cardLoaded && reminders.length > 0 && documents.length > 0 ? (
        <>
          {reminders.map((r, idx) => {
            const matchingDocument = documents?.find((d) => d.Filename.includes(r.Rappelnummer));
            return (
              matchingDocument && (
                <div key={idx} style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
                  <div style={{ display: 'flex' }}>
                    <Text>
                      {`${formatDate(matchingDocument.Datum)}: `}
                      <Link
                        target="_blank"
                        removeUnderline
                        url={getSharePointUrlForFirnrDocumentTypeIdDocName(company.Klantnummer, docTypeID, r.Rappelnummer, matchingDocument.Filename, documenttypes)}
                      >
                        {matchingDocument.Filename}
                      </Link>
                    </Text>
                  </div>
                  <ButtonGroup>
                    <Button disabled={loading.disabled || disabled} loading={loading.regenerate === r.rappelnr} onClick={() => handleDocumentGenerationButtonClick(r, 'regenerate')}>
                      Regenerate document
                    </Button>

                    {r.Verstuurd ? (
                      <Button disabled={loading.disabled || disabled} icon={CheckCircleIcon} onClick={() => markAsSent(r)}>
                        Reminder is sent
                      </Button>
                    ) : (
                      <ButtonGroup>
                        <Button disabled={loading.disabled || disabled} loading={loading.mail === r.rappelnr} onClick={() => handleMail(r, 'mail')}>
                          Mail reminder and all related invoices
                        </Button>
                        <Button disabled={loading.disabled || disabled} onClick={() => markAsSent(r)}>
                          Mark reminder as 'sent'
                        </Button>
                      </ButtonGroup>
                    )}
                  </ButtonGroup>

                  {idx === 0 && r.Verstuurd && invoice.Vervallen ? (
                    <Button disabled={loading.disabled || disabled} loading={loading.new} onClick={() => handleDocumentGenerationButtonClick(null, 'new')}>
                      Next reminder
                    </Button>
                  ) : null}
                </div>
              )
            );
          })}
        </>
      ) : invoice.Vervallen ? (
        <Box paddingBlockEnd={'300'}>
          <Button id="addReminder" disabled={loading.disabled || disabled} loading={loading.new} onClick={() => handleDocumentGenerationButtonClick(null, 'new')}>
            Create payment reminder for all outstanding invoices
          </Button>
        </Box>
      ) : (
        <Box paddingBlockEnd={'300'}>
          <Text>Payment reminders are available when invoice expires.</Text>
        </Box>
      )}
    </Box>
  );
};
