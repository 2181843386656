import { BlockStack, Box, Spinner } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { getRequest } from '../../../shared/components/functions';
import { useParams } from 'react-router-dom';
import { ReceiptCard } from './ReceiptCard';
import Toasts from '../../../shared/components/toasts/toasts';

export const OpenExpenses = () => {
  let { userId } = useParams();
  const [expenses, setExpenses] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);

  const fetchData = useCallback(async () => {
    const data = await getRequest(`/api/expenses/users/${userId}/open`);
    setExpenses(data);
    setLoaded(true);
  });

  useEffect(() => {
    if (userId) fetchData();
  }, [userId]);

  if (!loaded) return <Spinner size="small" />;
  if ((loaded && !expenses) || expenses.length === 0) return <Box padding="500">No expenses are awaiting your approval</Box>;

  return (
    <BlockStack gap="800">
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Changes saved" />
      {expenses.map((e, idx) => (
        <ReceiptCard key={`${e.OnkostenId}-${idx}`} expense={e} fetchData={fetchData} setToast={setToast} setErrorToast={setToastError} />
      ))}
    </BlockStack>
  );
};
