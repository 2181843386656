import React, { useState, useEffect } from 'react';
import { DataTable } from '@shopify/polaris';

export default function TechnicianCorrectionsComponent(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`/api/technicians/${props.technician_id}/corrections`)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  }, [props.technician_id]);

  function ConvertSaldo(mins) {
    var num = Math.abs(mins);
    var h = Math.floor(num / 60);
    var m = Math.round(num % 60);
    if (mins > 0) {
      return h + 'hrs ' + m + 'mins';
    } else {
      return '-' + h + 'hrs ' + m + 'mins';
    }
  }

  const rows = items.map((item) => {
    const correction = item.SaldoType === 'Overuren' ? ConvertSaldo(item.CorrectieHoveeelheid) : item.Saldo + ' days';
    return [String(item.Datum).substring(0, 10), item.SaldoType, correction, String(item.Memo).substring(0, 70)];
  });

  return (
    <DataTable
      stickyHeader={true}
      increasedTableDensity={true}
      verticalAlign="middle"
      columnContentTypes={['text', 'text', 'text', 'text']}
      headings={['Date', 'Name', 'Correction', 'Remarks']}
      rows={rows}
      truncate={false}
    />
  );
}
