import React, { useEffect, useState } from 'react';
import { DataTable, Link } from '@shopify/polaris';
import { getDocPdfLinkForListView, getRequest } from '../../../shared/components/functions';

export default function InquiriesCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/inquiries?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const customer_ref = item.RefKlant ? item.RefKlant : null;
        const customer_date = item.RefKlantDatum ? String(item.RefKlantDatum).substring(0, 10) : null;
        return [
          <Link removeUnderline url={'/inquiries/' + item.PrijsaanvraagID}>
            {item.Prijsaanvraagnummer}
          </Link>,
          getDocPdfLinkForListView(item, 'price_request', item.supplier_number, item.Prijsaanvraagnummer),
          String(item.Datum).substring(0, 10),
          <Link removeUnderline url={'/companies/' + item.KlantID}>
            {String(item.customer_name).substring(0, 30)}
          </Link>,
          customer_ref,
          customer_date,
          item.machinetype
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']}
      headings={[<b>{'Number'}</b>, <b>{'PDF'}</b>, <b>{'Date'}</b>, <b>{'Customer'}</b>, <b>{'Reference'}</b>, <b>{'Customer date'}</b>, <b>{'Machine type'}</b>]}
      rows={rows}
    />
  ) : (
    'No inquiries'
  );
}
