import { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Form, Select, FormLayout, Layout, Page, Link, Card, TextField, Button, DataTable, Checkbox, Banner, Badge, Spinner, Box, Text, BlockStack, InlineGrid, InlineStack } from '@shopify/polaris';
import LinesTable from '../../shared/components/linesTable';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { updateMyData } from '../../shared/util/updateLines';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import {
  getCurrenctDatetimeYYYMMDD,
  getExpiryDate,
  getRequest,
  TAV,
  alphabetize,
  formatToCurrency,
  formatDate,
  calcDateDiff,
  simplePostRequest,
  countryCodeToFlag,
  putRequest
} from '../../shared/components/functions';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import ProjectSelect from '../../shared/components/projectSelect.jsx';
import { InvoiceToCreditnote } from '../../shared/components/transformers.js';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import IncasserenSelect from '../../shared/components/incasserenSelect.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import LinesTotalCard from '../../shared/components/cards/LinesTotalsCard.jsx';
import VATinput from '../../shared/components/VATinput.jsx';
import WeightCard from '../../shared/components/cards/WeightCard.jsx';
import { round } from 'lodash';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas';
import { getAddCountryBanner } from '../company';
import EventTimeLine from '../../shared/components/events/eventTimeLine';
import CompanySelect from '../../shared/components/companySelect';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';
import { ContactReactSelect } from '../../shared/components/contactReactSelect.jsx';

export default function InvoicePage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const docTypeID = 1;
  const docType = 'invoice';
  const location = useLocation();
  const [savingResult, setSavingResult] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [initialInvoice, setInitialInvoice] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [linesDirty, setLinesDirty] = useState(url.get('linesDirty') || false);
  const [company, setCompany] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [creditnotes, setCreditnotes] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState({});
  const [isEU, setIsEU] = useState(false);
  const [editableCustomer, setEditableCustomer] = useState(false);
  const [documentGenerated, setDocumentGenereated] = useState(true);
  const [isBlurred, setIsBlurred] = useState(true);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [reopenLoading, setReopenLoading] = useState(false);
  const { countries, invoicestatuses, languages, paymentconditions, vatshifts } = useLookupStore();
  const { user } = useAuthStore();
  const pageTitle = getPageTitle(invoice.Factuurnummer, 'Invoice');
  const invoiceSchema = getSchema('invoice', url, TAV, user);

  var date = new Date();
  date.setDate(date.getDate() + 30);

  const lineInterface = {
    lineIsDirty: false,
    Lijnnr: null,
    faktuurnr: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: '',
    VP: null,
    aantal: 1,
    BTW: company.LandID === 300 ? 21 : null,
    MuntVP: null,
    AP: null,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': null,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const statusOptions = invoicestatuses.sort((a, b) => alphabetize(a, b, 'FactuurStatus')).map((st) => ({ value: st.Sequence, label: st.FactuurStatus }));

  const handleChange = (value, id) => {
    if (id === 'status') setInvoiceStatus(statusOptions.filter((s) => s.value === parseInt(value))[0]);
    if (id === 'refklantdatum' && (!value || value === '')) value = null;
    if (id === 'datumbetaling' && (!value || value === '')) value = null;
    setInvoice((invoice) => ({ ...invoice, [id]: value }), setIsDirty(true));
  };

  const handleCompanyChange = async (value) => {
    let data = await getRequest(`/api/companies/${value}`);
    if (data) {
      if (data.FirNrFactuur) {
        data = await getRequest(`/api/companies/${data.FirNrFactuur}`);
        alert(`The selected customer has a separate invoicing company! Customer and address details in this form will be updated to the invoicing company.`);
      }

      setCompany(data);
      setInvoice(
        (invoice) => ({
          ...invoice,
          klantnummer: data.FIRNR,
          klantnaam: data.NAAM,
          adres: data.STRAAT_NR,
          postkode: data.POSTNR,
          woonplaats: data.WOONPL,
          LandID: data.LandID,
          TaalID: data.TaalID,
          btwnummer: data.BTW
        }),
        setIsDirty(true)
      );
    }
  };

  function updateTotals() {
    const totalVatExclusive = lines.reduce((prev, curr, index) => {
      const { aantal, VP, BTW } = lines[index];
      return prev + (parseFloat(aantal) || 0) * (parseFloat(VP) || 0) * (1 + BTW / 100);
    }, 0);

    if (round(invoice.totaalbedrag, 2) !== round(totalVatExclusive, 2)) {
      const valuesToUpdate = { ...invoice, totaalbedrag: Math.round(totalVatExclusive * 100) / 100 };

      if (invoice.bedragopen === invoice.totaalbedrag && invoice.status !== 9) {
        //open value untouched
        valuesToUpdate.bedragopen = Math.round(totalVatExclusive * 100) / 100;
      } else if (invoice.bedragopen < invoice.totaalbedrag && invoice.status !== 9) {
        //invoice partially paid
        valuesToUpdate.bedragopen = valuesToUpdate.totaalbedrag - (invoice.totaalbedrag - invoice.bedragopen);
      }

      setInvoice((invoice) => ({ ...invoice, ...valuesToUpdate }), setIsDirty(true));
    }
  }

  useEffect(() => {
    if (dataLoaded) updateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines]);

  const generateDocument = async () => {
    const getBetalingIdentifier = {
      1: 'betalingNL',
      2: 'betalingFR',
      3: 'betalingUK',
      4: 'betalingDE'
    };

    const document = {
      addressee: {
        FIRNR: invoice.klantnummer,
        name: invoice.klantnaam,
        number: company.Klantnummer,
        address: invoice.adres,
        city: invoice.woonplaats,
        zip: invoice.postkode,
        country: countries.find((c) => c.LandId === parseInt(invoice.LandID))?.Land,
        countryId: invoice.LandID,
        vat: invoice.btwnummer,
        postal_code: invoice.postkode,
        EU: isEU,
        private: company.Particulier,
        co: invoice.tav
      },
      language: invoice.TaalID ? languages.find((l) => l.TaalID === parseInt(invoice.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      typeID: docTypeID,
      id: invoice.faktuurnr,
      number: invoice.Factuurnummer,
      reference: invoice.refklant,
      reference_date: invoice.refklantdatum ? String(invoice.refklantdatum).substring(0, 10) : '',
      date: String(invoice.datum).substring(0, 10),
      payment_condition: paymentconditions.find((pc) => pc.betalingID === parseInt(invoice.betalingID))[getBetalingIdentifier[invoice.TaalID]],
      BTWVerlegdID: invoice.BTWVerlegdID
    };

    setDocumentGenereated(false);
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) {
      setDocumentInProgress(false);
      setDocumentGenereated(true);
    }
  };

  const fetchData = useCallback(async () => {
    if (id === 'new') {
      setInvoice(invoiceSchema.default());
      setDataLoaded(true);
      if (location.state) {
        setLines(location.state);
        setLinesDirty(true);
      }
    } else {
      const data = await getRequest(`/api/invoices/${id}`);
      if (!data) {
        setInvoice(invoiceSchema.default());
        setDataLoaded(true);
        if (location.state) {
          setLines(location.state);
          setLinesDirty(true);
        }
        return;
      }
      setInvoice(data);
      setInitialInvoice(data);
      setInvoiceStatus(statusOptions.filter((s) => s.value === parseInt(data.status))[0]);
      if (data.Verstuurd) setDisabled(true);

      const data2 = await getRequest(`/api/invoices/${id}/lines`);
      if (url.get('appendLines') === 'true') {
        setLines((lines) => [...data2, ...location.state]);
        setInitialLines((lines) => [...data2, ...location.state]);
        setLinesDirty(true);
      } else {
        setLines(data2);
        setInitialLines(data2);
      }

      setCreditnotes(await getRequest(`/api/invoices/${id}/credit_notes`));
      setDataLoaded(true);
    }
  }, [id, disabled]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, disabled]);

  useEffect(() => {
    if (invoice.datum) updateExpiryDate();
  }, [invoice.betalingID, invoice.datum]);

  function updateExpiryDate() {
    setInvoice((invoice) => ({ ...invoice, Vervaldatum: getExpiryDate(invoice.betalingID || null, invoice?.datum || getCurrenctDatetimeYYYMMDD()) }));
  }

  useEffect(() => {
    async function fetchData() {
      const data3 = await getRequest(`/api/companies/${invoice.klantnummer}`);
      if (data3) {
        setCompany(data3);
        setIsEU(countries.filter((country) => country.LandId === data3.LandID)[0].EU);
      }
      const data4 = await getRequest(`/api/contacts?limit=10000&FIRNR=${invoice.klantnummer}&status=0`);
      if (data4) setContacts(data4);
      setContactsLoaded(true);
    }
    if (invoice.klantnummer) fetchData();
    //eslint-disable-next-line
  }, [invoice.klantnummer]);

  const ShopifyOrderMarkup = () => {
    return invoice.shopify_id ? (
      <Card>
        <CardHeading title="Shopify order" />
        <Link url={`https://perfectmoose.myshopify.com/admin/orders/${invoice.order.shopify_id}`}>Open order in shopfiy</Link>
      </Card>
    ) : null;
  };

  const OrderConfirmationMarkup = () => {
    //this is rendered when the invoice is created from an order
    return invoice.OrderbevestigingID ? (
      <Card>
        <CardHeading title="Sale" />
        This invoice is linked to a{' '}
        <Link removeUnderline url={`/sales/${invoice.OrderbevestigingID}`}>
          sale
        </Link>
      </Card>
    ) : null;
  };

  const accountingBanner = invoice.Verstuurd ? (
    <Box paddingBlockEnd="400">
      <Banner
        title="This invoice has been sent out. Editing will upset our accountant."
        secondaryAction={{ content: 'Edit nevertheless', onAction: () => setInvoice((invoice) => ({ ...invoice, Verstuurd: false })) }}
      />
    </Box>
  ) : null;

  const handleReopenInvoice = async () => {
    setReopenLoading(true);
    const payload = { status: 0, bedragopen: invoice.totaalbedrag, datumbetaling: null, memo: invoice.memo, Incasseren: invoice.Incasseren };
    await putRequest(`/api/invoices/${invoice.faktuurnr}`, payload, setToast, setToastError);
    await fetchData();
    setReopenLoading(false);
  };

  const statusBanner =
    dataLoaded && invoice.status === 9 ? (
      <Box paddingBlockEnd="400">
        <Banner title="This invoice is fully paid" status="success" action={(user.role === 1 || user.role === 6) && { content: 'Reopen', onAction: handleReopenInvoice, loading: reopenLoading }} />
      </Box>
    ) : invoice.status <= 4 ? (
      <Box paddingBlockEnd="400">
        <Banner title={`Invoice Status: ${invoiceStatus?.label || ''}`} tone="warning">
          <Text>
            Last reminder: <b>{formatDate(invoice.laatsteaanmaning)}</b>.
            {invoice.Vervaldatum && (
              <>
                Expiry date: <b>{formatDate(invoice.Vervaldatum)}</b>.
              </>
            )}
          </Text>
        </Banner>
      </Box>
    ) : invoice.status <= 8 ? (
      <Box paddingBlockEnd="400">
        <Banner title={`Invoice Status: ${invoiceStatus?.label || ''}`} tone="critical">
          Last reminder: <b>{formatDate(invoice.laatsteaanmaning)}</b>. Expiry date: <b>{formatDate(invoice.Vervaldatum)}</b>.
        </Banner>
      </Box>
    ) : null;

  const addCountryBanner = getAddCountryBanner(company);

  const payDayNegative = invoice.datum ? calcDateDiff(getExpiryDate(invoice.betalingID, invoice.datum), invoice.datumbetaling) : '';
  const payDayPositive = invoice.datum ? calcDateDiff(invoice.datumbetaling, getExpiryDate(invoice.betalingID, invoice.datum)) : '';

  const getReliableBanner = () => {
    const status = payDayNegative >= 0 ? 'warning' : 'success';
    const timing = payDayNegative >= 0 ? 'after' : 'before';
    let text;
    if (payDayNegative >= 0) {
      if (payDayNegative > 1) {
        text = `${payDayNegative} days`;
      } else {
        text = `${payDayNegative} day`;
      }
    } else {
      if (payDayPositive > 1) {
        text = `${payDayPositive} days`;
      } else {
        text = `${payDayPositive} day`;
      }
    }
    return (
      <Box paddingBlockEnd="400">
        <Banner
          tone={status}
          title={
            <Text>
              Paid within <b>{calcDateDiff(invoice.datum, invoice.datumbetaling)}</b> days.{' '}
            </Text>
          }
        >
          <Text>
            (<b>{text}</b> {timing} the initial expiry date.)
          </Text>
        </Banner>
      </Box>
    );
  };

  async function validateLinesVAT() {
    const lineVATs = lines.map((line) => parseInt(line.BTW) || 0);
    if (company.LandID === 300) {
      let lineCheck = lineVATs.filter((line) => line !== 0 && line !== 21).length;
      if (lineCheck !== 0) return 'Please confirm VAT on all invoice positions is either 0% or 21%.';
    } else {
      let sum = lineVATs.reduce((p, c) => p + c, 0);
      if (sum !== 0) return 'Please confirm VAT on all invoice positions is 0%.';
    }
  }

  async function saveForm() {
    //validate the invoice form
    const errorInSchema = await validateSchema(invoiceSchema, invoice);
    const errorInVATlines = await validateLinesVAT();
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        if (!errorInVATlines || (errorInVATlines && window.confirm(errorInVATlines))) {
          await handleSave(
            id,
            'invoices',
            invoice.faktuurnr,
            invoice,
            setInvoice,
            setInitialInvoice,
            setToast,
            setToastError,
            isDirty,
            setIsDirty,
            linesDirty,
            lines,
            setLines,
            setInitialLines,
            setLinesDirty,
            setSavingResult,
            navigate
          );

          setEditableCustomer(false);

          // auto generate pdf so latest changes are reflected in pdf
          if (id && id !== 'new') await generateDocument();
          setIsBlurred(false);
        }
      } else {
        alert(errorInSchema);
      }
    }
  }

  if (!dataLoaded || !contactsLoaded)
    return (
      <Box padding="400">
        <Spinner size="small" />
      </Box>
    );

  return (
    <Page title={pageTitle} titleMetadata={invoice.Vervallen ? <Badge tone="critical">Expired</Badge> : null} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Invoice saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        isDirtyDirty={isDirty}
        isDirtyLines={linesDirty}
        savingResult={savingResult}
        handleDiscard={() => {
          handleDiscard(initialInvoice, initialLines, setInvoice, setLines, setIsDirty, setLinesDirty);
          setEditableCustomer(false);
        }}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      {id !== 'new' ? statusBanner : null}
      {invoice.status === 9 ? getReliableBanner() : null}
      {accountingBanner}
      {addCountryBanner}
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Invoice details" actions={[{ content: 'Edit customer', onAction: () => setEditableCustomer(true), disabled: savingResult || invoice.Verstuurd }]} />
              <Form onSubmit={saveForm}>
                <FormLayout>
                  {editableCustomer ? (
                    <CompanySelect value={invoice.klantnummer} id="klantnummer" onChange={handleCompanyChange} label="" disabled={savingResult} />
                  ) : (
                    <TextField id="klantnummer" label="Company" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${invoice.klantnummer}`) }} />
                  )}

                  <FormLayout.Group>
                    <TextField id="adres" disabled={!editableCustomer} label="Address" value={invoice.adres} onChange={handleChange} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField id="postkode" disabled={!editableCustomer} label="ZIP" value={invoice.postkode} onChange={handleChange} />
                    <TextField id="woonplaats" disabled={!editableCustomer} label="City" value={invoice.woonplaats} onChange={handleChange} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      id="LandID"
                      label="Country"
                      placeholder="Select country"
                      value={parseInt(invoice.LandID) || ''}
                      disabled={!editableCustomer}
                      options={countries?.map((count) => ({ value: count.LandId, label: count.Land, prefix: countryCodeToFlag(count.ISO2) }))}
                      onChange={handleChange}
                    />
                  </FormLayout.Group>

                  <FormLayout.Group>
                    <TextField id="Orderbevestigingnummer" disabled label="Number" value={id !== 'new' ? String(invoice.Factuurnummer) : 'created automatically'} />
                    <Select id="status" options={statusOptions} label="Status" value={parseInt(invoice.status)} onChange={handleChange} disabled={savingResult || invoice.Verstuurd} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="datum"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Date"
                      value={String(invoice.datum).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                    <IncasserenSelect id="Incasseren" value={invoice.Incasseren} handleChange={handleChange} disabled={savingResult || invoice.Verstuurd} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="Vervaldatum"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Expiry date"
                      value={String(invoice.Vervaldatum).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                    <TextField
                      id="datumbetaling"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Payment date"
                      value={invoice.datumbetaling ? String(invoice.datumbetaling).substring(0, 10) : ''}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      id="TaalID"
                      options={languages?.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                      label="Language"
                      value={parseInt(invoice.TaalID) || ''}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                    <TextField
                      id="laatsteaanmaning"
                      disabled
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Last reminder"
                      value={String(invoice.laatsteaanmaning).substring(0, 10)}
                      onChange={handleChange}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="refklantdatum"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Customer date"
                      value={String(invoice.refklantdatum).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                    <TextField
                      id="customField"
                      disabled
                      maxLength={50}
                      autoComplete="off"
                      label="Amount excl TAX"
                      value={formatToCurrency(
                        String(Math.round(lines.reduce((prev, curr, index) => prev + (parseFloat(lines[index].aantal) || 0) * (parseFloat(lines[index].VP) || 0), 0) * 100) / 100)
                      )}
                      onChange={handleChange}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="refklant"
                      maxLength={100}
                      showCharacterCount
                      autoComplete="off"
                      label="Reference customer"
                      value={invoice.refklant || ''}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                    <TextField
                      id="bedragopen"
                      disabled
                      maxLength={50}
                      autoComplete="off"
                      label="Open value"
                      value={formatToCurrency(String(Math.round(invoice.bedragopen * 100) / 100))}
                      onChange={handleChange}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <InlineStack wrap={false} gap="300">
                      <div style={{ width: '50%' }}>
                        <ContactReactSelect value={invoice.tav} id="tav" onChange={handleChange} label="TAV" contacts={contacts} disabled={savingResult || invoice.Verstuurd} />
                      </div>
                      <div style={{ width: '50%' }}>
                        <TextField
                          id="totaalbedrag"
                          disabled
                          maxLength={50}
                          autoComplete="off"
                          label="Total value"
                          value={formatToCurrency(String(Math.round(invoice.totaalbedrag * 100) / 100))}
                          onChange={handleChange}
                        />
                      </div>
                    </InlineStack>
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <ProjectSelect label="Project" value={invoice.ProjectID} onChange={handleChange} id="ProjectID" isDisabled={savingResult || invoice.Verstuurd} />
                    <VATinput isEU={isEU} id="btwnummer" vat={invoice.btwnummer} onChange={handleChange} disabled={savingResult || invoice.Verstuurd} companyLand={company.LandID} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      placeholder="Select payment"
                      id="betalingID"
                      label="Payment condition"
                      value={parseInt(invoice.betalingID) || ''}
                      options={paymentconditions?.sort((a, b) => alphabetize(a, b, 'betalingNL')).map((cond) => ({ value: cond.betalingID, label: cond.betalingNL }))}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                    <Select
                      id="BTWVerlegdID"
                      placeholder="Select VAT shifted"
                      options={vatshifts?.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((vat) => ({ value: vat.BTWVerlegdID, label: vat.Omschrijving }))}
                      label="VAT shifted"
                      value={parseInt(invoice.BTWVerlegdID) || ''}
                      onChange={handleChange}
                      disabled={savingResult || invoice.Verstuurd}
                    />
                  </FormLayout.Group>
                  <InlineGrid columns={2}>
                    <BlockStack align="center">
                      <Checkbox id="Verstuurd" label="PDF document emailed" checked={invoice.Verstuurd} onChange={handleChange} disabled={savingResult} />
                    </BlockStack>
                    <OwnerSelect id="GebruikerID" label="Owner" value={invoice.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                  </InlineGrid>
                  <TextField id="memo" label="Note" maxLength={4000} showCharacterCount value={invoice.memo || ''} multiline={4} onChange={handleChange} disabled={savingResult || invoice.Verstuurd} />
                  <TextField
                    id="OrderbevestigingID"
                    label="Linked sale"
                    helpText="This is the ID of the linked sales order, not the number. Please do not change without knowing what you are doing!"
                    onChange={handleChange}
                    value={invoice.OrderbevestigingID ? String(invoice.OrderbevestigingID) : ''}
                    disabled={savingResult || invoice.Verstuurd}
                  />
                </FormLayout>
              </Form>
            </Card>
            <WeightCard lines={lines} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <LinesTotalCard summary="invoice" lines={lines} creditnotes={creditnotes} />
            {documentGenerated ? (
              <DocumentCard
                resource="INVOICE"
                id={id}
                title="Invoice document"
                docNumber={invoice.Factuurnummer}
                isDirty={isDirty}
                linesDirty={linesDirty}
                generateDocument={generateDocument}
                docTypeID={docTypeID}
                docType={docType}
                company={company}
                setResource={setInvoice}
                docLang={invoice.TaalID}
                documentButtonLoading={savingResult || documentInProgress}
                setDocumentButtonLoading={setSavingResult}
                invoice={invoice}
              />
            ) : (
              <Card>
                <Spinner size="small" />
              </Card>
            )}
            <OrderConfirmationMarkup />
            <ShopifyOrderMarkup />
            {id !== 'new' && (
              <Card>
                <CardHeading title={`Credit notes (${creditnotes.length})`} />
                <Button id="addCreditnoteOut" disabled={savingResult} onClick={() => InvoiceToCreditnote(invoice, lines, company, lineInterface, navigate)}>
                  Create credit note for this invoice
                </Button>
                {creditnotes.length > 0 && (
                  <DataTable
                    stickyHeader
                    increasedTableDensity
                    columnContentTypes={['text']}
                    headings={['Credit note']}
                    rows={creditnotes.map((cn) => [
                      <Link removeUnderline url={`/creditnotes/${cn.CreditnotaID}`}>
                        {cn.Creditnotanummer}
                      </Link>
                    ])}
                  />
                )}
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Content" />
            <LinesTable
              id={id}
              data={lines}
              updateMyData={updateMyData}
              setLines={setLines}
              setLinesDirty={setLinesDirty}
              onSubmit={saveForm}
              lineInterface={lineInterface}
              type="financial-simple"
              resource={invoice}
              company={company}
              disabled={invoice.status === 9 || savingResult || invoice.Verstuurd}
              setIsDirty={setIsDirty}
              setIsBlurred={setIsBlurred}
            />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <EventTimeLine resourceName="INVOICE" resourceId={id} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
