import { Box, Button, InlineGrid, Modal, Select, Spinner, Text } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { formatToCurrency, formatToPercent, getRequest } from '../../../shared/components/functions';
import { useEffect, useState } from 'react';
import { useLookupStore } from '../../../context/useStore';
import { SaleToInvoice } from '../../../shared/components/transformers';
import { doc_spec, languageMap } from '../../../utils/sharepoint';

export const SaleToInvoiceModal = ({ invoiceModalOpen, setInvoiceModalOpen, invoices, sale, lines, company, lineInterface }) => {
  const navigate = useNavigate();
  const isFirstInvoice = invoices.length === 0;
  const saleExcl = lines.reduce((prev, curr, index) => prev + (lines[index].aantal || 0) * (lines[index].VP || 0), 0);
  const saleExclTax = Math.round(saleExcl * 100) / 100;

  const paymentconditions = useLookupStore((state) => state.paymentconditions);
  const [remainingSaleExclTax, setRemainingSaleExclTax] = useState(0);
  const [invoiceExclTax, setInvoiceExclTax] = useState(remainingSaleExclTax);
  const [paymentCondition, setPaymentCondition] = useState(1);
  const [percentOptions, setPercentOptions] = useState([]);
  const [selectedPercentage, setSelectedPercentage] = useState(0.05);
  const [loaded, setLoaded] = useState(false);

  const paymentConditionsWithPercentage = [
    { id: 6, percent: 1 },
    { id: 7, percent: 0.5 },
    { id: 11, percent: 0.5 },
    { id: 14, percent: 0.3 },
    { id: 15, percent: 0.3 },
    { id: 16, percent: 0.5 },
    { id: 17, percent: 0.25 },
    { id: 18, percent: 0.3 }
  ];
  const companyPaymentCondition = company.betalingID;
  const conditionWithPercentage = paymentConditionsWithPercentage.map((p) => p.id).includes(companyPaymentCondition);

  useEffect(() => {
    // fetch invoice lines associated with this sale to get total invoice value excl tax + remaining sale value excl tax
    async function fetchInvoiceExclTax() {
      let invoiceLinesValue = 0;
      for (let i = 0; i < invoices.length; i++) {
        let linesData = await getRequest(`/api/invoices/${invoices[i].faktuurnr}/lines`);
        let filteredLines = linesData.filter((d) => d.aantal && d.VP);
        let lineValue = filteredLines.reduce((prev, curr, index) => prev + (filteredLines[index].aantal || 0) * (filteredLines[index].VP || 0), 0);
        invoiceLinesValue += lineValue;
      }
      setInvoiceExclTax(Math.round(invoiceLinesValue * 100) / 100);
      const remSaleExcl = Math.round((saleExclTax - invoiceLinesValue) * 100) / 100;
      setRemainingSaleExclTax(remSaleExcl);

      // calculate which options should be available in the percentage dropdown (can't be bigger than whats already invoiced)
      // eg. 25% invoiced: max 75% can be selected
      const percentInvoiced = 100 - Math.round(Math.abs(((remSaleExcl - saleExclTax) / saleExclTax) * 100) * 100) / 100;

      const options = [];
      for (let i = 5; i <= 95; i += 5) {
        options.push({ value: (i / 100).toString(), label: i, disabled: i > percentInvoiced });
      }
      setPercentOptions(options);
      setLoaded(true);
    }
    fetchInvoiceExclTax();
  }, [invoices]);

  useEffect(() => {
    if (companyPaymentCondition && conditionWithPercentage) {
      // set the invoice value for option 2 (if available)
      const percent = paymentConditionsWithPercentage.find((p) => p.id === companyPaymentCondition).percent;
      setInvoiceExclTax(Math.round(remainingSaleExclTax * percent * 100) / 100);
      setPaymentCondition(percent);
    }
  }, [companyPaymentCondition, remainingSaleExclTax]);

  const handleInvoiceCreation = (column) => {
    let updatedLines = [];

    switch (column) {
      case 2:
        updatedLines = lines.map((l) => ({ ...l, aantal: null, VP: null }));
        updatedLines.push({
          ...lineInterface,
          Lijnnr: null,
          aantal: 1,
          VP: Math.round(remainingSaleExclTax * parseFloat(paymentCondition) * 100) / 100,
          faktuurnr: 'new',
          produktnr: 1,
          tekstlijn: `1. ${doc_spec['invoice'].lan[languageMap[sale.TaalID]]}, ${formatToPercent(paymentCondition)}`
        });
        SaleToInvoice(sale, updatedLines, company, lineInterface, navigate);
        break;

      case 3:
        updatedLines = lines.map((l) => ({ ...l, aantal: null, VP: null }));
        updatedLines.push({
          ...lineInterface,
          Lijnnr: null,
          aantal: 1,
          VP: Math.round(saleExclTax * parseFloat(selectedPercentage) * 100) / 100,
          faktuurnr: 'new',
          produktnr: 1,
          tekstlijn: `${doc_spec['invoice'].lan[languageMap[sale.TaalID]]}, ${formatToPercent(selectedPercentage)}`
        });
        SaleToInvoice(sale, updatedLines, company, lineInterface, navigate);
        break;

      default:
        if (isFirstInvoice) {
          SaleToInvoice(sale, lines, company, lineInterface, navigate);
        } else {
          updatedLines = lines.map((l) => ({ ...l, aantal: null, VP: null }));
          updatedLines.push({
            ...lineInterface,
            Lijnnr: null,
            aantal: 1,
            VP: remainingSaleExclTax,
            faktuurnr: 'new',
            produktnr: 1,
            tekstlijn: `${doc_spec['invoice_remaining'].lan[languageMap[sale.TaalID]]}`
          });
          SaleToInvoice(sale, updatedLines, company, lineInterface, navigate);
        }
        return;
    }
  };

  return (
    <Modal open={invoiceModalOpen} onClose={() => setInvoiceModalOpen(false)} title="Invoice this sale" size="large">
      {!loaded ? (
        <Box padding="400">
          <Spinner size="small" />
        </Box>
      ) : (
        <Modal.Section>
          <InlineGrid gap="400" columns={isFirstInvoice && conditionWithPercentage ? 3 : 2}>
            <Column
              column={1}
              heading={isFirstInvoice ? 'Invoice full amount' : 'Invoice remaining amount'}
              buttonContent={formatToCurrency(remainingSaleExclTax)}
              content=""
              handleInvoice={handleInvoiceCreation}
            />
            {isFirstInvoice && conditionWithPercentage && (
              <Column
                column={2}
                heading="Invoice according to payment conditions"
                content={<Text>{paymentconditions.find((pc) => pc.betalingID === companyPaymentCondition).betalingNL}</Text>}
                buttonContent={formatToCurrency(invoiceExclTax)}
                handleInvoice={handleInvoiceCreation}
              />
            )}
            <Column
              column={3}
              heading="Select percentage to invoice"
              content={<Select label="%" labelInline options={percentOptions} onChange={setSelectedPercentage} value={selectedPercentage} />}
              buttonContent={formatToCurrency(Math.round(saleExclTax * parseFloat(selectedPercentage) * 100) / 100)}
              handleInvoice={handleInvoiceCreation}
            />
          </InlineGrid>
        </Modal.Section>
      )}
    </Modal>
  );
};

const Column = ({ column, heading, content, buttonContent, handleInvoice }) => {
  const columnStyle = { background: 'var(--p-color-bg)', borderRadius: 'var(--p-border-radius-200)', padding: '7px' };
  return (
    <Box style={columnStyle}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'space-between', justifyContent: 'space-between' }}>
        <Text variant="headingMd" as="h6">
          {heading}
        </Text>
        {content}
        <Button onClick={() => handleInvoice(column)}>{`Create invoice for ${buttonContent} (excl tax)`}</Button>
      </div>
    </Box>
  );
};
