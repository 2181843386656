import { Button, Toast, Tooltip } from '@shopify/polaris';
import { EmailIcon } from '@shopify/polaris-icons';
import { useState } from 'react';
import { ReminderMailModal } from './ReminderMailModal';

export const ReminderMailButton = ({ invoice, fetchInvoices }) => {
  const [openModal, setOpenModal] = useState(false);
  const [toast, setToast] = useState({ ok: false, error: false, attachements: false, sent: false });

  const toastMarkup = (
    <div>
      {toast.ok ? <Toast content="👍 Email is on its way! " onDismiss={() => setToast({ ...toast, ok: false })} /> : null}
      {toast.error ? <Toast error content="💣  Error sending email! " onDismiss={() => setToast({ ...toast, error: false })} /> : null}
      {toast.attachements ? <Toast error content="💣  Attachments are too big! " onDismiss={() => setToast({ ...toast, attachement: false })} /> : null}
      {toast.sent ? <Toast content="✔️  Document marked as sent! " onDismiss={() => setToast({ ...toast, sent: false })} /> : null}
    </div>
  );

  return (
    <>
      <Tooltip content={'Create payment reminder and send via mail'}>
        <Button size="slim" icon={EmailIcon} onClick={() => setOpenModal(true)} />
      </Tooltip>
      {toastMarkup}
      {openModal ? <ReminderMailModal modalActive={openModal} setModalActive={setOpenModal} invoice={invoice} fetchInvoices={fetchInvoices} toast={toast} setToast={setToast} /> : null}
    </>
  );
};
