const creditLimitInfo = {
  0: '',
  1: 'Debt level > 100%',
  2: 'Highly negative score',
  3: 'Highly negative score',
  4: 'Low liquidity and negative profitability',
  5: 'Social security summons',
  6: 'Protested bills of exchange',
  7: 'Judicial Reorganization',
  8: 'Activity stopped',
  9: 'Financial Assets > 33% of the balance sheet total',
  10: 'Highly negative profitability',
  11: 'No establishment',
  12: 'Negative history',
  13: 'Many address changes',
  14: 'Foreign registered office',
  15: 'No NACE code',
  16: 'Bankruptcy of a subsidiary',
  17: 'Net Worth is negative',
  21: 'VAT cancelled',
  22: 'Multiple Office relocations',
  24: 'No data available',
  25: 'Weak Cashflowposition',
  75: 'Administrative removal',
  100: 'Balance sheet > 75 million',
  101: 'Balance sheet > 150 million',
  200: 'Turnover > 250 million',
  300: 'Maximum limit',
  301: 'Maximum limit'
};

export { creditLimitInfo };
