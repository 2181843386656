import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Select, FormLayout, Layout, Page, Link, Card, TextField, DataTable, Banner, Badge, BlockStack, Checkbox, Box, InlineStack, Modal, InlineGrid } from '@shopify/polaris';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { updateMyData } from '../../shared/util/updateLines';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { alphabetize, formatToCurrency, getRequest, simplePostRequest } from '../../shared/components/functions.jsx';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { SaleToDelivery, SaleDuplicate, SaleToQuotation } from '../../shared/components/transformers';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import ProjectSelect from '../../shared/components/projectSelect';
import ContactSelect from '../../shared/components/contactSelect.jsx';
import CompanySelect from '../../shared/components/companySelect';
import LinesTable from '../../shared/components/linesTable';
import LinesTotalCard from '../../shared/components/cards/LinesTotalsCard.jsx';
import WeightCard from '../../shared/components/cards/WeightCard.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas';
import EventTimeLine from '../../shared/components/events/eventTimeLine';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';
import { SaleToInvoiceModal } from './components/saleToInvoiceModal.jsx';
import { LinkedInvoicesOverviewTable } from './components/linkedInvoicesOverviewTable.jsx';

/*
  FIXME:
  - logic for invoicing and deliveries if already some items are invoiced, how is this handled in access? 
  - totals? --> check all fields with access screenshots (NONE IN ACCESS)
*/

export const getBetalingIdentifier = {
  1: 'betalingNL',
  2: 'betalingFR',
  3: 'betalingUK',
  4: 'betalingDE'
};

export default function SalePage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const docTypeID = 6;
  const docType = 'order_confirmation';
  let location = useLocation();

  const [savingResult, setSavingResult] = useState(false);
  const [sale, setSale] = useState({});
  const [initialSale, setInitialSale] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [linesDirty, setLinesDirty] = useState(url.get('linesDirty') || false);
  const [company, setCompany] = useState({});
  const [invoices, setInvoices] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const [deliveredSum, setDeliveredSum] = useState();
  const [invoiceCompany, setInvoiceCompany] = useState();
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [creditSum, setCreditSum] = useState();
  const [isBlurred, setIsBlurred] = useState(true);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const [externalPayloadModalOpen, setExternalPayloadModalOpen] = useState(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const { user } = useAuthStore();
  const { countries, languages, paymentconditions } = useLookupStore();
  const pageTitle = getPageTitle(sale?.Orderbevestigingnummer, 'Sale');
  const saleSchema = getSchema('sale', url, null, user);
  const enableCustomerSelect = url.get('duplicate') || false;

  const lineInterface = {
    lineIsDirty: false,
    OrderbevestigingLijnID: null,
    OrderbevestigingID: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: '',
    VP: null,
    aantal: 1,
    BTW: company.LandID === 300 ? 21 : null,
    MuntVP: null,
    AP: null,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': null,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const handleChange = (value, id) => {
    if (id === 'RefKlantDatum' && (!value || value === '')) value = null;
    setSale((sale) => ({ ...sale, [id]: value }), setIsDirty(true));
  };

  const generateDocument = async () => {
    let invoiceAddressee, deliveryAddressee;
    if (invoiceCompany) {
      invoiceAddressee = {
        name: invoiceCompany.NAAM,
        number: invoiceCompany.Klantnummer,
        address: invoiceCompany.STRAAT_NR,
        city: invoiceCompany.WOONPL,
        zip: invoiceCompany.POSTNR,
        country: countries.find((c) => c.LandId === invoiceCompany.LandID)?.Land,
        countryId: invoiceCompany.LandID,
        vat: invoiceCompany.BTW
      };
    }
    if (sale.LeveringKlantID) {
      deliveryAddressee = {
        name: deliveryAddress.NAAM,
        number: deliveryAddress.Klantnummer,
        address: deliveryAddress.STRAAT_NR,
        city: deliveryAddress.WOONPL,
        zip: deliveryAddress.POSTNR,
        country: countries.find((c) => c.LandId === deliveryAddress.LandID)?.Land,
        countryId: deliveryAddress.LandID
      };
    }

    const document = {
      addressee: {
        FIRNR: sale.KlantID,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      eu: false,
      language: sale.TaalID ? languages.find((l) => l.TaalID === parseInt(sale.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      id: sale.OrderbevestigingID,
      typeID: docTypeID,
      number: sale.Orderbevestigingnummer,
      reference: sale.RefKlant,
      reference_date: sale.RefKlantDatum ? String(sale.RefKlantDatum).substring(0, 10) : '',
      date: String(sale.Datum).substring(0, 10),
      payment_condition: paymentconditions.find((pc) => pc.betalingID === company.betalingID)[getBetalingIdentifier[sale.TaalID]],
      delivery_term: sale.Leveringstermijn,
      machine_number: sale.MachineNummer,
      machine_type: sale.MachineType,
      invoiceAddressee: invoiceAddressee,
      addresseeContact: sale.KlantContactID ? companyContacts.find((c) => c.CONNR === parseInt(sale.KlantContactID))?.NAAM : '',
      deliveryAddressee: deliveryAddressee,
      prices: sale.DocumentPrijzen,
      supplier: sale.LeverancierID
    };
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setSale(saleSchema.default());
        setInvoices([]);
        setDeliveries([]);
        setIsBlurred(true);
      } else {
        let initial = await getRequest(`/api/sales/${id}`);
        if (!initial || initial.length === 0) return setSale(saleSchema.default());
        setSale(initial);
        setInitialSale(initial);
        setLines(await getRequest(`/api/sales/${id}/lines`));
        setInitialLines(await getRequest(`/api/sales/${id}/lines`));
        setInvoices(await getRequest(`/api/sales/${id}/invoices`));
        setDeliveries(await getRequest(`/api/sales/${id}/deliveries`));
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      const invoiceIDs = invoices.map((i) => i.faktuurnr);
      const creditnotes = await Promise.all(invoiceIDs.map(async (id) => await getRequest(`/api/invoices/${id}/credit_notes`)));
      let creditsums = creditnotes.map((cn) => cn.map((c) => c.BedragTotaal));
      creditsums = creditsums.map((line) => line.reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);
      setCreditSum(Math.round(creditsums * 100) / 100);
    }
    if (invoices.length > 0) fetchData();
  }, [invoices]);

  useEffect(() => {
    async function fetchData() {
      if (sale.KlantID) {
        const data = await getRequest(`/api/companies/${sale.KlantID}`);
        setCompany(data);
        setCompanyContacts(await getRequest(`/api/contacts?limit=10000&FIRNR=${sale.KlantID}`));

        // only overwrite TaalID if it is not already set
        if (!sale.TaalID) setSale({ ...sale, TaalID: data.TaalID });
        if (company.LandID && data.LandID !== company.LandID) {
          let updatedLines = [];
          if (data.LandID === 300) updatedLines = lines.map((l) => ({ ...l, BTW: 21 }));
          else updatedLines = lines.map((l) => ({ ...l, BTW: null }));
          setLines(updatedLines);
        }
      }
      if (sale.FacturatieKlantID && sale.FacturatieKlantID !== sale.KlantID) setInvoiceCompany(await getRequest(`/api/companies/${sale.FacturatieKlantID}`));
      if (sale.LeveringKlantID) setDeliveryAddress(await getRequest(`/api/companies/${sale.LeveringKlantID}`));
    }
    fetchData();
  }, [sale.KlantID, sale.FacturatieKlantID, sale.LeveringKlantID, company.LandID]);

  useEffect(() => {
    if (location.state) {
      setLines(location.state);
      setIsDirty(url.get('isDirty') || false);
      setLinesDirty(url.get('linesDirty') || false);
    }
  }, [location]);

  useEffect(() => {
    setSale((sale) => ({ ...sale, Verstuurd: emailSent }));
  }, [emailSent]);

  const ShopifyOrderMarkup = () => {
    const markup = sale.shopify_id ? (
      <Card>
        <CardHeading title="Shopify order" />
        <Link url={`https://perfectmoose.myshopify.com/admin/orders/${sale.order.shopify_id}`}>Open order in shopfiy</Link>
      </Card>
    ) : null;
    return markup;
  };

  async function saveForm() {
    const errorInSchema = await validateSchema(saleSchema, sale);
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        await handleSave(
          id,
          'sales',
          sale.OrderbevestigingID,
          sale,
          setSale,
          setInitialSale,
          setToast,
          setErrorToast,
          isDirty,
          setIsDirty,
          linesDirty,
          lines,
          setLines,
          setInitialLines,
          setLinesDirty,
          setSavingResult,
          navigate
        );

        // auto generate pdf so latest changes are reflected in pdf
        if (id && id !== 'new') await generateDocument();
        setIsBlurred(false);
      } else {
        alert(errorInSchema);
      }
    }
  }

  const CanceledBanner = sale.Canceled ? (
    <Banner
      title="This order is marked as cancelled."
      tone="critical"
      secondaryAction={{
        content: 'Mark as uncancelled',
        onAction: () => setSale((sale) => ({ ...sale, Canceled: false }), setIsDirty(true))
      }}
    />
  ) : null;

  const totalSaleValue = Math.round(lines.reduce((prev, curr, index) => prev + (lines[index].aantal || 0) * (lines[index].VP || 0) * (1 + lines[index].BTW / 100), 0) * 100) / 100;

  const accountingBanner = sale.Verstuurd ? (
    <Box paddingBlockEnd="400">
      <Banner
        title="This sale has been sent out. Editing will upset our accountant."
        secondaryAction={{
          content: 'Edit nevertheless',
          onAction: () => setSale((sale) => ({ ...sale, Verstuurd: false }))
        }}
      />
    </Box>
  ) : null;

  useEffect(() => {
    async function getDeliveredSum() {
      const deliveryIDs = deliveries.map((d) => d.Bonnr);
      const dLines = await Promise.all(deliveryIDs.map(async (id) => await getRequest(`/api/delivery_notes/${id}/lines`)));
      let sums = dLines.map((dl) =>
        dl.map((d) => {
          if (d.ProductID > 50) return d.aantalgeleverd;
          return null;
        })
      );
      sums = sums.map((line) => line.reduce((prev, curr) => prev + curr, 0)).reduce((prev, curr) => prev + curr, 0);
      setDeliveredSum(sums);
    }
    if (deliveries.length > 0) getDeliveredSum();
  }, []);

  const deliveryBanner =
    deliveries.length > 0 ? (
      <Box paddingBlockEnd="400">
        <Banner title={`Delivered goods: ${deliveredSum} of ${lines.filter((l) => l.ProductID > 50).reduce((prev, curr) => prev + curr.aantal, 0)} items`} tone="info" />
      </Box>
    ) : null;

  const titleMetadata =
    Math.round((totalSaleValue - invoices.reduce((prev, curr) => prev + curr.totaalbedrag, 0)) * 100) / 100 <= 0 ? (
      <InlineStack gap="200">
        <Badge tone="success">Fully invoiced</Badge>
        {Math.round(invoices.reduce((prev, curr) => prev + curr.bedragopen, 0) * 100) - 100 > 0 ? (
          <Badge tone="critical">Awaiting payment</Badge>
        ) : Math.round(invoices.reduce((prev, curr) => prev + curr.bedragopen, 0) * 100) / 100 <= 0 ? (
          <Badge tone="success">Paid</Badge>
        ) : (
          <Badge tone="warning">Partially paid</Badge>
        )}
      </InlineStack>
    ) : totalSaleValue === totalSaleValue - Math.round(invoices.reduce((prev, curr) => prev + curr.totaalbedrag, 0) * 100) / 100 ? (
      <Badge tone="critical">Not yet invoiced</Badge>
    ) : (
      <Badge tone="warning">Partially invoiced</Badge>
    );

  const ExternalPayloadModal = () => (
    <Modal open={externalPayloadModalOpen} onClose={() => setExternalPayloadModalOpen(false)} title="External payload">
      <Modal.Section>
        <pre>{JSON.stringify(JSON.parse(sale.ExternalPayload), null, 2)}</pre>
      </Modal.Section>
    </Modal>
  );

  const QuotationMarkup = () => {
    //this is rendered when the sale is created from a quotation
    return sale.OfferteID ? (
      <Card>
        <CardHeading title="Sale" />
        This sale is linked to a{' '}
        <Link removeUnderline url={`/quotations/${sale.OfferteID}`}>
          quotation
        </Link>
      </Card>
    ) : null;
  };

  return (
    <Page
      title={pageTitle}
      titleMetadata={titleMetadata}
      fullWidth
      primaryAction={sale.ExternalPayload ? { content: 'External payload', onAction: () => setExternalPayloadModalOpen(true) } : null}
      secondaryActions={[
        { content: 'Create duplicate', onAction: () => SaleDuplicate(sale, lines, navigate), disabled: id === 'new' },
        { content: 'Create quotation', onAction: () => SaleToQuotation(lines, navigate), disabled: id === 'new' }
      ]}
    >
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} toastContent="Sale saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        savingResult={savingResult}
        handleDiscard={() => handleDiscard(initialSale, initialLines, setSale, setLines, setIsDirty, setLinesDirty)}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      {invoiceModalOpen && (
        <SaleToInvoiceModal
          invoiceModalOpen={invoiceModalOpen}
          setInvoiceModalOpen={setInvoiceModalOpen}
          invoices={invoices}
          sale={sale}
          lines={lines}
          company={company}
          lineInterface={lineInterface}
        />
      )}
      {sale.ExternalPayload && <ExternalPayloadModal />}
      {CanceledBanner}
      {accountingBanner}
      {deliveryBanner}
      {sale.Canceled ? null : (
        <Layout>
          <Layout.Section>
            <BlockStack gap="400">
              <Card>
                <Form onSubmit={saveForm}>
                  <FormLayout>
                    <CompanySelect isClearable id="KlantID" label="Customer" value={company.FIRNR} onChange={handleChange} isDisabled={savingResult || sale.Verstuurd || !enableCustomerSelect} />
                    <FormLayout.Group>
                      <TextField id="Orderbevestigingnummer" disabled label="Number" value={id !== 'new' ? String(sale.Orderbevestigingnummer) : 'created automatically'} />
                      <CompanySelect isClearable id="LeverancierID" label="Supplier" value={sale.LeverancierID} onChange={handleChange} isDisabled={savingResult || sale.Verstuurd} />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        id="Datum"
                        maxLength={60}
                        type="date"
                        autoComplete="off"
                        label="Date"
                        value={String(sale.Datum).substring(0, 10)}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                      <TextField
                        id="RefKlant"
                        maxLength={100}
                        showCharacterCount
                        autoComplete="off"
                        label="Reference Customer"
                        value={sale.RefKlant || ''}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <Select
                        id="TaalID"
                        maxLength={60}
                        autoComplete="off"
                        label="Language"
                        value={parseInt(sale.TaalID) || ''}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                        options={languages.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                      />
                      <TextField
                        id="RefKlantDatum"
                        maxLength={50}
                        type="date"
                        autoComplete="off"
                        label="Date customer"
                        value={String(sale.RefKlantDatum).substring(0, 10)}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <TextField
                        id="Leveringstermijn"
                        maxLength={50}
                        showCharacterCount
                        autoComplete="off"
                        label="Delivery term"
                        value={sale.Leveringstermijn || ''}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                      <TextField
                        id="MachineType"
                        maxLength={50}
                        showCharacterCount
                        autoComplete="off"
                        label="Machine type"
                        value={sale.MachineType || ''}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <ProjectSelect id="ProjectID" label="Project" value={sale.ProjectID} onChange={handleChange} isDisabled={savingResult || sale.Verstuurd} />
                      <TextField
                        id="MachineNummer"
                        maxLength={50}
                        showCharacterCount
                        autoComplete="off"
                        label="Machine number"
                        value={sale.MachineNummer || ''}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group condensed>
                      <InlineGrid columns={2}>
                        <BlockStack>
                          <Checkbox id="Verstuurd" autoComplete="off" label="PDF Document emailed" checked={sale.Verstuurd} onChange={handleChange} disabled={savingResult} />
                          <Checkbox id="Canceled" label="Order is cancelled" checked={sale.Canceled} onChange={handleChange} disabled={savingResult || sale.Verstuurd} />
                        </BlockStack>
                        <OwnerSelect id="GebruikerID" label="Owner" value={sale.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                      </InlineGrid>
                      <TextField
                        id="Aanbod"
                        maxLength={50}
                        showCharacterCount
                        autoComplete="off"
                        label="Aanbod"
                        value={sale.Aanbod || ''}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                      />
                    </FormLayout.Group>
                    <FormLayout.Group>
                      <ContactSelect
                        FIRNR={sale.KlantID}
                        id="KlantContactID"
                        label="Customer contact"
                        value={sale.KlantContactID}
                        onChange={handleChange}
                        contacts={companyContacts}
                        disabled={savingResult || sale.Verstuurd}
                      />
                    </FormLayout.Group>
                    <CompanySelect isClearable id="FacturatieKlantID" label="Invoicing address" value={sale.FacturatieKlantID} onChange={handleChange} isDisabled={savingResult || sale.Verstuurd} />
                    <CompanySelect isClearable id="LeveringKlantID" label="Delivery address" value={sale.LeveringKlantID} onChange={handleChange} isDisabled={savingResult || sale.Verstuurd} />
                    <FormLayout.Group>
                      <TextField id="Notities" label="Note" value={sale.Notities || ''} multiline={4} onChange={handleChange} disabled={savingResult || sale.Verstuurd} />
                      <TextField
                        id="DocumentPrijzen"
                        label="Prijzen on document"
                        value={sale.DocumentPrijzen || ''}
                        multiline={4}
                        onChange={handleChange}
                        disabled={savingResult || sale.Verstuurd}
                        placeholder="Edit the price section in the document's terms and conditions block."
                      />
                    </FormLayout.Group>
                  </FormLayout>
                </Form>
              </Card>
              <WeightCard lines={lines} />
            </BlockStack>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <BlockStack gap="400">
              <DocumentCard
                resource="SALE"
                id={id}
                title="Sale document"
                company={company}
                docNumber={sale.Orderbevestigingnummer}
                isDirty={isDirty}
                linesDirty={linesDirty}
                generateDocument={generateDocument}
                docTypeID={docTypeID}
                docType={docType}
                setResource={setSale}
                setEmailSent={setEmailSent}
                docLang={sale.TaalID}
                documentButtonLoading={savingResult || documentInProgress}
                setDocumentButtonLoading={setSavingResult}
              />
              <LinesTotalCard summary={'sale'} lines={lines} />
              <Card>
                <BlockStack gap="300">
                  <Box>
                    <CardHeading title={`Linked invoices (${invoices.length})`} />
                    <LinkedInvoicesOverviewTable lines={lines} saleInclTax={totalSaleValue} invoices={invoices} creditSum={creditSum} />
                  </Box>
                  <Box>
                    {invoices.length > 0 && (
                      <DataTable
                        stickyHeader
                        increasedTableDensity
                        columnContentTypes={['text', 'numeric', 'numeric']}
                        headings={['Invoice', 'Value', 'Value open']}
                        rows={invoices.map((invoice) => [
                          <Link removeUnderline url={`/invoices/${invoice.faktuurnr}`}>
                            {invoice.Factuurnummer}
                          </Link>,
                          formatToCurrency(invoice.totaalbedrag),
                          formatToCurrency(invoice.bedragopen)
                        ])}
                      />
                    )}
                  </Box>
                  <Box>
                    <Button
                      disabled={savingResult || Math.round((totalSaleValue - invoices.reduce((prev, curr) => prev + curr.totaalbedrag, 0)) * 100) / 100 <= 0}
                      onClick={() => setInvoiceModalOpen(true)}
                    >
                      Invoice this sale
                    </Button>
                  </Box>
                  <Box paddingBlockStart="400">
                    <CardHeading title={`Linked deliveries (${deliveries.length})`} />
                    {deliveries.length > 0 && (
                      <DataTable
                        stickyHeader
                        increasedTableDensity
                        columnContentTypes={['text']}
                        headings={['Delivery']}
                        rows={deliveries.map((delivery) => [
                          <Link removeUnderline url={`/delivery_notes/${delivery.Bonnr}`}>
                            {delivery.LeveringsbonNummer}
                          </Link>
                        ])}
                      />
                    )}
                  </Box>
                  <Box>
                    <Button disabled={savingResult} onClick={() => SaleToDelivery(sale, lines, company, lineInterface, navigate)}>
                      Create delivery note for this sale
                    </Button>
                  </Box>
                </BlockStack>
              </Card>
              <ShopifyOrderMarkup />
              <QuotationMarkup />
            </BlockStack>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <CardHeading title="Content" />
              <LinesTable
                id={id}
                data={lines}
                updateMyData={updateMyData}
                setLines={setLines}
                setLinesDirty={setLinesDirty}
                onSubmit={saveForm}
                lineInterface={lineInterface}
                resource={sale}
                company={company}
                disabled={savingResult || sale.Verstuurd}
                setIsBlurred={setIsBlurred}
              />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <EventTimeLine resourceName="SALE" resourceId={id} />
          </Layout.Section>
        </Layout>
      )}
    </Page>
  );
}
