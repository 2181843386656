/*
    Function validates a YUP schema.
    RETURNS FALSE if valid, errorString if not.
    IF not valid, it will return the errorString and print the errors to the console. 
*/

export async function validateSchema(schema, object) {
  const validationResult = await schema.validate(object, { abortEarly: false }).catch((err) => err);

  //if there are errors log them all out to the console
  if (validationResult.errors) {
    console.log(validationResult.errors);
    //return validationResult.errors;
    //construct a string with all the errors
    let errorString = 'Validation errors: \n';
    errorString = validationResult.errors
      .filter(function (item, pos) {
        //filter is used to remove duplicate errors
        return validationResult.errors.indexOf(item) == pos; //eslint-disable-line
      })
      .map((error) => `❌ ${error}`)
      .join('\n');

    return errorString;
  }
  return false;
}

export async function validateLines(lines) {
  const dontExistArr = lines?.filter((line) => line.notExisting).map((l) => l.produktnr);

  if (dontExistArr.length > 0) {
    let errorString = 'The following product codes do not exist: \n' + dontExistArr.map((error) => `❌ #${error}`).join('\n');
    return errorString;
  }

  return false;
}
