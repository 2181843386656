/*
    Sharepoint url definition:

    example:
    https://schuilenburg.sharepoint.com/Schuilenburg/01076/Factuur/20211288/Facture%2020211288.pdf


    Opening of an excel file in the browser path:
    For opening word/excel in the browser:
    https://docs.microsoft.com/en-us/sharepoint/troubleshoot/lists-and-libraries/files-downloaded-instead-of-opening-office-application


    */

export function pad_with_zeroes(number, length) {
  var my_string = '' + number;
  while (my_string.length < length) {
    my_string = '0' + my_string;
  }
  return my_string;
}

export default function getSharePointUrlForFirnrDocumentTypeIdDocName(Klantnummer, DocumentTypeID, fileNumber, FileName, documentTypes, user) {
  //get the documenttypes out of localstorage and filter it on the DocumentTypeID
  // let documentTypes = JSON.parse(localStorage.getItem('documenttypes'));
  try {
    let documentType = documentTypes?.filter((documenttype) => documenttype.DocumentTypeID === DocumentTypeID)[0].DocumentTypeNL;
    const currentUser = user || JSON.parse(localStorage.getItem('currentUser'));

    let baseUrl = `https://schuilenburg.sharepoint.com/${currentUser.SHAREPOINT_DOCUMENT_LIBRARY_NAME}/${pad_with_zeroes(Klantnummer, 5)}/${documentType}`;

    if (DocumentTypeID !== 28 && DocumentTypeID !== 22) {
      baseUrl += '/' + fileNumber;
    }
    if (FileName) {
      baseUrl += '/' + FileName;
    }
    return baseUrl + '?web=1';
  } catch (err) {
    console.log('Error generating sharepoint url: currentUser is null?!');
    console.log('user:', JSON.parse(localStorage.getItem('currentUser')));
    throw new Error(`Error at getSharePointUrlForFirnrDocumentTypeIdDocName`, err);
  }
}

export function getSharePointPath(Klantnummer, DocumentTypeID, fileNumber, documentTypes) {
  let documentType = documentTypes?.filter((documenttype) => documenttype.DocumentTypeID === DocumentTypeID)[0].DocumentTypeNL;
  let path = `/${pad_with_zeroes(Klantnummer, 5)}/${documentType}`;
  if (DocumentTypeID !== 28 && DocumentTypeID !== 22) {
    path += '/' + fileNumber;
  }
  return path;
}

export function getSharePointPathForFirnrDocumentTypeIdDocName(Klantnummer, DocumentTypeID, fileNumber, FileName, documentTypes) {
  //This returns the path for the API functions, not to view in a browser!!
  let documentType = documentTypes.filter((documenttype) => documenttype.DocumentTypeID === DocumentTypeID)[0].DocumentTypeNL;

  let baseUrl = `/${pad_with_zeroes(Klantnummer, 5)}/${documentType}`;

  if (DocumentTypeID !== 28) baseUrl += '/' + fileNumber;

  if (FileName) baseUrl += '/' + FileName;

  return baseUrl;
}

/*
  LanguageID is the language id as defined in the database
*/
export function getSharepointSystemDocUrl(customerNumber, doctype, fileNumber, languageID) {
  const languages = {
    1: 'N',
    2: 'F',
    3: 'E',
    4: 'D'
  };
  const languageKey = languages[languageID];

  return `https://schuilenburg.sharepoint.com/${JSON.parse(localStorage.getItem('currentUser')).SHAREPOINT_DOCUMENT_LIBRARY_NAME}/${pad_with_zeroes(customerNumber, 5)}/${
    doc_spec[doctype].lan['N']
  }/${fileNumber}/${doc_spec[doctype].lan[languageKey]}%20${fileNumber}.pdf`;
}

export const GENERAL_CONDITIONS_DOC_NAMES = {
  N: 'Algemene Voorwaarden.pdf',
  F: 'Conditions Générales.pdf',
  E: 'General Conditions.pdf',
  D: 'Allgemeine Geschäftsbedingungen.pdf'
};

export const languageMap = {
  1: 'N',
  2: 'F',
  3: 'E',
  4: 'D'
};

export const doc_spec = {
  invoice: {
    lan: {
      N: 'Factuur',
      F: 'Facture',
      D: 'Rechnung',
      E: 'Invoice'
    }
  },
  service_report: {
    lan: {
      N: 'Montagebon',
      F: 'Bon de montage',
      D: 'Montagebericht',
      E: 'Service Report'
    }
  },
  delivery_note: {
    lan: {
      N: 'Leveringsbon',
      F: 'Bon de livraison',
      D: 'Lieferschein',
      E: 'Delivery Note'
    }
  },
  quotation: {
    lan: {
      N: 'Offerte',
      F: 'Offre',
      D: 'Angebot',
      E: 'Quotation'
    }
  },
  order_confirmation: {
    lan: {
      N: 'Orderbevestiging',
      F: 'Confirmation de commande',
      D: 'Auftragsbestätigung',
      E: 'Order confirmation'
    }
  },
  maintenance_contract: {
    lan: {
      N: 'Onderhoudscontract',
      F: "Contrat d'entretien",
      D: 'Wartungsvertrag',
      E: 'Maintenance contract'
    }
  },
  order: {
    lan: {
      N: 'Bestelling',
      F: 'Commande',
      D: 'Bestellung',
      E: 'Order'
    }
  },
  price_request: {
    lan: {
      N: 'Prijsaanvraag',
      F: 'Demande de prix',
      D: 'Preisanfrage',
      E: 'Price Request'
    }
  },
  reminder: {
    lan: {
      N: 'Herinnering',
      F: 'Rappel',
      D: 'Erinnerung',
      E: 'Reminder'
    }
  },
  project: {
    lan: {
      N: 'Project',
      F: 'Projet',
      D: 'Projekt',
      E: 'Project'
    }
  },
  credit_note: {
    lan: {
      N: 'Creditnota',
      F: 'Note de crédit',
      D: 'Gutschrift',
      E: 'Credit Note'
    }
  },
  proforma: {
    lan: {
      N: 'Proforma Factuur',
      F: 'Proforma Facture',
      D: 'Proforma Rechnung',
      E: 'Proforma Invoice'
    }
  },
  invoice_in: {
    lan: {
      N: 'Factuur In',
      F: 'Facture In',
      D: 'Rechnung In',
      E: 'Invoice In'
    }
  },
  credit_note_in: {
    lan: {
      N: 'Creditnota In',
      F: 'Note de crédit In',
      D: 'Gutschrift In',
      E: 'Credit Note In'
    }
  },
  letter: {
    lan: {
      N: 'Breif',
      F: 'Lettre',
      D: 'Schreiben',
      E: 'Letter'
    }
  },
  fax: {
    lan: {
      N: 'Fax',
      F: 'Fax',
      D: 'Fax',
      E: 'Fax'
    }
  },
  info_schuilenburg: {
    lan: {
      N: 'Info Schuilenburg',
      F: 'Info Schuilenburg',
      D: 'Info Schuilenburg',
      E: 'Info Schuilenburg'
    }
  },
  part: {
    lan: {
      N: 'Onderdeel',
      F: 'Pièce',
      D: 'Teil',
      E: 'Part'
    }
  },
  customer: {
    lan: {
      N: 'Klant',
      F: 'Client',
      D: 'Kunde',
      E: 'Customer'
    }
  },
  invoice_remaining: {
    lan: {
      N: 'Factuur voor de resterende waarde',
      F: 'Facture pour la valeur restante',
      D: 'Rechnung über Restwert',
      E: 'Invoice for remaining value'
    }
  }
};
