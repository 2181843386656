import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Badge, Layout, Page, Card, Link, DescriptionList, DataTable, Button, Banner, Box, InlineStack, BlockStack, Spinner, TextField, Text, Divider } from '@shopify/polaris';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { calculateTimeDifference, formatHtml, getRequest, getTimeSum, getUserOrg, putRequest, simplePostRequest, stripHtml, truncate } from '../../shared/components/functions.jsx';
import { TimeSlotRemarksPopover } from './components/TimeslotRemarksPopover.jsx';
import { ResetIcon } from '@shopify/polaris-icons';
import { toggleJobClosed, toggleJobFeedback, toggleJobInvoiced } from './helpers/index.js';
import { doc_spec, languageMap } from '../../utils/sharepoint.js';
import { JobTransformerModal } from './components/JobTransformerModal.jsx';
import { TabTitle } from '../../shell/helmet.jsx';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';
import Select from 'react-select';
import { getUserOptions } from '../../shared/components/listFilter.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import { FormattedHTML } from '../../shared/components/FormattedHtml.jsx';

/*
  FIXME:
  - invoiced prop on Timeslot is never set to 1

  - pay attention delivered products are coupled in a very strange way:
    - every time a product is delivered on a job, a Leveringsbon is created
    - this leveringsbon is coupled to the job via the pop 'refklant' 
    - PAY ATTENTION: the jobNumber is used here, not the jobID thus not the PRIMARY KEY

  - FIND LOGIC ACCESS IN HELPERS FOLDER
*/

export default function JobPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  const users = useLookupStore((state) => state.users);
  const organisations = useLookupStore((state) => state.organisations);
  const countries = useLookupStore((state) => state.countries);
  const languages = useLookupStore((state) => state.languages);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);
  const [job, setJob] = useState('');
  const [initialJob, setInitialJob] = useState({});
  const [timeslots, setTimeslots] = useState([]);
  const [company, setCompany] = useState('');
  const [deliveredParts, setDeliveredParts] = useState([]);
  const [onderhoudsContract, setOnderhoudsContract] = useState(null);
  const [tarieven, setTarieven] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [s3Documents, setS3Documents] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [modalResource, setModalResource] = useState('invoice');
  const [isSaving, setIsSaving] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [documentInProgress, setDocumentInProgress] = useState(false);

  const fetchJob = useCallback(async () => {
    //fetch job
    const data = await getRequest(`/api/jobs/${id}`);
    setJob(data[0]);
    setInitialJob(data[0]);

    //fetch associations
    setTimeslots(await getRequest(`/api/jobs/${id}/timeslots`));
    setDeliveredParts(await getRequest(`/api/jobs/${id}/deliveredparts`));
    setTarieven(await getRequest(`/api/jobs/${id}/tarieven`));

    setDataLoaded(true);
  }, []);

  useEffect(() => {
    fetchJob();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (job.CustomerId) {
        setCompany(await getRequest(`/api/companies/${job.CustomerId}`));
        setS3Documents(await getRequest(`/api/s3/jobs/${job.JobNumber}`));

        if (job.MaintenanceContractNr) {
          const data4 = await getRequest(`/api/jobs/${id}/onderhoudscontract/${job.MaintenanceContractNr}`);
          setOnderhoudsContract(data4[0]);
        }
      }
    }
    fetchData();
    //eslint-disable-next-line
  }, [job.CustomerId]);

  const totalWorkingHours =
    timeslots.length > 0
      ? timeslots.reduce((acc, timeslot) => {
          return acc + Math.abs(new Date(timeslot.EndTechnician) - new Date(timeslot.StartTechnician)) / 36e5;
        }, 0)
      : 0;
  const totalKms =
    timeslots.length > 0
      ? timeslots.reduce((acc, timeslot) => {
          return acc + timeslot.Km;
        }, 0)
      : 0;
  //const totalWorkingHoursCost = totalWorkingHours * workingHourCostFactorEUR;

  const translations = {
    normal: {
      1: 'Uren',
      2: 'Heures',
      3: 'Hours',
      4: 'Stunden'
    },
    overtime: {
      1: 'Overuren',
      2: 'Heures supplémentaires',
      3: 'Overtime',
      4: 'Überstunden'
    },
    saturday: {
      1: 'Uren Zaterdag',
      2: 'Heures Samedi',
      3: 'Hours Saturday',
      4: 'Stunden Samstage'
    },
    sunday: {
      1: 'Uren Zondag',
      2: 'Heures Dimanche',
      3: 'Hours Sunday',
      4: 'Stunden Sonntag'
    },
    atelier: {
      1: '(Atelier) ',
      2: '(Atelier) ',
      3: '(Workplace) ',
      4: '(Studio) '
    }
  };

  function getMinutesBetweenDates(startDate, endDate) {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }

  const handleJobTransform = async (appendToExistingInvoice = false, invoiceId, resource) => {
    /*
      STILL TO DO:
      - middagvergoeding als zowel gewerkt in voormiddag als namiddag
      - checken of het een feestdag is
    */

    //calculate overhours //mon-thurs: 9hrs normal tarrif and then overhour tarrif, fri-sat: 9hrs normal tarrif
    //use the correct tarrif for calculating cost
    //vat considerations?
    //include prelines
    //include driven kms
    //include delivered products

    const preLines = [
      // {
      //   tekstlijn: `Berekening werken in regie `,
      //   VP: 0,
      //   aantal: 0,
      //   BTW: 0
      // },
      {
        tekstlijn: `${doc_spec['service_report'].lan[languageMap[company.TaalID]]} N° ${job.JobNumber} `,
        VP: 0,
        aantal: 0,
        BTW: company.LandID === 300 ? 21 : 0,
        produktnr: 1,
        faktuurnr: 'new',
        Lijnnr: null
      }
    ];

    let timeslotLines = [];

    //this is build on the assumption that the timeslots are ordered by date and technician
    let currentDate = '';
    let currentTech = '';
    let totalMins = 0;
    let tooManyMins = 0;
    //FIXME: stil to do
    //let workedMorning = false;
    //let workedAfternoon = false;
    let tarief = '';
    let productNr = 1;

    function setTarief(productNr) {
      tarief = tarieven.find((tarief) => tarief.ProductID === productNr)['Eenheids verkoopprijs'];
      return tarief;
    }

    for (let i = 0; i < timeslots.length; i++) {
      const timeslot = timeslots[i];
      const techName = users.find((user) => user.GebruikerID === timeslot.TechnicianID).Gebruikernaam;

      //reset if new date or new technician
      if (timeslot.Date !== currentDate || techName !== currentTech) {
        currentDate = timeslot.Date;
        currentTech = techName;
        totalMins = 0;
        tooManyMins = 0;
        //workedMorning = false;
        //workedAfternoon = false;
      }

      //check if technicians worked in noon or afternoon, if both are then a middagvergoeding should be awarded
      if (parseInt(timeslot.StartTechnician.substring(11, 13)) < 12) {
        //workedMorning = true;
      }
      if (parseInt(timeslot.StartTechnician.substring(11, 13)) >= 12) {
        //workedAfternoon = true;
      }

      //some technicians have an IT Tarrif, they are thus more expensive
      let itTariff = users.find((user) => user.GebruikerID === timeslot.TechnicianID).ITTarief;
      let OHC = false;
      let KT = false;
      let VT = false;
      let VTOC = false;
      let DCM = false;

      //define all the tariffs FIXME: check logic with JAD
      if (!itTariff) {
        //check if it is onderhoudscontract
        OHC = job.MaintenanceContractNr ? true : false;

        //FIXME: I think there is a mistake here in the code in access? Reference tot the Onderhoudcontractnumber
        if (OHC) {
          switch (onderhoudsContract.Machinemerk) {
            case 1: //Probat: KT Tarieven
              KT = true;
              break;
            case 2: //Langguth: VTOC Tarieven
              VTOC = true;
              break;
            case 4: //Mahlk�nig: DC/M Tarieven
              DCM = true;
              break;
            case 10: //ICA: VTOC Tarieven
              VTOC = true;
              break;
            case 15: //Dalla Corte: DC/M Tarieven
              DCM = true;
              break;
            case 19: //Kvdw: DC/M Tarieven
              DCM = true;
              break;
            default:
              //Overige merken: VTOC Tarieven
              VTOC = true;
          }

          //log out all the tarrifs
          console.log(`${job.MaintenanceContractNr} - ${onderhoudsContract.Machinemerk} - KT: ${KT} - VT: ${VT} - VTOC: ${VTOC} - DCM: ${DCM}`);
        } else {
          //Geen onderhoudscontract: VT Tarieven
          VT = true;
        }
      }

      //get weekday of date  Sunday = 0, Monday = 1
      let weekday = new Date(timeslot.Date).getDay();

      //TO DO CHECK IF DATE IS FEESTDAG IF YES THEN CONSIDER IT TO BE A SUNDAY AND SET WEEKDAY TO 0

      /*
        -- uren zaterdag 150 en alle overuren op andere dagen
        -- uren zondag & feestdagen 200
        -- access makes use of startTechnician and endTechnician
      */

      switch (weekday) {
        case 0:
          if (itTariff) {
            tarief = setTarief(19);
            productNr = 19;
          }
          if (KT) {
            tarief = setTarief(10);
            productNr = 10;
          }
          if (VT) {
            tarief = setTarief(13);
            productNr = 13;
          }
          if (VTOC) {
            tarief = setTarief(16);
            productNr = 16;
          }
          if (DCM) {
            tarief = setTarief(23);
            productNr = 23;
          }

          //sunday (or converted feestdag, see above) -- all hours 200%
          timeslotLines.push({
            tekstlijn: `${timeslot.Date.substring(0, 10)}  ${translations.sunday[company.TaalID]} ${
              timeslot.Warehouse ? translations.atelier[company.TaalID] : ''
            } ${techName}: ${timeslot.StartTechnician.substring(11, 16)} - ${timeslot.EndTechnician.substring(11, 16)}`,
            aantal: Math.round((Math.abs(new Date(timeslot.EndTechnician) - new Date(timeslot.StartTechnician)) / 36e5) * 100) / 100,
            VP: tarief,
            produktnr: productNr,
            BTW: company.LandID === 300 ? 21 : 0,
            faktuurnr: 'new',
            Lijnnr: null
          });
          break;
        case 6:
          if (itTariff) {
            tarief = setTarief(18);
            productNr = 18;
          }
          if (KT) {
            tarief = setTarief(9);
            productNr = 9;
          }
          if (VT) {
            tarief = setTarief(12);
            productNr = 12;
          }
          if (VTOC) {
            tarief = setTarief(15);
            productNr = 15;
          }
          if (DCM) {
            tarief = setTarief(22);
            productNr = 22;
          }

          //saturday -- all hours 150%
          timeslotLines.push({
            tekstlijn: `${timeslot.Date.substring(0, 10)}  ${translations.saturday[company.TaalID]} ${
              timeslot.Warehouse ? translations.atelier[company.TaalID] : ''
            } ${techName}: ${timeslot.StartTechnician.substring(11, 16)} - ${timeslot.EndTechnician.substring(11, 16)}`,
            aantal: Math.round((Math.abs(new Date(timeslot.EndTechnician) - new Date(timeslot.StartTechnician)) / 36e5) * 100) / 100,
            VP: tarief,
            produktnr: productNr,
            BTW: company.LandID === 300 ? 21 : 0,
            faktuurnr: 'new',
            Lijnnr: null
          });
          break;

        default: //Maximaal 540 minuten (9 uur) normaal tarief, 8 uur op vrijdag
          if (itTariff) {
            tarief = setTarief(17);
            productNr = 17;
          }
          if (KT) {
            tarief = setTarief(8);
            productNr = 8;
          }
          if (VT) {
            tarief = setTarief(11);
            productNr = 11;
          }
          if (VTOC) {
            tarief = setTarief(14);
            productNr = 14;
          }
          if (DCM) {
            tarief = setTarief(21);
            productNr = 21;
          }

          //weekday

          let maxMinutesDay = weekday === 5 ? 480 : 540;

          let minsTimeslot = getMinutesBetweenDates(new Date(timeslot.StartTechnician), new Date(timeslot.EndTechnician));
          //console.log('minsTimeslot', minsTimeslot);
          totalMins += minsTimeslot;
          tooManyMins = totalMins - maxMinutesDay;

          if (tooManyMins > 0) {
            //write a corrected normal line
            let endDate = new Date(new Date(timeslot.EndTechnician) - tooManyMins * 60000);
            let endDateFormattedHrs = endDate.toISOString().substring(11, 16);

            timeslotLines.push({
              tekstlijn: `${timeslot.Date.substring(0, 10)}  ${translations.normal[company.TaalID]} ${
                timeslot.Warehouse ? translations.atelier[company.TaalID] : ''
              } ${techName}: ${timeslot.StartTechnician.substring(11, 16)} - ${endDateFormattedHrs}`,
              aantal: Math.round((Math.abs(endDate - new Date(timeslot.StartTechnician)) / 36e5) * 100) / 100,
              VP: tarief,
              produktnr: productNr,
              BTW: company.LandID === 300 ? 21 : 0,
              faktuurnr: 'new',
              Lijnnr: null
            });

            //write an extra overtime line 150%
            let startDate = new Date(new Date(timeslot.EndTechnician) - tooManyMins * 60000);
            let startDateFormattedHrs = startDate.toISOString().substring(11, 16);

            if (itTariff) {
              tarief = setTarief(18);
              productNr = 18;
            }
            if (KT) {
              tarief = setTarief(9);
              productNr = 9;
            }
            if (VT) {
              tarief = setTarief(12);
              productNr = 12;
            }
            if (VTOC) {
              tarief = setTarief(15);
              productNr = 15;
            }
            if (DCM) {
              tarief = setTarief(22);
              productNr = 22;
            }

            timeslotLines.push({
              tekstlijn: `${timeslot.Date.substring(0, 10)}  ${translations.overtime[company.TaalID]} ${
                timeslot.Warehouse ? translations.atelier[company.TaalID] : ''
              } ${techName}: ${startDateFormattedHrs} - ${timeslot.EndTechnician.substring(11, 16)}`,
              aantal: Math.round((Math.abs(new Date(timeslot.EndTechnician) - startDate) / 36e5) * 100) / 100,
              VP: tarief,
              produktnr: productNr,
              BTW: company.LandID === 300 ? 21 : 0,
              faktuurnr: 'new',
              Lijnnr: null
            });
          } else {
            //write a normal line
            timeslotLines.push({
              tekstlijn: `${timeslot.Date.substring(0, 10)}  ${translations.normal[company.TaalID]} ${
                timeslot.Warehouse ? translations.atelier[company.TaalID] : ''
              } ${techName}: ${timeslot.StartTechnician.substring(11, 16)} - ${timeslot.EndTechnician.substring(11, 16)}`,
              aantal: Math.round((Math.abs(new Date(timeslot.EndTechnician) - new Date(timeslot.StartTechnician)) / 36e5) * 100) / 100,
              VP: tarief,
              produktnr: productNr,
              BTW: company.LandID === 300 ? 21 : 0,
              faktuurnr: 'new',
              Lijnnr: null
            });
          }
          break;
      }
    }

    const kmsProdNr = job.MaintenanceContractNr ? 6 : 3;
    const kmsTarief = setTarief(kmsProdNr);
    const tksLijn = tarieven.find((tarief) => tarief.ProductID === kmsProdNr);

    function returnTekstLijn(part) {
      let tks = '';
      switch (company.TaalID) {
        case 1:
          tks = part.ProductNameNL;
          break;
        case 2:
          tks = part.ProductNameFR;
          break;
        case 3:
          tks = part.ProductNameUK;
          break;
        case 4:
          tks = part.ProductNameDE;
          break;
        default:
          tks = part.ProductNameNL;
          break;
      }
      return tks;
    }

    const kmsline = {
      tekstlijn: returnTekstLijn(tksLijn),
      aantal: Math.round(totalKms * 100) / 100 || 0,
      VP: kmsTarief,
      produktnr: kmsProdNr,
      BTW: company.LandID === 300 ? 21 : 0,
      faktuurnr: 'new',
      Lijnnr: null
    };

    const parts = deliveredParts.map((part) => {
      return {
        tekstlijn: part.tekstlijn,
        aantal: part.aantalgeleverd,
        VP: part['Eenheids verkoopprijs'],
        produktnr: part.produktnr,
        BTW: company.LandID === 300 ? 21 : 0,
        faktuurnr: 'new',
        Lijnnr: null,
        AP: part.Eenheidsaankoopprijs
      };
    });

    //flow depends here if job needs to be appended to an existing invoice/quotation
    //case 1: default case, create a new invoice/quotation
    if (!appendToExistingInvoice) {
      let com = company;

      if (company.FirNrFactuur) {
        //get seperate invoicing company from api
        com = await getRequest(`/api/companies/${company.FirNrFactuur}`);
        if (!window.confirm(`ATTENTION: This company has a separate invoicing address, this will create an invoice on ${com.NAAM}`)) return;
      }

      const { FIRNR, NAAM, STRAAT_NR, POSTNR, WOONPL, LandID, BTW, TaalID, betalingID } = com;
      const name = NAAM.replace('&', '%26');
      let url = `?klantnummer=${FIRNR}&klantnaam=${name}&adres=${STRAAT_NR}&postkode=${POSTNR}&woonplaats=${WOONPL}&LandID=${LandID}&btwnummer=${BTW}&TaalID=${TaalID}&betalingID=${betalingID}&GebruikerID=${
        job.OwnerUserID || user.id
      }&refklant=${job.Reference || `Job: ${job.JobNumber}`}&isDirty=true&linesDirty=true`;

      navigate(`/${resource}/new${url}`, { state: [...preLines, ...timeslotLines, kmsline, ...parts] });
    }
    //append to existing invoice
    else {
      navigate(`/${resource}/${invoiceId}?appendLines=true`, { state: [...preLines, ...timeslotLines, kmsline, ...parts] });
    }
  };

  const handleOwnerChange = async (value) => {
    setIsSaving(true);
    await putRequest(`/api/jobs/${id}`, { OwnerUserID: value }, setToast, setToastError);
    fetchJob();
    setIsSaving(false);
  };

  const handleChange = async (value, id) => {
    setJob((job) => ({ ...job, [id]: value }), setIsDirty(true));
  };

  const saveForm = async () => {
    setIsSaving(true);
    const res = await putRequest(`/api/jobs/${id}`, job, setToast, setToastError);
    if (res) setIsDirty(false);
    setIsSaving(false);
  };

  const getPdfTimes = (timeslot) => {
    let dates = [timeslot.StartTechnician, timeslot.PlannedStartTime, timeslot.PlannedEndTime, timeslot.EndTechnician];
    if (dates[0].includes('00:00:00')) dates[0] = dates[1];
    if (dates[3].includes('00:00:00')) dates[3] = dates[2];
    return dates;
  };

  const downloadPdf = async () => {
    const timeTable = timeslots.map((timeslot) => {
      const times = getPdfTimes(timeslot);
      const extractedTime = times.map((t) => t.substring(t.indexOf('T') + 1, 16));

      const calcTravelTime = (time) => {
        const dateA = new Date(time[0]);
        const dateB = new Date(time[1]);
        const dateC = new Date(time[2]);
        const dateD = new Date(time[3]);
        const durationAB = dateB.getTime() - dateA.getTime();
        const durationCD = dateD.getTime() - dateC.getTime();
        const totalDuration = durationAB + durationCD;
        const totalHours = Math.floor(totalDuration / (60 * 60 * 1000));
        const totalMinutes = Math.floor((totalDuration % (60 * 60 * 1000)) / (60 * 1000));
        return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}`;
      };

      return {
        date: timeslot.Date.substring(0, 10),
        technician: users.find((user) => user.GebruikerID === timeslot.TechnicianID).Gebruiker,
        technicianStart: extractedTime[0],
        plannedStart: extractedTime[1],
        plannedEnd: extractedTime[2],
        technicianEnd: extractedTime[3],
        totalTravel: calcTravelTime(times),
        totalWork: calculateTimeDifference(times[1], times[2]),
        totalHours: calculateTimeDifference(times[0], times[3])
      };
    });

    const deliveredPartsTable = deliveredParts.map((p) => {
      return {
        produktnr: p.produktnr,
        description: p.tekstlijn,
        delivered: `x${p.aantalgeleverd}`,
        warranty: p.garantie ? 'Yes' : 'No'
      };
    });

    const payload = {
      addressee: {
        FIRNR: company.FIRNR,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      id: job.JobID,
      language: company.TaalID ? languages.find((l) => l.TaalID === parseInt(company.TaalID))?.Taal : 'N',
      number: job.JobNumber,
      type: 'job',
      items: timeTable,
      totalSum: getTimeSum(timeTable.map((t) => t.totalHours)),
      deliveredParts: deliveredPartsTable,
      jobDescription: formatHtml(job.Remarks)
    };

    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen/job`, payload);

    if (res.ok) {
      setDocumentInProgress(false);

      const blob = await res.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = `Job_${job.JobNumber}.pdf`;
      a.click();
      URL.revokeObjectURL(url);
    } else {
      console.error('Error:', res.status, res.statusText);
      setToastError(true);
    }
  };

  const handleModal = (resource) => {
    setModalActive(true);
    setModalResource(resource);
  };

  if (!dataLoaded)
    return (
      <Box padding="400">
        <Spinner size="small" />
      </Box>
    );

  return (
    <Page
      title={`Job #${job.JobNumber}`}
      fullWidth
      primaryAction={{ content: 'Invoice job', disabled: false, onAction: () => handleModal('invoice') }}
      secondaryActions={[
        {
          content: 'Open in planning',
          accessibilityLabel: 'Open job in technicians planning',
          onAction: () => window.open(`https://tech.schuilenburg.be/Jobscherm/${id}`, '_blank').focus()
        },
        { content: 'Download job report', onAction: downloadPdf, loading: documentInProgress },
        { content: 'Create pro forma invoice', onAction: () => handleModal('quotation') }
      ]}
    >
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Owner saved" />
      <TheSaveBar
        isDirty={isDirty}
        savingResult={isSaving}
        handleSave={saveForm}
        handleDiscard={() => {
          setJob(initialJob);
          setIsDirty(false);
        }}
      />
      <TabTitle title={`Job #${job.JobNumber}`} />
      <JobTransformerModal setJob={setJob} active={modalActive} setActive={setModalActive} handleJobTransform={handleJobTransform} company={company} resource={modalResource} />
      <Box paddingBlockEnd="400">
        {!job.Invoiced && (
          <Banner
            tone="warning"
            action={{ content: 'Mark as invoiced', onAction: async () => setJob(await toggleJobInvoiced(job.JobID, !job.Invoiced)) }}
            title="Pay attention! This job is not yet invoiced."
          />
        )}
        {!job.Closed && <Banner title="This job is still open!" tone="info" action={{ content: 'Mark as closed', onAction: async () => setJob(await toggleJobClosed(job.JobID, !job.Closed)) }} />}
        {!job.ToDoOfficeProcessed && (
          <Banner
            action={{ content: 'Mark as feedback processed', onAction: async () => setJob(await toggleJobFeedback(job.JobID, !job.ToDoOfficeProcessed)) }}
            title="Pay attention! A technician left some feedback that still needs to be processed."
          />
        )}
      </Box>
      <Layout>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="400">
              <ListBox heading="Job number" content={job.JobNumber} />
              <Divider />
              <ListBox heading="Reference" content={job.Reference} />
              <Divider />
              <ListBox heading="Description" content={job.Description ? <FormattedHTML html={job.Description} /> : ''} />
              <Divider />
              <ListBox
                heading="Customer"
                content={
                  <Link removeUnderline url={`/companies/${job.CustomerId}`}>
                    {company.NAAM}
                  </Link>
                }
              />
              <Divider />
              <ListBox heading="Order date" content={job.OrderDate.substring(0, 10)} />
              <Divider />
              <ListBox heading="Deadline" content={String(job.DeadLine).substring(0, 10)} />
              <Divider />
              <ListBox
                heading="Status closed"
                content={
                  job.Closed ? (
                    <InlineStack gap="200" blockAlign="center">
                      <Box>
                        <Badge size="small">Closed</Badge>
                      </Box>
                      <Button icon={ResetIcon} size="micro" onClick={async () => setJob(await toggleJobClosed(job.JobID, !job.Closed))} />
                    </InlineStack>
                  ) : (
                    <Badge tone="warning">Not closed</Badge>
                  )
                }
              />
              <Divider />
              <ListBox
                heading="Status invoiced"
                content={
                  job.Invoiced ? (
                    <InlineStack gap="200" blockAlign="center">
                      <Box>
                        <Badge>Invoiced</Badge>
                      </Box>
                      <Button icon={ResetIcon} size="micro" onClick={async () => setJob(await toggleJobInvoiced(job.JobID, !job.Invoiced))} />
                    </InlineStack>
                  ) : (
                    <Badge tone="warning">Not invoiced</Badge>
                  )
                }
              />
              <Divider />
              <ListBox heading="Invoice status" content={<TextField value={job.InvoiceStatusDescription || ''} id="InvoiceStatusDescription" onChange={handleChange} multiline={4} />} />
              <Divider />
              <ListBox
                heading="Owner"
                content={
                  <Box width="50%">
                    <Select
                      id="OwnerUserID"
                      options={filteredUsers}
                      onChange={(e) => handleOwnerChange(e.value)}
                      value={filteredUsers.find((c) => parseInt(c.value) === parseInt(job.OwnerUserID)) || ''}
                      isDisabled={isSaving}
                      menuPortalTarget={document.body}
                      components={{ IndicatorSeparator: () => null }}
                      styles={{
                        valueContainer: (base) => ({ ...base, marginTop: -4 }),
                        indicatorsContainer: (base) => ({ ...base, height: '25px' }),
                        control: (base) => ({ ...base, height: '25px', minHeight: '25px', borderRadius: '5px', borderColor: '#919191' }),
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                      }}
                    />
                  </Box>
                }
              />
              <Divider />
              <ListBox heading="Changed by" content={job.ChangedBy} />
              <Divider />
              <ListBox heading="Last changed" content={job.ChangedOn.substring(0, 10)} />
              <Divider />
              <ListBox heading="Feedback office" content={job.ToDoOffice ? <FormattedHTML html={job.ToDoOffice} /> : ''} />
              <Divider />
              <ListBox
                heading="Feedback processed"
                content={
                  job.ToDoOfficeProcessed ? (
                    <InlineStack gap="200" blockAlign="center">
                      <Box>
                        <Badge>Feedback processed</Badge>
                      </Box>
                      <Button icon={ResetIcon} size="micro" onClick={async () => setJob(await toggleJobFeedback(job.JobID, !job.ToDoOfficeProcessed))} />
                    </InlineStack>
                  ) : (
                    <Badge tone="warning">Feedback not processed</Badge>
                  )
                }
              />
              <Divider />
              <ListBox heading="Last changed" content={job.ChangedOn.substring(0, 10)} />
              <Divider />
              <ListBox heading="Remarks" content={job.Remarks ? <FormattedHTML html={job.Remarks} /> : ''} />
            </BlockStack>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Jobs stats summary" />
              <DescriptionList
                items={[
                  { term: 'Total hrs on job', description: Math.round(totalWorkingHours * 100) / 100 + ' hrs' },
                  // {
                  //   term: 'Normal hours',
                  //   description: Math.round(totalWorkingHours * 100) / 100 + ' hrs -  ' + formatToCurrency((Math.round(totalWorkingHours * 100) / 100) * 67.5)
                  // },
                  // {
                  //   term: 'Over hours',
                  //   description: Math.round(totalWorkingHours * 100) / 100 + ' hrs -  ' + formatToCurrency((Math.round(totalWorkingHours * 100) / 100) * 67.5)
                  // },
                  { term: 'Total kms on job', description: Math.round(totalKms * 100) / 100 + ' Kms ' }
                ]}
              />
            </Card>
            <Card>
              <CardHeading title="Delivered products" />
              {deliveredParts.length > 0 ? (
                <DataTable
                  stickyHeader={true}
                  increasedTableDensity={true}
                  verticalAlign="middle"
                  columnContentTypes={['text', 'text', 'text', 'text']}
                  headings={['Code', 'Part', 'Qty', 'Warranty']}
                  rows={deliveredParts.map((part) => {
                    return [
                      <Link removeUnderline url={`/products/${part.produktnr}`}>
                        {part.produktnr}
                      </Link>,
                      truncate(part.tekstlijn, 40, false),
                      `x${part.aantalgeleverd}`,
                      part.garantie ? 'Yes' : 'No'
                    ];
                  })}
                  truncate={false}
                />
              ) : (
                'No delivered products'
              )}
            </Card>
            <Card>
              <CardHeading title="Documents & pictures" />
              {s3Documents.length > 0
                ? s3Documents.map((document) => {
                    return (
                      <Link key={document.Key} removeUnderline url={`http://werkbonnen.s3-website-eu-west-1.amazonaws.com/${document.Key}`}>
                        {String(document.Key).split('/')[2]}
                      </Link>
                    );
                  })
                : 'No documents'}
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Logged timeslots" />
            <DataTable
              stickyHeader={true}
              increasedTableDensity={true}
              verticalAlign="middle"
              columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
              headings={['Date', 'Technician', 'StartTechnician', 'PlannedStartTime', 'PlannedEndTime', 'EndTechnician', 'Remarks', 'Km', 'Signed Off', 'Invoiced']}
              rows={timeslots.map((timeslot) => {
                return [
                  timeslot.Date.substring(0, 10),
                  users.find((user) => user.GebruikerID === timeslot.TechnicianID).Afkorting,
                  timeslot.StartTechnician.substring(0, 16).replace('T', ' '),
                  timeslot.PlannedStartTime.substring(0, 16).replace('T', ' '),
                  timeslot.PlannedEndTime.substring(0, 16).replace('T', ' '),
                  timeslot.EndTechnician.substring(0, 16).replace('T', ' '),
                  timeslot.Remarks ? <TimeSlotRemarksPopover remark={timeslot.Remarks} /> : 'none',
                  timeslot.Km,
                  timeslot.SignedOff ? <Badge>Yes</Badge> : <Badge tone="warning">No</Badge>,
                  timeslot.Invoiced ? <Badge>Yes</Badge> : <Badge tone="warning">No</Badge>
                ];
              })}
              truncate={false}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const ListBox = ({ heading, content }) => {
  return (
    <InlineStack gap="100" wrap={false}>
      <Box width="150px">
        <Text as="p" fontWeight="bold">
          {heading}
        </Text>
      </Box>
      <div style={{ width: '100%' }}>{content}</div>
    </InlineStack>
  );
};
