import { useState, useEffect } from 'react';
import { Badge, DataTable, Text, Card, Modal, TextField, Checkbox, Button, Box, FormLayout } from '@shopify/polaris';
import { CardHeading } from '../../../shared/components/cards/CardHeading';

export default function HolidaysComponent() {
  const [holidays, setHolidays] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isADV, setIsADV] = useState(false);
  const [isRecupFD, setIsRecupFD] = useState(false);
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(null);

  const handleModalToggle = () => {
    setIsRecupFD(false);
    setIsADV(false);
    setDescription('');
    setDate(null);
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const getHolidays = () => {
    fetch('/api/holidays/upcoming')
      .then((response) => response.json())
      .then((data) => {
        setHolidays(data);
      });
  };

  const rows_holiday = Array.isArray(holidays)
    ? holidays.map((item) => {
        const holiday_type =
          item.Brugdag === false && item.RecupFDDag === false ? (
            <Badge>Normal</Badge>
          ) : item.Brugdag === true ? (
            <Badge tone="info">Arbeidsduurverkorting</Badge>
          ) : (
            <Badge tone="warning">RecupFD</Badge>
          );

        return [
          String(item.Datum).substring(0, 10),
          item.Omschrijving,
          holiday_type,
          <Button size="micro" variant="plain" tone="critical" onClick={() => removeHoliday(item)}>
            delete
          </Button>
        ];
      })
    : [];

  const removeHoliday = async (item) => {
    await fetch(`/api/holidays/${item.FeestdagenID}/remove`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    getHolidays();
  };

  const addHoliday = async () => {
    //check if only isAdv or isRecupFD is true, they can not be true at the same time. If it is true alert the user
    if (isADV === true && isRecupFD === true) {
      alert('You can not add a holiday with both ADV and RecupFD');
      return;
    }
    if (date == null) {
      alert('Please select a date');
      return;
    } else {
      //post async fetch function to add holiday to db
      await fetch('/api/holidays/add', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          date: date,
          description: description,
          isAdv: isADV,
          isRecupFD: isRecupFD
        })
      });
      getHolidays();
      setModalOpen(false);
    }
  };

  return (
    <Card>
      <CardHeading title={'Upcoming official holidays'} actions={[{ content: 'Add holidays', onAction: () => handleModalToggle() }]} />
      <Modal
        open={modalOpen}
        onClose={handleModalToggle}
        title="Add holiday"
        primaryAction={{
          content: 'Add holiday',
          onAction: () => addHoliday()
        }}
      >
        <Modal.Section>
          <FormLayout>
            <TextField
              id="Datum"
              label="Date"
              type="date"
              value={date}
              onChange={(value) => {
                setDate(value);
              }}
            />
            <TextField multiline={3} label="Description" type="textarea" value={description} onChange={(value) => setDescription(value)}></TextField>
            <Checkbox label="ADV" checked={isADV} onChange={() => setIsADV(!isADV)}></Checkbox>
            <Checkbox label="RecupFD" checked={isRecupFD} onChange={() => setIsRecupFD(!isRecupFD)}></Checkbox>
          </FormLayout>
        </Modal.Section>
      </Modal>

      <Box paddingBlockEnd={'300'}>
        <Text tone="subdued">Listed holidays are automatically added to the technicians application, so please keep it up to date by adding them at the beginning of the year.</Text>
      </Box>

      <DataTable
        stickyHeader={true}
        increasedTableDensity={true}
        verticalAlign="middle"
        columnContentTypes={['text', 'text', 'text', 'numeric']}
        headings={['Date', 'Holiday', 'Type', 'Remove']}
        rows={rows_holiday}
      />
    </Card>
  );
}
