import { Banner, Modal, TextField, Select as PSelect, Text, Link, Box, Form, FormLayout } from '@shopify/polaris';
import CreatableSelect from 'react-select/creatable';
import { useAuthStore, useLookupStore } from '../../../context/useStore';
import { useEffect, useState } from 'react';
import { alphabetize, getRequest, simplePostRequest, formatToCurrency } from '../../../shared/components/functions';
import { GENERAL_CONDITIONS_DOC_NAMES, getSharePointPathForFirnrDocumentTypeIdDocName, languageMap } from '../../../utils/sharepoint';
import { setVerstuurd } from '../../../shared/requests/setVerstuurd';
import { performTracking } from '../../../analytics/segment';
import { selectStyles } from '../../../shared/components/inputs/selectColors';

export const ReminderMailModal = ({ modalActive, setModalActive, invoice, fetchInvoices, toast, setToast }) => {
  const { user } = useAuthStore();
  const emailformats = useLookupStore((state) => state.emailformats);
  const languages = useLookupStore((state) => state.languages);
  const countries = useLookupStore((state) => state.countries);
  const documenttypes = useLookupStore((state) => state.documenttypes);
  const docTypeID = 15;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [sendMailButtonLoading, setSendMailButtonLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [openInvoices, setOpenInvoices] = useState([]);
  const [ccOptions, setCcOptions] = useState([]);
  const [mailTo, setMailTo] = useState(null);
  const [mailCcs, setMailCcs] = useState([]);
  const [mailBccs, setMailBccs] = useState([]);
  const [mailSubject, setMailSubject] = useState('');
  const [mailContent, setMailContent] = useState('');
  const [language, setLanguage] = useState(invoice.TaalID || '3');

  const taalIdToKey = {
    1: 'NL',
    2: 'FR',
    3: 'UK',
    4: 'DE'
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/contacts?limit=10000&FIRNR=${invoice.klantnummer}`);
      setContacts(data);
      setCcOptions([
        ...data
          .filter((con) => !con.DISCONTINUED && con['E-mail'] !== null)
          .sort((a, b) => alphabetize(a, b, 'Afkorting'))
          .map((con) => {
            return { value: con.CONNR, label: `${con.NAAM} (${con['E-mail']})`, TaalID: con.TaalID, email: con['E-mail'] };
          })
      ]);
      const invoiceData = await getRequest(`/api/invoices?limit=10000&FIRNR=${invoice.klantnummer}&isOpen=1`);
      setOpenInvoices(invoiceData);
      setDataLoaded(true);
    }
    if (invoice.klantnummer) fetchData();
  }, [invoice.klantnummer]);

  useEffect(() => {
    if (emailformats && emailformats.length > 0) {
      const emailFormat = emailformats.find((em) => em.DocumentTypeID === 15);
      const startKey = `EmailBodyStart${taalIdToKey[language]}`;
      const bodyKey = `EmailBody${taalIdToKey[language]}`;
      setMailContent(`${emailFormat[startKey]}\r\r${emailFormat[bodyKey]}\r${user?.full_name}`);
      const subjectKey = `EmailSubject${taalIdToKey[language]}`;
      setMailSubject(`${emailFormat[subjectKey]}`);
    }
  }, [language, emailformats]);

  useEffect(() => {
    if (dataLoaded && contacts.map((con) => con['E-mail']).includes(mailTo.email)) {
      setLanguage(mailTo.TaalID);
    }
  }, [mailTo]);

  const handleReminderMail = async () => {
    setSendMailButtonLoading(true);

    //get company
    const companyData = await getRequest(`/api/companies/${invoice.klantnummer}`);

    //create reminders
    const document = {
      addressee: {
        FIRNR: invoice.klantnummer,
        name: invoice.klantnaam,
        number: companyData.Klantnummer,
        address: invoice.adres,
        city: invoice.woonplaats,
        zip: invoice.postkode,
        country: countries.find((c) => c.LandId === parseInt(invoice.LandID))?.Land,
        countryId: invoice.LandID,
        vat: invoice.btwnummer,
        postal_code: invoice.postkode,
        EU: countries.find((country) => country.LandId === companyData.LandID).EU,
        private: companyData.Particulier
      },
      language: invoice.TaalID ? languages.filter((l) => l.TaalID === invoice.TaalID)[0].Taal : 'N',
      type: 'reminder',
      typeID: 15,
      id: invoice.faktuurnr,
      reference: invoice.refklant,
      reference_date: invoice.refklantdatum ? String(invoice.refklantdatum).substring(0, 10) : '',
      invoice_status: invoice.status,
      number: null
    };
    const res = await simplePostRequest(`/api/payment_reminders`, document);

    if (res.ok) {
      const reminderData = await res.json();
      // console.log('reminderData', reminderData);
      const reminder = await getRequest(`/api/payment_reminders?rappelnummer=${reminderData.reminder}`);
      // console.log('reminder', reminder);
      const documentsData = await getRequest(`/api/documents/id/typeid?id=${invoice.faktuurnr}&typeID=${docTypeID}`);
      // console.log('documentsData', documentsData);
      const reminderInvoices = await getRequest(`/api/payment_reminders/related_invoices?rappelnummer=${reminder[0].Rappelnummer}&DocumentTypeID=1`);
      // console.log('reminderInvoices', reminderInvoices);

      // send mail
      const sharepointUploadPath = getSharePointPathForFirnrDocumentTypeIdDocName(companyData.Klantnummer, docTypeID, reminder[0].Rappelnummer, false, documenttypes);

      const sysDoc = documentsData.find((d) => d.Filename.includes(reminder[0].Rappelnummer));

      let sharepointDocs = [
        { sharepointDocName: sysDoc.Filename, sharepointPath: sharepointUploadPath },
        ...reminderInvoices,
        { sharepointDocName: GENERAL_CONDITIONS_DOC_NAMES[languageMap[invoice.TaalID]], sharepointPath: null }
      ];
      // console.log('sharepointDocs', sharepointDocs);

      const payload = {
        emailSubject: `${mailSubject} ${reminder[0].Rappelnummer}`,
        emailBody: mailContent,
        replyTo: user.email,
        toAddress: mailTo.email,
        cc: [user.email, ...mailCcs.map((cc) => cc.email)],
        bcc: mailBccs.map((bcc) => bcc.email),
        docTypeID: docTypeID,
        docNumber: reminder[0].Rappelnummer,
        sharepointDocs,
        resource: 'REMINDER',
        resourceId: reminder[0].rappelnr
      };

      const response = await simplePostRequest('/api/email/systemdoc', payload);
      // console.log('response', response);
      const data = await response.json();

      if (data.error) {
        const { error } = data;
        if (error === 'attachements_too_big') {
          setToast({ ...toast, attachements: true });
          setSendMailButtonLoading(false);
        } else {
          setToast({ ...toast, error: true });
          setSendMailButtonLoading(false);
        }
      } else {
        await setVerstuurd('payment_reminders/mark_as_sent', reminder[0].rappelnr, docTypeID);
        //analytics
        performTracking('email_systemdoc', payload);
        setToast({ ...toast, ok: true, sent: true });
        fetchInvoices();
        setSendMailButtonLoading(false);
        setModalActive(false);
      }
    }
    setSendMailButtonLoading(false);
  };

  return (
    dataLoaded && (
      <Modal
        large
        open={modalActive}
        onClose={() => setModalActive(false)}
        title="Email configuration"
        primaryAction={{
          content: 'Create and send payment reminder',
          disabled: !mailTo,
          loading: sendMailButtonLoading,
          onAction: handleReminderMail
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setModalActive(false);
              setSendMailButtonLoading(false);
            }
          }
        ]}
      >
        <Modal.Section>
          <div style={{ zIndex: 10500, position: 'relative' }}>
            <Banner status="info">The email will be automatically sent to you in CC, you do not need to add yourself as CC. The system document will be automatically send as attachment.</Banner>
            <Box paddingBlockStart={'300'}>
              <Form onSubmit={handleReminderMail}>
                <FormLayout>
                  <div>
                    <Box paddingBlockEnd={'100'}>Send email to:</Box>
                    <CreatableSelect
                      isClearable
                      onChange={(props, actionMeta) => {
                        if (actionMeta.action === 'create-option') {
                          setMailTo({ ...props, email: props.value });
                        } else {
                          setMailTo({ ...props });
                        }
                      }}
                      options={contacts
                        .filter((con) => !con.DISCONTINUED && con['E-mail'] !== null)
                        .sort((a, b) => alphabetize(a, b, 'NAAM'))
                        .map((con) => {
                          return { value: con.CONNR, label: `${con.NAAM} (${con['E-mail']})`, TaalID: con.TaalID, email: con['E-mail'] };
                        })}
                      menuPortalTarget={document.body}
                      styles={selectStyles}
                    />
                  </div>
                  <FormLayout.Group>
                    <div>
                      <Box paddingBlockEnd={'100'}>CC</Box>
                      <CreatableSelect
                        options={ccOptions}
                        onChange={(props) => setMailCcs([...props])}
                        isMulti
                        onCreateOption={(value) => setMailCcs([...mailCcs, { label: value, email: value }])}
                        value={mailCcs}
                        menuPortalTarget={document.body}
                        styles={selectStyles}
                      />
                    </div>
                    <div>
                      <Box paddingBlockEnd={'100'}>BCC</Box>
                      <CreatableSelect
                        options={ccOptions}
                        onChange={(props) => setMailBccs([...props])}
                        isMulti
                        onCreateOption={(value) => setMailBccs([...mailBccs, { label: value, email: value }])}
                        value={mailBccs}
                        menuPortalTarget={document.body}
                        styles={selectStyles}
                      />
                    </div>
                  </FormLayout.Group>
                  <div>
                    <Box paddingBlockEnd={'100'}>Language</Box>
                    <PSelect
                      options={languages
                        .sort((a, b) => alphabetize(a, b, 'Omschrijving'))
                        .map((lan) => {
                          return { value: lan.TaalID, label: lan.Omschrijving };
                        })}
                      value={parseInt(language)}
                      onChange={(value) => {
                        setLanguage(value);
                      }}
                    />
                  </div>

                  <TextField value={mailSubject} onChange={(props) => setMailSubject(props)} label="Mail Subject" style={{ zIndex: 1 }} />
                  <TextField label="Mail content" value={mailContent} onChange={(val) => setMailContent(val)} multiline={10} autoComplete="off" />
                </FormLayout>
              </Form>
            </Box>
          </div>
          <br />
          {openInvoices.length > 0 ? (
            <Banner status="warning">
              <Text fontWeight="semibold">The following open invoices will be attached to the email:</Text>
              {openInvoices.map((i, idx) => (
                <Text key={idx}>
                  <Link removeUnderline url={`/invoices/${i.faktuurnr}`}>
                    {i.Factuurnummer}
                  </Link>
                  {` - ${formatToCurrency(i.bedragopen)}`}
                </Text>
              ))}
            </Banner>
          ) : null}
        </Modal.Section>
      </Modal>
    )
  );
};
