import { InlineStack } from '@shopify/polaris';
import { useLookupStore } from '../../context/useStore';

export const CountryWithFlag = ({ countryId }) => {
  const countries = useLookupStore((state) => state.countries);
  const iso = countries.find((c) => c.LandId === countryId)?.ISO2;

  return (
    <InlineStack blockAlign="center" gap="100" wrap={false}>
      <img
        src={`https://flagcdn.com/w20/${String(iso).toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${String(iso).toLowerCase()}.png 2x,https://flagcdn.com/w60/${String(iso).toLowerCase()}.png 3x`}
        width="18"
        height="13"
        alt={`${iso.toLowerCase()}`}
      />
      {countries.find((country) => parseInt(country.LandId) === parseInt(countryId))?.Land || ''}
    </InlineStack>
  );
};
