import { Layout, Page } from '@shopify/polaris';
import HolidaysComponent from './components/holidays';
import HolidayRequestsComponent from './components/holidayrequests';
import userRoles from '../../utils/userRoles';
import { useAuthStore } from '../../context/useStore';

export default function HolidaysPage() {
  const { user } = useAuthStore();

  return (
    <Page title="Holidays administration" fullWidth>
      <Layout>
        <Layout.Section>{user?.role === userRoles.AdminFull ? <HolidayRequestsComponent /> : null}</Layout.Section>
        <Layout.Section variant={user?.role === userRoles.AdminFull ? 'oneHalf' : 'fullWidth'}>
          <HolidaysComponent />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
