import { useState, useCallback } from 'react';
import { Popover, Button, Card } from '@shopify/polaris';
export function TimeSlotRemarksPopover({ remark }) {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const activator = (
    <div style={{ margin: '-12px' }}>
      <Button size="slim" onClick={togglePopoverActive} disclosure>
        Remark
      </Button>
    </div>
  );

  return (
    <Popover active={popoverActive} activator={activator} autofocusTarget="first-node" onClose={togglePopoverActive}>
      <Card>{remark}</Card>
    </Popover>
  );
}
