import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, Badge, Tabs } from '@shopify/polaris';
import InvoicesTable from './components/invoicesTable.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import TotalsModal from './components/totalsModal.jsx';
import { getRequest } from '../../shared/components/functions.jsx';

export default function InvoicesPage() {
  let url = useUrl();
  let navigate = useNavigate();

  const [selected, setSelected] = useState(url.get('tab') ? parseInt(url.get('tab')) : 0);
  const [totals, setTotals] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate(`${window.location.pathname}?tab=${selectedTabIndex}`, { replace: true });
    },
    [navigate]
  );

  const fetchTotals = async () => {
    const data = await getRequest(`/api/invoices/type/overview/all`);
    setTotals(data);
    setDataLoaded(true);
  };

  useEffect(() => {
    async function fetchData() {
      fetchTotals();
    }
    fetchData();
  }, [selected, navigate]);

  const TabBadge = ({ total }) => {
    let tBatch = total && dataLoaded ? <Badge tone="new">{total?.amount}</Badge> : <Badge tone="new">0</Badge>;
    return tBatch;
  };

  const tabs = [
    {
      id: 'all-invoices',
      content: 'All',
      accessibilityLabel: 'All Invoices',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={null} />
    },
    {
      id: 'all-invoices-unpaid',
      content: (
        <span>
          Unpaid <TabBadge total={totals.filter((total) => total.status === 0)[0]} />
        </span>
      ),
      accessibilityLabel: 'All unpaid invoices',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={0} />
    },
    {
      id: 'all-invoices-vervallen',
      content: (
        <span>
          Expired <TabBadge total={totals.filter((total) => total.status === 100)[0]} />
        </span>
      ),
      accessibilityLabel: 'All invoices vervallen',
      panelID: 'all-invoices-vervallen',
      comp: <InvoicesTable factuurstatusID={'vervallen'} />
    },
    {
      id: 'all-invoices-1e-aanmaling',
      content: (
        <span>
          1st reminder <TabBadge total={totals.filter((total) => total.status === 1)[0]} />
        </span>
      ),
      accessibilityLabel: 'All 1e aanmaning',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={1} />
    },
    {
      id: 'all-invoices-2e-aanmaling',
      content: (
        <span>
          2nd reminder <TabBadge total={totals.filter((total) => total.status === 2)[0]} />
        </span>
      ),
      accessibilityLabel: 'All 2e aanmaning',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={2} />
    },
    {
      id: 'all-invoices-aangetekende-zending',
      content: (
        <span>
          Registered mail <TabBadge total={totals.filter((total) => total.status === 3)[0]} />
        </span>
      ),
      accessibilityLabel: 'All aangetekende zending',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={3} />
    },
    {
      id: 'all-invoices-aangetekende-betwisting',
      content: (
        <span>
          Dispute <TabBadge total={totals.filter((total) => total.status === 7)[0]} />
        </span>
      ),
      accessibilityLabel: 'Betwisting',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={7} />
    },
    {
      id: 'all-invoices-aangetekende-nietincasseerbaar',
      content: (
        <span>
          Not collectible <TabBadge total={totals.filter((total) => total.status === 5)[0]} />
        </span>
      ),
      accessibilityLabel: 'Niet incasseerbaar',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={5} />
    },
    {
      id: 'all-invoices-aangetekende-faling',
      content: (
        <span>
          Failure <TabBadge total={totals.filter((total) => total.status === 8)[0]} />
        </span>
      ),
      accessibilityLabel: 'Faling',
      panelID: 'all-invoices-faling',
      comp: <InvoicesTable factuurstatusID={8} />
    },
    {
      id: 'all-invoices-afbetalingsplan',
      content: (
        <span>
          Repayment Plan <TabBadge total={totals.filter((total) => total.status === 4)[0]} />
        </span>
      ),
      accessibilityLabel: 'Afbetalingsplan',
      panelID: 'all-invoices-content',
      comp: <InvoicesTable factuurstatusID={4} />
    }
  ];

  return (
    <Page title="Invoices" secondaryActions={[{ content: 'Totals', onAction: () => setModalOpen(true) }]} fullWidth>
      <TotalsModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].comp}
        </Tabs>
      </Card>
    </Page>
  );
}
