import { useEffect, useState } from 'react';
import { DataTable, Link } from '@shopify/polaris';
import { getRequest } from '../../../shared/components/functions';

export default function WorkReceiptsCompany({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/montage_bons?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };
    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={'/montage_bons/' + item.Montagebriefnummer}>
            {item.Montagebonnummer}
          </Link>,
          String(item.datum).substring(0, 10),
          item.Datum1 ? String(item.Datum1).substring(0, 10) : '',
          item.Merk,
          item.Type,
          item.Serie,
          item.Monteur,
          String(item.Opmerkingen).substring(0, 50),
          item.Verstuurd ? 'Yes' : 'No'
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
      headings={[<b>{'Number'}</b>, <b>{'Date'}</b>, <b>{'Tussenkomst'}</b>, <b>{'Brand'}</b>, <b>{'Type'}</b>, <b>{'Serie'}</b>, <b>{'Technician'}</b>, <b>{'Notes'}</b>, <b>{'PDF Mailed'}</b>]}
      rows={rows}
    />
  ) : (
    'No work receipts'
  );
}
