import React from 'react';
import { Helmet } from 'react-helmet';
import { capitalizeFirstLetter } from '../shared/components/functions';
import { logo } from './theme';
import { useAuthStore } from '../context/useStore';

export function getPageTitle(number, title) {
  return number ? `${title} #${number}` : `New ${title.toLowerCase()}`;
}

export const TabTitle = ({ title }) => {
  const { user } = useAuthStore();

  return (
    <Helmet>
      <link rel="icon" href={logo[user?.ORGANISATION_NAME]?.ico} />
      <title>{`Admin ${title || capitalizeFirstLetter(window.location.href.split('?')[0].split('/')[3])} `}</title>
    </Helmet>
  );
};
