import { Card, Link, Button, DropZone, Spinner, Text, ButtonGroup, Box } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { performTracking } from '../../../analytics/segment';
import { getRequest } from '../functions';
import { useAuthStore, useLookupStore } from '../../../context/useStore';
import { ReminderCardSection } from './ReminderCardSection';
import { CardHeading } from './CardHeading';
import PdfViewer from '../pdf/pdfViewer';
import DocumentCardModal from './DocumentCardModal';
import DetailFileBrowser from '../files/DetailFileBrowser';
import getSharePointUrlForFirnrDocumentTypeIdDocName, { getSharePointPath } from '../../../utils/sharepoint';

export default function DocumentCard({
  resource,
  id,
  title,
  docNumber,
  isDirty,
  linesDirty,
  generateDocument,
  docTypeID,
  docType,
  company,
  setResource,
  docLang,
  documentButtonLoading,
  setDocumentButtonLoading,
  invoice,
  setInvoice,
  setIsDirty,
  setHasSysDoc
}) {
  const [sysDoc, setSysDoc] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [sysDocUploading, setSysDocUploading] = useState(false);
  const [documentGenerated, setDocumentGenerated] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [sysDocLoaded, setSysDocLoaded] = useState(false);
  const [pdfLinkLoaded, setPdfLinkLoaded] = useState(false);
  const { documenttypes } = useLookupStore();
  const user = useAuthStore((state) => state.user);

  const fetchDocuments = async () => {
    /*
      //FIXME:
      First line under this can be removed when ms access is not used anymore
      Access is not marking incomig invoices and credit notes as system documents
      So this is a hack to get them to show up in the document browser when still created in access
      We mark them as system documents when created through this app
    */
    const sysDoc = docTypeID === 8 || docTypeID === 19 || docTypeID === 20 ? '0' : '1';
    const data = await getRequest(`/api/documents/id/typeid?id=${id}&typeID=${docTypeID}&sysDoc=${sysDoc}`);

    //This is built on the assumption that there is only one sysDoc
    //available per resource, this is coverd by the application logic
    if (data && data.length > 0) {
      setSysDoc(data[0]);
      if (docTypeID === 19 || docTypeID === 20) setHasSysDoc(true);
    }

    //also fetch the other documents (for later reference in email sending)
    let data2 = await getRequest(`/api/documents/id/typeid?id=${id}&typeID=${docTypeID}`);

    //fetch closest available linked documents (e.g. sale for invoice)
    const linkedDocuments = await getRequest(`/api/documents/id/linked?id=${id}&typeID=${docTypeID}`);

    setDocuments(data2.concat(linkedDocuments));

    //if the system doc is an invoice then the reminders shoudl be loaded
    // const data3 = await getRequest(`/api/documents/id/typeid?id=${id}&typeID=${15}`);
    // console.log(id, data3)
    // setDocuments(data3);
    setSysDocLoaded(true);
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setSysDoc(null);
        return setSysDocLoaded(true);
      }
      await fetchDocuments();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, documentButtonLoading]);

  useEffect(() => {
    if (sysDoc) {
      window.open(getSharePointUrlForFirnrDocumentTypeIdDocName(company.Klantnummer, docTypeID, docNumber, sysDoc?.Filename, documenttypes), '_blank');
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentGenerated]);

  async function streamSharePointDoc() {
    // Klantnummer, DocumentTypeID, fileNumber, documentTypes
    ///company.Klantnummer, docTypeID, docNumber, sysDoc.Filename, documenttypes
    // console.log(company.Klantnummer, docTypeID, docNumber, documenttypes);
    const sharepointPath = getSharePointPath(company.Klantnummer, docTypeID, docNumber, documenttypes);

    const response = await fetch('/api/docgen/streamsharepointdoc/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ path: sharepointPath, fileName: sysDoc.Filename })
    });
    const blob = await response.blob();

    if (blob.type === 'application/pdf') {
      const url = window.URL.createObjectURL(blob);
      // console.log('the pdf link is: ', url);
      setPdfLink(url);
    } else {
      console.error('The received file is not a PDF.');
    }
  }

  useEffect(() => {
    async function fetchPdfLink() {
      await streamSharePointDoc();
      setPdfLinkLoaded(true);
    }
    if (id === 'new' || generateDocument || !sysDoc || !company.Klantnummer || !docTypeID || !docNumber || !documenttypes) return;
    fetchPdfLink();
  }, [company.Klantnummer, docTypeID, docNumber, documenttypes, sysDoc, generateDocument, id]);

  const handleDocumentGenerationButtonClick = async () => {
    if (!company.LandID) {
      alert('Please add a country for this customer before you continue.');
    } else {
      setDocumentButtonLoading(true);
      //post request to api docgen
      await generateDocument();
      await fetchDocuments();
      performTracking('sysdoc_generate', { id, title, docNumber });
      setDocumentButtonLoading(false);
    }
  };

  const documentGeneratorMarkup = () => {
    return (
      <Card>
        <CardHeading title={title} />
        {openModal ? (
          <DocumentCardModal
            resource={resource}
            resourceId={id}
            modalActive={openModal}
            setModalActive={setOpenModal}
            company={company}
            docTypeID={docTypeID}
            docNumber={docNumber}
            sysDoc={sysDoc}
            documents={documents.length > 0 ? documents.filter((document) => document?.DocumentTypeID !== docTypeID || (document?.DocumentTypeID === docTypeID && !document?.Systeem)) : []}
            setResource={setResource}
            docLang={docLang}
          />
        ) : null}
        {sysDoc && company.Klantnummer ? (
          <>
            <Link target="_blank" removeUnderline url={getSharePointUrlForFirnrDocumentTypeIdDocName(company.Klantnummer, docTypeID, docNumber, sysDoc.Filename, documenttypes, user)}>
              {sysDoc.Filename}
            </Link>
            <Box paddingBlockStart="200" paddingBlockEnd="400">
              <ButtonGroup>
                <Button disabled={isDirty || linesDirty} onClick={() => setOpenModal(true)}>
                  Mail this document
                </Button>
                <Button disabled={documentButtonLoading || isDirty || linesDirty} loading={documentButtonLoading} onClick={handleDocumentGenerationButtonClick}>
                  Regenerate document
                </Button>
              </ButtonGroup>
            </Box>

            {docTypeID === 1 && !documentButtonLoading ? <ReminderCardSection disabled={documentButtonLoading || isDirty || linesDirty} invoice={invoice} company={company} /> : null}

            <DetailFileBrowser
              docTypeId={docTypeID}
              resourceId={id}
              Klantnummer={company.Klantnummer}
              docNumber={docNumber}
              FIRNR={company.FIRNR}
              docType={docType}
              heightPxs="180px"
              parentRefetchDocuments={fetchDocuments}
            />
          </>
        ) : null}
        {!sysDoc && id !== 'new' ? (
          <Button id="pdf" disabled={isDirty || linesDirty} loading={documentButtonLoading} onClick={handleDocumentGenerationButtonClick}>
            Generate pdf document
          </Button>
        ) : id === 'new' ? (
          <Text>First add the document content before generating the pdf.</Text>
        ) : null}
      </Card>
    );
  };

  const documentFinderMarkup = () => {
    if (id === 'new') {
      return (
        <Card>
          <Text>Upload will be available after saving.</Text>
        </Card>
      );
    }

    if (!sysDocLoaded || sysDocUploading) {
      return (
        <Card>
          <Spinner size="small" />
        </Card>
      );
    }

    if (!sysDoc) {
      return (
        <Card>
          <UploadSysDocComponent
            overwrite={false}
            company={company}
            docType={docType}
            docNumber={docNumber}
            id={id}
            docTypeID={docTypeID}
            sysDocUploading={sysDocUploading}
            setSysDocUploading={setSysDocUploading}
            fetchDocuments={fetchDocuments}
          />
        </Card>
      );
    }

    return (
      sysDoc &&
      company.Klantnummer && (
        <Card>
          <CardHeading
            title={
              <Link target="_blank" removeUnderline url={getSharePointUrlForFirnrDocumentTypeIdDocName(company.Klantnummer, docTypeID, docNumber, sysDoc.Filename, documenttypes)}>
                {sysDoc.Filename}
              </Link>
            }
            actions={
              invoice && invoice.processed && invoice.S3ObjectKeys && JSON.parse(invoice.S3ObjectKeys).length > 1
                ? [
                    {
                      content: 'Select different document',
                      onAction: () => {
                        setIsDirty(true);
                        setInvoice({ ...invoice, processed: false });
                      }
                    }
                  ]
                : null
            }
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {pdfLinkLoaded ? <PdfViewer pdfLink={pdfLink} /> : <Spinner size="small" />}
            <UploadSysDocComponent
              overwrite={sysDoc}
              company={company}
              docType={docType}
              docNumber={docNumber}
              id={id}
              docTypeID={docTypeID}
              sysDocUploading={sysDocUploading}
              setSysDocUploading={setSysDocUploading}
              fetchDocuments={fetchDocuments}
            />
          </div>
        </Card>
      )
    );
  };

  return generateDocument ? documentGeneratorMarkup() : documentFinderMarkup();
}

const UploadSysDocComponent = ({ overwrite, company, docType, docNumber, id, docTypeID, sysDocUploading, setSysDocUploading, fetchDocuments }) => {
  /*
    This will upload the document as a system document.
    There can only be one system document per resource! 
  */

  const handleDropZoneDrop = async (file) => {
    setSysDocUploading(true);

    var formData = new FormData();
    formData.append('file', file[0]);
    formData.append('customerNumber', company.Klantnummer);
    formData.append('docType', docType);
    formData.append('docNumber', docNumber);
    formData.append('FIRNR', company.FIRNR);
    formData.append('resourceId', id);
    formData.append('docTypeId', docTypeID);
    formData.append('isSystemDoc', '1');

    await fetch(`/api/docgen/uploadfile`, { method: 'POST', body: formData });
    await fetchDocuments();
    setSysDocUploading(false);
  };

  return (
    <DropZone allowMultiple={false} onDrop={handleDropZoneDrop} disabled={sysDocUploading}>
      <DropZone.FileUpload actionHint={overwrite ? 'Existing file will be overwritten.' : ''} />
    </DropZone>
  );
};
