import { doc_spec, languageMap } from '../../utils/sharepoint';
import { getRequest } from './functions';

export const getCompanyName = (companyName) => companyName.replace('&', '%26');
const encode = (string) => (string ? encodeURIComponent(string) : '');

export const DeliveryToInvoice = async (delivery, lines, company, lineInterface, setIsTransforming, navigate) => {
  setIsTransforming(true);

  for (var i = 0; i < lines.length; i++) {
    let sellingPrice = 0;
    const line = lines[i];
    if (line.produktnr) {
      const productData = await getRequest(`/api/products/${line.produktnr}`);
      sellingPrice = productData['Eenheids verkoopprijs'];
    }
    lines[i].VP = sellingPrice || 0;
  }

  const newLines = [
    {
      ...lineInterface,
      Lijnnr: null,
      aantal: null,
      VP: null,
      BTW: 0,
      faktuurnr: 'new',
      produktnr: 1,
      tekstlijn: `${doc_spec['delivery_note'].lan[languageMap[delivery.TaalID]] || 'Delivery note'} N° ${delivery.LeveringsbonNummer} dd ${String(delivery.datum).substring(0, 10)}`
    },
    ...lines.map((line) => ({
      ...line,
      Lijnnr: null,
      faktuurnr: 'new',
      aantal: line.aantalgeleverd || 0,
      BTW: parseInt(company.LandID) === 300 ? 21 : 0
    }))
  ];

  let specificUrl = '';
  let confirmed = true;
  const queryUrl = `&btwnummer=${company.BTW}&TaalID=${company.TaalID}&betalingID=${company.betalingID}&isDirty=true&linesDirty=true&refklant=${encode(delivery.refklant)}`;
  if (company.FirNrFactuur) {
    //get seperate invoicing company from api
    const invoicingCompany = await getRequest(`/api/companies/${company.FirNrFactuur}`);
    let invoicingCompanyName = invoicingCompany.NAAM.replace('&', '%26');
    if (window.confirm(`ATTENTION: This company has a separate invoicing address, this will create an invoice on ${invoicingCompanyName}`)) {
      specificUrl = `?klantnummer=${invoicingCompany.FIRNR}&klantnaam=${invoicingCompanyName}&adres=${invoicingCompany.STRAAT_NR}&postkode=${invoicingCompany.POSTNR}&woonplaats=${invoicingCompany.WOONPL}&LandID=${invoicingCompany.LandID}`;
    } else {
      confirmed = false;
    }
  } else {
    let companyName = company.NAAM.replace('&', '%26');
    specificUrl = `?klantnummer=${company.FIRNR}&klantnaam=${companyName}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}&LandID=${company.LandID}`;
  }

  if (!confirmed) return;

  navigate({ pathname: `/invoices/new`, search: `${specificUrl}${queryUrl}` }, { state: newLines });

  setIsTransforming(false);
};

export const InquiryToPurchase = async (inquiry, lines, company, lineInterface, navigate) => {
  const machineLine =
    inquiry.MachineType || inquiry.MachineNummer
      ? {
          ...lineInterface,
          Lijnnr: null,
          aantal: null,
          VP: null,
          faktuurnr: 'new',
          produktnr: 1,
          tekstlijn: `${inquiry.MachineType && `Machine Type: ${inquiry.MachineType}`} ${inquiry.MachineNummer && `N° ${inquiry.MachineNummer}`} `
        }
      : null;

  const newLines = [
    {
      ...lineInterface,
      BestellingLijnID: null,
      aantal: null,
      VP: null,
      BestellingID: 'new',
      produktnr: 1,
      tekstlijn: `${doc_spec['price_request'].lan[languageMap[inquiry.TaalID]]} N° ${inquiry.Prijsaanvraagnummer} (${String(inquiry.Datum).substring(0, 10)})`
    },
    machineLine,
    ...lines.map((line) => ({ ...line, BestellingLijnID: null, BestellingID: 'new' }))
  ];

  navigate(
    {
      pathname: `/purchases/new`,
      search: `?LeverancierID=${company.FIRNR}&TaalID=${company.TaalID}&LandID=${company.LandID}&KlantID=${inquiry.KlantID}&isDirty=true&linesDirty=true${
        inquiry.ProjectID ? `&ProjectID=${inquiry.ProjectID}` : ''
      }&RefKlant=${encode(inquiry.RefKlant)}&RefKlantDatum=${inquiry.RefKlantDatum || ''}&MachineType=${inquiry.MachineType || ''}&MachineNummer=${inquiry.MachineNummer || ''}`
    },
    { state: newLines.filter((line) => line) }
  );
};

export const InvoiceToCreditnote = async (invoice, lines, company, lineInterface, navigate) => {
  /*
          if invoicing address is different, then invoice on correct address
          should also expose a lot of variables that are not exposed by the frontend
          such as the address, zip code, city, country, etc.
          This are fields that are copied from the company info. 
        */

  //tranform the lines object to adhere to the invoice lines object
  const newLines = [
    {
      ...lineInterface,
      CreditnotaLijnID: null,
      aantal: null,
      VP: null,
      CreditnotaID: 'new',
      produktnr: 1,
      tekstlijn: `${doc_spec['invoice'].lan[languageMap[invoice.TaalID]]} N° ${invoice.Factuurnummer} (${String(invoice.datum).substring(0, 10)})`
    },
    ...lines.map((line) => ({ ...line, CreditnotaLijnID: null, CreditnotaID: 'new' }))
  ];

  const queryUrl = `&FIRNR=${company.FIRNR}&FactuurID=${invoice.faktuurnr}&TaalID=${company.TaalID}&FIRNR=${company.FIRNR}&isDirty=true&linesDirty=true&BedragOpen=${
    invoice.totaalbedrag
  }&BedragTotaal=${invoice.totaalbedrag}&Referentie=${encode(invoice.refklant)}`;

  navigate({ pathname: `/creditnotes/new`, search: `${queryUrl}` }, { state: newLines });
};

export const SaleToInvoice = async (sale, lines, company, lineInterface, navigate) => {
  /*
            if invoicing address is different, then invoice on correct address
            should also expose a lot of variables that are not exposed by the frontend
            such as the address, zip code, city, country, etc.
            This are fields that are copied from the company info. 
          */

  //tranform the lines object to adhere to the invoice lines object
  const machineLine =
    sale.MachineType || sale.MachineNummer
      ? {
          ...lineInterface,
          Lijnnr: null,
          aantal: null,
          VP: null,
          faktuurnr: 'new',
          produktnr: 1,
          tekstlijn: `${sale.MachineType && `Machine Type: ${sale.MachineType}`} ${sale.MachineNummer && `N° ${sale.MachineNummer}`} `
        }
      : null;

  const newLines = [
    {
      ...lineInterface,
      Lijnnr: null,
      aantal: null,
      VP: null,
      faktuurnr: 'new',
      produktnr: 1,
      tekstlijn: `${doc_spec['order_confirmation'].lan[languageMap[sale.TaalID]]} N° ${sale.Orderbevestigingnummer} (${String(sale.Datum).substring(0, 10)})`
    },
    machineLine,
    ...lines.map((line) => ({ ...line, Lijnnr: null, faktuurnr: 'new' }))
  ];

  let specificUrl = '';
  let confirmed = true;
  const queryUrl = `&TaalID=${company.TaalID}&betalingID=${company.betalingID}&isDirty=true&linesDirty=true&OrderbevestigingID=${sale.OrderbevestigingID}${
    sale.ProjectID ? `&ProjectID=${sale.ProjectID}` : '' + sale.RefKlant === null || !sale.RefKlant ? '' : `&refklant=${encode(sale.RefKlant)}`
  }`;
  if (!company.FirNrFactuur && !company.BTW) {
    if (window.confirm(`ATTENTION: This customer has no VAT number. Do you want to continue?`)) {
      let companyName = company.NAAM.replace('&', '%26');
      specificUrl = `?klantnummer=${company.FIRNR}&klantnaam=${companyName}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}&LandID=${company.LandID}`;
    }
  }
  if (company.FirNrFactuur) {
    //get seperate invoicing company from api
    const invoicingCompany = await getRequest(`/api/companies/${company.FirNrFactuur}`);
    let invoicingCompanyName = invoicingCompany.NAAM.replace('&', '%26');
    if (window.confirm(`ATTENTION: This company has a separate invoicing address, this will create an invoice on ${invoicingCompanyName}`)) {
      specificUrl = `?klantnummer=${invoicingCompany.FIRNR}&klantnaam=${invoicingCompanyName}&adres=${invoicingCompany.STRAAT_NR}&postkode=${invoicingCompany.POSTNR}&woonplaats=${invoicingCompany.WOONPL}&LandID=${invoicingCompany.LandID}&btwnummer=${invoicingCompany.BTW}`;
    } else {
      confirmed = false;
    }
    if (!invoicingCompany.BTW) {
      if (window.confirm(`The company to be invoiced (${invoicingCompanyName}) has no VAT number. Do you want to continue?`)) {
        specificUrl = `?klantnummer=${invoicingCompany.FIRNR}&klantnaam=${invoicingCompanyName}&adres=${invoicingCompany.STRAAT_NR}&postkode=${invoicingCompany.POSTNR}&woonplaats=${invoicingCompany.WOONPL}&LandID=${invoicingCompany.LandID}`;
      }
    }
  } else {
    let companyName = company.NAAM.replace('&', '%26');
    specificUrl = `?klantnummer=${company.FIRNR}&klantnaam=${companyName}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}&LandID=${company.LandID}&btwnummer=${company.BTW}`;
  }

  if (!confirmed) return;

  navigate({ pathname: `/invoices/new`, search: `${specificUrl}${queryUrl}` }, { state: newLines.filter((line) => line) });
};

export const SaleToDelivery = async (sale, lines, company, lineInterface, navigate) => {
  //tranform the lines object to adhere to the delivery lines object
  //FIXME: see how this is handled in access with the amount to deliver and the amount ordered
  //this needs some extra logic
  const machineLine =
    sale.MachineType || sale.MachineNummer
      ? {
          ...lineInterface,
          Lijnnr: null,
          aantal: null,
          VP: null,
          faktuurnr: 'new',
          produktnr: 1,
          tekstlijn: `${sale.MachineType && `Machine Type:  ${sale.MachineType}`} ${sale.MachineNummer && `N° ${sale.MachineNummer}`} `
        }
      : null;

  const newLines = [
    {
      ...lineInterface,
      Lijnnr: null,
      aantal: null,
      VP: null,
      faktuurnr: 'new',
      produktnr: 1,
      tekstlijn: `${doc_spec['order_confirmation'].lan[languageMap[sale.TaalID]]} N° ${sale.Orderbevestigingnummer} (${String(sale.Datum).substring(0, 10)})`
    },
    machineLine,
    ...lines.map((line) => ({
      ...line,
      Lijnnr: null,
      bonnr: 'new',
      aantalbesteld: line.aantal,
      aantalgeleverd: line.aantal,
      plaats: null,
      signedoff: false,
      opbon: false,
      garantie: false
    }))
  ];

  let companyName = '';
  if (sale.LeveringKlantID) {
    //this sales order has a different delivery address
    const deliveryAddress = await getRequest(`/api/companies/${sale.LeveringKlantID}`);
    companyName = deliveryAddress.NAAM.replace('&', '%26');
    company = deliveryAddress;
  } else {
    companyName = company.NAAM.replace('&', '%26');
  }

  navigate(
    {
      pathname: `/delivery_notes/new`,
      search: `?klantnummer=${company.FIRNR}&klantnaam=${companyName}&TaalID=${company.TaalID}&LandID=${company.LandID}&adres=${company.STRAAT_NR || ''}&postkode=${company.POSTNR}&woonplaats=${
        company.WOONPL
      }&isDirty=true&linesDirty=true&OrderbevestigingID=${sale.OrderbevestigingID}&refklant=${encode(sale.RefKlant)}&refklantdatum=${sale.RefKlantDatum || ''}`
    },
    { state: newLines.filter((line) => line) }
  );
};

export const SaleToQuotation = async (lines, navigate) => {
  if (!window.confirm('💥 Do you want to create a quotation from this sale?')) return;

  const newLines = [...lines.filter((l) => l.aantal && l.VP).map((l) => ({ ...l, lineIsDirty: true, Lijnnr: null, offertenr: null }))];

  navigate({ pathname: `/quotations/new`, search: `?fromSale=true&isDirty=true&isLinesDirty=true` }, { state: newLines.filter((line) => line) });
};

export const QuotationToSale = async (quotation, lines, company, lineInterface, navigate) => {
  const machineLine =
    quotation.machinetype || quotation.machinenunner
      ? {
          ...lineInterface,
          OrderbevestigingLijnID: null,
          aantal: null,
          VP: null,
          OrderbevestigingID: 'new',
          produktnr: 1,
          tekstlijn: `${quotation.machinetype && `Machine Type:  ${quotation.machinetype}`} ${quotation.machinenunner && `N° ${quotation.machinenunner}`} `
        }
      : null;

  const newLines = [
    {
      ...lineInterface,
      OrderbevestigingLijnID: null,
      aantal: null,
      VP: null,
      OrderbevestigingID: 'new',
      produktnr: 1,
      tekstlijn: `${doc_spec['quotation'].lan[languageMap[quotation.TaalID]]} N° ${quotation.Offertenummer} (${String(quotation.datum).substring(0, 10)})`
    },
    machineLine,
    ...lines.map((line) => ({ ...line, OrderbevestigingLijnID: null, OrderbevestigingID: 'new' }))
  ];

  navigate(
    {
      pathname: `/sales/new`,
      search: `?TaalID=${company.TaalID}${quotation.LeverancierID ? `&LeverancierID=${quotation.LeverancierID}` : ''}&LandID=${company.LandID}&KlantID=${
        company.FIRNR
      }&isDirty=true&linesDirty=true&refklant=${encode(quotation.refklant)}&MachineType=${encode(quotation.machinetype)}&MachineNummer=${encode(quotation.machinenunner)}&RefKlant=${encode(
        quotation.refklant
      )}&RefKlantDatum=${quotation.refklantdatum || ''}&OfferteID=${quotation.offertenr || ''}`
    },
    { state: newLines.filter((line) => line) }
  );
};

export const QuotationDuplicate = async (quotation, lines, navigate) => {
  if (!window.confirm('💥 Do you want to duplicate this quotation?')) return;

  const newLines = [...lines.map((line) => ({ ...line, Lijnnr: null, offertenr: 'new' }))];

  navigate(
    {
      pathname: `/quotations/new`,
      search: `?duplicate=true&LandID=${quotation.LandID || ''}&klantnummer=${quotation.klantnummer}&refklant=${encode(quotation.refklant)}&TaalID=${quotation.TaalID}&refklantdatum=${
        quotation.refklantdatum || ''
      }&machinetype=${encode(quotation.machinetype)}&machinenunner=${encode(quotation.machinenunner)}&leveringstermijn=${encode(quotation.leveringstermijn)}&Angebot=${encode(
        quotation.Angebot
      )}&isDirty=true&linesDirty=true&ProjectID=${quotation.ProjectID || ''}&LeverancierID=${quotation.LeverancierID || ''}`
    },
    { state: newLines.filter((line) => line) }
  );
};

export const SaleDuplicate = async (sale, lines, navigate) => {
  if (!window.confirm('💥 Do you want to duplicate this sale?')) return;

  const newLines = [...lines.filter((l) => l.aantal && l.VP).map((l) => ({ ...l, lineIsDirty: true, Lijnnr: null, OrderbevestigingLijnID: null, OrderbevestigingID: 'new' }))];

  navigate(
    {
      pathname: `/sales/new`,
      search: `?duplicate=true&Aanbod=${encode(sale.Aanbod)}&TaalID=${sale.TaalID}&KlantID=${sale.KlantID}&Leveringstermijn=${encode(sale.Leveringstermijn)}&MachineNummer=${encode(
        sale.MachineNummer
      )}&Notities=${encode(sale.Notities)}&MachineType=${encode(sale.MachineType)}&RefKlant=${encode(sale.RefKlant)}&RefKlantDatum=${
        sale.RefKlantDatum || ''
      }&isDirty=true&linesDirty=true&LeverancierID=${sale.LeverancierID || ''}&ProjectID=${sale.ProjectID || ''}&FacturatieKlantID=${sale.FacturatieKlantID || ''}&KlantContactID=${
        sale.KlantContactID || ''
      }&LeveringKlantID=${sale.LeveringKlantID || ''}`
    },
    { state: newLines.filter((line) => line) }
  );
};
