import { InlineStack, Link } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from './functions';

export default function CompanySelect({ value, id, onChange, label, isDisabled, isClearable, company }) {
  /*
        value: initial id of the selected company, component will search the label for the given value id
        id: id is the property name of the object
        onChange: callback function to be called when the selected company changes

        returns: a react component that renders a select box with the list of companies that is loaded aysncronously
    */

  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    async function fetchCompanies() {
      if (value) {
        const data = await getRequest(`/api/companies/${value}`);
        if (data) setSelectValue({ value: data.FIRNR, label: data.NAAM });
      } else if (company) {
        setSelectValue({ value: company.FIRNR, label: company.NAAM });
      }
    }
    fetchCompanies();
  }, [value, company]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      //calls the api companies for this inputvalue
      const data = await getRequest(`/api/companies/simple/search?q=NAAM:${inputValue}`);
      const options = data.map((company) => {
        const street = company.STRAAT_NR ? `${company.STRAAT_NR},` : '';
        const adress = company.STRAAT_NR || company.POSTNR || company.WOONPL ? `(${street} ${company.POSTNR || ''} ${company.WOONPL || ''})` : '';
        return {
          value: company.FIRNR,
          label: `${company.NAAM} ${adress}`
        };
      });
      callback(options);
    }
  };

  return (
    <>
      <div style={{ marginBottom: '4px' }}>
        <InlineStack align="space-between">
          <div style={{ color: isDisabled ? 'rgba(181, 181, 181, 1)' : 'black' }}>{label}</div>
          {value && (
            <Link removeUnderline url={`/companies/${value}`}>
              open
            </Link>
          )}
        </InlineStack>
      </div>

      <AsyncSelect
        id="CompanySelect"
        isDisabled={isDisabled}
        value={value ? selectValue : null}
        loadOptions={loadOptions}
        isClearable={isClearable}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: isDisabled ? '#ebebeb' : '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
        defaultOptions
        onChange={(props) => {
          if (props) {
            const { value, label } = props;
            setSelectValue({ value: value, label: label });
            onChange(value, id);
          } else {
            setSelectValue({ value: null, label: null });
            onChange(null, id);
          }
        }}
      />
    </>
  );
}
