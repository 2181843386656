import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Page, Card, Link } from '@shopify/polaris';
import { formatToCurrency, getTableFooter, truncate, getUserOrg, alphabetize, fetchTableData } from '../../shared/components/functions.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function ProjectsPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);
  const projectstatuses = useLookupStore((state) => state.projectstatuses);

  //FIMXE: mapping here is not on id, problem with database schema!!
  const userOrg = user && getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = users
    .filter((item) => item.UserType !== 4 && item.UserType !== 7 && item.Vennootschap === userOrg)
    .sort((a, b) => alphabetize(a, b, 'Afkorting'))
    .map((item) => ({ value: item.Gebruiker, label: item.Gebruiker }));

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    requestDate: { type: 'date', name: 'Request date from', value: url.get('requestDate') || '' },
    requestDateTo: { type: 'date', name: 'Request date to', value: url.get('requestDateTo') || '' },
    description: { type: 'text', name: 'Description', value: url.get('description') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    supplier: { type: 'text', name: 'Supplier', value: url.get('supplier') || '' },
    value: {
      type: 'range',
      name: 'Value range',
      value: url.get('value') ? JSON.parse(url.get('value')) : null,
      rangeBottom: 0,
      rangeTop: 1000000,
      prefix: '€'
    },
    buyPercentRange: {
      type: 'range',
      name: 'Buy percentage range',
      value: url.get('buyPercentRange') ? JSON.parse(url.get('buyPercentRange')) : null,
      rangeBottom: 0,
      rangeTop: 100,
      prefix: '%'
    },
    buyUsPercentRange: {
      type: 'range',
      name: 'Buy us percentage range',
      value: url.get('buyUsPercentRange') ? JSON.parse(url.get('buyUsPercentRange')) : null,
      rangeBottom: 0,
      rangeTop: 100,
      prefix: '%'
    },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers },
    status: {
      type: 'multiple',
      name: 'Status',
      value: url.get('status') ? JSON.parse(url.get('status')) : null,
      choices: projectstatuses.map((stat) => ({ value: stat.offertestatusnr, label: stat.offertestatustekst }))
    }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/projects', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const getBadgeStatus = (status) => {
    switch (status) {
      case 0:
        return 'warning';
      case 1:
        return null;
      case 2:
        return 'critical';
      case 3:
        return 'error';
      case 4:
        return 'success';
      case 5:
        return null;
      default:
        return null;
    }
  };

  const getBadgeProgress = (status) => {
    switch (status) {
      case 0:
        return 'incomplete';
      case 1:
        return 'partiallyComplete';
      case 4:
        return 'complete';
      default:
        return null;
    }
  };

  const rows = Array.isArray(items)
    ? items.map((item) => [
        <Link removeUnderline url={'/projects/' + item.ID}>
          {item.Projectnummer}
        </Link>,
        item['aanvraag offerte'] ? String(item['aanvraag offerte']).substring(0, 10) : '',
        truncate(item.omschrijving, 40, false),
        <Link removeUnderline url={'/companies/' + item.firnr}>
          {item.customer}
        </Link>,
        truncate(item.supplier, 30, true),
        formatToCurrency(item.waarde),
        item.Gebruiker,
        <Badge tone={getBadgeStatus(item.status)} progress={getBadgeProgress(item.status)}>
          {item.offertestatustekst}
        </Badge>,
        item['%aankoopkans'] + '%',
        item['%aankoopbijons'] + '%'
      ])
    : [];

  return (
    <Page title="Projects" fullWidth>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter projects on number, customer name, status text, supplier name, description, our ref, ref customer, user and ref supplier"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric', 'text', 'text', 'numeric', 'numeric']}
          headings={[<b>Number</b>, <b>Request</b>, <b>Description</b>, <b>Customer</b>, <b>Supplier</b>, <b>Value</b>, <b>Owner</b>, <b>Status</b>, <b>Buy %</b>, <b>Buy % us</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'projects')}
        />
      </Card>
    </Page>
  );
}
