import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataTable, RangeSlider, Layout, TextField, FormLayout, Form, Page, Card, Select, Link, Banner, Button, Tooltip, Text, BlockStack, Box } from '@shopify/polaris';
import CompanySelect from '../../shared/components/companySelect.jsx';
import { handleSaveWithoutLines, useUrl } from '../../shared/util/hanldesave.js';
import { formatToCurrency, getCurrenctDatetimeYYYMMDD, getRequest, postRequest, stripHtml, alphabetize, truncate } from '../../shared/components/functions.jsx';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import ProjectTimeLine from './components/ProjectTimeLine.jsx';
import ProjectProfitLine from './components/ProjectProfitLine.jsx';
import ProjectPiechart from './components/ProjectPieChart.jsx';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';

/*
    FIXME: BUSINESS LOGIC:
    - Problem with user of this component as the gebruikerID is not used but the gebruiker as a nvarchar field in the database, this is problematic
    
    - Not one invoice in is assocciated with a project so we cant do any calculations on this for outgoing invoices, ingoing invoices dont even have yet the projectID property
    - Not one outgoing invoice is coupled to a project so we cant do any calculations
    - Almost no JOBS (<15) are connected to a Project, so we can not calculate how many hours are worked on this project

    - Purchases can also be coupled but almost never done! 

    --> Simpelest way to resolve this: 
      - If on job creation this is set correctly

    
    FIXME: 
    For a correct calculation, credit notes should also be assocciated with the right project. 
    Some changes should be made on the db level for this. 
    - Add field to incoming credit note to link to project
    - Add commissie field [FLOAT] to the project table in order to know if this will be paid on commision (then the goal should be to check
    if the commision is paid on the project)

    INTERESTING: 
    Purchase can also be connected to a project, but this is not loaded yet on this page. 
*/

export default function ProjectPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const { user } = useAuthStore();
  const { projectstatuses, users } = useLookupStore();

  const [savingResult, setSavingResult] = useState(false);
  const [initialProject, setInitialProject] = useState('');
  const [project, setProject] = useState('');
  const [invoicesOut, setInvoicesOut] = useState([]);
  const [invoicesIn, setInvoicesIn] = useState([]);
  const [associatedJobs, setAssociatedJobs] = useState([]);
  const [associatedTimeslots, setAssociatedTimeslots] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [company, setCompany] = useState(''); //eslint-disable-line
  const [showPie, setShowPie] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [loadingDropboxUrl, setLoadingDropboxUrl] = useState(false);
  const [supplierNames, setSupplierNames] = useState([]);
  const pageTitle = getPageTitle(project.Projectnummer, 'Project');
  const projectSchema = getSchema('project', url, null, user);

  const handleChange = (value, id) => {
    if (id === 'Beslissing' && (!value || value === '')) value = null;
    if (id === 'Leverings' && (!value || value === '')) value = null;
    if (id === 'Einde' && (!value || value === '')) value = null;
    setProject(
      (project) => ({
        ...project,
        [id]: value
      }),
      setIsDirty(true)
    );
  };

  const handleDiscard = () => {
    setProject(initialProject);
    setIsDirty(false);
  };

  const handleStatusChange = (newValue) => {
    switch (newValue) {
      case '1':
        if (window.confirm('Caution: this will set the date of offerte to the date of today. Update manually if this is not true.')) {
          project.offerte = getCurrenctDatetimeYYYMMDD();
        }
        break;
      case '2':
        if (window.confirm('Caution: this will set the date of decision to the date of today. Update manually if this is not true')) {
          project.Beslissing = getCurrenctDatetimeYYYMMDD();
        }
        break;
      case '4':
        if (window.confirm('Caution: this will set the date of decision to the date of today. Update manually if this is not true.')) {
          project.Beslissing = getCurrenctDatetimeYYYMMDD();
        }
        break;
      case '5':
        if (window.confirm('Caution: this will set the date of the end of the project to the date of today. Update manually if this is not true.')) {
          project.Einde = getCurrenctDatetimeYYYMMDD();
        }
        break;
      case '6':
        if (window.confirm('Caution: this will set the date of the end of the project to the date of today. Update manually if this is not true.')) {
          project.Einde = getCurrenctDatetimeYYYMMDD();
        }
        break;
      default:
        break;
    }
  };

  async function handleSave() {
    //form validation
    const errorInSchema = await validateSchema(projectSchema, projectSchema.cast(project));

    if (!errorInSchema) {
      await handleSaveWithoutLines(id, projectSchema.cast(project), 'projects', project.ID, 'ID', setProject, setInitialProject, setIsDirty, setToastError, setToast, setSavingResult, navigate);
    } else {
      alert(errorInSchema);
    }
  }

  useEffect(() => {
    async function fetchProject() {
      //if url contains /new then set machine to its interface
      if (id === 'new') {
        setProject(projectSchema.default());
        setInitialProject(projectSchema.default());
      } else {
        //else get the machine resource via api get request
        const data = await getRequest(`/api/projects/${id}`);
        if (!data || data.length === 0) {
          setProject(projectSchema.default());
          setInitialProject(projectSchema.default());
          return;
        }
        setProject(data[0]);
        setInitialProject(data[0]);

        //fetch associated resources
        setAssociatedJobs(await getRequest(`/api/projects/${id}/jobs`));
        setInvoicesOut(await getRequest(`/api/projects/${id}/invoices/out`));
        setInvoicesIn(await getRequest(`/api/projects/${id}/invoices/in`));
        setAssociatedTimeslots(await getRequest(`/api/projects/${id}/jobs/timeslots`));
      }
    }
    fetchProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (project.firnr) {
        setCompany(await getRequest(`/api/companies/${project.firnr}`));
      }
    }
    fetchData();
  }, [project.firnr]);

  useEffect(() => {
    async function fetchSuppliers() {
      let suppliers = invoicesIn.map((i) => i.LeverancierNummer);
      let uniqueSuppliers = [...new Set(suppliers)];
      let names = await Promise.all(
        uniqueSuppliers.map(async (s) => {
          let company = await getRequest(`/api/companies/${s}`);
          return { id: s, name: company.NAAM };
        })
      );
      setSupplierNames(names);
    }
    if (invoicesIn) fetchSuppliers();
  }, [invoicesIn]);

  const totalInvoicedOut = invoicesOut.reduce((acc, invoice) => acc + Math.round(invoice.totaalbedrag * 100) / 100, 0);
  const totalInvoicedIn = invoicesIn.reduce((acc, invoice) => acc + Math.round(invoice.Bedrag * 100) / 100, 0);
  const workingHourCostFactorEUR = 40;

  const totalWorkingHours =
    associatedTimeslots.length > 0
      ? associatedTimeslots.reduce((acc, timeslot) => {
          return acc + Math.abs(new Date(timeslot.EndTechnician) - new Date(timeslot.StartTechnician)) / 36e5;
        }, 0)
      : 0;
  const totalWorkingHoursCost = totalWorkingHours * workingHourCostFactorEUR;

  const invoiceRows = [
    ['Project value', formatToCurrency(project.waarde)],
    ['Invoiced out', formatToCurrency(totalInvoicedOut)],
    ['Invoiced in', formatToCurrency(totalInvoicedIn)],
    ['Timeslots logged by technicians', Math.round(totalWorkingHours * 100) / 100 + ' hrs'],
    ['Technicians cost (@40EUR/hr)', formatToCurrency(totalWorkingHoursCost)]
  ];

  const invoiceCardOutRows = Array.isArray(invoicesOut)
    ? invoicesOut.map((item) => {
        return [
          <Link removeUnderline url={`/invoices/${item.faktuurnr}`}>
            {item.Factuurnummer}
          </Link>,
          formatToCurrency(item.totaalbedrag),
          <Tooltip content={item.memo && item.memo.length > 10 ? item.memo : ''}>
            <Text as="span">{truncate(item.memo, 15, false)}</Text>
          </Tooltip>
        ];
      })
    : [];

  const invoiceCardInRows = Array.isArray(invoicesIn)
    ? invoicesIn.map((item) => {
        return [
          <Link removeUnderline url={`/invoices_in/${item.faktuurinvolgnummer}`}>
            {item.FactuurInNummer}
          </Link>,
          formatToCurrency(item.Bedrag),
          <Link removeUnderline url={`/companies/${item.LeverancierNummer}`}>
            {supplierNames.length > 0 && supplierNames.filter((i) => i.id === item.LeverancierNummer)[0].name}
          </Link>,
          <Tooltip content={item.memo && item.memo.length > 10 ? item.memo : ''}>
            <Text as="span">{truncate(item.memo, 15, false)}</Text>
          </Tooltip>
        ];
      })
    : [];

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <TheSaveBar isDirty={isDirty} handleSave={handleSave} handleDiscard={handleDiscard} savingResult={savingResult} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent={'Project saved'} />
      <Box paddingBlockEnd={'400'}>
        <Banner title="Project summary">
          <ProjectTimeLine project={project} />
          {project.status === 4 && (
            <>
              <Box paddingBlockStart={'200'}>
                <ProjectProfitLine project={project} totalInvoicedOut={totalInvoicedOut} />
              </Box>
              <Box paddingBlockStart={'200'}>
                <Button onClick={() => setShowPie((showPie) => !showPie)}>{showPie ? 'Hide 📈' : 'Show 📈'}</Button>
              </Box>
              <div style={{ height: totalInvoicedOut > 0 ? '600px' : '20px', display: showPie ? 'inherit' : 'none', marginTop: '15px' }}>
                <ProjectPiechart projectValue={project.waarde ? project.waarde : 0} totalInvoicedOut={totalInvoicedOut} totalInvoicedIn={totalInvoicedIn} techniciansCost={totalWorkingHoursCost} />
              </div>
            </>
          )}
        </Banner>
      </Box>
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={() => handleSave()}>
              <FormLayout>
                <CompanySelect isClearable={false} isDisabled={savingResult || id !== 'new'} id="firnr" label="Customer" value={project.firnr} onChange={handleChange} />
                <FormLayout.Group>
                  <TextField id="Projectnummer" disabled label="Number" value={project.Projectnummer ? String(project.Projectnummer) : 'created automatically'} />
                  <TextField id="waarde" type="number" onChange={handleChange} label="Project value" prefix="€" value={project.waarde} disabled={savingResult} />
                </FormLayout.Group>
                <TextField label="Description" id="omschrijving" value={project.omschrijving || ''} multiline={7} onChange={handleChange} disabled={savingResult} />
                <FormLayout.Group>
                  <CompanySelect isClearable id="supplierID" label="Supplier" value={project.supplierID} onChange={handleChange} isDisabled={savingResult} />
                  <TextField
                    id="aanvraag offerte"
                    maxLength={60}
                    type="date"
                    autoComplete="off"
                    label="Date request"
                    value={String(project['aanvraag offerte']).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="onzeref" maxLength={20} showCharacterCount autoComplete="off" label="Our reference" value={project.onzeref || ''} onChange={handleChange} disabled={savingResult} />
                  <TextField
                    id="offerte"
                    maxLength={60}
                    type="date"
                    autoComplete="off"
                    label="Date offerte"
                    value={String(project.offerte).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="refklant" maxLength={100} showCharacterCount autoComplete="off" label="Ref customer" value={project.refklant || ''} onChange={handleChange} disabled={savingResult} />
                  <TextField
                    id="Beslissing"
                    maxLength={60}
                    type="date"
                    autoComplete="off"
                    label="Date decision"
                    value={String(project.Beslissing).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id="refleverancier"
                    maxLength={20}
                    showCharacterCount
                    autoComplete="off"
                    label="Ref supplier"
                    value={project.refleverancier || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                  <TextField
                    id="Leverings"
                    maxLength={60}
                    type="date"
                    autoComplete="off"
                    label="Date delivery"
                    value={String(project.Leverings).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="Einde" maxLength={60} type="date" autoComplete="off" label="Date end" value={String(project.Einde).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    id="status"
                    label="Status"
                    value={parseInt(project.status) || ''}
                    options={projectstatuses
                      .sort((a, b) => alphabetize(a, b, 'offertestatustekst'))
                      .map((status) => {
                        return { value: status.offertestatusnr, label: status.offertestatustekst, key: 'STATUS-' + status.offertestatusnr };
                      })}
                    onChange={(value) => {
                      handleChange(value, 'status');
                      handleStatusChange(value);
                    }}
                    disabled={savingResult}
                  />
                  <Select
                    placeholder="Select user"
                    disabled
                    id="Gebruiker"
                    label="User"
                    value={parseInt(project.Gebruiker) || ''}
                    options={users.map((user) => {
                      return { value: user.GebruikerID, label: user.Afkorting };
                    })}
                    onChange={handleChange}
                  />
                </FormLayout.Group>
                <TextField
                  id="statusomschrijving"
                  label="Status info"
                  value={project.statusomschrijving || ''}
                  multiline={10}
                  onChange={handleChange}
                  placeholder="Edit the project status info ✍️"
                  disabled={savingResult}
                />
                <TextField
                  id="verloop"
                  label="Development progress"
                  value={project.verloop || ''}
                  multiline={25}
                  onChange={handleChange}
                  placeholder="Edit the project development ✍️"
                  disabled={savingResult}
                />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Buy probability" />
              <Form>
                <FormLayout>
                  <RangeSlider
                    min={0}
                    max={100}
                    prefix={'0%'}
                    suffix={'100%'}
                    step={5}
                    id="%aankoopkans"
                    label={'Buy percentage ' + (project['%aankoopkans'] ? project['%aankoopkans'] : '0') + '%'}
                    value={project['%aankoopkans'] ? project['%aankoopkans'] : '0'}
                    onChange={handleChange}
                    output
                    disabled={savingResult}
                  />
                  <RangeSlider
                    min={0}
                    max={100}
                    step={5}
                    prefix={'0%'}
                    suffix={'100%'}
                    id="%aankoopbijons"
                    label={'Buy percentage us ' + (project['%aankoopbijons'] ? project['%aankoopbijons'] : '0') + '%'}
                    value={project['%aankoopbijons'] ? project['%aankoopbijons'] : '0'}
                    onChange={handleChange}
                    output
                    disabled={savingResult}
                  />
                </FormLayout>
              </Form>
            </Card>
            <Card>
              <CardHeading title="Docs" />
              {id !== 'new' ? (
                <Tooltip content="If folder exists, opens it online. If folder does not exists folder gets created and is then opened.">
                  <Button
                    disabled={savingResult}
                    loading={loadingDropboxUrl}
                    onClick={async () => {
                      setLoadingDropboxUrl(true);

                      const payload = { projectNumber: project.Projectnummer };
                      const data = await postRequest('/api/docgen/dropbox/shareableurl', payload);

                      setLoadingDropboxUrl(false);
                      window.open(data.url, '_blank');
                    }}
                  >
                    Open online dropbox folder
                  </Button>
                </Tooltip>
              ) : null}
            </Card>
            <Card>
              <BlockStack gap="500">
                <Box>
                  <CardHeading title="Summary" />
                  <DataTable stickyHeader={true} increasedTableDensity={true} verticalAlign="middle" columnContentTypes={['text', 'numeric']} headings={[]} rows={invoiceRows} />
                </Box>
                <Box>
                  <CardHeading title="Invoices out" />
                  {invoicesOut.length > 0 ? (
                    <DataTable
                      stickyHeader={true}
                      increasedTableDensity={true}
                      verticalAlign="middle"
                      columnContentTypes={['text', 'text', 'text', 'text']}
                      headings={['Number', 'Value', 'Note']}
                      rows={invoiceCardOutRows}
                    />
                  ) : (
                    'No outgoing invoices for this project'
                  )}
                </Box>
                <Box>
                  <CardHeading title="Invoices in" />
                  {invoicesIn.length > 0 ? (
                    <DataTable
                      stickyHeader={true}
                      increasedTableDensity={true}
                      verticalAlign="middle"
                      columnContentTypes={['text', 'text', 'text', 'text']}
                      headings={['Number', 'Value', 'Supplier', 'Note']}
                      rows={invoiceCardInRows}
                    />
                  ) : (
                    'No incoming invoices for this project'
                  )}
                </Box>
                <Box>
                  <CardHeading title="Jobs" />
                  {associatedJobs.length > 0 ? (
                    <DataTable
                      stickyHeader={true}
                      increasedTableDensity={true}
                      verticalAlign="middle"
                      columnContentTypes={['text', 'text']}
                      headings={['Number', 'Description']}
                      rows={associatedJobs.map((job) => {
                        return [
                          <Link removeUnderline url={`https://tech.schuilenburg.be/Jobscherm/${job.JobID}`}>
                            {'#' + job.JobNumber}
                          </Link>,
                          stripHtml(job.Description).substring(0, 50)
                        ];
                      })}
                    />
                  ) : (
                    'No jobs for this project'
                  )}
                </Box>
                <Box>
                  <CardHeading title="Calculation - missing parts" />
                  <Text>Credit notes are not yet implemented (db schema needs changes)</Text>
                </Box>
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
