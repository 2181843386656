import { FormLayout, Modal, TextField } from '@shopify/polaris';
import { useState } from 'react';
import { useLookupStore } from '../../../context/useStore';

export const LabelPrintModal = ({ active, setActive, isSmall, company }) => {
  const countries = useLookupStore((state) => state.countries);
  const [isLoading, setIsLoading] = useState(false);
  const [smallInput, setSmallInput] = useState(company.NAAM);
  const [bigInput, setBigInput] = useState({
    name: company.NAAM || '',
    street: company.STRAAT_NR || '',
    zip: company.POSTNR || '',
    city: company.WOONPL || '',
    country: countries.find((c) => c.LandId === company.LandID).Land || '',
    tel: company.TEL || '',
    btw: company.BTW || ''
  });

  const handleChange = (value, id) => {
    setBigInput((bigInput) => ({
      ...bigInput,
      [id]: value
    }));
  };

  const submitModal = async () => {
    setIsLoading(true);
    const payload = { id: company.FIRNR, input: isSmall ? smallInput : bigInput, isSmall };
    const response = await fetch('/api/companies/label/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = `company_${company.FIRNR}.pdf`;
      a.click();
      URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.status, response.statusText);
    }

    handleModalClose();
    setIsLoading(false);
  };

  const handleModalClose = () => {
    setActive(false);
    setSmallInput('');
  };

  return (
    company && (
      <Modal
        height="auto"
        open={active}
        onClose={handleModalClose}
        title={`Print ${isSmall ? 'small' : 'big'} label`}
        primaryAction={{
          content: 'Create label',
          onAction: () => submitModal(),
          disabled: isLoading,
          loading: isLoading
        }}
        loading={isLoading}
      >
        <Modal.Section>
          {isSmall ? (
            <TextField id="smallLabel" label="Label:" value={smallInput || ''} onChange={(e) => setSmallInput(e)} maxLength={45} autoComplete="off" showCharacterCount />
          ) : (
            <FormLayout>
              <TextField id="name" label="Company name:" value={bigInput.name} onChange={handleChange} autoComplete="off" />
              <TextField id="street" label="Street & number:" value={bigInput.street} onChange={handleChange} autoComplete="off" />
              <TextField id="zip" label="ZIP:" value={bigInput.zip} onChange={handleChange} autoComplete="off" />
              <TextField id="city" label="City:" value={bigInput.city} onChange={handleChange} autoComplete="off" />
              <TextField id="country" label="Country:" value={bigInput.country} onChange={handleChange} autoComplete="off" />
              <TextField id="tel" label="Tel:" value={bigInput.tel} onChange={handleChange} autoComplete="off" />
              <TextField id="btw" label="BTW:" value={bigInput.btw} onChange={handleChange} autoComplete="off" />
            </FormLayout>
          )}
        </Modal.Section>
      </Modal>
    )
  );
};
