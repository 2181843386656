import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, Tabs } from '@shopify/polaris';
import { useUrl } from '../../shared/util/hanldesave';
import { filtersToApiUrl, alphabetize, getUserOrg, fetchTableData, getRequest } from '../../shared/components/functions';
import InvoicesInTable from './components/invoicesInTable';
import InvoiceInCollection from './components/invoiceInCollection';
import { useAuthStore, useLookupStore } from '../../context/useStore';
import { useDebounce } from '../../shared/components/inputs/useDebounce';
import { getUserOptions } from '../../shared/components/listFilter';

export default function InvoicesInPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);
  const userOrg = user && getUserOrg(organisations, user.ORGANISATION_NAME);
  const userChoices = users
    .filter((item) => item.UserType !== 4 && item.UserType !== 7 && item.Vennootschap === userOrg)
    .sort((a, b) => alphabetize(a, b, 'Afkorting'))
    .map((item) => ({ key: item.GebruikerID, value: item.GebruikerID, label: `${item.Afkorting} (${item.Gebruikernaam})` }));
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [invoiceCollectionModalOpen, setInvoiceCollectionModalOpen] = useState(false);
  const [collectionInvoice, setCollectionIvoice] = useState({});
  const [fullInvoiceCollection, setFullInvoiceCollection] = useState(false); //full if fully paid
  const [selectedTab, setSelectedTab] = useState(url.get('tab') ? parseInt(url.get('tab')) : 0);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    supplier: { type: 'text', name: 'Supplier', value: url.get('supplier') || '' },
    note: { type: 'text', name: 'Note', value: url.get('note') || '' },
    dateInFrom: { type: 'date', name: 'Date in from', value: url.get('dateInFrom') || '' },
    dateInTo: { type: 'date', name: 'Date in to', value: url.get('dateInTo') || '' },
    dateExpireFrom: { type: 'date', name: 'Date expire from', value: url.get('dateExpireFrom') || '' },
    dateExpireTo: { type: 'date', name: 'Date expire to', value: url.get('dateExpireTo') || '' },
    dateInvoice: { type: 'date', name: 'Date invoice', value: url.get('dateInvoice') || '' },
    reference: { type: 'text', name: 'Reference', value: url.get('reference') || '' },
    value: { type: 'range', name: 'Value', value: url.get('value') ? JSON.parse(url.get('value')) : null, rangeBottom: 0, rangeTop: 1000000, prefix: '€' },
    valueOpen: { type: 'range', name: 'Value open', value: url.get('valueOpen') ? JSON.parse(url.get('valueOpen')) : null, rangeBottom: 0, rangeTop: 1000000, prefix: '€' },
    datePayment: { type: 'date', name: 'Date payment', value: url.get('datePayment') || '' },
    approval: { type: 'multiple', name: 'Approval', value: url.get('approval') ? JSON.parse(url.get('approval')) : null, choices: userChoices },
    tab: { type: 'none', value: url.get('tab') || null, name: 'Tab' },
    Open: { type: 'none', value: url.get('Open') || null, name: 'Open' },
    GaatVervallen: { type: 'none', value: url.get('GaatVervallen') || null, name: 'Gaat Vervallen' },
    Vervallen: { type: 'none', value: url.get('Vervallen') || null, name: 'Vervallen' },
    Paid: { type: 'none', value: url.get('Paid') || null, name: 'Paid' },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/invoices_in', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  async function fetchInvoices() {
    const data = await getRequest(`/api/invoices_in?page=${page}&limit=100&${filtersToApiUrl(filters)}`);
    setItems(data);
  }

  const tabIds = ['all-invoices', 'all-invoices-open', 'all-invoices-going-to-expire', 'all-invoices-vervallen', 'all-invoices-paid'];

  let table = [
    <InvoicesInTable
      key={tabIds.map((id) => id)}
      items={items}
      page={page}
      setPage={setPage}
      filters={filters}
      setFilters={setFilters}
      navigate={navigate}
      setInvoiceCollectionModalOpen={setInvoiceCollectionModalOpen}
      setCollectionIvoice={setCollectionIvoice}
      setFullInvoiceCollection={setFullInvoiceCollection}
      filterLoading={filterLoading}
    />
  ];

  let tabs;
  tabs = [
    {
      id: tabIds[0],
      content: 'All',
      panelID: 'all-invoices-content',
      comp: table,
      filter: ''
    },
    {
      id: tabIds[1],
      content: 'Open',
      panelID: 'all-invoices-open',
      comp: table,
      filter: 'Open'
    },
    {
      id: tabIds[2],
      content: 'Expiring soon (2 days)',
      panelID: 'all-invoices-expired',
      comp: table,
      filter: 'GaatVervallen'
    },
    {
      id: tabIds[3],
      content: 'Expired',
      panelID: tabIds[3],
      comp: table,
      filter: 'Vervallen'
    },
    {
      id: tabIds[4],
      content: 'Paid',
      panelID: tabIds[4],
      comp: table,
      filter: 'Paid'
    }
  ];

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelectedTab(selectedTabIndex);
      let removeTabsFilters = {};
      for (let i = 1; i < tabs.length; i++) {
        if (tabs[i].filter === tabs[selectedTabIndex].filter) {
          removeTabsFilters[tabs[i].filter] = { type: 'none', value: '1' };
        } else {
          removeTabsFilters[tabs[i].filter] = { type: 'none', value: null };
        }
      }
      setFilters(
        (filters) => ({ ...filters, tab: { type: 'none', value: selectedTabIndex }, ...removeTabsFilters }),
        navigate(
          {
            pathname: window.location.pathname,
            search: `${filtersToApiUrl({ ...filters, tab: { type: 'none', value: selectedTabIndex }, ...removeTabsFilters })}`
          },
          { replace: true }
        )
      );
    },
    [navigate, filters, tabs]
  );

  const autoProcessEmails = {
    schuilenburg: ' invoices@admin.schuilenburg.com ',
    aha: 'invoices-aha@admin.schuilenburg.com',
    jadibo: 'invoices-jadibo@admin.schuilenburg.com',
    adriaens: 'invoices-adriaens@admin.schuilenburg.com',
    localhost: 'invoices-localhost@admin.schuilenburg.com' // for testing
  };

  const getAutoProcessEmail = () => {
    const url = window.location.href;
    const keys = Object.keys(autoProcessEmails);
    for (let i = 0; i < keys.length; i++) {
      if (url.includes(keys[i])) autoProcessEmails[keys[i]];
    }
    return '';
  };

  return (
    <Page title="Invoices in" subtitle={`Upload automatically by emailing to ${getAutoProcessEmail()}`} fullWidth primaryAction={{ content: 'New incoming invoice', url: '/invoices_in/new' }}>
      <InvoiceInCollection
        modalOpen={invoiceCollectionModalOpen}
        setModalOpen={setInvoiceCollectionModalOpen}
        collectionInvoice={collectionInvoice}
        fetchData={fetchInvoices}
        fullInvoiceCollection={fullInvoiceCollection}
      />
      <Card>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          {tabs[selectedTab].comp}
        </Tabs>
      </Card>
    </Page>
  );
}
