import { TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import validator from 'validator';

export default function CustomEmailInput({ value, id, onChange, label }) {
  const [email, setEmail] = useState('xx');

  useEffect(() => {
    if (value) {
      setEmail(value);
      //log the number of chars in the value string
      //console.log(value.length);
    }
  }, [value]);

  return (
    <TextField
      id={id}
      showCharacterCount={value}
      labelAction={{ content: 'Email this company', onAction: () => (window.location = `mailto:${value}`) }}
      error={email.length > 5 ? (validator.isEmail(email) ? false : true) : false}
      maxLength={60}
      helpText={email.length > 5 ? (validator.isEmail(email) ? false : 'Email not valid') : false}
      autoComplete="off"
      label={label}
      value={value || ''}
      onChange={onChange}
    />
  );
}
