import { useState, useEffect } from 'react';
import { Modal, TextField, Banner, DescriptionList } from '@shopify/polaris';
import { formatToCurrency, getCurrenctDatetimeYYYMMDD, putRequest } from '../../../shared/components/functions';
import { performTracking } from '../../../analytics/segment';
import IncasserenSelect from '../../../shared/components/incasserenSelect';

export default function CreditnoteInCollection({ modalOpen, setModalOpen, fullInvoiceCollection, collectionInvoice, fetchData }) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState({
      bedragopen: fullInvoiceCollection ? 0 : Math.round(collectionInvoice.bedragopen * 100) / 100,
      CreditnotaINvolgnummer: collectionInvoice.CreditnotaINvolgnummer,
      memo: collectionInvoice.memo,
      status: fullInvoiceCollection ? 1 : collectionInvoice.status,
      partiallyPaid: 0,
      datumbetalingout: fullInvoiceCollection ? getCurrenctDatetimeYYYMMDD() : null,
      Incasseren: fullInvoiceCollection ? 'BELFIUS' : null
    });
    //eslint-disable-next-line
  }, [collectionInvoice]);

  const handleChange = (value, id) => {
    if (id === 'partiallyPaid') {
      setState((state) => ({ ...state, bedragopen: collectionInvoice.bedragopen - Math.round(parseFloat(String(value).replace(',', '.')) * 100) / 100 }));
    }
    setState((state) => ({ ...state, [id]: value }));
  };

  const collectInvoice = async () => {
    setLoading(true);
    await putRequest(`/api/creditnotes_in/${state.CreditnotaINvolgnummer}`, state);
    setModalOpen(false);
    setLoading(false);
    await fetchData();
    if (fullInvoiceCollection) {
      performTracking('payment_collected_creditnote_in_full', state);
    } else {
      performTracking('payment_collected_creditnote_in_partial', state);
    }
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title={`Payment collection for credit note #${collectionInvoice.CreditnotaInNummer}`}
        primaryAction={{
          content: fullInvoiceCollection ? 'Collect payment' : 'Collect partial payment',
          onAction: () => collectInvoice(),
          loading: loading
        }}
        secondaryActions={[{ content: 'Cancel', onAction: () => setModalOpen(false) }]}
      >
        <Modal.Section>
          {fullInvoiceCollection ? (
            <Banner tone="info">The credit note status will be automatically set to paid. And the open amount to 0.</Banner>
          ) : (
            <Banner tone="warning">The credit note status will not be set to paid. Please set the remaining open value yourself.</Banner>
          )}
          <br />
          <DescriptionList
            items={[
              {
                term: 'Credit note number',
                description: `#${collectionInvoice.CreditnotaInNummer}`
              },
              {
                term: 'Credit note reference',
                description: collectionInvoice.faktuurref
              },
              {
                term: 'Company',
                description: collectionInvoice.supplier_name
              },
              {
                term: 'Total value',
                description: formatToCurrency(collectionInvoice.Bedragfaktuur)
              }
            ]}
          />
          <br />
          <br />
          {fullInvoiceCollection && (
            <>
              <TextField id="datumbetalingout" type="date" label="Payment date" value={String(state.datumbetalingout).substring(0, 10)} onChange={handleChange} />
              <br />
            </>
          )}
          {!fullInvoiceCollection && (
            <>
              <TextField
                prefix="€"
                id="partiallyPaid"
                disabled={fullInvoiceCollection}
                type="currency"
                label="AMOUNT THAT IS PARTIALLY PAID"
                value={String(state.partiallyPaid)}
                onChange={handleChange}
              />
              <br />
            </>
          )}
          <TextField id="bedragopen" disabled type="number" prefix="€" label="Open amount" value={String(state.bedragopen)} onChange={handleChange} />
          <br />
          {fullInvoiceCollection && (
            <>
              <IncasserenSelect id="Incasseren" value={state.Incasseren} handleChange={handleChange} />
              <br />
            </>
          )}
          <TextField multiline={5} id="memo" label="Note" value={state.memo} onChange={handleChange} />
          <br />
        </Modal.Section>
      </Modal>
    </div>
  );
}
