import React, { useState, useEffect } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';

export default function TechnicianOvertimeCalendar(props) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetch(`/api/technicians/${props.technician_id}/overtime_calculations`)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
        props.setCalenderItems(data);
        //Return to parents component if last month is fully filled in
        var d = new Date();
        d.setDate(0); //last day previous month

        var check_month = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2); //last month format: yyyy-mm
        if (
          //Check if there are days that are not filled in by filtering resultset
          data.filter((entry) => {
            if (String(entry.Date).substring(0, 7) === check_month && entry.day_planning_filled_in === 0) {
              return entry;
            } else {
              return null;
            }
          }).length > 0
        ) {
          //omzeilen dat Franky niet op woensdag werkt, hardcoded manier, hij kan altijd afgesloten worden!
          if (props.technician_id === '62') {
            //eslint-disable-line
            props.setPlanningMonthFilled(true);
          } else {
            props.setPlanningMonthFilled(false);
          }
        } else {
          props.setPlanningMonthFilled(true);
        }
      });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ConvertSaldo(mins) {
    var num = Math.abs(mins);
    var h = Math.floor(num / 60);
    var m = Math.round(num % 60);
    if (mins >= 0) {
      if (h > 0 && m > 0) {
        return h + 'h ' + m + 'm';
      } else if (h === 0 && m > 0) {
        return +m + 'm';
      } else if (h > 0 && m === 0) {
        return h + 'h';
      } else if (h === 0 && m === 0) {
        return '0';
      }
    } else {
      if (h > 0 && m > 0) {
        return '-' + h + 'h ' + m + 'm';
      } else if (h === 0 && m > 0) {
        return '-' + +m + 'm';
      } else if (h > 0 && m === 0) {
        return '-' + h + 'h';
      } else if (h === 0 && m === 0) {
        return '0';
      }
    }
  }

  /*
    function ConvertSaldo2(mins){
    var num  = Math.abs(mins)
    var h = Math.floor((num)/60);
    var m = Math.round(num%60);
      if(h > 0 && m >0){ 
        return (h + "h " +m+ "m")
      }else if(h === 0 && m > 0){
        return ( +m+ "m")
      }else if(h > 0 && m === 0){
        return (h + "h")
      }else if(h === 0 && m === 0){
        return ("")
      }
  }
  */
  var flag_locked = false;
  const rows = items.map((item, idx) => {
    if (flag_locked === false) {
      flag_locked = item.saldo_afsluiting !== null ? true : false;
    }
    //var flag_lock_value = (flag_locked == true) ? 'l' : 'nl'
    var planning = item.day_planning_filled_in ? (
      <Badge tone="success">
        <Link
          target="_blank"
          removeUnderline
          url={`https://tech.schuilenburg.be/Dagoverzicht/${props.technician_id}/${String(item.Date).substring(5, 7)}-${String(item.Date).substring(8, 10)}-${String(item.Date).substring(0, 4)}`}
        >
          Ok
        </Link>
      </Badge>
    ) : (
      <Badge tone="critical">
        <Link
          target="_blank"
          removeUnderline
          url={`https://tech.schuilenburg.be/Dagoverzicht/${props.technician_id}/${String(item.Date).substring(5, 7)}-${String(item.Date).substring(8, 10)}-${String(item.Date).substring(0, 4)}`}
        >
          NOK
        </Link>
      </Badge>
    );

    const type = item.official_holiday ? (
      <Badge tone="warning" size="small">
        Feestag
      </Badge>
    ) : item.official_brug_recupfd ? (
      <Badge tone="warning" size="small">
        Brug/RecupFD
      </Badge>
    ) : flag_locked ? (
      <div style={{ marginTop: '-6px' }}>
        <Badge progress="complete" size="small">
          Locked
        </Badge>
      </div>
    ) : (
      <div style={{ marginTop: '-6px' }}>
        <Badge progress="incomplete" size="small">
          Unlocked
        </Badge>
      </div>
    );

    return [
      item.Date,
      String(item.weekday).substring(0, 3),
      type,
      planning,
      ConvertSaldo(item.worked_mins),
      ConvertSaldo(item.recup_mins),
      ConvertSaldo(item.overtime_100),
      ConvertSaldo(item.overtime_150),
      ConvertSaldo(item.overtime_200),
      ConvertSaldo(item.overtime_300),
      ConvertSaldo(item.overtime_day_saldo_diff)
    ];
  });
  return (
    <DataTable
      stickyHeader={true}
      increasedTableDensity={true}
      verticalAlign="middle"
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
      headings={['Date', 'Day', 'Type', 'Planning', 'Worked', 'Recup', '100%', '150%', '200%', '300%', 'Overtime']}
      rows={rows}
      truncate={false}
    />
  );
}
