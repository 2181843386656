import React, { useState } from 'react';
import { DataTable, Modal, Button, TextField, Card, Banner, Box, FormLayout } from '@shopify/polaris';
import { CardHeading } from '../../../shared/components/cards/CardHeading';

export default function TechnicianSaldoComponent(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saldo, setSaldo] = useState('0');
  const [initSaldo, setInitSaldo] = useState('0');
  const [saldoType, setSaldoType] = useState('saldo');
  const [saldoTypeID, setSaldoTypeID] = useState('0');
  const [noteValue, setNoteValue] = useState('');

  const handleModalClose = () => setModalOpen(false);
  const updateSaldo = (new_saldo) => setSaldo(new_saldo);
  const handleModalOpen = (saldo, saldo_type, saldo_type_id) => {
    setNoteValue('');
    setSaldo(String(saldo));
    setInitSaldo(String(saldo));
    setSaldoType(String(saldo_type));
    setSaldoTypeID(String(saldo_type_id));
    setModalOpen(true);
  };

  function ConvertSaldo(mins) {
    var num = Math.abs(mins);
    var h = Math.floor(num / 60);
    var m = Math.round(num % 60);

    return h + 'hrs ' + m + 'mins';
  }
  const rows = props.saldos.map((item) => {
    const saldo = item.SaldoType === 'Overuren' ? ConvertSaldo(item.Saldo) : item.Saldo + ' days';
    return [
      item.SaldoType,
      saldo,
      <Button disabled={!props.last_month_locked} onClick={() => handleModalOpen(item.Saldo, item.SaldoType, item.VerlofSaldoTypeID)} size="micro">
        Edit saldo
      </Button>
    ];
  });

  const updateSaldoDb = async () => {
    //do fectch to database to upoload saldo
    //technician_id, saldo_type_id, correction, saldo, note

    setLoading(true);
    //post the saldo to database with a fetch post call
    await fetch(`/api/technicians/saldos/new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        technician_id: props.technician_id,
        saldo_type_id: saldoTypeID,
        correction: parseFloat(saldo) - parseFloat(initSaldo),
        saldo: saldo,
        note: noteValue
      })
    });
    props.fetchData();
    setModalOpen(false);
    setLoading(false);
  };

  const inputSuffix = saldoType === 'Overuren' ? 'minutes' : 'days';

  return (
    <Card>
      <CardHeading title="Saldo overview" />
      {props.last_month_locked ? null : (
        <Box paddingBlockEnd={'200'}>
          <Banner tone="info" title="Lock the month before you can update any saldos!" />
        </Box>
      )}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        title={`Adjust saldo ${saldoType}`}
        primaryAction={{
          content: `ADJUST SALDO`,
          onAction: () => updateSaldoDb(),
          loading: loading
        }}
      >
        <Modal.Section>
          <FormLayout>
            <TextField suffix={inputSuffix} label="Saldo in database :" value={saldo} onChange={updateSaldo} type="number" />
            <TextField label="Note" value={noteValue} onChange={(value) => setNoteValue(value)} multiline={4} />
          </FormLayout>
        </Modal.Section>
      </Modal>
      <DataTable
        stickyHeader={true}
        increasedTableDensity={true}
        verticalAlign="middle"
        columnContentTypes={['text', 'text', 'text']}
        headings={['Name', 'Saldo', 'Edit']}
        rows={rows}
        truncate={false}
      />
    </Card>
  );
}
