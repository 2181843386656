import React, { useEffect, useState } from 'react';
import { DataTable, Link } from '@shopify/polaris';
import { getDocPdfLinkForListView, getRequest } from '../../../shared/components/functions';

export default function DeliveriesCompany({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/delivery_notes?limit=1000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const customer_ref_date = item.refklantdatum ? item.refklantdatum : '';
        return [
          <Link removeUnderline url={'/delivery_notes/' + item.Bonnr}>
            {item.LeveringsbonNummer}
          </Link>,
          getDocPdfLinkForListView(item, 'delivery_note', item.company_number, item.LeveringsbonNummer),
          String(item.datum).substring(0, 10),
          item.refklant,
          String(customer_ref_date).substring(0, 10)
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text']}
      headings={[<b>{'Number'}</b>, <b>{'PDF'}</b>, <b>{'Date'}</b>, <b>{'Reference customer'}</b>, <b>{'Date customer'}</b>]}
      rows={rows}
    />
  ) : (
    'No deliveries'
  );
}
