import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Select, FormLayout, Layout, Page, Card, TextField, Checkbox, Button, BlockStack, InlineGrid } from '@shopify/polaris';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { updateMyData } from '../../shared/util/updateLines';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { alphabetize, getRequest, simplePostRequest } from '../../shared/components/functions.jsx';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import { InquiryToPurchase } from '../../shared/components/transformers';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import CompanySelect from '../../shared/components/companySelect';
import ProjectSelect from '../../shared/components/projectSelect.jsx';
import LinesTotalCard from '../../shared/components/cards/LinesTotalsCard.jsx';
import LinesTable from '../../shared/components/linesTable';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';

export default function InquiryPage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const docTypeID = 14;
  const docType = 'price_request';
  const [savingResult, setSavingResult] = useState(false);
  const [inquiry, setInquiry] = useState({});
  const [initialInquiry, setInitialInquiry] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(false);
  const [linesDirty, setLinesDirty] = useState(false);
  const [company, setCompany] = useState({});
  const [customer, setCustomer] = useState({});
  const [isBlurred, setIsBlurred] = useState(true);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const countries = useLookupStore((state) => state.countries);
  const languages = useLookupStore((state) => state.languages);
  const { user } = useAuthStore();
  const pageTitle = getPageTitle(inquiry.Prijsaanvraagnummer, 'Inquiry');
  const inquirySchema = getSchema('inquiry', url, null, user);

  const lineInterface = {
    lineIsDirty: false,
    PrijsaanvraagLijnID: null,
    PrijsaanvraagID: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: null,
    VP: null,
    aantal: 1,
    BTW: company.LandID === 300 ? 21 : null,
    MuntVP: null,
    AP: null,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': null,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const handleChange = (value, id) => {
    if (id === 'RefKlantDatum' && (!value || value === '')) value = null;
    setInquiry((inquiry) => ({ ...inquiry, [id]: value }), setIsDirty(true));
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setInquiry(inquirySchema.default());
      } else {
        const response = await getRequest(`/api/inquiries/${id}`);
        if (!response) return setInquiry(inquirySchema.default());
        setInquiry(response);
        setInitialInquiry(response);

        const response2 = await getRequest(`/api/inquiries/${id}/lines`);
        setLines(response2);
        setInitialLines(response2);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (inquiry.LeverancierID) {
        const response3 = await getRequest(`/api/companies/${inquiry.LeverancierID}`);
        setCompany(response3);
      }
      if (inquiry.KlantID) {
        const response4 = await getRequest(`/api/companies/${inquiry.KlantID}`);
        setCustomer(response4);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inquiry.LeverancierID, inquiry.KlantID]);

  const generateDocument = async () => {
    const document = {
      addressee: {
        FIRNR: inquiry.LeverancierID,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      eu: false,
      language: inquiry.TaalID ? languages.find((l) => l.TaalID === parseInt(inquiry.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      id: inquiry.PrijsaanvraagID,
      typeID: docTypeID,
      number: inquiry.Prijsaanvraagnummer,
      reference: customer.NAAM,
      reference_date: inquiry.RefKlantDatum ? String(inquiry.RefKlantDatum).substring(0, 10) : '',
      date: String(inquiry.Datum).substring(0, 10),
      payment_condition: null,
      machine_type: inquiry.MachineType,
      machine_number: inquiry.MachineNummer
    };

    //check if it is dirty if not tell user to save first
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  async function saveForm() {
    const errorInSchema = await validateSchema(inquirySchema, inquiry);
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        handleSave(
          id,
          'inquiries',
          inquiry.PrijsaanvraagID,
          inquiry,
          setInquiry,
          setInitialInquiry,
          setToast,
          setToastError,
          isDirty,
          setIsDirty,
          linesDirty,
          lines,
          setLines,
          setInitialLines,
          setLinesDirty,
          setSavingResult,
          navigate
        );

        // auto generate pdf so latest changes are reflected in pdf
        if (id && id !== 'new') await generateDocument();
        setIsBlurred(false);
      } else {
        alert(errorInSchema);
      }
    }
  }

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Inquiry saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        savingResult={savingResult}
        handleDiscard={() => handleDiscard(initialInquiry, initialLines, setInquiry, setLines, setIsDirty, setLinesDirty)}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={saveForm}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="Bestellingnummer" disabled label="Number" value={String(inquiry.Prijsaanvraagnummer)} />
                  <TextField id="LeverancierID" label="Supplier" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${inquiry.LeverancierID}`) }} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="Datum" maxLength={60} type="date" autoComplete="off" label="Date" value={String(inquiry.Datum).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                  <CompanySelect isClearable id="KlantID" label="Customer" value={inquiry.KlantID} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    id="TaalID"
                    maxLength={60}
                    autoComplete="off"
                    placeholder="Choose language"
                    label="Language"
                    value={parseInt(inquiry.TaalID) || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                    options={languages
                      .sort((a, b) => alphabetize(a, b, 'Omschrijving'))
                      .map((lan) => {
                        return { value: lan.TaalID, label: lan.Omschrijving };
                      })}
                  />
                  <TextField
                    id="RefKlant"
                    maxLength={100}
                    showCharacterCount
                    type="text"
                    autoComplete="off"
                    label="Reference customer"
                    value={inquiry.RefKlant || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id="Leveringstermijn"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Delivery term"
                    value={inquiry.Leveringstermijn || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                  <TextField
                    id="RefKlantDatum"
                    maxLength={60}
                    type="date"
                    autoComplete="off"
                    label="Customer date"
                    value={String(inquiry.RefKlantDatum).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <ProjectSelect value={inquiry.ProjectID} onChange={handleChange} id="ProjectID" label="Project" isDisabled={savingResult} />
                  <TextField
                    id="MachineType"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Machine type"
                    value={inquiry.MachineType || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <InlineGrid columns={2}>
                    <BlockStack>
                      <Checkbox id="Verstuurd" label="PDF document emailed" checked={inquiry.Verstuurd} onChange={handleChange} disabled={savingResult} />
                      <Checkbox id="Ontvangen" label="Received" checked={inquiry.Ontvangen} onChange={handleChange} disabled={savingResult} />
                    </BlockStack>
                    <OwnerSelect id="GebruikerID" label="Owner" value={inquiry.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                  </InlineGrid>
                  <TextField
                    id="MachineNummer"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Machine number"
                    value={inquiry.MachineNummer || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <LinesTotalCard summary="inquiry" lines={lines} />
            <DocumentCard
              resource="INQUIRY"
              id={id}
              title="Inquiry document"
              docNumber={inquiry.Prijsaanvraagnummer}
              isDirty={isDirty}
              linesDirty={linesDirty}
              generateDocument={generateDocument}
              docTypeID={docTypeID}
              docType={docType}
              company={company}
              setResource={setInquiry}
              docLang={inquiry.TaalID}
              documentButtonLoading={savingResult || documentInProgress}
              setDocumentButtonLoading={setSavingResult}
            />
            <Card>
              <CardHeading title="Purchases" />
              <Button disabled={savingResult} onClick={() => InquiryToPurchase(inquiry, lines, company, lineInterface, navigate)}>
                Make purchase order of this inquiry
              </Button>
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Content" />
            <LinesTable
              id={id}
              data={lines}
              updateMyData={updateMyData}
              setLines={setLines}
              setLinesDirty={setLinesDirty}
              onSubmit={saveForm}
              lineInterface={lineInterface}
              resource={inquiry}
              company={company}
              disabled={savingResult}
              setIsBlurred={setIsBlurred}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
