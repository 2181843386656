import { Badge, Box, Button, DataTable, InlineStack, Link, Tooltip } from '@shopify/polaris';
import { useState } from 'react';
import { formatDate, formatToCurrency, getTableFooter, putRequest } from '../../../shared/components/functions';
import { useLookupStore } from '../../../context/useStore';
import { WalletFilledIcon, WalletIcon } from '@shopify/polaris-icons';
import { ReceiptQuickview } from './ReceiptQuickview';

export const ExpenseHistoryTable = ({ items, fetchUpdatedData }) => {
  const countries = useLookupStore((state) => countries);
  const [page, setPage] = useState(1);

  const handlePaymentUpdate = async (item) => {
    const status = !item.Uitbetaald;
    const payload = { Uitbetaald: status, UitbetaaldDatum: status ? new Date() : null };
    await putRequest(`/api/expenses/${item.OnkostenId}`, payload);
    fetchUpdatedData();
  };

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const data = JSON.parse(item.meta_data);
        const transactionId = data && data.transaction_id !== 'NO_TRANSACTION_ID' && data.transaction_id !== 'NO_RECEIPT' ? data.transaction_id : '';

        const countryISO = countries.find((c) => c.LandId === item.LandId)?.ISO2;
        const countryFlag = countryISO ? (
          <InlineStack blockAlign="center" gap="100" wrap={false}>
            <img
              src={`https://flagcdn.com/w20/${String(countryISO).toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${String(countryISO).toLowerCase()}.png 2x,https://flagcdn.com/w60/${String(countryISO).toLowerCase()}.png 3x`}
              width="18"
              height="13"
              alt={`${countryISO}`}
            />
          </InlineStack>
        ) : null;

        const paymentStatus = <Badge tone={item.Uitbetaald ? 'success' : ''}>{item.Uitbetaald ? 'paid' : 'open'}</Badge>;
        const approvalStatus = (
          <Badge tone={item.approved === true ? 'success' : item.approved === false ? 'critical' : ''}>{item.approved === true ? 'approved' : item.approved === false ? 'declined' : 'awaiting'}</Badge>
        );

        return [
          `#${item.OnkostenId}`,
          transactionId,
          <ReceiptQuickview expense={item} />,
          formatDate(item.Datum),
          countryFlag,
          item.type,
          <Link removeUnderline url={`https://planning.schuilenburg.be/day/${item.GebruikerId}/${formatDate(item.Datum)}`} target="_blank">
            {item.TimeSlotId}
          </Link>,
          item.job ? (
            <Link removeUnderline url={'/jobs/' + item.job}>
              {item.jobNumber}
            </Link>
          ) : (
            ''
          ),
          item.jobCustomer ? (
            <Link removeUnderline url={`/companies/${item.jobCustomer}`}>
              {item.jobCustomerName}
            </Link>
          ) : (
            ''
          ),
          formatToCurrency(item.Prijs),
          approvalStatus,
          paymentStatus,
          item.UitbetaaldDatum ? formatDate(item.UitbetaaldDatum) : '',
          <div style={{ margin: '-4px 0' }}>
            <Tooltip content={item.Uitbetaald ? 'Mark as unpaid' : 'Mark as paid'}>
              <Button size="slim" icon={item.Uitbetaald ? WalletFilledIcon : WalletIcon} onClick={() => handlePaymentUpdate(item)} />
            </Tooltip>
          </div>
        ];
      })
    : [];

  return (
    <Box>
      <DataTable
        stickyHeader
        increasedTableDensity
        verticalAlign="middle"
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'numeric', 'text', 'numeric']}
        headings={[
          <b>ID</b>,
          <b>Transaction ID</b>,
          <b>Receipt</b>,
          <b>Date</b>,
          <b>Country</b>,
          <b>Type</b>,
          <b>Timeslot</b>,
          <b>Job</b>,
          <b>Customer</b>,
          <b>Value</b>,
          <b>Approved</b>,
          <b>Paid</b>,
          <b>Payment date</b>,
          <b>Update</b>
        ]}
        rows={rows}
        footerContent={getTableFooter(rows, page, setPage, 'expenses')}
      />
    </Box>
  );
};
