import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, Tabs } from '@shopify/polaris';
import JobsTable from './components/jobsTable.jsx';
import { useUrl } from '../../shared/util/hanldesave';

export default function JobsPage() {
  let url = useUrl();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(url.get('tab') ? parseInt(url.get('tab')) : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate(`${window.location.pathname}?tab=${selectedTabIndex}`, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'jobs-all',
      content: 'All jobs',
      panelID: 'all-customers-content',
      comp: <JobsTable mode="all_jobs"></JobsTable>
    },
    {
      id: 'jobs-to-plan',
      content: 'Jobs to plan (since 2022)',
      panelID: 'repeat-customers-content',
      comp: <JobsTable mode="jobs_to_plan"></JobsTable>
    },
    {
      id: 'jobs-to-process',
      content: 'Jobs with technician feedback ',
      panelID: 'repeat-customers-content',
      comp: <JobsTable mode="jobs_to_process"></JobsTable>
    }
  ];

  return (
    <Page title="Jobs" fullWidth>
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].comp}
        </Tabs>
      </Card>
    </Page>
  );
}
