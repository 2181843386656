import { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getRequest } from '../../../shared/components/functions';

export default function ProductsCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/products?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };
    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var status = item.Discontinued ? <Badge tone="critical">Discontinued</Badge> : <Badge tone="success">Active</Badge>;
        return [
          <Link removeUnderline url={'/products/' + item.ProductID}>
            {item.ProductID}
          </Link>,
          item.Productref,
          item.ProductNameNL,
          status,
          formatToCurrency(item.Eenheidsaankoopprijs),
          formatToCurrency(item['Eenheids verkoopprijs'])
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'numeric']}
      headings={[<b>{'Code'}</b>, <b>{'Product reference'}</b>, <b>{'Product name'}</b>, <b>{'Status'}</b>, <b>{'Purchase price'}</b>, <b>{'Selling price'}</b>]}
      rows={rows}
    />
  ) : (
    'No products'
  );
}
