import { useMemo } from 'react';
import { useTable } from 'react-table';

export const Table = ({ tableColumns, tableData, border, padding }) => {
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => tableColumns,
    // eslint-disable-next-line
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <>
      {tableData && tableData.length > 0 && (
        <table style={{ width: '100%' }} {...getTableProps()} size="sm">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr style={{ textAlign: 'left', border: border ? '1px solid grey' : 'none' }} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps({ style: { maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width, textAlign: column.textAlign, paddingBottom: padding } })}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps({ style: { border: '1px solid black' } })}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: { maxWidth: cell.column.maxWidth, minWidth: cell.column.minWidth, width: cell.column.width, textAlign: cell.column.textAlign, paddingBottom: padding }
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};
