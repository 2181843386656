import * as Sentry from '@sentry/react';
import React from 'react';
import '@shopify/polaris/build/esm/styles.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Frame, ActionList, AppProvider, TopBar } from '@shopify/polaris';
import { useSafeLayoutEffect } from '@chakra-ui/react';
import { useState, useCallback, useEffect } from 'react';
import { Link as ReactRouterLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { PageError } from './errorboundries/pageError';
import { userMenuActions, navigationMarkup } from './shell/navigation';
import { theme } from './shell/theme';
import { TabTitle } from './shell/helmet';
import { useToggle } from './shared/useToogle';
import { formatDate, formatToCurrency } from './shared/components/functions';
import HolidayModal from './shared/components/modals/holidayModal.jsx';
import Toasts from './shared/components/toasts/toasts';
import { analytics } from './analytics/segment';
import { inProduction } from './helpers/inProduction';
import { useAuthStore, useLookupStore } from './context/useStore';
import { useDebounce } from './shared/components/inputs/useDebounce.js';

function Link({ children, url = '', external, ...rest }) {
  // Use a regular a tag for external and download links
  if (isOutboundLink(url) || rest.download) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest} className="Polaris-Link">
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

function isOutboundLink(url) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

function PageWrapper() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuthStore();
  const today = formatDate(new Date()).slice(5);
  const users = useLookupStore((state) => state.users);
  const birthdays = Array.isArray(users) ? users?.filter((u) => (!u.Inactief || u.UserType !== 7) && String(u.Birthday).slice(5) === today) : [];

  //open secondary action links in navbar in the same tab
  const links = document.getElementsByClassName('Polaris-Navigation__SecondaryAction');
  for (let i = 0, linksLength = links.length; i < linksLength; i++) {
    if (links[i].hostname === window.location.hostname) {
      links[i].target = '_self';
      links[i].rel = 'noreferrer noopener';
    }
  }

  const [holidaymodalactivated, setHolidayModalActivated] = useState(false);
  const [shotcutmodalactivated, setShotcutmodalactivated] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [toastErrorActive, setToastErrorActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFocused, setSearchFocused] = useToggle();
  const [searchResults, setSearchResults] = useState([]);
  const [userMenuActive, setUserMenuActive] = useToggle();
  const [mobileNavigationActive, setMobileNavigationActive] = useToggle();

  useSafeLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchFieldChange = async (value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  };

  const fetchData = async (abortController) => {
    try {
      const response = await fetch(`/api/search?page=1&limit=20&q=${encodeURIComponent(searchValue).replace(/_/g, '[_]')}`, { signal: abortController.signal });
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      if (error.name === 'AbortError') return;
    }
  };

  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort();
  }, [searchValue]);

  const handleLogoutAction = useCallback(async () => {
    await logout();
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    handleSearchResultsDismiss();
  }, [location.pathname, handleSearchResultsDismiss]);

  useEffect(() => {
    if (inProduction) analytics.page();
  }, [location]);

  const userMenuMarkup = user && (
    <TopBar.UserMenu
      actions={userMenuActions(handleLogoutAction, setHolidayModalActivated, holidaymodalactivated, setShotcutmodalactivated, shotcutmodalactivated)}
      name={user.username}
      open={userMenuActive}
      onToggle={setUserMenuActive}
    />
  );

  const searchResultsMarkup = (
    <ActionList
      items={searchResults.map((result) => {
        var text = '';
        var url = '';
        if (result.entity_type === 'company') {
          var city = result.helptext1 ? result.helptext1 : '';
          var companyGesperd = result.status === 2 ? ' ❌ - ' : '';
          text = `🏠 - ${companyGesperd} ${result.Name} - #${result.helptext3} ${city} (${result.helptext2})`;
          url = `/companies/${result.ID}`;
        } else if (result.entity_type === 'product') {
          text = `🛠 -  ${result.Name} - #${result.ID}, ${formatToCurrency(result.helptext2)}, ${result.helptext1}`;
          url = `/products/${result.ID}`;
        } else {
          text = `👔 - ${result.Name}- ${result.helptext1}`;
          url = `/contacts/${result.ID}`;
        }
        return { content: text, url: url };
      })}
    />
  );

  const searchFieldMarkup = (
    <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search companies, contacts and products" style={{ backgroundColor: '#bf9b5c' }} focused={searchFocused} />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle={true}
      userMenu={userMenuMarkup}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={setMobileNavigationActive}
    />
  );

  return (
    <AppProvider i18n={enTranslations} linkComponent={Link} theme={'light'}>
      <>
        <TabTitle />
        {/* <GlobalHotKeys keyMap={keyMap} handlers={handlers(setSearchFocused, navigate)} /> */}
        <Sentry.ErrorBoundary fallback={PageError} showDialog={false}>
          <Frame
            logo={theme(user).logo}
            topBar={topBarMarkup}
            navigation={navigationMarkup(location, user, birthdays)}
            showMobileNavigation={mobileNavigationActive}
            onNavigationDismiss={setMobileNavigationActive}
          >
            <Toasts toast={toastActive} setToast={setToastActive} errorToast={toastErrorActive} setErrorToast={setToastErrorActive} toastContent="Changes saved" />
            <div style={{ paddingBottom: '30px' }}>
              <Outlet />
            </div>
            <HolidayModal activated={holidaymodalactivated} toggle={setHolidayModalActivated}></HolidayModal>
            {/* <ShortcutModal activated={shotcutmodalactivated} toggle={setShotcutmodalactivated}></ShortcutModal> */}
          </Frame>
        </Sentry.ErrorBoundary>
      </>
    </AppProvider>
  );
}

export default PageWrapper;
