import { useState, useEffect } from 'react';
import { Badge, DataTable, Link, Page, Card } from '@shopify/polaris';
import { getRequest } from '../../shared/components/functions';
import { CardHeading } from '../../shared/components/cards/CardHeading';

export default function TechniciansPage() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const json = await getRequest('/api/technicians');
      setItems(json);
    };
    fetchData();
  }, []);

  function ConvertSaldo(num) {
    // var d = Math.floor(num/1440); // 60*24
    var h = Math.floor(num / 60);
    var m = Math.round(num % 60);

    return h + 'hrs ' + m + 'mins';
  }

  const rows = items.map((item, idx) => {
    var d = new Date();
    //get first day of two months ago
    d = new Date(d.getFullYear(), d.getMonth() - 1, 1);

    var locked_check = new Date(d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2));
    let compare_date = new Date(String(item.Datum).substring(0, 7));
    // console.log('compare_date', compare_date, locked_check);

    var timeslots_locked = locked_check <= compare_date ? <Badge tone="success">{String(item.Datum).substring(0, 7)}</Badge> : <Badge tone="critical">{String(item.Datum).substring(0, 7)}</Badge>;
    var overtime_saldo = ConvertSaldo(item.Saldo);

    return [
      <Link removeUnderline url={`/technicians/${item.GebruikerID}`}>
        {item.Gebruiker}
      </Link>,
      <Badge>{item.Afkorting}</Badge>,
      overtime_saldo,
      timeslots_locked
    ];
  });

  return (
    <Page
      title="Technicians"
      fullWidth
      primaryAction={{
        content: 'Open instructions manual',
        onAction: () => window.open('https://longitude.slite.com/api/s/note/Hhern88uKL7FzmCrJ7LbDz/HR-Manual', '_blank').focus()
      }}
    >
      <Card>
        <CardHeading title={'Saldo overview'} />
        <DataTable
          stickyHeader={true}
          increasedTableDensity={true}
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text']}
          headings={['Name', 'Abbreviation', 'Overtime saldo', 'Timeslots locked']}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
