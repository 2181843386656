import React, { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getDocPdfLinkForListView, getRequest } from '../../../shared/components/functions';

export default function CreditnotesCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/creditnotes?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var mail_verstuurd = item.Verstuurd ? (
          <Badge size="small" tone="info">
            Sent
          </Badge>
        ) : (
          <Badge size="small">Not sent</Badge>
        );
        var remark = item.Opmerking ? String(item.Opmerking).substring(0, 20) : '';
        return [
          <Link removeUnderline url={'/creditnotes/' + item.CreditnotaID}>
            {item.Creditnotanummer}
          </Link>,
          getDocPdfLinkForListView(item, 'credit_note', item.customer_number, item.Creditnotanummer),
          String(item.Datum).substring(0, 10),
          getDocPdfLinkForListView(item, 'invoice', item.customer_number, item.invoice_number),
          item.Referentie,
          remark,
          mail_verstuurd,
          formatToCurrency(item.BedragTotaal),
          formatToCurrency(item.BedragOpen)
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric']}
      headings={[
        <b>{'Number'}</b>,
        <b>{'PDF'}</b>,
        <b>{'Date'}</b>,
        <b>{'Invoice PDF'}</b>,
        <b>{'Reference customer'}</b>,
        <b>{'Remarks'}</b>,
        <b>{'Mail'}</b>,
        <b>{'Value total'}</b>,
        <b>{'Value open'}</b>
      ]}
      rows={rows}
    />
  ) : (
    'No credit notes'
  );
}
