import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Flex,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useColorModeValue,
  Stack,
  Checkbox
} from '@chakra-ui/react';
import { addContact, addCompany } from '../../../shared/components/functions';
import { useField } from 'formik';
import ContactForms from './newContact';
import CombinedForm from './newCompany';

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isRequired={props.isRequired} aria-required="true" isInvalid={meta.touched && meta.error}>
      <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
      <Input className="text-input" {...field} {...props} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const SelectInput = ({ label, options, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isRequired={props.isRequired} aria-required="true" isInvalid={meta.touched && meta.error}>
      <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
      <Select {...field} {...props}>
        {options}
      </Select>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const TextareaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl aria-required="true" isInvalid={meta.touched && meta.error}>
      <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
      <Textarea className="text-input" {...field} {...props} />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export const CheckboxInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <FormControl isInvalid={meta.touched && meta.error}>
      <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>
      <Stack spacing={5} direction="row">
        <Checkbox size="lg" id="Greg" onChange={props.handleChange}>
          Greg
        </Checkbox>
        <Checkbox size="lg" id="Jack" onChange={props.handleChange}>
          Jack
        </Checkbox>
      </Stack>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};

export default function CombinedForms({ newCompany, newContact, company, isLoading, setIsLoading, pubs }) {
  const location = useLocation();
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState(false);
  const { isOpen, onOpen } = useDisclosure();

  async function handleSubmit(values) {
    if (newCompany) {
      addCompany({ ...values, companyNAAM: company.NAAM }, pubs, setIsLoading, toast, setIsDisabled, onOpen, location);
    } else {
      addContact({ ...values, KLANR: company.FIRNR }, pubs, setIsLoading, toast, setIsDisabled, onOpen, location);
    }
  }

  const SubmitButton = (
    <Flex direction="row-reverse">
      <Button disabled={isDisabled} type="submit" color="black" isLoading={isLoading} size="lg">
        Submit
      </Button>
    </Flex>
  );

  const combinedForm = newCompany ? <CombinedForm handleSubmit={handleSubmit} SubmitButton={SubmitButton} /> : null;
  const onlyContact = newContact && !newCompany ? <ContactForms handleSubmit={handleSubmit} SubmitButton={SubmitButton} /> : null;

  return (
    <div>
      {combinedForm}
      {onlyContact}
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => (window.location.href = `/fairs/form${location.search}`)}>
        <ModalOverlay color="white" bgColor={useColorModeValue('sm', 'sm-dark')} />
        <ModalContent p={3}>
          <ModalHeader>Thank you! 🎉 🎉 </ModalHeader>
          <ModalCloseButton />
          <ModalBody>We successfully saved your information.</ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              size="lg"
              onClick={() => {
                window.location.href = `/fairs/form${location.search}`;
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
