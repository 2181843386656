import { formatHtml } from './functions';
import React from 'react';

export const FormattedHTML = ({ html }) => {
  const formatted = formatHtml(html);
  return formatted.split('\n').map((l, idx) => (
    <React.Fragment key={idx}>
      {l}
      {idx !== formatted.length - 1 && <br />}
    </React.Fragment>
  ));
};
