import { Box, InlineStack, Link, Text } from '@shopify/polaris';
import Select from 'react-select';

export const ReactSelect = ({ id, label, placeholder, options, savingResult, handleChange, value, disabled, labelAction }) => {
  return (
    <Box>
      <Box paddingBlockEnd="100">
        <InlineStack align="space-between">
          {label && (
            <Box>
              <Text>{label}</Text>
            </Box>
          )}
          {labelAction && (
            <Link removeUnderline onClick={labelAction.onAction}>
              {labelAction.content}
            </Link>
          )}
        </InlineStack>
      </Box>

      <Select
        id={id}
        placeholder={placeholder}
        options={options}
        onChange={(e) => handleChange(e.value, id)}
        value={options.find((c) => c.value === value) || ''}
        isDisabled={disabled || savingResult}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
      />
    </Box>
  );
};
