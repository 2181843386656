import { Modal, DataTable, Text } from '@shopify/polaris';
import { useLookupStore } from '../../../context/useStore';

export const BelgiumZipModal = ({ active, setActive }) => {
  const zipcodes = useLookupStore((state) => state.zipcodes);

  return (
    <Modal open={active} onClose={() => setActive(false)} title="Overview of all Belgian zip codes">
      <Modal.Section>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text']}
          headings={['ZIP', 'City']}
          rows={zipcodes.sort((a, b) => (a.Plaatsnaam > b.Plaatsnaam ? 1 : b.Plaatsnaam > a.Plaatsnaam ? -1 : 0)).map((zip) => [zip.Postcode, zip.Plaatsnaam])}
        />
      </Modal.Section>
    </Modal>
  );
};

export const USZipModal = ({ active, setActive }) => {
  return (
    <Modal open={active} onClose={() => setActive(false)} title="Formatted US ZIP codes">
      <Modal.Section>
        <Text>Please enter the company's ZIP code in this format:</Text>
        <Text fontWeight="semibold">XX 12345</Text>
        <Text>or</Text>
        <Text fontWeight="semibold">XX 12345-6789</Text>
      </Modal.Section>
    </Modal>
  );
};
