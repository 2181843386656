import { Card, Text, Layout, Link, Box } from '@shopify/polaris';
import { formatToCurrency } from '../../shared/components/functions';

export default function MetricsCard({ metrics }) {
  return (
    <Box paddingBlockEnd="400">
      <Layout>
        <Layout.Section variant="oneThird">
          <Card>
            <Link removeUnderline url={`/invoices?tab=2`}>
              {metrics[0]?.metric_value} Expired outgoing invoices
            </Link>
            {metrics[1]?.metric_value && <Text>{formatToCurrency(metrics[1]?.metric_value)}</Text>}
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Card>
            <Link removeUnderline url={`/invoices_in?tab=2&Vervallen=1`}>
              {metrics[2]?.metric_value} Expired incoming invoices
            </Link>
            {metrics[3]?.metric_value && <Text>{formatToCurrency(metrics[3]?.metric_value)}</Text>}
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Card>
            <Link removeUnderline url={`/jobs?tab=1`}>
              Total Jobs to plan
            </Link>
            <Text>{metrics[4]?.metric_value}</Text>
          </Card>
        </Layout.Section>
      </Layout>
    </Box>
  );
}
