import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from './functions';

export default function ProductSelect({ value, onChange, disabled }) {
  /*
        value: initial id of the selected product, component will search the label for the given value id
        id: id is the property name of the object
        onChange: callback function to be called when the selected company changes

        returns: a react component that renders a select box with the list of companies that is loaded aysncronously
    */

  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (value && !isNaN(Number(value)) && Number.isInteger(Number(value))) {
        const data = await getRequest(`/api/products/${value}`);
        if (data) {
          //FIXME: ugly hack to make it work
          setSelectValue({ value: data.ProductID, label: data.ProductNameNL });
        }
      }
    }
    fetchData();
  }, [value]);

  const loadOptions = async (inputValue, callback) => {
    //calls the api companies for this inputvalue
    const data = await getRequest(`/api/products/simple/search?q=ProductNameNL:${inputValue}`);
    const options = data.map((product) => {
      return {
        value: product.ProductID,
        label: product.Discontinued ? `${product.ProductNameNL} 🔴 ` : `${product.ProductNameNL}`
      };
    });
    callback(options);
  };

  return (
    <AsyncSelect
      value={selectValue}
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
      styles={{
        valueContainer: (base) => ({ ...base, height: '32px' }),
        indicatorSeperator: (base) => ({ ...base, height: '32px' }),
        indicatorsContainer: (base) => ({ ...base, height: '32px' }),
        control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: disabled ? '#ebebeb' : '#919191' }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
      }}
      defaultOptions
      isClearable
      isDisabled={disabled}
      onChange={(props) => {
        if (props) {
          const { value, label } = props;
          setSelectValue({ value: value, label: label });
          onChange(value);
        } else {
          setSelectValue({ value: null, label: null });
          onChange(null);
        }
      }}
    />
  );
}
