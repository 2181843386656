import { ContextualSaveBar, Modal } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
//still marked as unstable as long as they collect user feedback on the feature
import { unstable_useBlocker as useBlocker, useLocation } from 'react-router-dom';

export const TheSaveBar = ({ isDirty, handleSave, handleDiscard, savingResult, disabled }) => {
  const location = useLocation();
  const [isBlocking, setIsBlocking] = useState(isDirty); //so navigating on handleSave() can happen while isDirty is still true
  let blocker = useBlocker(isBlocking);

  const save = () => {
    setIsBlocking(false);
    handleSave();
  };

  const discard = () => {
    setIsBlocking(false);
    if (window.location.pathname.split('/')[2] === 'new') {
      //if in new resource go one page back in history
      window.history.back();
      handleDiscard();
    } else {
      handleDiscard();
    }
  };

  useEffect(() => {
    handleLocationChange(location.pathname);
    setIsBlocking(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, location.pathname]);

  // ALERT for route change:

  function BlockNavigation({ blocker }) {
    if (blocker.state === 'blocked') {
      return (
        <Modal
          open={blocker.state === 'blocked'}
          onClose={() => blocker.reset()}
          title="Attention!"
          primaryAction={{
            content: 'Stay on form',
            onAction: () => blocker.reset()
          }}
          secondaryActions={{
            content: 'Leave anyway',
            onAction: () => blocker.proceed()
          }}
        >
          <Modal.Section>Unsaved changes will be lost!</Modal.Section>
        </Modal>
      );
    }
  }

  // PROMPT for closing/reloading the tab (independent from blocker):

  const handleLocationChange = () => {
    window.onbeforeunload = () => {
      if (isDirty) return 'message';
      else window.onbeforeunload = null;
    };
  };

  // HOT KEYS:
  const keyMap = { ENTER_SAVE_BAR: 'Return' };
  const handlers = { ENTER_SAVE_BAR: save };

  return isDirty ? (
    <>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      {blocker ? <BlockNavigation blocker={blocker} /> : null}
      <ContextualSaveBar
        message={`Unsaved changes. ${disabled ? 'Please make sure your input is correct and click anywhere to proceed.' : 'Click save or press enter.'}`}
        saveAction={{ onAction: save, loading: savingResult, disabled: disabled }}
        fullWidth={false}
        alignContentFlush
        discardAction={{ onAction: discard }}
      />
    </>
  ) : null;
};
