import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { Form, Select, FormLayout, Layout, Page, Card, TextField, Checkbox, Button, BlockStack, InlineGrid, DataTable, Link } from '@shopify/polaris';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { alphabetize, postRequest, getRequest, simplePostRequest } from '../../shared/components/functions.jsx';
import { updateMyData } from '../../shared/util/updateLines';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import { QuotationDuplicate, QuotationToSale } from '../../shared/components/transformers';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import CompanySelect from '../../shared/components/companySelect';
import ProjectSelect from '../../shared/components/projectSelect.jsx';
import WeightCard from '../../shared/components/cards/WeightCard.jsx';
import LinesTotalCard from '../../shared/components/cards/LinesTotalsCard.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import LinesTable from '../../shared/components/linesTable';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas.js';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';

/*
  FIXME:
  - figure out if the statusID is referencing the ID from the lookup table or the sequence, now configured as the sequence, but i not sure this is right
  - in access there is a field for the bestelling, what is this usefull for, still used actively?
  - vervangofferte ID validation to add according following access logic:
    If vMsgBox = Empty And Ontvangen And OfferteStatusID < 2 Then vMsgBox = "De status van de offerte is niet correct."
    If vMsgBox = Empty And OfferteStatusID = 3 And IsNull(VervangingsOfferteID) Then vMsgBox = "Het nieuwe offertenummer is verplicht." 'Vervangen
    If vMsgBox = Empty And OfferteStatusID = 4 And IsNull(BestellingID) Then vMsgBox = "Het nummer van de bestelling is verplicht." 'Besteld

  IMPROVEME:
  - MachinetypeID is declared in db but nothing is done with it
*/

export default function QuotationPage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const docTypeID = 5;
  const docType = 'quotation';
  const proformaDocTypeID = 18;
  const proformaDocType = 'proforma';
  let location = useLocation();
  const { user } = useAuthStore();
  const { countries, languages, paymentconditions, quotationstatuses } = useLookupStore();

  const [savingResult, setSavingResult] = useState(false);
  const [inquiry, setInquiry] = useState({});
  const [initialInquiry, setInitialInquiry] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [sales, setSales] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [linesDirty, setLinesDirty] = useState(url.get('linesDirty') || false);
  const [company, setCompany] = useState({});
  const [isBlurred, setIsBlurred] = useState(true);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const pageTitle = getPageTitle(inquiry.Offertenummer, 'Quotation');
  const quotationSchema = getSchema('quotation', url, null, user);
  const enableCustomerSelect = url.get('duplicate') || url.get('fromSale') || false;

  const lineInterface = {
    lineIsDirty: false,
    Lijnnr: null,
    offertenr: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: null,
    VP: null,
    aantal: 1,
    BTW: company.LandID === 300 ? 21 : null,
    MuntVP: null,
    AP: null,
    GetDetails: null,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': null,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const handleChange = (value, id) => {
    if (id === 'refklantdatum' && (!value || value === '')) value = null;
    setInquiry((inquiry) => ({ ...inquiry, [id]: value }), setIsDirty(true));
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setInquiry(quotationSchema.default());
        setIsBlurred(true);
        if (location.state) {
          setLines(location.state);
          setLinesDirty(true);
        }
      } else {
        const data = await getRequest(`/api/quotations/${id}`);
        if (!data) {
          if (location.state) {
            setLines(location.state);
            setLinesDirty(true);
          }
          return setInquiry(quotationSchema.default());
        }
        setInquiry(data);
        setInitialInquiry(data);

        const data2 = await getRequest(`/api/quotations/${id}/lines`);
        if (url.get('appendLines') === 'true') {
          setLines(() => [...data2, ...location.state]);
          setInitialLines(() => [...data2, ...location.state]);
          setLinesDirty(true);
        } else {
          setLines(data2);
          setInitialLines(data2);
        }

        setSales(await getRequest(`/api/quotations/${id}/sales`));
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/companies/${inquiry.klantnummer}`);
      setCompany(data);
      setInquiry({ ...inquiry, TaalID: data.TaalID });

      if (company.LandID && data.LandID !== company.LandID) {
        let updatedLines = [];
        if (data.LandID === 300) updatedLines = lines.map((l) => ({ ...l, BTW: 21 }));
        else updatedLines = lines.map((l) => ({ ...l, BTW: null }));
        setLines(updatedLines);
      }
    }
    if (inquiry.klantnummer) fetchData();
  }, [inquiry.klantnummer, company.LandID]);

  const generateDocument = async () => {
    const getBetalingIdentifier = {
      1: 'betalingNL',
      2: 'betalingFR',
      3: 'betalingUK',
      4: 'betalingDE'
    };

    const document = {
      addressee: {
        FIRNR: inquiry.klantnummer,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      eu: false,
      language: inquiry.TaalID ? languages.find((l) => l.TaalID === parseInt(inquiry.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      id: inquiry.offertenr,
      typeID: docTypeID,
      number: inquiry.Offertenummer,
      reference: inquiry.refklant,
      reference_date: inquiry.refklantdatum ? String(inquiry.refklantdatum).substring(0, 10) : '',
      date: String(inquiry.datum).substring(0, 10),
      payment_condition: paymentconditions.find((pc) => pc.betalingID === company.betalingID)[getBetalingIdentifier[inquiry.TaalID]],
      delivery_term: inquiry.leveringstermijn,
      validity: inquiry.Angebot, //Thi is a unique field for a quoatation
      machine_type: inquiry.machinetype,
      machine_number: inquiry.machinenunner,
      prices: inquiry.DocumentPrijzen
    };
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  const generateProFormaDocument = async () => {
    const getBetalingIdentifier = {
      1: 'betalingNL',
      2: 'betalingFR',
      3: 'betalingUK',
      4: 'betalingDE'
    };

    const document = {
      addressee: {
        FIRNR: inquiry.klantnummer,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      eu: false,
      language: languages.find((l) => l.TaalID === parseInt(inquiry.TaalID))?.Taal,
      items: lines,
      type: proformaDocType,
      id: inquiry.offertenr,
      typeID: proformaDocTypeID,
      number: inquiry.Offertenummer,
      reference: inquiry.refklant,
      reference_date: inquiry.refklantdatum ? String(inquiry.refklantdatum).substring(0, 10) : '',
      date: String(inquiry.datum).substring(0, 10),
      payment_condition: paymentconditions.find((pc) => pc.betalingID === company.betalingID)[getBetalingIdentifier[inquiry.TaalID]],
      delivery_term: inquiry.leveringstermijn,
      validity: inquiry.Angebot,
      machine_type: inquiry.machinetype,
      machine_number: inquiry.machinenunner
    };
    const data = await postRequest(`/api/docgen/`, document);
    return data;
  };

  async function saveForm() {
    const errorInSchema = await validateSchema(quotationSchema, inquiry);
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        await handleSave(
          id,
          'quotations',
          inquiry.offertenr,
          inquiry,
          setInquiry,
          setInitialInquiry,
          setToast,
          setToastError,
          isDirty,
          setIsDirty,
          linesDirty,
          lines,
          setLines,
          setInitialLines,
          setLinesDirty,
          setSavingResult,
          navigate
        );

        // auto generate pdf so latest changes are reflected in pdf
        if (id && id !== 'new') await generateDocument();
        setIsBlurred(false);
      } else {
        alert(errorInSchema);
      }
    }
  }

  return (
    <Page title={pageTitle} fullWidth secondaryActions={[{ content: 'Create duplicate', onAction: () => QuotationDuplicate(inquiry, lines, navigate), disabled: id === 'new' }]}>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Quotation saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        savingResult={savingResult}
        handleDiscard={() => handleDiscard(initialInquiry, initialLines, setInquiry, setLines, setIsDirty, setLinesDirty)}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <Form onSubmit={saveForm}>
                <FormLayout>
                  <CompanySelect isClearable id="klantnummer" label="Customer" value={company.FIRNR} onChange={handleChange} isDisabled={savingResult || !enableCustomerSelect} />
                  <FormLayout.Group>
                    <TextField id="Bestellingnummer" disabled label="Number" value={inquiry.Offertenummer ? String(inquiry.Offertenummer) : 'created automatically'} />
                    <CompanySelect isClearable id="LeverancierID" label="Supplier" value={inquiry.LeverancierID} onChange={handleChange} isDisabled={savingResult} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField id="datum" maxLength={60} type="date" autoComplete="off" label="Date" value={String(inquiry.datum).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                    <TextField
                      id="refklant"
                      maxLength={100}
                      showCharacterCount
                      type="text"
                      autoComplete="off"
                      label="Reference customer"
                      value={inquiry.refklant || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      id="TaalID"
                      maxLength={60}
                      autoComplete="off"
                      label="Language"
                      value={parseInt(inquiry.TaalID) || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                      options={languages.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                    />
                    <TextField
                      id="refklantdatum"
                      maxLength={60}
                      type="date"
                      autoComplete="off"
                      label="Customer date"
                      value={String(inquiry.refklantdatum).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <ProjectSelect label="Project" value={inquiry.ProjectID} onChange={handleChange} id="ProjectID" disabled={savingResult} />
                    <TextField
                      id="machinetype"
                      maxLength={50}
                      showCharacterCount
                      autoComplete="off"
                      label="Machine type"
                      value={inquiry.machinetype || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <InlineGrid columns={2}>
                      <BlockStack>
                        <Checkbox id="Verstuurd" label="PDF Document emailed" checked={inquiry.Verstuurd} onChange={handleChange} disabled={savingResult} />
                        <Checkbox id="Ontvangen" label="Received" checked={inquiry.Ontvangen} onChange={handleChange} disabled={savingResult} />
                      </BlockStack>
                      <OwnerSelect id="GebruikerID" label="Owner" value={inquiry.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                    </InlineGrid>
                    <TextField
                      id="machinenunner"
                      maxLength={50}
                      showCharacterCount
                      autoComplete="off"
                      label="Machine number"
                      value={inquiry.machinenunner || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="leveringstermijn"
                      maxLength={50}
                      showCharacterCount
                      autoComplete="off"
                      label="Delivery term"
                      value={inquiry.leveringstermijn || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField id="BestellingID" maxLength={50} autoComplete="off" label="Purchase" disabled value={inquiry.BestellingID || ''} onChange={handleChange} />
                    <TextField id="Angebot" maxLength={50} showCharacterCount autoComplete="off" label="Aanbod" value={inquiry.Angebot || ''} onChange={handleChange} disabled={savingResult} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      id="OfferteStatusID"
                      maxLength={60}
                      autoComplete="off"
                      label="Status"
                      value={parseInt(inquiry.OfferteStatusID) || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                      options={quotationstatuses.sort((a, b) => alphabetize(a, b, 'offertestatustekst')).map((status) => ({ value: status.offertestatusnr, label: status.offertestatustekst }))}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="DocumentPrijzen"
                      label="Prijzen on document"
                      value={inquiry.DocumentPrijzen || ''}
                      multiline={4}
                      onChange={handleChange}
                      disabled={savingResult}
                      placeholder="Edit the price section in the document's terms and conditions block."
                    />
                  </FormLayout.Group>
                </FormLayout>
              </Form>
            </Card>
            <WeightCard lines={lines} />
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <LinesTotalCard summary="quotation" lines={lines} />
            <DocumentCard
              resource="QUOTATION"
              id={id}
              title="Quotation document"
              docNumber={inquiry.Offertenummer}
              isDirty={isDirty}
              linesDirty={linesDirty}
              generateDocument={generateDocument}
              docTypeID={docTypeID}
              docType={docType}
              company={company}
              setResource={setInquiry}
              docLang={inquiry.TaalID}
              documentButtonLoading={savingResult || documentInProgress}
              setDocumentButtonLoading={setSavingResult}
            />
            <DocumentCard
              resource="PROFORMA"
              id={id}
              title="Proforma invoice document"
              docNumber={inquiry.Offertenummer}
              isDirty={isDirty}
              linesDirty={linesDirty}
              generateDocument={generateProFormaDocument}
              docTypeID={proformaDocTypeID}
              docType={proformaDocType}
              company={company}
              setResource={setInquiry}
              docLang={inquiry.TaalID}
              documentButtonLoading={savingResult || documentInProgress}
              setDocumentButtonLoading={setSavingResult}
            />
            <Card>
              <CardHeading title="Sales document" />
              <Button disabled={savingResult} onClick={() => QuotationToSale(inquiry, lines, company, lineInterface, navigate)}>
                Make a sale for the quotation
              </Button>
              {sales.length > 0 && (
                <DataTable
                  stickyHeader
                  increasedTableDensity
                  columnContentTypes={['text']}
                  headings={['Sale']}
                  rows={sales.map((sale) => [
                    <Link removeUnderline url={`/sales/${sale.OrderbevestigingID}`}>
                      {sale.Orderbevestigingnummer}
                    </Link>
                  ])}
                />
              )}
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Content" />
            <LinesTable
              id={id}
              data={lines}
              updateMyData={updateMyData}
              setLines={setLines}
              setLinesDirty={setLinesDirty}
              onSubmit={saveForm}
              lineInterface={lineInterface}
              resource={inquiry}
              company={company}
              disabled={savingResult}
              setIsBlurred={setIsBlurred}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
