import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getRequest } from './functions';
import { InlineStack, Text } from '@shopify/polaris';

export default function InvoiceSelect({ id, label, value, onChange, disabled }) {
  /*
        value: initial id of the selected invoice, component will search the label for the given value id
        id: id is the property name of the object
        onChange: callback function to be called when the selected company changes

        returns: a react component that renders a select box with the list of companies that is loaded aysncronously
    */

  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (value) {
        const data = await getRequest(`/api/invoices/${value}`);

        if (data) {
          //FIXME: ugly hack to make it work
          setSelectValue({ value: data.faktuurnr, label: data.Factuurnummer });
        }
      }
    }
    fetchData();
  }, [value]);

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      const data = await getRequest(`/api/invoices/simple/search?Factuurnummer:${inputValue}`);
      const options = data.map((invoice) => {
        return {
          value: invoice.faktuurnr,
          label: invoice.Factuurnummer
        };
      });
      callback(options);
    }
  };

  return (
    <>
      {label ? (
        <div style={{ marginBottom: '4px' }}>
          <InlineStack align="space-between">
            <Text>{label}</Text>
          </InlineStack>
        </div>
      ) : null}

      <AsyncSelect
        id="InvoiceSelect"
        value={selectValue}
        loadOptions={loadOptions}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: disabled ? '#ebebeb' : '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
        defaultOptions
        onChange={(props) => {
          if (props) {
            const { value, label } = props;
            setSelectValue({ value: value, label: label });
            onChange(value, id);
          } else {
            setSelectValue({ value: null, label: null });
            onChange(null, id);
          }
        }}
      />
    </>
  );
}
