import { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getRequest } from '../../../shared/components/functions';

export default function ProjectsCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/projects?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };
    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var omschrijving = item.omschrijving;
        omschrijving = String(omschrijving).substring(0, 40);
        return [
          <Link removeUnderline url={'/projects/' + item.ID}>
            {item.Projectnummer}
          </Link>,
          String(item['aanvraag offerte']).substring(0, 10),
          omschrijving,
          String(item.supplier).substring(0, 25),
          formatToCurrency(item.waarde),
          item.Gebruiker,
          <Badge>{item.offertestatustekst}</Badge>,
          item['%aankoopkans'] + '%',
          item['%aankoopbijons'] + '%'
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'numeric', 'text', 'text', 'numeric', 'numeric']}
      headings={[<b>{'Number'}</b>, <b>{'Request'}</b>, <b>{'Description'}</b>, <b>{'Supplier'}</b>, <b>{'Value'}</b>, <b>{'Owner'}</b>, <b>{'Status'}</b>, <b>{'Buy %'}</b>, <b>{'Buy % us'}</b>]}
      rows={rows}
    />
  ) : (
    'No projects'
  );
}
