import { useState, useEffect } from 'react';
import { Modal, TextField, Banner, DescriptionList } from '@shopify/polaris';
import { formatToCurrency, getCurrenctDatetimeYYYMMDD, putRequest } from '../../../shared/components/functions';
import { performTracking } from '../../../analytics/segment';
import IncasserenSelect from '../../../shared/components/incasserenSelect';

export default function InvoiceCollection({ modalOpen, setModalOpen, fullInvoiceCollection, collectionInvoice, fetchData }) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState({
      datumbetaling: fullInvoiceCollection ? getCurrenctDatetimeYYYMMDD() : null,
      bedragopen: fullInvoiceCollection ? 0 : Math.round(collectionInvoice.bedragopen * 100) / 100,
      Incasseren: fullInvoiceCollection ? 'BELFIUS' : null,
      faktuurnr: collectionInvoice.faktuurnr,
      memo: collectionInvoice.memo || '',
      Verstuurd: fullInvoiceCollection ? 1 : collectionInvoice.Verstuurd,
      Vervallen: fullInvoiceCollection ? 0 : collectionInvoice.Vervallen,
      status: fullInvoiceCollection ? 9 : collectionInvoice.status,
      partiallyPaid: 0
    });
    //eslint-disable-next-line
  }, [fullInvoiceCollection, collectionInvoice]);

  const handleChange = (value, id) => {
    if (id === 'partiallyPaid') {
      setState((state) => ({
        ...state,
        bedragopen: collectionInvoice.bedragopen - Math.round(parseFloat(String(value).replace(',', '.')) * 100) / 100
      }));
    }

    setState((state) => ({ ...state, [id]: value }));
  };

  const collectInvoice = async () => {
    setLoading(true);
    await putRequest(`/api/invoices/${state.faktuurnr}`, state);
    setModalOpen(false);
    await fetchData();
    if (fullInvoiceCollection) {
      performTracking('payment_collected_invoice_full', state);
    } else {
      performTracking('payment_collected_invoice_partial', state);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title={`Payment collection for invoice #${collectionInvoice.Factuurnummer}`}
      primaryAction={{
        content: fullInvoiceCollection ? 'Collect payment' : 'Collect partial payment',
        onAction: () => collectInvoice(),
        loading
      }}
      secondaryActions={[{ content: 'Cancel', onAction: () => setModalOpen(false) }]}
    >
      <Modal.Section>
        {fullInvoiceCollection ? (
          <Banner tone="info">The invoice status will be automatically set to paid. And the open amount to 0.</Banner>
        ) : (
          <Banner tone="warning">The invoice status will not be set to paid. Please set the remaining open value yourself.</Banner>
        )}
        <br />
        <DescriptionList
          items={[
            { term: 'Invoice number', description: `#${collectionInvoice.Factuurnummer}` },
            { term: 'Invoice reference', description: collectionInvoice.refklant },
            { term: 'Company', description: collectionInvoice.klantnaam },
            { term: 'Total value', description: formatToCurrency(collectionInvoice.totaalbedrag) }
          ]}
        />
        <br />
        <br />
        {fullInvoiceCollection && (
          <>
            <TextField id="datumbetaling" type="date" label="Payment date" value={String(state.datumbetaling).substring(0, 10)} onChange={handleChange} />
            <br />
          </>
        )}
        {!fullInvoiceCollection && (
          <>
            <TextField
              prefix="€"
              id="partiallyPaid"
              disabled={fullInvoiceCollection}
              type="currency"
              label="AMOUNT THAT IS PARTIALLY PAID"
              value={String(state.partiallyPaid)}
              onChange={handleChange}
            />
            <br />
          </>
        )}
        <TextField id="bedragopen" prefix="€" disabled type="number" label="Open amount" value={String(state.bedragopen)} onChange={handleChange} />
        <br />
        {fullInvoiceCollection && (
          <>
            <IncasserenSelect id="Incasseren" value={state.Incasseren} handleChange={handleChange} />
            <br />
          </>
        )}
        <TextField multiline={5} id="memo" label="Note" value={state.memo} onChange={handleChange} />
        <br />
      </Modal.Section>
    </Modal>
  );
}
