import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, DataTable, Page, Link, Badge, Card } from '@shopify/polaris';
import { getTableFooter, truncate, fetchTableData } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { useLookupStore } from '../../context/useStore.js';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';
import { CountryWithFlag } from '../../shared/components/countryWIthFlag.jsx';

export default function ContactsPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const choices = useLookupStore((state) => state.pubcodes).map((item) => ({ label: `${item.kode} (${item.omschrijving})`, value: `${item.counter}` }));
  const companyCountryChoices = useLookupStore((state) => state.countries).map((item) => ({ label: `${item.Land}`, value: `${item.LandId}` }));

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    status: { type: 'status', name: 'Status', value: url.get('status') || 'all' },
    pubcode: { type: 'multiple', name: 'Pub codes', value: url.get('pubcode') ? JSON.parse(url.get('pubcode')) : null, choices: choices },
    name: { type: 'text', name: 'Name', value: url.get('name') || '' },
    email: { type: 'text', name: 'E-mail', value: url.get('email') || '' },
    function: { type: 'text', name: 'Function', value: url.get('function') || '' },
    company: { type: 'text', name: 'Company', value: url.get('company') || '' },
    companyCountry: { type: 'multiple', name: 'Company country', value: url.get('companyCountry') ? JSON.parse(url.get('companyCountry')) : null, choices: companyCountryChoices },
    companyStreet: { type: 'text', name: 'Company street', value: url.get('companyStreet') || '' },
    companyZip: { type: 'text', name: 'Company ZIP', value: url.get('companyZip') || '' },
    companyRemark: { type: 'text', name: 'Company remark', value: url.get('companyRemark') || '' },
    description: { type: 'text', name: 'Description', value: url.get('description') || '' },
    tel: { type: 'text', name: 'Tel', value: url.get('tel') || '' },
    gsm: { type: 'text', name: 'GSM', value: url.get('gsm') || '' }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/contacts', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const getPubTags = (pubs) => (pubs ? pubs.map((item, idx) => <Badge key={idx}>{item.kode}</Badge>) : null);

  const rows = Array.isArray(items)
    ? items.map((item) => [
        <Link removeUnderline url={'/contacts/' + item.CONNR}>
          {item.CONNR}
        </Link>,
        item.NAAM,
        item['E-mail'],
        item.DEPT,
        <Link removeUnderline url={'/companies/' + item.KLANR}>
          {truncate(item.company, 30)}
        </Link>,
        <CountryWithFlag countryId={item.companyLandID} />,
        item.companyStreet,
        item.companyZip,
        truncate(item.companyRemark, 15),
        item.TEL,
        getPubTags(JSON.parse(item.serialized_pubs)),
        item.DISCONTINUED ? <Badge tone="critical">Discontinued</Badge> : <Badge tone="success">Active</Badge>
      ])
    : [];

  return (
    <Page title="Contacts" fullWidth>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter contacts on name, function, company name, description, TEL, email and GSM"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={[
            <b>Number</b>,
            <b>Contact name</b>,
            <b>Email</b>,
            <b>Function</b>,
            <b>Company</b>,
            <b>Company country</b>,
            <b>Company street</b>,
            <b>Company ZIP</b>,
            <b>Company remarks</b>,
            <b>Tel</b>,
            <b>Pubs</b>,
            <b>Status</b>
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'contacts')}
        />
      </Card>
    </Page>
  );
}
