import React from 'react';
import { formatToCurrency } from '../../../shared/components/functions';

export default function ProjectProfitLine({ totalInvoicedOut, project }) {
  /*
    This component is only rendered when a project is oredered,
    it gives an indication on how much is already invoiced out of the total project budget.

    It should turn red if not yet everything is invoiced, green if at least the project value is invoiced out. 
  */

  //invoiced percentage rounded to 2 decimals
  const invoicedPercentage = Math.round((totalInvoicedOut / project.waarde) * 100);
  let invoicedPercentageFill = Math.round((totalInvoicedOut / project.waarde) * 100);
  if (invoicedPercentageFill > 100) {
    invoicedPercentageFill = 100;
  }

  return (
    <>
      <div style={{ fontSize: '18px', marginBottom: '10px' }}>{`Invoiced percentage of the total value of the project: ${invoicedPercentage}%`}</div>
      <div style={{ width: '100%', height: '30px', color: 'white', fontSize: '18px', textAlign: 'center', fontWeight: '700', position: 'relative' }}>
        <div style={{ width: '100%', height: '30px', backgroundColor: '#FF6666', borderRadius: '5px', position: 'absolute' }}></div>
        <div style={{ width: invoicedPercentageFill + '%', height: '30px', backgroundColor: '#53BD7F', borderRadius: '5px', position: 'absolute', zIndex: '1', opacity: '1' }}></div>
        <div style={{ width: '100%', height: '30px', borderRadius: '5px', position: 'absolute', zIndex: '2', padding: '5px', fontWeight: '800', fontSize: '22px' }}>
          {`${formatToCurrency(totalInvoicedOut)} / ${formatToCurrency(project.waarde)}`}
        </div>
      </div>
    </>
  );
}
