import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Page, Card, Link, Tooltip, Button } from '@shopify/polaris';
import { getTableFooter, formatToCurrency, truncate, getDocPdfLinkForListView, getModalButton, fetchTableData, getRequest, filtersToApiUrl, getUserOrg } from '../../shared/components/functions.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../shared/components/listFilter.jsx';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { emailSentBadge } from '../../shared/components/badges/badges.jsx';
import CreditnoteCollection from './components/creditnoteCollection';
import { CreditCardIcon, CreditCardPercentIcon } from '@shopify/polaris-icons';
import QuickViewModal from '../../shared/components/modals/quickViewModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';

export default function CreditnotesPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [invoiceCollectionModalOpen, setInvoiceCollectionModalOpen] = useState(false);
  const [collectionInvoice, setCollectionIvoice] = useState({});
  const [fullInvoiceCollection, setFullInvoiceCollection] = useState(false); //full if fully paid
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    date: { type: 'date', name: 'Date', value: url.get('date') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    refCustomer: { type: 'text', name: 'Reference customer', value: url.get('refCustomer') || '' },
    remarks: { type: 'text', name: 'Remarks', value: url.get('remarks') || '' },
    mail: { type: 'status', name: 'PDF emailed', value: url.get('mail') || 'all' },
    valueTotal: { type: 'range', name: 'Value total', value: url.get('valueTotal') ? JSON.parse(url.get('valueTotal')) : null, rangeBottom: 0, rangeTop: 400000, prefix: '€' },
    valueOpen: { type: 'range', name: 'Value open', value: url.get('valueOpen') ? JSON.parse(url.get('valueOpen')) : null, rangeBottom: 0, rangeTop: 400000, prefix: '€' },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/creditnotes', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  async function fetchCreditnotes() {
    const data = await getRequest(`/api/creditnotes?page=${page}&limit=100&${filtersToApiUrl(filters)}`);
    setItems(data);
  }

  const rows = Array.isArray(items)
    ? items.map((item) => [
        <Link removeUnderline url={'/creditnotes/' + item.CreditnotaID}>
          {item.Creditnotanummer}
        </Link>,
        getModalButton(item.CreditnotaID, item.Creditnotanummer, setModalOpen, setSelectedItem),
        getDocPdfLinkForListView(item, 'credit_note', item.customer_number, item.Creditnotanummer),
        String(item.Datum).substring(0, 10),
        <Link removeUnderline url={'/companies/' + item.FIRNR}>
          {truncate(item.customer_name, 25, true)}
        </Link>,
        getDocPdfLinkForListView(item, 'invoice', item.customer_number, item.FactuurID),
        truncate(item.Referentie, 15, false),
        truncate(item.Opmerking, 20, false),
        formatToCurrency(item.BedragTotaal),
        formatToCurrency(item.BedragOpen),
        item.Afkorting ? <Badge>{item.Afkorting}</Badge> : '',
        emailSentBadge(item.Verstuurd),
        item.Betaald ? <Badge tone="success">Paid</Badge> : <Badge tone="error">Unpaid</Badge>,
        !item.Betaald ? (
          <div style={{ margin: '-4px 0' }}>
            <Tooltip content="Mark as fully paid">
              <Button
                size="slim"
                icon={CreditCardIcon}
                onClick={() => {
                  openCollectionModal();
                  setCollectionIvoice(item);
                  setFullInvoiceCollection(true);
                }}
              />
            </Tooltip>
            <Tooltip content="Mark as partially paid">
              <Button
                size="slim"
                icon={CreditCardPercentIcon}
                onClick={() => {
                  openCollectionModal();
                  setCollectionIvoice(item);
                  setFullInvoiceCollection(false);
                }}
              />
            </Tooltip>
          </div>
        ) : (
          ''
        )
      ])
    : [];

  const openCollectionModal = () => setInvoiceCollectionModalOpen(true);

  return (
    <Page title="Credit notes" fullWidth>
      <QuickViewModal modalOpen={modalOpen} setModalOpen={setModalOpen} id={selectedItem.id} type="financial-simple" source="creditnotes" title={`Creditnote #${selectedItem.number}`} />
      <CreditnoteCollection
        modalOpen={invoiceCollectionModalOpen}
        setModalOpen={setInvoiceCollectionModalOpen}
        collectionInvoice={collectionInvoice}
        fetchData={fetchCreditnotes}
        fullInvoiceCollection={fullInvoiceCollection}
      />
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter credit notes on name, number, reference, value open, remark and total value"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'text', 'text', 'text', 'numeric']}
          headings={[
            <b>Number</b>,
            <b>View</b>,
            <b>PDF</b>,
            <b>Date</b>,
            <b>Customer</b>,
            <b>Invoice PDF</b>,
            <b>Reference customer</b>,
            <b>Remarks</b>,
            <b>Value total</b>,
            <b>Value open</b>,
            <b>Owner</b>,
            <b>PDF emailed</b>,
            <b>Paid</b>,
            <b>Payment</b>
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'credit notes')}
        />
      </Card>
    </Page>
  );
}
