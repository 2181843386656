import { InlineStack, Link, Text } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getRequest } from './functions';

export default function ProjectBriefSelect({ value, id, onChange, label, isDisabled, FIRNR }) {
  const [selectValue, setSelectValue] = useState(null);
  const [options, setOptions] = useState([]);

  const getOptions = async () => {
    if (FIRNR) {
      const data = await getRequest(`/api/projects?limit=10000&FIRNR=${FIRNR}`);
      const options = data.map((project) => ({ value: project.ID, label: ` ${project.Projectnummer} | ${project.omschrijving} ` }));
      setOptions(options);
      return options;
    } else {
      return [];
    }
  };

  useEffect(() => {
    async function fetchData() {
      const opts = await getOptions();
      setSelectValue(opts.find((opt) => opt.value === value));
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FIRNR]);

  return (
    <>
      {label ? (
        <div style={{ marginBottom: '4px' }}>
          <InlineStack align="space-between">
            <Text>{label}</Text>
            {value && (
              <Link removeUnderline url={`/projects/${value}`}>
                open
              </Link>
            )}
          </InlineStack>
        </div>
      ) : null}
      <Select
        maxMenuHeight="150px"
        isDisabled={isDisabled}
        options={options}
        placeholder="Select project"
        width="200px !important"
        isClearable
        defaultValue={selectValue}
        value={selectValue}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: isDisabled ? '#ebebeb' : '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
        onChange={(props) => {
          if (props) {
            setSelectValue(props);
            onChange(props, id);
          } else {
            setSelectValue({ value: '', label: '' });
            onChange('', id);
          }
        }}
      />
    </>
  );
}
