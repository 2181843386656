import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CompanySelect from '../../shared/components/companySelect.jsx';
import { Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Select, BlockStack, Banner, Text, Button, InlineStack, Box, InlineGrid } from '@shopify/polaris';
import { useUrl, handleSaveWithSecondPayload } from '../../shared/util/hanldesave';
import { alphabetize, getCurrenctDatetimeYYYMMDD, getExpiryDate, getRequest, putRequest } from '../../shared/components/functions.jsx';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import { ApprovalCard } from './components/ApprovalCard.jsx';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import IncasserenSelect from '../../shared/components/incasserenSelect.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import EventTimeLine from '../../shared/components/events/eventTimeLine.jsx';
import { PdfCard } from './components/PdfCard.jsx';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';
/*
  FIXME:
    - Not one invoice is assocciated with a project so we cant do any calculations on this for outgoing invoices, ingoing invoices dont even have yet the projectID property
    - not tracked how many vat is charged on these invoices?  hard to do revenue and profit calculations on those and compare it to the outgoing invoices
*/

export default function InvoiceInPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const docTypeID = 19;
  const docType = 'invoice_in';
  const currencies = useLookupStore((state) => state.currencies);
  const paymentconditions = useLookupStore((state) => state.paymentconditions);
  const { user } = useAuthStore();
  const [initialInvoiceIn, setInitialInvoiceIn] = useState('');
  const [invoiceIn, setInvoiceIn] = useState('');
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [approvalsDirty, setApprovalsDirty] = useState(false);
  const [company, setCompany] = useState({});
  const [approvals, setApprovals] = useState([]);
  const [initialApprovals, setInitialApprovals] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedS3Key, setSelectedS3Key] = useState();
  const [hasSysDoc, setHasSysDoc] = useState(false);
  const [reopenLoading, setReopenLoading] = useState(false);
  const pageTitle = getPageTitle(invoiceIn?.FactuurInNummer, 'Invoice IN');
  const invoiceInSchema = getSchema('invoice_in', url, null, user);

  const handleChange = (value, id) => {
    // console.log('id:', id, 'value:', value);
    let invoice = invoiceIn;
    if (id === 'status') invoice = { ...invoice, status: value, bedragopen: 0 };
    if (id === 'Bedragfaktuur') invoice = { ...invoice, Bedragfaktuur: value, bedragopen: value };
    setInvoiceIn({ ...invoice, [id]: value });
    setIsDirty(true);
  };

  const handleDiscard = () => {
    setInvoiceIn(initialInvoiceIn);
    setApprovals(initialApprovals);
    setApprovalsDirty(false);
    setIsDirty(false);
    setFormSubmitting(false);
  };

  async function saveForm() {
    setFormSubmitting(true);
    if (invoiceIn.datum === '') invoiceIn.datum = null;
    if (invoiceIn.datumin === '') invoiceIn.datumin = null;
    if (invoiceIn.datumbetaling === '') invoiceIn.datumbetaling = null;
    if (invoiceIn.datumbetalingout === '') invoiceIn.datumbetalingout = null;
    if (typeof invoiceIn.bedragopen === 'string' && invoiceIn.bedragopen.includes(',')) invoiceIn.bedragopen = parseFloat(invoiceIn.bedragopen.replace(',', '.'));
    if (typeof invoiceIn.Bedragfaktuur === 'string' && invoiceIn.Bedragfaktuur.includes(',')) invoiceIn.Bedragfaktuur = parseFloat(invoiceIn.Bedragfaktuur.replace(',', '.'));

    const errorInSchema = await validateSchema(invoiceInSchema, invoiceInSchema.cast(invoiceIn));

    if (!errorInSchema) {
      //check if combo exists
      const doubling = await getRequest(`/api/invoices_in?supplier=${company.NAAM}&reference=${invoiceIn.faktuurref}`);
      const filteredDoublings = doubling.filter((d) => d.faktuurinvolgnummer !== invoiceIn.faktuurinvolgnummer);
      if (
        filteredDoublings &&
        filteredDoublings.length > 0 &&
        !confirm(`One or more incoming invoices for this customer with the same reference already exists (${filteredDoublings.map((d) => d.FactuurInNummer).join(', ')}). Continue?`)
      ) {
        setApprovalsDirty(false);
        setIsDirty(false);
        return setFormSubmitting(false);
      }

      if (!invoiceIn.processed && invoiceIn.S3ObjectKeys) {
        // get PDF
        const response = await fetch(`/api/invoices_in/download?fileKey=${encodeURIComponent(selectedS3Key)}`);
        const blob = await response.blob();
        const pathArr = selectedS3Key.split('/');
        const downloadedFile = { blob, name: pathArr[pathArr.length - 1], type: 'application/pdf' };

        // create FormData object for sharepoint
        const formData = new FormData();
        formData.append('file', downloadedFile.blob, downloadedFile.name);
        formData.append('customerNumber', company.Klantnummer);
        formData.append('docType', 'invoice_in');
        formData.append('docNumber', invoiceIn.FactuurInNummer);
        formData.append('FIRNR', company.FIRNR);
        formData.append('resourceId', invoiceIn.faktuurinvolgnummer);
        formData.append('docTypeId', 19);
        formData.append('isSystemDoc', '1');
        await fetch(`/api/docgen/uploadfile`, { method: 'POST', body: formData });
      }

      invoiceIn.processed = true;

      if (approvalsDirty) {
        for (let i = 0; i < approvals.length; i++) {
          let valueOpen = parseFloat(approvals[i].Bedrag);
          if (!valueOpen || isNaN(valueOpen)) {
            setFormSubmitting(false);
            setApprovalsDirty(false);
            setIsDirty(false);
            return alert('❌ Saving not possible: Please check if the values of the approval lines are correct.');
          }
        }
      }

      const payloadTwo = { payload: approvals, link: window.location.href };

      await handleSaveWithSecondPayload(
        id,
        'invoices_in',
        invoiceIn.faktuurinvolgnummer,
        'faktuurinvolgnummer',
        invoiceInSchema.cast(invoiceIn), //payloadOne
        payloadTwo,
        setInvoiceIn,
        setInitialInvoiceIn,
        `invoice-approvals/dump/lines/${id}`,
        setApprovals,
        setInitialApprovals,
        isDirty,
        setIsDirty,
        approvalsDirty,
        setApprovalsDirty,
        setToast,
        setToastError,
        navigate
      );
    } else {
      console.log('Invoice In:', invoiceIn);
      alert(errorInSchema);
    }
    setFormSubmitting(false);
  }

  const getApprovals = async () => {
    const data = await getRequest(`/api/invoice-approvals/${id}`);
    setInitialApprovals(data);
    setApprovals(data);
  };

  const fetchData = useCallback(async () => {
    //if url contains /new then set invoice in to its interface
    if (id === 'new') {
      setInvoiceIn(invoiceInSchema.default());
      setInitialInvoiceIn(invoiceInSchema.default());
    } else {
      //else get the invoice in resource via api get request
      const data = await getRequest(`/api/invoices_in/${id}`);
      if (data && data.length > 0) {
        const invoice = data[0];
        setInvoiceIn(invoice);
        setInitialInvoiceIn(invoice);

        if (invoice.LeverancierNummer) setCompany(await getRequest(`/api/companies/${invoice.LeverancierNummer}`));

        await getApprovals();
      } else {
        setInvoiceIn(invoiceInSchema.default());
        setInitialInvoiceIn(invoiceInSchema.default());
      }
    }
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    updateExpiryDate();
  }, [invoiceIn.betalingID, invoiceIn.datum]);

  function updateExpiryDate() {
    setInvoiceIn((invoiceIn) => ({ ...invoiceIn, datumbetaling: getExpiryDate(invoiceIn.betalingID || undefined, invoiceIn?.datum || getCurrenctDatetimeYYYMMDD()) }));
  }

  useEffect(() => {
    async function fetchData() {
      if (invoiceIn.LeverancierNummer) {
        const data3 = await getRequest(`/api/companies/${invoiceIn.LeverancierNummer}`);
        setCompany(data3);

        if (!data3.Leverancier) alert('This company is not marked as supplier, so not possible to set the correct payment conditions!');

        const invoiceCompanyData = { Leveranciernaam: data3.NAAM };
        if (!invoiceIn.processed && !hasSysDoc) invoiceCompanyData.betalingID = data3.LevBetalingID || undefined;

        setInvoiceIn((invoiceIn) => ({ ...invoiceIn, ...invoiceCompanyData }));
      }
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceIn.LeverancierNummer]);

  const handleDelete = async () => {
    if (confirm('Are you sure you want to delete this invoice?')) {
      setIsDirty(false);
      const result = await fetch(`/api/invoices_in/${id}`, { method: 'DELETE' });
      if (!result.ok) return setToastError(true);
      navigate(`/invoices_in`, { replace: true });
    }
  };

  const handleReopenInvoice = async () => {
    setReopenLoading(true);
    const payload = { status: false, bedragopen: invoiceIn.Bedragfaktuur, datumbetalingout: null, memo: invoiceIn.memo, Incasseren: invoiceIn.Incasseren };
    await putRequest(`/api/invoices_in/${invoiceIn.faktuurinvolgnummer}`, payload, setToast, setToastError);
    await fetchData();
    setReopenLoading(false);
  };

  return (
    dataLoaded && (
      <Page title={pageTitle} fullWidth primaryAction={{ content: 'New incoming invoice', url: '/invoices_in/new', disabled: !invoiceIn.processed }}>
        <TabTitle title={pageTitle} />
        <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Incoming Invoice saved" />
        <TheSaveBar isDirty={isDirty || approvalsDirty} savingResult={formSubmitting} handleDiscard={handleDiscard} handleSave={saveForm} />
        {invoiceIn && id !== 'new' && !invoiceIn.processed && !hasSysDoc ? (
          <div style={{ marginBottom: '20px' }}>
            <Banner
              tone="warning"
              title={
                <BlockStack inlineAlign="start">
                  <Text variant="headingMd">This is a new incoming invoice. Please add information on supplier, reference and value to save this invoice.</Text>
                  <InlineStack gap="200">
                    <Text variant="bodyMd" as="h5">
                      Mistakenly uploaded the document for this invoice?
                    </Text>
                    <div style={{ marginTop: '1px' }}>
                      <Button variant="plain" tone="critical" onClick={handleDelete}>
                        Delete invoice
                      </Button>
                    </div>
                  </InlineStack>
                </BlockStack>
              }
            />
          </div>
        ) : null}
        {invoiceIn && invoiceIn.status && (
          <Box paddingBlockEnd="400">
            <Banner title="This invoice is fully paid" status="success" action={(user.role === 1 || user.role === 6) && { content: 'Reopen', onAction: handleReopenInvoice, loading: reopenLoading }} />
          </Box>
        )}
        <Layout>
          <Layout.Section variant="oneHalf">
            <Card>
              <Form onSubmit={saveForm}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField id="FactuurInNummer" disabled label="Number" value={invoiceIn.FactuurInNummer ? String(invoiceIn.FactuurInNummer) : 'created automatically'} />
                    <TextField
                      className="sentry-ignore"
                      id="Bedragfaktuur"
                      maxLength={60}
                      prefix="€"
                      type="number"
                      autoComplete="off"
                      label={
                        <InlineStack>
                          Total amount <p style={{ color: 'red' }}>*</p>
                        </InlineStack>
                      }
                      value={invoiceIn.Bedragfaktuur ? String(invoiceIn.Bedragfaktuur) : ''}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <CompanySelect
                      id="LeverancierNummer"
                      label={
                        <InlineStack>
                          Supplier <p style={{ color: 'red' }}>*</p>{' '}
                        </InlineStack>
                      }
                      value={invoiceIn.LeverancierNummer}
                      onChange={handleChange}
                      isDisabled={formSubmitting}
                    />
                    <TextField
                      className="sentry-ignore"
                      id="bedragopen"
                      maxLength={60}
                      prefix="€"
                      type="number"
                      autoComplete="off"
                      label="Amount open"
                      value={invoiceIn.bedragopen ? String(invoiceIn.bedragopen) : ''}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="datumin"
                      maxLength={60}
                      type="date"
                      autoComplete="off"
                      label="Date in"
                      value={String(invoiceIn.datumin).substring(0, 10)}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <Select
                      id="ValutaID"
                      autoComplete="off"
                      label="Currency"
                      value={parseInt(invoiceIn.ValutaID) || ''}
                      options={currencies.map((curr) => {
                        return { value: curr.ValutaID, label: curr.Valuta, key: 'CURR-' + curr.ValutaID };
                      })}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="datum"
                      maxLength={60}
                      type="date"
                      autoComplete="off"
                      label="Date invoice"
                      value={String(invoiceIn.datum).substring(0, 10)}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <IncasserenSelect id="Incasseren" value={invoiceIn.Incasseren} handleChange={handleChange} disabled={formSubmitting} isClearable />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="faktuurref"
                      maxLength={50}
                      showCharacterCount
                      autoComplete="off"
                      label={
                        <InlineStack>
                          Reference invoice <p style={{ color: 'red' }}>*</p>
                        </InlineStack>
                      }
                      value={invoiceIn.faktuurref || ''}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <TextField
                      id="datumbetaling"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Expiry date"
                      value={String(invoiceIn.datumbetaling).substring(0, 10)}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <InlineGrid columns={2}>
                      <BlockStack>
                        <Checkbox id="status" label="Paid" checked={invoiceIn.status} onChange={handleChange} disabled={formSubmitting} />
                        <Checkbox id="Vervallen" label="Expired" checked={invoiceIn.Vervallen} onChange={handleChange} disabled={formSubmitting} />
                      </BlockStack>
                      <OwnerSelect id="GebruikerID" label="Owner" value={invoiceIn.GebruikerID} handleChange={handleChange} disabled={formSubmitting} />
                    </InlineGrid>
                    <TextField
                      clearButton
                      onClearButtonClick={() => handleChange(null, 'datumbetalingout')}
                      id="datumbetalingout"
                      maxLength={50}
                      type="date"
                      label="Payment date"
                      value={String(invoiceIn.datumbetalingout).substring(0, 10)}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      placeholder="Select payment"
                      id="betalingID"
                      label="Payment condition"
                      value={parseInt(invoiceIn.betalingID) || ''}
                      options={paymentconditions.sort((a, b) => alphabetize(a, b, 'betalingNL')).map((cond) => ({ value: cond.betalingID, label: cond.betalingNL, key: 'PC' + cond.betalingID }))}
                      onChange={handleChange}
                      disabled={formSubmitting}
                    />
                    <TextField id="OGM" maxLength={20} showCharacterCount autoComplete="off" label="OGM" value={invoiceIn.OGM || ''} onChange={handleChange} disabled={formSubmitting} />
                  </FormLayout.Group>
                  <TextField id="memo" label="Note" value={invoiceIn.memo || ''} multiline={6} onChange={handleChange} disabled={formSubmitting} />
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            {id !== 'new' && !invoiceIn.processed && invoiceIn.S3ObjectKeys ? (
              <Box paddingBlockEnd="300">
                <PdfCard keys={JSON.parse(invoiceIn.S3ObjectKeys) || []} selectedS3Key={selectedS3Key} setSelectedS3Key={setSelectedS3Key} setIsDirty={setIsDirty} resource={'invoices_in'} />
              </Box>
            ) : null}
            <DocumentCard
              resource="INVOICE_IN"
              id={id}
              title="Incoming invoice document"
              company={company}
              docNumber={invoiceIn.FactuurInNummer}
              isDirty={isDirty}
              generateDocument={false}
              docTypeID={docTypeID}
              docType={docType}
              docLang={company.TaalID}
              documentButtonLoading={formSubmitting}
              setDocumentButtonLoading={setFormSubmitting}
              invoice={invoiceIn}
              setInvoice={setInvoiceIn}
              setIsDirty={setIsDirty}
              setHasSysDoc={setHasSysDoc}
            />
          </Layout.Section>
          <Layout.Section variant="fullWidth">
            <ApprovalCard
              handleSave={saveForm}
              id={id}
              approvals={approvals}
              setApprovals={setApprovals}
              resource={invoiceIn}
              setApprovalsDirty={setApprovalsDirty}
              resourceName="Invoice"
              resourceID="faktuurinvolgnummer"
            />
          </Layout.Section>
          <Layout.Section variant="fullWidth">
            <EventTimeLine resourceName="INVOICE_IN" resourceId={id} />
          </Layout.Section>
        </Layout>
      </Page>
    )
  );
}
