import React from 'react';
import { Page } from '@shopify/polaris';

export default class FilesPage extends React.Component {
  render() {
    return (
      <>
        <Page title="Files Page"></Page>
      </>
    );
  }
}
