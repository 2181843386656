import { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, ChoiceList, Banner, Icon, Text, Box } from '@shopify/polaris';
import { ExportIcon } from '@shopify/polaris-icons';
import { handleFiltersClearAll } from '../listFilter';
import { filtersToApiUrl, simplePostRequest } from '../functions';
import { saveAs } from 'file-saver';
import { performTracking } from '../../../analytics/segment';

export default function ExcelExporterModal({ filters, setFilters, page, setPage }) {
  const navigate = useNavigate();
  const resourceName = window.location.pathname.split('/')[1];

  const [active, setActive] = useState(false);
  const [selectedExport, setSelectedExport] = useState([1]);
  const [isExporting, setIsExporting] = useState(false);
  const buttonRef = useRef(null);

  const handleModalChange = useCallback(() => setActive(!active), [active]);

  const exportExcel = async () => {
    setIsExporting(true);
    let appliedFilters = { ...filters };

    if (selectedExport[0] === 1) {
      appliedFilters.limit = { type: 'none', name: 'Limit', value: 100 };
      appliedFilters.page = { type: 'none', name: 'Page', value: page };
    }

    const response = await simplePostRequest(`/api/excel/export?${filtersToApiUrl(appliedFilters)}`, { resource: resourceName });

    const blob = await response.blob();
    saveAs(blob, `${resourceName}_admin_export_${+new Date()}.xls`);

    setIsExporting(false);
    performTracking('excel_export', { resoure: resourceName, filters: filtersToApiUrl(filters) }); //typo?
    setActive(false);
  };

  const activator = (
    <div ref={buttonRef}>
      <Button onClick={handleModalChange} icon={<Icon source={ExportIcon} tone="base" />}>
        Export
      </Button>
    </div>
  );

  // BANNER
  let filterValues = Object.keys(filters).map((filterKey) => filters[filterKey].value);
  let appliedFilters = filterValues.filter((fil) => fil !== '' && fil !== null && fil !== 'all');

  return (
    <>
      {activator}
      <Modal
        activator={buttonRef}
        open={active}
        onClose={handleModalChange}
        title="Export to Excel"
        primaryAction={{
          content: 'Export',
          onAction: exportExcel,
          loading: isExporting
        }}
      >
        <Modal.Section>
          {appliedFilters.length > 0 && (
            <Box paddingBlockEnd={'400'}>
              <Banner action={{ content: 'Remove all filters', onAction: () => handleFiltersClearAll(filters, setFilters, navigate, setPage) }} tone="warning">
                <Text>You applied filters to this list. Proceed with filters or click to remove.</Text>
              </Banner>
            </Box>
          )}
          <ChoiceList
            title={`How many ${resourceName.replace('_', ' ')} do you want to export?`}
            choices={[
              { label: 'Current page', value: 1 },
              { label: `All entries in ${resourceName.replace('_', ' ')}`, value: 2 }
            ]}
            selected={selectedExport}
            onChange={(e) => setSelectedExport(e)}
          />
        </Modal.Section>
      </Modal>
    </>
  );
}
