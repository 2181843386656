import React from 'react';
import { contactSchema } from './schemas';
import { Formik, Form } from 'formik';
import { Box, Stack, Heading, Container, Flex, Spacer, useDisclosure, Collapse, Button } from '@chakra-ui/react';
import { languagesEN, titlesEN } from '../../../shared/components/functions';
import { useLookupStore } from '../../../context/useStore';
import { TextInput, SelectInput, TextareaInput, CheckboxInput } from './forms';

export default function ContactForms({ handleSubmit, SubmitButton }) {
  const { languages, contacttitels } = useLookupStore();
  const langEN = languagesEN(languages);
  const titles = titlesEN(contacttitels);

  const { isOpen, onToggle } = useDisclosure();

  let allValues = {
    NAAM: '',
    DEPT: '',
    AanspreektitelID: '1',
    TaalID: '3',
    'E-mail': '',
    GSM: '',
    TEL: '',
    OPMERKING: '',
    interestedIn: []
  };

  return (
    <Formik initialValues={allValues} validationSchema={contactSchema} onSubmit={(values) => handleSubmit(values)}>
      {(formikProps) => (
        <Form>
          <Container py={{ base: '4', md: '8' }} px={{ base: '0', md: '0' }}>
            <Stack spacing="5">
              <Heading size="xl">General information</Heading>
            </Stack>

            <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between">
              <Box bg="bg-surface" borderRadius="lg" flex="1">
                <Stack spacing="5" py={{ base: '5', md: '6' }}>
                  <TextInput isRequired aria-required="true" autoFocus label="Your name (first and last name)" name="NAAM" type="text" />
                  <TextInput isRequired aria-required="true" label="E-Mail" name="E-mail" type="text" />
                  <CheckboxInput
                    label="Interested in"
                    name="interestedIn"
                    handleChange={(e) => {
                      if (e.target.checked) {
                        formikProps.setFieldValue('interestedIn', [...formikProps.values.interestedIn, e.target.id]);
                      } else {
                        formikProps.setFieldValue(
                          'interestedIn',
                          formikProps.values.interestedIn.filter((i) => i !== e.target.id)
                        );
                      }
                    }}
                  />
                  <TextareaInput label="Note" name="OPMERKING" type="text" />
                </Stack>
              </Box>
            </Stack>
          </Container>

          <Container py={{ base: '4', md: '8' }} px={{ base: '0', md: '0' }}>
            <Flex spacing="5" direction="row" verticalAlign="center">
              <Heading size="xl">Some more information about you</Heading>
              <Spacer />
              <Button onClick={onToggle} color="black">
                <div style={{ transform: `rotate(${isOpen ? '270' : '90'}deg)` }}>▶️</div>
              </Button>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
              <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }} justify="space-between">
                <Box bg="bg-surface" borderRadius="lg" flex="1">
                  <Stack spacing="5" py={{ base: '5', md: '6' }}>
                    <TextInput label="Function" name="DEPT" type="text" />
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                      <SelectInput label="Title" autoFocus={isOpen} name="AanspreektitelID" as="select" options={titles} />
                      <SelectInput label="Language" name="TaalID" as="select" options={langEN} />
                    </Stack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                      <TextInput label="Phone" name="GSM" type="text" />
                      <TextInput label="Mobile phone" name="TEL" type="text" />
                    </Stack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                      <TextareaInput label="Note" name="OPMERKING" type="text" />
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Collapse>
          </Container>
          {SubmitButton}
        </Form>
      )}
    </Formik>
  );
}
