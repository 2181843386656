import React from 'react';
import { Toast } from '@shopify/polaris';

const Toasts = ({ toast, setToast, errorToast, setErrorToast, toastContent }) => {
  const toastMarkup = toast && !errorToast ? <Toast content={toastContent || 'Successfully saved'} onDismiss={() => setToast(false)} /> : null;
  const toastErrorMarkup = errorToast ? <Toast content={'Server Error'} error={errorToast} onDismiss={() => setErrorToast(false)} /> : null;

  return (
    <>
      {toastMarkup}
      {toastErrorMarkup}
    </>
  );
};

export default Toasts;
