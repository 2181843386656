import React from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import { formatToCurrency } from '../../../shared/components/functions';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, absoluteValue } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fontSize="25px">
        {payload.name}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fontSize="24px" fill="#333">{`${absoluteValue}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={25} textAnchor={textAnchor} fill="#999" fontSize="17px">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export default function ProjectPiechart({ techniciansCost, totalInvoicedIn, totalInvoicedOut }) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  //still to calculate, set hard for reference
  const installationCost = techniciansCost;
  const installationCostPercentage = (installationCost / totalInvoicedOut) * 100;
  const invInPercentage = (totalInvoicedIn / totalInvoicedOut) * 100;
  const margePercentage = ((totalInvoicedOut - totalInvoicedIn - installationCost) / totalInvoicedOut) * 100; //bereken marge

  const data = [
    { name: 'Marge', value: margePercentage, absoluteValue: formatToCurrency(totalInvoicedOut - totalInvoicedIn - installationCost), fill: '#00C49F' },
    { name: 'Technicians cost', value: installationCostPercentage, absoluteValue: formatToCurrency(installationCost), fill: '#FFBB28' },
    { name: 'Total invoices IN', value: invInPercentage, absoluteValue: formatToCurrency(totalInvoicedIn), fill: '#FF8042' }
  ];

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  if (totalInvoicedOut > 0) {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie activeIndex={activeIndex} activeShape={renderActiveShape} data={data} cx="50%" cy="50%" innerRadius={200} outerRadius={250} fill="#8884d8" dataKey="value" onMouseEnter={onPieEnter} />
        </PieChart>
      </ResponsiveContainer>
    );
  } else {
    return (
      <div style={{ marginTop: '15px', fontSize: '17px' }}>
        <h1>No outgoing invoices found, first link at least one outgoing invoice to this project in order to see analytics</h1>
      </div>
    );
  }
}
