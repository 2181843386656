import { useState } from 'react';
import { DataTable, Page, Card } from '@shopify/polaris';
import SettingsAddEditModal, { getSettingsEditLink } from '../../settingsAddEditModal';
import { openAddModal } from '../../../../shared/components/settingsFunctions.js';
import Toasts from '../../../../shared/components/toasts/toasts.jsx';
import { useLookupStore } from '../../../../context/useStore.js';
import { useToggle } from '../../../../shared/useToogle.js';

export default function SettingsDocumentTypesPage() {
  const [documenttypes, setDocumenttypes] = useLookupStore((state) => [state.documenttypes, state.setDocumenttypes]);
  const [modalOpen, setModalOpen] = useToggle(false);
  const [entryId, setEntryId] = useState('');
  const [isAdd, setIsAdd] = useState(true);
  const [entry, setEntry] = useState({
    DocumentTypeNL: { type: 'text', label: 'NL', max: '50', multi: true, value: '' },
    DocumentTypeFR: { type: 'text', label: 'FR', max: '50', multi: true, value: '' },
    DocumentTypeDE: { type: 'text', label: 'DE', max: '50', multi: true, value: '' },
    DocumentTypeUK: { type: 'text', label: 'EN', max: '50', multi: true, value: '' }
  });
  const [toast, setToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const url = '/api/documenttypes';
  const title = 'Document types';

  const rows = documenttypes.map((item) => {
    return [item.DocumentTypeNL, item.DocumentTypeFR, item.DocumentTypeDE, item.DocumentTypeUK, getSettingsEditLink(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, item.DocumentTypeID)];
  });

  return (
    <Page
      title={title}
      fullWidth
      backAction={{ content: 'Settings', url: '/settings' }}
      primaryAction={{ content: 'Add document type', onAction: async () => openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) }}
    >
      <SettingsAddEditModal
        modalTitle={title}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isAdd={isAdd}
        entry={entry}
        setEntry={setEntry}
        entryId={entryId}
        url={url}
        setToast={setToast}
        setErrorToast={setErrorToast}
        setItems={setDocumenttypes}
      />
      <Toasts toast={toast} setToast={setToast} errorToast={errorToast} setErrorToast={setErrorToast} />
      <Card>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'numeric']}
          headings={[<b>NL</b>, <b>FR</b>, <b>DE</b>, <b>EN</b>, <b>Edit</b>]}
          rows={rows}
        />
      </Card>
    </Page>
  );
}
