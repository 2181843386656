import { useEffect, useState } from 'react';
import { Badge, DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getDocPdfLinkForListView, getRequest } from '../../../shared/components/functions';

export default function QuotationsCompanyPage({ FIRNR }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/quotations?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    };
    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        var mail_verstuurd = item.Verstuurd ? <Badge tone="info">Sent</Badge> : <Badge>Not sent</Badge>;
        var status = '';
        if (item.OfferteStatusID === 0) {
          //Aangevraagd
          status = <Badge>{item.offertestatustekst}</Badge>;
        } else if (item.OfferteStatusID === 1) {
          //Bij klant
          status = <Badge tone="info">{item.offertestatustekst}</Badge>;
        } else if (item.OfferteStatusID === 2) {
          //Verloren
          status = <Badge tone="critical">{item.offertestatustekst}</Badge>;
        } else if (item.OfferteStatusID === 3) {
          //Vervangen
          status = <Badge tone="info">{item.offertestatustekst}</Badge>;
        } else if (item.OfferteStatusID === 4) {
          //Besteld
          status = <Badge tone="success">{item.offertestatustekst}</Badge>;
        } else if (item.OfferteStatusID === 5) {
          //Geen investering
          status = <Badge tone="warning">{item.offertestatustekst}</Badge>;
        } else if (item.OfferteStatusID === 6) {
          //Divers
          status = <Badge>{item.offertestatustekst}</Badge>;
        }
        return [
          <Link removeUnderline url={'/quotations/' + item.offertenr}>
            {item.Offertenummer}
          </Link>,
          getDocPdfLinkForListView(item, 'quotation', item.customer_number, item.Offertenummer),
          String(item.datum).substring(0, 10),
          item.refklant,
          item.supplier_name,
          formatToCurrency(item.price),
          status,
          mail_verstuurd
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric', 'text', 'text']}
      headings={[<b>{'Number'}</b>, <b>{'PDF'}</b>, <b>{'Date'}</b>, <b>{'Reference customer'}</b>, <b>{'Supplier'}</b>, <b>{'Price'}</b>, <b>{'Status'}</b>, <b>{'Mail'}</b>]}
      rows={rows}
    />
  ) : (
    'No quotations'
  );
}
