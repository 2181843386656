import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Form, Select, FormLayout, Layout, Page, Card, TextField, Checkbox, Button, BlockStack, InlineGrid, InlineStack, Spinner, Box } from '@shopify/polaris';
import LinesTable from '../../shared/components/linesTable';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { updateMyData } from '../../shared/util/updateLines';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { alphabetize, getRequest, simplePostRequest } from '../../shared/components/functions.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import { DeliveryToInvoice } from '../../shared/components/transformers';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas';
import EventTimeLine from '../../shared/components/events/eventTimeLine';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';
import { UpdateStockModal } from './components/updateStockModal.jsx';
import { ContactReactSelect } from '../../shared/components/contactReactSelect.jsx';

export default function DeliveryPage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const docTypeID = 4;
  const docType = 'delivery_note';
  const location = useLocation();
  const [savingResult, setSavingResult] = useState(false);
  const [delivery, setDelivery] = useState({});
  const [initialDelivery, setInitialDelivery] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [linesDirty, setLinesDirty] = useState(url.get('linesDirty') || false);
  const [company, setCompany] = useState({});
  const [isTransforming, setIsTransforming] = useState(false);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const [contacts, setContacts] = useState([]);
  const countries = useLookupStore((state) => state.countries);
  const languages = useLookupStore((state) => state.languages);
  const { user } = useAuthStore();
  const pageTitle = getPageTitle(delivery.LeveringsbonNummer, 'Delivery note');
  const deliverySchema = getSchema('delivery', url, null, user);
  const [isBlurred, setIsBlurred] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [contactsLoaded, setContactsLoaded] = useState(false);

  const lineInterface = {
    lineIsDirty: false,
    Lijnnr: null,
    bonnr: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: null,
    aantalbesteld: 1,
    aantalgeleverd: 1,
    plaats: null,
    signedoff: false,
    opbon: false,
    garantie: false,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': 0,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const handleChange = (value, id) => {
    if (id === 'refklantdatum' && (!value || value === '')) value = null;
    setDelivery((delivery) => ({ ...delivery, [id]: value }), setIsDirty(true));
  };

  useEffect(() => {
    //if url contains /new then set machine to its interface
    async function fetchData() {
      if (id === 'new') {
        setDelivery(deliverySchema.default());
      } else {
        const data = await getRequest(`/api/delivery_notes/${id}`);
        if (!data) return setDelivery(deliverySchema.default());
        setDelivery(data);
        setInitialDelivery(data);

        const data2 = await getRequest(`/api/delivery_notes/${id}/lines`);
        setLines(data2);
        setInitialLines(data2);
      }
      setDataLoaded(true);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      const data3 = await getRequest(`/api/companies/${delivery.klantnummer}`);
      if (data3) setCompany(data3);
      const data4 = await getRequest(`/api/contacts?limit=10000&FIRNR=${delivery.klantnummer}&status=0`);
      if (data4) setContacts(data4);
      setContactsLoaded(true);
    }
    if (delivery.klantnummer) fetchData();
  }, [delivery.klantnummer]);

  useEffect(() => {
    if (location.state) setLines(location.state);
  }, [location]);

  const generateDocument = async () => {
    const document = {
      addressee: {
        FIRNR: delivery.klantnummer,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode,
        co: delivery.tav
      },
      eu: false,
      language: delivery.TaalID ? languages.find((l) => l.TaalID === parseInt(delivery.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      id: delivery.Bonnr,
      typeID: docTypeID,
      number: delivery.LeveringsbonNummer,
      reference: delivery.refklant,
      reference_date: delivery.refklantdatum ? String(delivery.refklantdatum).substring(0, 10) : '',
      date: String(delivery.datum).substring(0, 10),
      payment_condition: null
    };

    //check if it is dirty if not tell user to save first
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  async function saveForm() {
    const errorInSchema = await validateSchema(deliverySchema, delivery);
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        handleSave(
          id,
          'delivery_notes',
          delivery.Bonnr,
          delivery,
          setDelivery,
          setInitialDelivery,
          setToast,
          setToastError,
          isDirty,
          setIsDirty,
          linesDirty,
          lines,
          setLines,
          setInitialLines,
          setLinesDirty,
          setSavingResult,
          navigate
        );

        // auto generate pdf so latest changes are reflected in pdf
        if (id && id !== 'new') await generateDocument();
        setIsBlurred(false);
      } else {
        alert(errorInSchema);
      }
    }
  }

  if (!dataLoaded || !contactsLoaded)
    return (
      <Box padding="400">
        <Spinner size="small" />
      </Box>
    );

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Changes saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        savingResult={savingResult}
        handleDiscard={() => handleDiscard(initialDelivery, initialLines, setDelivery, setLines, setIsDirty, setLinesDirty)}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={saveForm}>
              <FormLayout>
                <TextField id="klantnummer" label="Customer" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${delivery.klantnummer}`) }} />
                <FormLayout.Group>
                  <TextField id="LeveringsbonNummer" disabled label="Number" value={id !== 'new' ? String(delivery.LeveringsbonNummer) : 'Created automatically'} />
                  <Select
                    id="TaalID"
                    maxLength={50}
                    autoComplete="off"
                    label="Language"
                    value={parseInt(delivery.TaalID) || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                    options={languages.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <InlineStack wrap={false} gap="300">
                    <TextField id="datum" maxLength={50} type="date" autoComplete="off" label="Date" value={String(delivery.datum).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                    <ContactReactSelect value={delivery.tav} id="tav" onChange={handleChange} label="TAV" contacts={contacts} disabled={savingResult} />
                  </InlineStack>
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id="refklant"
                    maxLength={100}
                    showCharacterCount
                    type="text"
                    autoComplete="off"
                    label="Reference customer"
                    value={delivery.refklant || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="adres" maxLength={50} showCharacterCount autoComplete="off" label="Address" value={delivery.adres || ''} onChange={handleChange} disabled={savingResult} />
                  <TextField
                    id="refklantdatum"
                    maxLength={50}
                    type="date"
                    autoComplete="off"
                    label="Customer date"
                    value={String(delivery.refklantdatum).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="postkode" maxLength={50} showCharacterCount autoComplete="off" label="ZIP" value={delivery.postkode || ''} onChange={handleChange} disabled={savingResult} />
                  <TextField id="woonplaats" maxLength={50} showCharacterCount autoComplete="off" label="City" value={delivery.woonplaats || ''} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    id="LandID"
                    label="Country"
                    value={parseInt(delivery.LandID) || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                    options={countries.map((country) => ({ value: country.LandId, key: country.LandId, label: country.Land }))}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <InlineGrid columns={2}>
                    <BlockStack align="center">
                      <Checkbox id="Verstuurd" label="PDF document emailed" checked={delivery.Verstuurd} onChange={handleChange} disabled={savingResult} />
                    </BlockStack>
                    <OwnerSelect id="GebruikerID" label="Owner" value={delivery.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                  </InlineGrid>
                  <TextField id="OrderbevestigingID" disabled maxLength={60} autoComplete="off" label="Sales order" value={delivery.OrderbevestigingID || ''} onChange={handleChange} />
                </FormLayout.Group>
                <TextField id="Notities" label="Note" value={delivery.Notities || ''} multiline={4} onChange={handleChange} disabled={savingResult || delivery.Verstuurd} />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <DocumentCard
              resource="DELIVERY_ORDER"
              id={id}
              title="Delivery document"
              docNumber={delivery.LeveringsbonNummer}
              isDirty={isDirty}
              linesDirty={linesDirty}
              generateDocument={generateDocument}
              docTypeID={docTypeID}
              docType={docType}
              company={company}
              setResource={setDelivery}
              docLang={delivery.TaalID}
              documentButtonLoading={savingResult || documentInProgress}
              setDocumentButtonLoading={setSavingResult}
            />
            <Card>
              <CardHeading title="Invoice this delivery note" />
              <Button loading={isTransforming} disabled={savingResult} onClick={() => DeliveryToInvoice(delivery, lines, company, lineInterface, setIsTransforming, navigate)}>
                Make an invoice for this delivery note
              </Button>
            </Card>
            <Card>
              <UpdateStockModal lines={lines} savingResult={savingResult} deliverynoteNumber={delivery.LeveringsbonNummer} setToast={setToast} setErrorToast={setToastError} />
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Content" />
            <LinesTable
              id={id}
              data={lines}
              updateMyData={updateMyData}
              setLines={setLines}
              setLinesDirty={setLinesDirty}
              onSubmit={saveForm}
              lineInterface={lineInterface}
              type="non-financial"
              resource={delivery}
              company={company}
              disabled={savingResult}
              setIsBlurred={setIsBlurred}
            />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <EventTimeLine resourceName="DELIVERY_ORDER" resourceId={id} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
