import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import ProtectedRoute from './shared/components/ProtectedRoute';
import userRoles from './utils/userRoles';
// Pages Main Components Import
import Login from './pages/login/index.jsx';
import HomePage from './pages/home/index.jsx';
import CompaniesPage from './pages/companies/index.jsx';
import CompanyPage from './pages/company/index.jsx';
import ProjectsPage from './pages/projects/index.jsx';
import ProjectPage from './pages/project/index.jsx';
import PurchasesPage from './pages/purchases/index.jsx';
import PurchasePage from './pages/purchase/index.jsx';
import SalesPage from './pages/sales/index.jsx';
import SalePage from './pages/sale/index';
import DeliveriesPage from './pages/deliveries/index.jsx';
import DeliveryPage from './pages/delivery/index.jsx';
import MachinesPage from './pages/machines/index.jsx';
import MachinePage from './pages/machine/index.jsx';
import TechniciansPage from './pages/technicians/index.jsx';
import TechnicianPage from './pages/technician/index.jsx';
import ContactsPage from './pages/contacts/index.jsx';
import ContactPage from './pages/contact/index.jsx';
import ProductsPage from './pages/products/index.jsx';
import ProductPage from './pages/product/index.jsx';
import FilesPage from './pages/files/index.jsx';
import AccountPage from './pages/account/index.jsx';
import InvoicesInPage from './pages/invoices_in/index.jsx';
import InvoiceInPage from './pages/invoice_in/index.jsx';
import InvoicesPage from './pages/invoices/index.jsx';
import InvoicePage from './pages/invoice/index.jsx';
import QuotationsPage from './pages/quotations/index.jsx';
import QuotationPage from './pages/quotation/index.jsx';
import CreditnotesPage from './pages/creditnotes/index.jsx';
import CreditnotePage from './pages/creditnote/index.jsx';
import CreditnotesInPage from './pages/creditnotes_in/index.jsx';
import CreditnoteInPage from './pages/creditnote_in/index.jsx';
import JobsPage from './pages/jobs/index.jsx';
import JobPage from './pages/job/index';
import InquiriesPage from './pages/inquiries/index.jsx';
import InquiryPage from './pages/inquiry/index.jsx';
import HolidayRequestsComponent from './pages/holidays/index.jsx';
import AnalyticsPage from './pages/analytics/index.jsx';
import CashbookPage from './pages/cashbook';
import SettingsPage from './pages/settings/index.jsx';
import SettingsUsersPage from './pages/settings/settings_details/settings_users/settingsUsers.jsx';
import SettingsUserTypesPage from './pages/settings/settings_details/settings_usertypes/settingsUserTypes.jsx';
import SettingsContactTitlesPage from './pages/settings/settings_details/settings_contacttitles/settingsContactTitles.jsx';
import SettingsPurchaseTypes from './pages/settings/settings_details/settings_purchasetypes/index.jsx';
import SettingsMachineBrands from './pages/settings/settings_details/settings_machinebrands/index.jsx';
import SettingsUnitsPage from './pages/settings/settings_details/settings_units/index.jsx';
import SettingsPubsPage from './pages/settings/settings_details/settings_pubcodes/index.jsx';
import SettingsIndustriesPage from './pages/settings/settings_details/settings_industries/index.jsx';
import StockPlacesPage from './pages/settings/settings_details/settings_pickingplaces/index.jsx';
import SettingsAgreementTypesPage from './pages/settings/settings_details/settings_agreementtypes/index.jsx';
import SettingsDocumentTypesPage from './pages/settings/settings_details/settings_documenttypes/index.jsx';
import SettingsEmailFormatsPage from './pages/settings/settings_details/settings_emailformats/index.jsx';
import SettingsPaymentCollectionsPage from './pages/settings/settings_details/settings_incasseren/index.jsx';
import SettingsQuotationsStatusesPage from './pages/settings/settings_details/settings_quotationstatuses/index.jsx';
import SettingsMaintenanceContractsPage from './pages/settings/settings_details/settings_maintenancecontracts/index.jsx';
import SettingsMachineTypesPage from './pages/settings/settings_details/settings_machinetypes/index.jsx';
import SettingsPaymentConditionsPage from './pages/settings/settings_details/settings_paymentconditions/index.jsx';
import SettingsVatShiftsPage from './pages/settings/settings_details/settings_vatshifts/index.jsx';
import SettingsInvoiceStatusesPage from './pages/settings/settings_details/settings_invoicestatuses/index.jsx';
import SettingsReminderTextsPage from './pages/settings/settings_details/settings_paymentreminders/index.jsx';
import MaintenanceContractsPage from './pages/maintenance_contracts';
import MaintenanceContractPage from './pages/maintenance_contract';
import MontageBonsPage from './pages/montage_bons';
import MontageBonPage from './pages/montage_bon';
import * as Sentry from '@sentry/react';
import { extraErrorDataIntegration, httpClientIntegration } from '@sentry/integrations';
import FairForm from './pages/fairs/main';
import FairsEntry from './pages/fairs';
import PageWrapper from './PageWrapper';
import { useAuthStore, useLookupStore } from './context/useStore';
import { SettingsCompanyTagsPage } from './pages/settings/settings_details/settings_companytags/index.jsx';
import { ExpensesPage } from './pages/expenses/index.jsx';
import { ExpenseForUserDetailPage } from './pages/expenseForUser/index.jsx';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ef38dc4126ac4a50979f021b15c2540a@o534347.ingest.sentry.io/4504322151743488',
    debug: false,
    release: 'latest-release',
    environment: process.env.NODE_ENV,
    sampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    maxBreadcrumbs: 50,
    replaysSessionSampleRate: 0,
    tracesSampleRate: 0,
    sendDefaultPii: true,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({ useEffect: React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes }),
      Sentry.replayIntegration({ blockAllMedia: false, maskAllText: false, maskInputOptions: { password: true } }),
      extraErrorDataIntegration({ depth: 4 }),
      httpClientIntegration()
    ]
  });
}

export function App() {
  const { user, setUser } = useAuthStore();
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const localStorageUser = JSON.parse(localStorage.getItem('currentUser'));

  useEffect(() => {
    if (localStorageUser) {
      setUser(localStorageUser);
      setInitiallyLoaded(true);
    }
  }, []);

  // check if url contains saml
  const url = window.location.href;
  const saml = url.includes('Saml');

  const {
    setAgreementtypes,
    setCompanystatuses,
    setCompanytags,
    setContacttitels,
    setCountries,
    setCurrencies,
    setDocumenttypes,
    setEmailformats,
    setIncasseren,
    setInvoicestatuses,
    setLanguages,
    setMachinebrands,
    setMachinetypes,
    setOrganisations,
    setPaymentconditions,
    setPickingplaces,
    setProjectstatuses,
    setPubcodes,
    setPurchasetypes,
    setRappeltexts,
    setQuotationstatuses,
    setSectors,
    setServicecontracttypes,
    setUnits,
    setUsers,
    setUsertypes,
    setVatshifts,
    setZipcodes
  } = useLookupStore();

  if (user && !initiallyLoaded) {
    setAgreementtypes();
    setCompanystatuses();
    setCompanytags();
    setContacttitels();
    setCountries();
    setCurrencies();
    setDocumenttypes();
    setEmailformats();
    setIncasseren();
    setInvoicestatuses();
    setLanguages();
    setMachinebrands();
    setMachinetypes();
    setOrganisations();
    setPaymentconditions();
    setPickingplaces();
    setProjectstatuses();
    setPubcodes();
    setPurchasetypes();
    setRappeltexts();
    setQuotationstatuses();
    setSectors();
    setServicecontracttypes();
    setUnits();
    setUsers();
    setUsertypes();
    setVatshifts();
    setZipcodes();
    setInitiallyLoaded(true);
  } else if (!saml && !user && !localStorageUser) {
    window.history.pushState({ pathname: '/login' }, 'login', '/login');
  }

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter([
    { path: '/login', element: <Login /> },
    {
      element: <PageWrapper />,
      children: [
        {
          element: <ProtectedRoute />,
          children: [
            { path: '/', element: <HomePage /> },
            { path: '/home', element: <HomePage /> },
            { path: '/*', element: <HomePage /> },
            { path: '/companies', element: <CompaniesPage /> },
            { path: '/companies/:id', element: <CompanyPage /> },
            { path: '/contacts', element: <ContactsPage /> },
            { path: '/contacts/:id', element: <ContactPage /> },
            { path: '/quotations', element: <QuotationsPage /> },
            { path: '/quotations/:id', element: <QuotationPage /> },
            { path: '/inquiries', element: <InquiriesPage /> },
            { path: '/inquiries/:id', element: <InquiryPage /> },
            { path: '/sales', element: <SalesPage /> },
            { path: '/sales/:id', element: <SalePage /> },
            { path: '/purchases', element: <PurchasesPage /> },
            { path: '/purchases/:id', element: <PurchasePage /> },
            { path: '/delivery_notes', element: <DeliveriesPage /> },
            { path: '/delivery_notes/:id', element: <DeliveryPage /> },
            { path: '/invoices', element: <InvoicesPage /> },
            { path: '/invoices/:id', element: <InvoicePage /> },
            { path: '/invoices_in', element: <InvoicesInPage /> },
            { path: '/invoices_in/:id', element: <InvoiceInPage /> },
            { path: '/creditnotes', element: <CreditnotesPage /> },
            { path: '/creditnotes/:id', element: <CreditnotePage /> },
            { path: '/creditnotes_in', element: <CreditnotesInPage /> },
            { path: '/creditnotes_in/:id', element: <CreditnoteInPage /> },
            { path: '/products', element: <ProductsPage /> },
            { path: '/products/:id', element: <ProductPage /> },
            { path: '/projects', element: <ProjectsPage /> },
            { path: '/projects/:id', element: <ProjectPage /> },
            { path: '/jobs', element: <JobsPage /> },
            { path: '/jobs/:id', element: <JobPage /> },
            { path: '/montage_bons', element: <MontageBonsPage /> },
            { path: '/montage_bons/:id', element: <MontageBonPage /> },
            { path: '/machines', element: <MachinesPage /> },
            { path: '/machines/:id', element: <MachinePage /> },
            { path: '/maintenance_contracts', element: <MaintenanceContractsPage /> },
            { path: '/maintenance_contracts/:id', element: <MaintenanceContractPage /> },
            { path: '/technicians', element: <TechniciansPage /> },
            { path: '/technicians/:id', element: <TechnicianPage /> },
            { path: '/cashbook', element: <CashbookPage /> },
            { path: '/holidays', element: <HolidayRequestsComponent /> },
            { path: '/account', element: <AccountPage /> },
            { path: '/files', element: <FilesPage /> },
            { path: '/analytics', element: <AnalyticsPage /> }
          ]
        },
        {
          element: <ProtectedRoute roles={[userRoles.AdminFull, userRoles.AdminData]} />,
          children: [
            { path: '/settings', element: <SettingsPage /> },
            { path: '/settings/agreement_types', element: <SettingsAgreementTypesPage /> },
            { path: '/settings/companytags', element: <SettingsCompanyTagsPage /> },
            { path: '/settings/contacttitels', element: <SettingsContactTitlesPage /> },
            { path: '/settings/documenttypes', element: <SettingsDocumentTypesPage /> },
            { path: '/settings/emailformats', element: <SettingsEmailFormatsPage /> },
            { path: '/settings/invoicestatuses', element: <SettingsInvoiceStatusesPage /> },
            { path: '/settings/machinebrands', element: <SettingsMachineBrands /> },
            { path: '/settings/machinetypes', element: <SettingsMachineTypesPage /> },
            { path: '/settings/paymentcollections', element: <SettingsPaymentCollectionsPage /> },
            { path: '/settings/paymentconditions', element: <SettingsPaymentConditionsPage /> },
            { path: '/settings/pickingplaces', element: <StockPlacesPage /> },
            { path: '/settings/pubCodes', element: <SettingsPubsPage /> },
            { path: '/settings/purchasetypes', element: <SettingsPurchaseTypes /> },
            { path: '/settings/quotationstatuses', element: <SettingsQuotationsStatusesPage /> },
            { path: '/settings/remindertexts', element: <SettingsReminderTextsPage /> },
            { path: '/settings/sectors', element: <SettingsIndustriesPage /> },
            { path: '/settings/service_contracttypes', element: <SettingsMaintenanceContractsPage /> },
            { path: '/settings/units', element: <SettingsUnitsPage /> },
            { path: '/settings/users', element: <SettingsUsersPage /> },
            { path: '/settings/usertypes', element: <SettingsUserTypesPage /> },
            { path: '/settings/vatshifts', element: <SettingsVatShiftsPage /> },
            { path: '/expenses', element: <ExpensesPage /> },
            { path: '/expenses/:userId', element: <ExpenseForUserDetailPage /> }
          ]
        }
      ]
    },
    {
      element: <ProtectedRoute />,
      children: [
        { path: '/fairs/*', element: <FairsEntry /> },
        { path: '/fairs/form', element: <FairForm /> }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
