import React from 'react';
import { ChakraProvider, Box, Button, useColorModeValue as mode } from '@chakra-ui/react';

export default function Login() {
  return (
    <ChakraProvider>
      <div
        style={{
          backgroundColor: 'white',
          height: '100vh',
          backgroundImage: 'url(schuilenburg.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Box style={{ paddingTop: '25vh' }} />
        <Box display="flex" mt={20} mx={{ sm: 'auto' }}>
          <Box bg={mode('gray', 'gray.700')} p="4" shadow="base" rounded={{ sm: 'lg' }} mx="auto">
            <Button colorScheme="whiteAlpha" variant="solid" onClick={async () => (window.location.href = '/api/saml/login')}>
              LOGIN WITH MICROSOFT ACCOUNT
            </Button>
          </Box>
        </Box>
      </div>
    </ChakraProvider>
  );
}
