import { useState, useEffect } from 'react';
import { Badge, Card, DataTable, Filters, Link, Page, Tooltip } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { useUrl } from '../../shared/util/hanldesave';
import { getTableFooter, truncate, fetchTableData } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';
import { CountryWithFlag } from '../../shared/components/countryWIthFlag.jsx';

export default function CompaniesPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const companystatuses = useLookupStore((state) => state.companystatuses);
  const countries = useLookupStore((state) => state.countries);
  const companytags = useLookupStore((state) => state.companytags);
  const choices = countries.map((item) => ({ label: `${item.Land}`, value: `${item.LandId}` }));
  const statusChoices = companystatuses.map((item) => ({ label: `${item.Status}`, value: `${item.KlantStatusID}` }));
  const tagChoices = companytags.map((item) => ({ label: item.omschrijving, value: `${item.id}` }));

  const relations = [
    { label: 'Customer', value: 'Customer' },
    { label: 'Supplier', value: 'Supplier' },
    { label: 'Private', value: 'Private' }
  ];

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    name: { type: 'text', name: 'Name', value: url.get('name') || '' },
    address: { type: 'text', name: 'Address', value: url.get('address') || '' },
    zip: { type: 'text', name: 'ZIP', value: url.get('zip') || '' },
    city: { type: 'text', name: 'City', value: url.get('city') || '' },
    country: { type: 'multiple', name: 'Country', value: url.get('country') ? JSON.parse(url.get('country')) : null, choices: choices },
    relation: { type: 'multiple', name: 'Relationship', value: url.get('relation') ? JSON.parse(url.get('relation')) : null, choices: relations },
    remark: { type: 'text', name: 'Remark', value: url.get('remark') || '' },
    tel: { type: 'text', name: 'Tel', value: url.get('tel') || '' },
    status: { type: 'multiple', name: 'Status', value: url.get('status') ? JSON.parse(url.get('status')) : null, choices: statusChoices },
    tags: { type: 'multiple', name: 'Company tags', value: url.get('tags') ? JSON.parse(url.get('tags')) : null, choices: tagChoices }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/companies', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const getTags = (c_tags) => {
    if (!c_tags) return null;
    return c_tags.map((item, idx) => (
      <Tooltip key={idx} content={item.omschrijving}>
        <Badge>{truncate(item.omschrijving, 4)}</Badge>
      </Tooltip>
    ));
  };

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const particulier = item.Particulier ? <Badge>Private</Badge> : '';
        const supplier = item.Leverancier ? <Badge>Supplier</Badge> : '';
        const customer = item.Klant ? <Badge>Customer</Badge> : '';

        const company_status =
          item.Status === 'Actief' ? (
            <Badge tone="success">Actief</Badge>
          ) : item.Status === 'Gesperd' ? (
            <Badge tone="critical">Gesperd</Badge>
          ) : item.Status === 'In Aanvraag' ? (
            <Badge tone="info">In aanvraag</Badge>
          ) : item.Status === 'Inactief' ? (
            <Badge tone="warning">Inactief</Badge>
          ) : item.Status === 'Lead' ? (
            <Badge tone="info">Lead</Badge>
          ) : (
            ''
          );

        return [
          <Link removeUnderline url={'/companies/' + item.FIRNR}>
            {item.Klantnummer}
          </Link>,
          truncate(item.NAAM, 30, false),
          item.STRAAT_NR ? String(item.STRAAT_NR).substring(0, 30) : '',
          item.POSTNR,
          item.WOONPL,
          <CountryWithFlag countryId={item.LandID} />,
          <div>
            {particulier}
            {supplier}
            {customer}
          </div>,
          getTags(JSON.parse(item.serialized_tags)),
          item.OPMERKING ? String(item.OPMERKING).substring(0, 30) : '',
          item.TEL,
          company_status
        ];
      })
    : [];

  return (
    <Page title="Companies" primaryAction={{ content: 'Add company', url: '/companies/new' }} fullWidth>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter companies on name, number, VAT, ZIP and city"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={[<b>Number</b>, <b>Name</b>, <b>Street and number</b>, <b>ZIP</b>, <b>City</b>, <b>Country</b>, <b>Relationship</b>, <b>Tags</b>, <b>Remark</b>, <b>Tel</b>, <b>Status</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'companies')}
        />
      </Card>
    </Page>
  );
}
