import { Card, DataTable, Popover, Button, InlineStack } from '@shopify/polaris';
import { useToggle } from '../../useToogle';
import { formatRoundedWeight } from '../functions';
import { CardHeading } from './CardHeading';

export default function WeightCard({ lines }) {
  const [popoverActive, setPopoverActive] = useToggle();

  return (
    <Card>
      <InlineStack align="space-between">
        <CardHeading title="Estimated weight" />
        <Popover
          active={popoverActive}
          activator={
            <Button onClick={setPopoverActive} disclosure variant="plain">
              Details
            </Button>
          }
          onClose={setPopoverActive}
          fluidContent
          preferredPosition="below"
          preferredAlignment="right"
        >
          <Popover.Pane>
            <Card>
              <CardHeading title="Estimated weights" />
              <DataTable
                stickyHeader={true}
                increasedTableDensity={true}
                verticalAlign="middle"
                showTotalsInFooter
                columnContentTypes={['text', 'numeric', 'numeric', 'numeric']}
                headings={['Item', 'Weight item', 'Qty', 'Weight total']}
                rows={lines.filter((l) => l.ProductID > 50).map((l) => [l.ProductNameNL, formatRoundedWeight(l.Kg), `x${l.aantal}`, formatRoundedWeight(l.Kg * l.aantal)])}
                totals={['', '', '', formatRoundedWeight(lines.filter((l) => l.ProductID > 50).reduce((prev, curr) => prev + curr.Kg * curr.aantal, 0))]}
              />
            </Card>
          </Popover.Pane>
        </Popover>
      </InlineStack>

      <DataTable
        stickyHeader={true}
        increasedTableDensity={true}
        verticalAlign="middle"
        columnContentTypes={['text', 'numeric']}
        headings={[]}
        rows={[
          ['Estimated Total Weight', formatRoundedWeight(lines.filter((l) => l.ProductID > 50).reduce((prev, curr) => prev + curr.Kg * curr.aantal, 0))],
          [
            'Items with weight specification',
            lines.filter((l) => l.ProductID > 50 && l.Kg).reduce((prev, curr) => prev + curr.aantal, 0) + '/' + lines.filter((l) => l.ProductID > 50).reduce((prev, curr) => prev + curr.aantal, 0)
          ]
        ]}
      />
    </Card>
  );
}
