import { InlineStack, Link, Select, Text } from '@shopify/polaris';
import { alphabetize } from './functions';

export default function ContactSelect({ value, id, onChange, label, contacts, disabled }) {
  return (
    <>
      {label && (
        <div style={{ marginBottom: '4px' }}>
          <InlineStack align="space-between">
            <Text>{label}</Text>
            {value && (
              <Link removeUnderline url={`/contacts/${value}`}>
                open
              </Link>
            )}
          </InlineStack>
        </div>
      )}

      <Select
        disabled={disabled}
        options={contacts.map((contact, idx) => ({ key: idx, value: contact.CONNR, label: contact.NAAM })).sort((a, b) => alphabetize(a, b, 'label'))}
        placeholder="Select contact"
        value={parseInt(value) || ''}
        onChange={(val) => onChange(val, id)}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: disabled ? '#ebebeb' : '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
      />
    </>
  );
}
