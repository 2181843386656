import { inProduction } from '../helpers/inProduction';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  app: 'schuilenburg-admin',
  plugins: [
    segmentPlugin({
      writeKey: 'OH4yuAMeaLND6Tli1g3Rh7bluQ49fPvE'
    })
  ]
});

export function performTracking(eventName, eventProperties) {
  if (inProduction) analytics.track(eventName, eventProperties);
}
