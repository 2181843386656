import { useLocation } from 'react-router-dom';
import { performTracking } from '../../analytics/segment';

export const handleSave = async (
  id,
  resourceName,
  resourceID,
  payload,
  setObject,
  setInitialObject,
  setToast = console.log,
  setErrorToast = console.log,
  isDirty,
  setIsDirty,
  linesDirty,
  linesPayload,
  setLines,
  setInitialLines,
  setLinesDirty,
  setSavingResult,
  navigate
) => {
  /*
      This method is creating or updating a resource with optional associated lines (like most of the objects in this system)

      It evaluatest the id to see if it has to save or update the resource.
    */
  setSavingResult(true);
  //NEW CONTENT
  if (id === 'new' && !resourceID) {
    // If the id is new, we create a new resource
    const result = await fetch(`/api/${resourceName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    //APPROVE NEW CONTENT
    if (result.status >= 200 && result.status <= 399) {
      const resultJson = await result.json();
      setObject(resultJson);
      var resourceID = resultJson[Object.keys(resultJson)[0]];
      //analytics
      performTracking(`create_${resourceName}`, payload);
      //LINES
      if (linesDirty) {
        var response = await fetch(`/api/${resourceName}/${resourceID}/lines`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(linesPayload)
        });
        //APPROVE LINES
        if (response.status >= 200 && response.status <= 399) {
          const data = await response.json();
          setLines(data);
          //analytics
          performTracking(`create_lines_${resourceName}`, linesPayload);
          //DENIED LINES
        } else {
          console.log('Error: Response', response);
          setErrorToast(true);
        }
      }
      setIsDirty(false);
      setLinesDirty(false);
      navigate(`/${resourceName}/${resourceID}`, { replace: true });

      //DENIED NEW CONTENT
    } else if (!result.ok || !response.ok) {
      console.log('Error: Result', result);
      setErrorToast(true);
    }

    //create the lines for the newly created object, need to find the id
    //of the newly created object

    //UPDATE CONTENT
  } else {
    if (isDirty) {
      var contentResult = await fetch(`/api/${resourceName}/${resourceID}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      //APPROVE CONTENT
      if (contentResult.status >= 200 && contentResult.status <= 399) {
        const data = await contentResult.json();
        setObject(data);
        setInitialObject(data);
        setIsDirty(false);
        //analytics
        performTracking(`update_${resourceName}`, payload);
        //DENIED CONTENT
      } else if (!contentResult.ok) {
        console.log('Error: Result', contentResult);
      }
    }
    //LINES
    if (linesDirty) {
      var linesResponse = await fetch(`/api/${resourceName}/${resourceID}/lines`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(linesPayload)
      });
      //APPROVE LINES
      if (linesResponse.status >= 200 && linesResponse.status <= 399) {
        const data = await linesResponse.json();
        if (data) {
          setLines(data);
          setInitialLines(data);
        }
        setIsDirty(false);
        setLinesDirty(false);
        //analytics
        performTracking(`update_lines_${resourceName}`, linesPayload);
        //DENIED LINES
      } else if (!linesResponse.ok) {
        console.log('Error: Response', linesResponse);
      }
    }
    if ((linesResponse && !linesResponse.ok) || (contentResult && !contentResult.ok)) {
      setErrorToast(true);
    } else {
      setToast(true);
    }
  }
  setSavingResult(false);
};

export const handleSaveWithoutLines = async (
  id,
  payload,
  resourceName,
  resourceID,
  resourceIdName,
  setResource,
  setInitialResource,
  setIsDirty,
  setErrorToast = console.log,
  setToast = console.log,
  setSavingResult,
  navigate
) => {
  setSavingResult(true);
  const url = id === 'new' && !resourceID ? `/api/${resourceName}` : `/api/${resourceName}/${resourceID}`;
  const method = id === 'new' && !resourceID ? 'POST' : 'PUT';
  const response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
  if (response.status >= 200 && response.status <= 399) {
    setIsDirty(false);
    const updated = await response.json();
    if (id === 'new' && !resourceID) {
      performTracking(`create_${resourceName}`, payload);
      navigate(`/${resourceName}/${updated[resourceIdName]}`, { replace: true });
    } else {
      performTracking(`update_${resourceName}`, payload);
      setToast(true);
    }
    setResource(updated);
    setInitialResource(updated);
  } else {
    console.log('Error, Response:', response);
    setErrorToast(true);
  }
  setSavingResult(false);
};

export const handleSaveWithSecondPayload = async (
  id,
  resourceName,
  resourceID,
  resourceIdName,
  payloadOne,
  payloadTwo,
  setResource,
  setInitialResource,
  resourceTwoPath,
  setResourceTwo,
  setInitialResourceTwo,
  isDirty,
  setIsDirty,
  isTwoDirty,
  setIsTwoDirty,
  setToast = console.log,
  setErrorToast = console.log,
  navigate
) => {
  if (isDirty) {
    const url = id === 'new' && !resourceID ? `/api/${resourceName}` : `/api/${resourceName}/${resourceID}`;
    const method = id === 'new' && !resourceID ? 'POST' : 'PUT';
    var contentResult = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payloadOne)
    });
    if (contentResult.status >= 200 && contentResult.status <= 399) {
      setIsDirty(false);
      const updated = await contentResult.json();
      if (id === 'new' && !resourceID) {
        // create inventory instance
        if (resourceName === 'products') {
          await fetch('/api/inventory', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              Productid: updated[resourceIdName],
              Stockplaats: 1,
              aantal: 0,
              Rack: 0,
              Legplank: 0
            })
          });
        }
        navigate(`/${resourceName}/${updated[resourceIdName]}`, { replace: true });
        performTracking(`create_${resourceName}`, payloadOne);
      } else {
        performTracking(`update_${resourceName}`, payloadOne);
      }
      setResource(updated);
      setInitialResource(updated);
    } else {
      console.log('Error: Content', contentResult);
    }
  }

  if (isTwoDirty) {
    var linesResponse = await fetch(`/api/${resourceTwoPath}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payloadTwo)
    });
    //APPROVE LINES
    if (linesResponse.status >= 200 && linesResponse.status <= 399) {
      const data = await linesResponse.json();
      let resourceData = data;
      if (resourceIdName === 'CONNR') {
        resourceData = data.map((pub) => {
          return { value: pub.counter, label: `${pub.kode} (${pub.omschrijving})` };
        });
      }
      if (resourceIdName === 'FIRNR') {
        resourceData = data.map((tag) => {
          return { value: tag.id, label: tag.omschrijving };
        });
      }
      setResourceTwo(resourceData);
      setInitialResourceTwo(resourceData);
      setIsTwoDirty(false);
      //analytics
      performTracking(`update_${resourceName}`, payloadTwo);
      //DENIED LINES
    } else if (!linesResponse.ok) {
      console.log('Error: Response', linesResponse);
    }
  }

  if ((linesResponse && !linesResponse.ok) || (contentResult && !contentResult.ok)) {
    setErrorToast(true);
    if (resourceTwoPath.includes('approvals')) throw new Error();
  } else {
    setToast(true);
  }
};

export const handleDiscard = async (initialObject, initialLines, setObject, setLines, setIsDirty, setLinesDirty) => {
  setObject(initialObject);
  setLines(initialLines);
  setIsDirty(false);
  setLinesDirty(false);
};

export const removeLineByIndex = (setLines, setLinesDirty, rowIndex, setIsBlurred) => {
  setLines((old) => old.filter((row, index) => index !== rowIndex));
  setLinesDirty(true);
  setIsBlurred(true);
};

export const addEmptyLine = (setLines, setLinesDirty, lineInterface) => {
  setLines((old) => [...old, lineInterface]);
  setLinesDirty(true);
};

export const addLineByIndex = (setLines, setLinesDirty, lineInterface, index) => {
  setLines((old) => [...old.slice(0, index), lineInterface, ...old.slice(index)]);
  setLinesDirty(true);
};

export const shiftLineUpIndex = (setLines, setLinesDirty, rowIndex) => {
  //The line ids are not overwritten so this is not persisted to the database, not a problem as we only allow it
  //when resource is not yet created thus just on the frontend
  setLines((old) => [...old.slice(0, rowIndex - 1), old[rowIndex], old[rowIndex - 1], ...old.slice(rowIndex + 1)]);
  setLinesDirty(true);
};

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useUrl() {
  return new URLSearchParams(useLocation().search);
}
