import { BlockStack, Card, DescriptionList, InlineGrid, InlineStack, Link, Spinner, Text } from '@shopify/polaris';
import { formatDate, formatToCurrency, postRequest, putRequest } from '../../../shared/components/functions';
import { FormattedHTML } from '../../../shared/components/FormattedHtml';
import { useEffect, useState } from 'react';

export const ReceiptCard = ({ expense, fetchData, setToast, setToastError }) => {
  const [S3ObjectUrl, setS3ObjectUrl] = useState();
  const [loading, setLoading] = useState({ approve: false, decline: false });

  useEffect(() => {
    async function fetchS3ObjectUrl() {
      const s3Key = expense.s3_key;
      if (!s3Key || s3Key.length === 0) return setS3ObjectUrl(null);
      const data = await postRequest(`/api/s3-expenses`, { key: s3Key });
      setS3ObjectUrl(data);
    }
    if (expense.s3_key) fetchS3ObjectUrl();
  }, []);

  const items = [
    {
      term: 'Expense type',
      description: expense.expenseType
    },
    {
      term: 'Date',
      description: formatDate(expense.Datum)
    },
    {
      term: 'Sum',
      description: formatToCurrency(expense.Prijs)
    },
    {
      term: 'Timeslot date',
      description: expense.startTechnician.substring(0, 10)
    },
    {
      term: 'Technician time',
      description: `${expense.startTechnician.substring(11, 16)} - ${expense.endTechnician.substring(11, 16)}`
    },
    {
      term: 'Planned time',
      description: `${expense.startPlanned.substring(11, 16)} - ${expense.endPlanned.substring(11, 16)}`
    },
    {
      term: 'Timeslot remarks',
      description: <FormattedHTML html={expense.timeslotRemarks || ''} />
    }
  ];

  if (expense.jobTypeId === '3') {
    items.splice(
      8,
      0,
      {
        term: 'Job number',
        description: (
          <Link url={`/jobs/${expense.job}`} removeUnderline>
            {expense.jobNumber}
          </Link>
        )
      },
      {
        term: 'Customer',
        description: (
          <Link url={`/companies/${expense.jobCustomer}`} removeUnderline>
            {expense.jobCustomerName}
          </Link>
        )
      },
      {
        term: 'Job internal description',
        description: <FormattedHTML html={expense.jobDescription || ''} />
      },
      {
        term: 'Job external description',
        description: <FormattedHTML html={expense.jobRemarks || ''} />
      }
    );
  }

  const image =
    S3ObjectUrl && S3ObjectUrl.url ? (
      <InlineStack align="center">
        <img src={S3ObjectUrl.url} alt="receipt" />
      </InlineStack>
    ) : (
      ''
    );

  const buttonStyle = { borderRadius: '8px', height: '60px', width: '120px', color: 'white', fontSize: 20, fontWeight: 'bold', cursor: 'pointer' };

  const approveReceipt = async (value) => {
    setLoading({ ...loading, [value]: true });
    await putRequest(`/api/expenses/${expense.OnkostenId}`, { approved: value === 'approve' }, setToast, setToastError);
    setLoading({ ...loading, [value]: false });
    fetchData();
  };

  return (
    <Card>
      <BlockStack gap="400">
        <Text variant="headingLg" as="h5">{`Receipt #${expense.OnkostenId}`}</Text>
        <InlineGrid align="space-between" gap="500" columns={['twoThirds', 'oneThird']}>
          <BlockStack align="space-between" gap="500">
            <DescriptionList items={items} />
            <InlineStack align="center" gap="800">
              <button
                style={{ ...buttonStyle, background: '#22AB38', border: '1px solid #1C902E' }}
                disabled={loading.approve || loading.decline}
                type="button"
                onClick={() => approveReceipt('approve')}
              >
                {loading.approve ? <Spinner size="small" /> : 'APPROVE'}
              </button>
              <button
                style={{ ...buttonStyle, background: '#E13F3F', border: '1px solid #BA3838' }}
                disabled={loading.approve || loading.decline}
                type="button"
                onClick={() => approveReceipt('decline')}
              >
                {loading.decline ? <Spinner size="small" /> : 'DECLINE'}
              </button>
            </InlineStack>
          </BlockStack>
          {image}
        </InlineGrid>
      </BlockStack>
    </Card>
  );
};
