import { Card, Badge, Button, Modal, Box, InlineStack, Text } from '@shopify/polaris';
import { useEffect, useRef, useState } from 'react';
import { useLookupStore } from '../../../context/useStore';
import ReactJsonViewCompare from 'react-json-view-compare';
import { CardHeading } from '../cards/CardHeading';

export default function EventTimeLine({ resourceName, resourceId }) {
  const users = useLookupStore((state) => state.users);
  const [selectedEvents, setSelectedEvents] = useState([]);

  async function fetchEvents() {
    if (resourceId === undefined || resourceId === 'new') return;

    try {
      const response = await fetch(`/api/events/${resourceName}/${resourceId}`);
      const events = await response.json();
      if (events) setSelectedEvents(events);
    } catch (err) {
      console.error('Network error fetching events:', err);
    }
  }

  useEffect(() => {
    fetchEvents();
  }, []);

  if (resourceId === undefined || resourceId === 'new') return <></>;
  if (selectedEvents.length === 0) return null;

  return (
    <Card>
      <CardHeading title="Timeline" />
      <div style={{ overflowY: selectedEvents?.length > 24 ? 'auto' : 'hidden', maxHeight: '800px', paddingTop: '5px' }}>
        {Array.isArray(selectedEvents) &&
          selectedEvents?.map((event, idx) => {
            return (
              <Box key={'event-' + idx} paddingBlockEnd={'100'}>
                <InlineStack gap="300">
                  <Text>
                    {event.type === 'CREATE' || event.type === 'REMINDER_CREATED'
                      ? '🎉'
                      : event.type === 'UPDATE'
                      ? '🛠'
                      : event.type === 'DELETE'
                      ? '❌'
                      : event.type === 'EMAIL_SENT' || event.type === 'REMINDER_SENT'
                      ? '✉️'
                      : '📣'}
                  </Text>
                  <Text>{users.find((user) => user.GebruikerID === event.user_id)?.Afkorting}</Text>
                  <Text>
                    <Badge tone="success">
                      {event.type === 'CREATE' ? 'create' : event.type === 'UPDATE' ? 'update' : event.type === 'DELETE' ? 'delete' : event.type === 'MAIL' ? 'mail sent' : event.type}
                    </Badge>
                  </Text>
                  <Text>{getNiceDate(event.timestamp * 1)}</Text>
                  {getChangedFields(event, idx, selectedEvents)}
                  <PayloadModal event={event} idx={idx} selectedEvents={selectedEvents} />
                </InlineStack>
              </Box>
            );
          })}
      </div>
    </Card>
  );
}

export function getNiceDate(timestamp) {
  const date = new Date(timestamp);
  const now = new Date();

  const secondsAgo = Math.floor((now - date) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);

  if (secondsAgo < 60) return 'Just now';
  if (minutesAgo < 2) return '1 minute ago';
  if (minutesAgo < 60) return `${minutesAgo} minutes ago`;
  if (hoursAgo < 2) return '1 hour ago';
  if (date.toDateString() === now.toDateString()) return `${hoursAgo} hour(s) ago`;

  // Yesterday
  let yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday at ${date.toLocaleTimeString()}`;
  }

  // For days older than yesterday
  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return date.toLocaleString(undefined, options);
}

function diffKeys(obj1, obj2) {
  let diffKeys = [];

  // Get all the keys from both objects
  let keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  // Check if the values for each key are the same in both objects
  keys.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      diffKeys.push(key);
    }
  });

  return diffKeys;
}

function getChangedFields(event, idx, selectedEvents) {
  // if (idx === 0) return;
  if (event.type !== 'UPDATE') return;

  const previousEvent = selectedEvents[idx + 1];
  if (previousEvent?.data === undefined || previousEvent.type !== 'UPDATE') return;

  return diffKeys(JSON.parse(event.data), JSON.parse(selectedEvents[idx + 1]?.data)).map((key, idx) => {
    return (
      <div key={`subkey-${key}`}>
        <Badge key={idx}>{key}</Badge>
      </div>
    );
  });
}

const PayloadModal = ({ event, idx, selectedEvents }) => {
  const [active, setActive] = useState(false);
  const buttonRef = useRef(null);

  const eventData = JSON.parse(event.data);

  let previousEventSameType = null;
  if (event.type === 'UPDATE') previousEventSameType = selectedEvents.slice(idx + 1).find((e) => e.type === event.type || e.type === 'CREATE');
  else previousEventSameType = selectedEvents.slice(idx + 1).find((e) => e.type === event.type);

  if (!event?.data) return null;

  const activator = (
    <div ref={buttonRef}>
      <Button size="micro" onClick={() => setActive(true)}>
        🔖
      </Button>
    </div>
  );

  if (event.type === 'EMAIL_SENT') {
    return (
      <>
        {activator}
        <Modal activator={buttonRef} open={active} size="large" onClose={() => setActive(false)} title="Email sent event">
          <Modal.Section>TO: {eventData?.toAddress}</Modal.Section>
          <Modal.Section>CC: {eventData?.cc?.join(', ')}</Modal.Section>
          <Modal.Section>BCC: {eventData?.bcc?.join(', ')}</Modal.Section>
          <Modal.Section>{eventData?.emailSubject}</Modal.Section>
          <Modal.Section>
            <div style={{ font: 'inherit' }}>
              <pre>{eventData?.emailBody}</pre>
            </div>
          </Modal.Section>
          <Modal.Section>Emailed docs: {eventData?.sharepointDocs?.map((doc) => doc.sharepointDocName).join(', ')}</Modal.Section>
        </Modal>
      </>
    );
  }

  if (!previousEventSameType)
    return (
      <>
        {activator}
        <Modal activator={buttonRef} open={active} size="large" onClose={() => setActive(false)} title="Event">
          <Modal.Section>
            <pre>{JSON.stringify(JSON.parse(event.data), null, 2)}</pre>
          </Modal.Section>
        </Modal>
      </>
    );

  // if eventTypeIsUpdateAndACreateEventExists then create the update with the create event

  // compare if event with same type exists that happend before this event
  return (
    <>
      {activator}
      <Modal activator={buttonRef} open={active} size="large" onClose={() => setActive(false)} title="Event">
        <Modal.Section>
          <ReactJsonViewCompare newData={JSON.parse(event?.data)} oldData={JSON.parse(previousEventSameType?.data)} />
        </Modal.Section>
      </Modal>
    </>
  );
};
