import { ChakraProvider, Container, Stack, Heading, useColorModeValue } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CompanySelect from './helpers/companySelect';
import CombinedForms from './helpers/forms';
import { Helmet } from 'react-helmet';
import { useLookupStore } from '../../context/useStore';

export default function FairForm() {
  const location = useLocation();
  const [company, setCompany] = useState({});
  const [newCompany, setNewCompany] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pubcodes } = useLookupStore();

  let pubs = location.search;
  pubs = pubs.substring(pubs.indexOf('[') + 1, pubs.indexOf(']')).split(',');

  let titlepubs = pubs.map((pub) => {
    return pubcodes.filter((pc) => pc.counter === parseInt(pub))[0]?.kode;
  });

  const companySelect = <CompanySelect isClearable={false} maxW={{ lg: '3xl' }} label="Company" setCompany={setCompany} setNewCompany={setNewCompany} setNewContact={setNewContact} />;

  const combinedForms = <CombinedForms newCompany={newCompany} newContact={newContact} company={company} isLoading={isLoading} setIsLoading={setIsLoading} pubs={pubs} />;

  return (
    <ChakraProvider>
      <Helmet>
        <link
          rel="icon"
          href={window.location.href.includes('perfectmoose') ? 'https://perfectmoose.com/favicon.ico' : 'https://schuilenburg.com/wp-content/uploads/2019/03/cropped-schuilenburg-ico.png'}
        />
        <title>{'Fairs - ' + titlepubs}</title>
      </Helmet>
      <Container
        py={{
          base: '4',
          md: '8'
        }}
        color="white"
        bgColor={useColorModeValue('sm', 'sm-dark')}
        minH="100vh"
      >
        <Stack spacing="5">
          <Heading>👋 How can we contact you?</Heading>
        </Stack>
        <Stack spacing="1">
          {companySelect}​{combinedForms}
        </Stack>
      </Container>
    </ChakraProvider>
  );
}
