import React from 'react';
import { Icon, Page, Card, ActionList, BlockStack } from '@shopify/polaris';
import {
  ChevronRightIcon,
  EmailIcon,
  CashEuroIcon,
  CategoriesIcon,
  PersonAddIcon,
  PersonIcon,
  OrderDraftIcon,
  EmailNewsletterIcon,
  FormsIcon,
  IconsIcon,
  TargetIcon,
  NoteIcon,
  PaymentIcon,
  ProductIcon,
  ProfileIcon,
  ReceiptIcon,
  ReplaceIcon,
  ShopcodesIcon,
  TaxIcon,
  WrenchIcon,
  InventoryUpdatedIcon,
  CollectionIcon
} from '@shopify/polaris-icons';
import { CardHeading } from '../../shared/components/cards/CardHeading';

function GeneralSettingsList() {
  return (
    <ActionList
      items={[
        {
          content: 'Industries',
          prefix: <Icon source={InventoryUpdatedIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/sectors'
        },
        {
          content: 'Contact titles',
          prefix: <Icon source={PersonAddIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/contacttitels'
        },
        {
          content: 'Company tags',
          prefix: <Icon source={CollectionIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/companytags'
        },
        {
          content: 'PUB codes',
          prefix: <Icon source={ShopcodesIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/pubCodes'
        },
        {
          content: 'Quotation statuses',
          prefix: <Icon source={IconsIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/quotationstatuses'
        },
        {
          content: 'Purchase types',
          prefix: <Icon source={OrderDraftIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/purchasetypes'
        },
        {
          content: 'Product units',
          prefix: <Icon source={ProductIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/units'
        },
        {
          content: 'Picking places',
          prefix: <Icon source={ReplaceIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/pickingplaces'
        },
        {
          content: 'Maintenance contract types',
          prefix: <Icon source={WrenchIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/service_contracttypes'
        },
        {
          content: 'Machine brands',
          prefix: <Icon source={CategoriesIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/machinebrands'
        },
        {
          content: 'Machine types',
          prefix: <Icon source={EmailNewsletterIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/machinetypes'
        },
        {
          content: 'Agreement types',
          prefix: <Icon source={FormsIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/agreement_types'
        }
      ]}
    />
  );
}

function EmailDocSettingsList() {
  return (
    <ActionList
      items={[
        {
          content: 'Document types',
          prefix: <Icon source={NoteIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/documenttypes'
        },
        {
          content: 'Email formats',
          prefix: <Icon source={EmailIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/emailformats'
        }
      ]}
    />
  );
}

function FincancialSettingsList() {
  return (
    <ActionList
      items={[
        {
          content: 'Payment reminder texts',
          prefix: <Icon source={TargetIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/remindertexts'
        },
        {
          content: 'Payment conditions',
          prefix: <Icon source={PaymentIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/paymentconditions'
        },
        {
          content: 'VAT shifts',
          prefix: <Icon source={TaxIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/vatshifts'
        },
        {
          content: 'Invoice statuses',
          prefix: <Icon source={ReceiptIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/invoicestatuses'
        },
        {
          content: 'Payment collection types',
          prefix: <Icon source={CashEuroIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/paymentcollections'
        }
      ]}
    />
  );
}

function UsersSettingsList() {
  return (
    <ActionList
      items={[
        {
          content: 'Users',
          prefix: <Icon source={ProfileIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/users'
        },
        {
          content: 'User types',
          prefix: <Icon source={PersonIcon} />,
          suffix: <Icon source={ChevronRightIcon} />,
          url: '/settings/usertypes'
        }
      ]}
    />
  );
}

export default function SettingsPage() {
  return (
    <Page title="Settings" fullWidth>
      <BlockStack gap="400">
        <Card>
          <CardHeading title="General settings" />
          <GeneralSettingsList />
        </Card>
        <Card>
          <CardHeading title="Financial settings" />
          <FincancialSettingsList />
        </Card>
        <Card>
          <CardHeading title="Email and document settings" />
          <EmailDocSettingsList />
        </Card>
        <Card>
          <CardHeading title="User settings" />
          <UsersSettingsList />
        </Card>
      </BlockStack>
    </Page>
  );
}
