import { useState, useEffect } from 'react';
import { Modal, TextField, Banner, DescriptionList } from '@shopify/polaris';
import { formatToCurrency, getCurrenctDatetimeYYYMMDD, putRequest } from '../../../shared/components/functions';
import { performTracking } from '../../../analytics/segment';
import IncasserenSelect from '../../../shared/components/incasserenSelect';

export default function InvoiceInCollection({ modalOpen, setModalOpen, fullInvoiceCollection, collectionInvoice, fetchData }) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState({
      bedragopen: fullInvoiceCollection ? 0 : Math.round(collectionInvoice.bedragopen * 100) / 100,
      faktuurinvolgnummer: collectionInvoice.faktuurinvolgnummer,
      memo: collectionInvoice.memo || '',
      status: fullInvoiceCollection ? 1 : collectionInvoice.status,
      partiallyPaid: 0,
      datumbetalingout: fullInvoiceCollection ? getCurrenctDatetimeYYYMMDD() : null,
      Incasseren: fullInvoiceCollection ? 'BELFIUS' : null
    });
  }, [collectionInvoice, fullInvoiceCollection]);

  const handleChange = (value, id) => {
    if (id === 'partiallyPaid') {
      setState((state) => ({
        ...state,
        bedragopen: Math.round((collectionInvoice.bedragopen - parseFloat(String(value).replace(',', '.'))) * 100) / 100
      }));
    }
    setState((state) => ({ ...state, [id]: value }));
  };

  const collectInvoice = async () => {
    setLoading(true);
    await putRequest(`/api/invoices_in/${state.faktuurinvolgnummer}`, state);
    setModalOpen(false);
    setLoading(false);
    await fetchData();
    if (fullInvoiceCollection) {
      performTracking('payment_collected_invoice_in_full', state);
    } else {
      performTracking('payment_collected_invoice_in_partial', state);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title={`Payment invoice #${collectionInvoice.FactuurInNummer}`}
      primaryAction={{
        content: fullInvoiceCollection ? 'Mark as fully paid' : 'Mark as partially paid',
        onAction: () => collectInvoice(),
        loading: loading
      }}
      secondaryActions={[{ content: 'Cancel', onAction: () => setModalOpen(false) }]}
    >
      <Modal.Section>
        {fullInvoiceCollection ? (
          <Banner tone="info">The invoice status will be automatically set to paid. And the open amount to 0.</Banner>
        ) : (
          <Banner tone="warning">The invoice status will not be set to paid. Please set the remaining open value yourself.</Banner>
        )}
        <br />
        <DescriptionList
          items={[
            {
              term: 'Invoice number',
              description: `#${collectionInvoice.FactuurInNummer}`
            },
            {
              term: 'Invoice reference',
              description: collectionInvoice.faktuurref
            },
            {
              term: 'Supplier',
              description: collectionInvoice.supplier_name
            },
            {
              term: 'Total value',
              description: formatToCurrency(collectionInvoice.Bedragfaktuur)
            }
          ]}
        />
        <br />
        <br />
        {fullInvoiceCollection && (
          <>
            <TextField id="datumbetalingout" type="date" label="Payment date" value={String(state.datumbetalingout).substring(0, 10)} onChange={handleChange} />
            <br />
          </>
        )}
        {!fullInvoiceCollection && (
          <>
            <TextField
              prefix="€"
              id="partiallyPaid"
              disabled={fullInvoiceCollection}
              type="currency"
              label="AMOUNT THAT IS PARTIALLY PAID"
              value={String(state.partiallyPaid)}
              onChange={handleChange}
            />
            <br />
          </>
        )}
        <TextField id="bedragopen" disabled type="number" prefix="€" label="Open amount" value={String(state.bedragopen)} onChange={handleChange} />
        <br />
        {fullInvoiceCollection && (
          <>
            <IncasserenSelect id="Incasseren" value={state.Incasseren} handleChange={handleChange} />
            <br />
          </>
        )}
        <TextField multiline={5} id="memo" label="Note" value={state.memo} onChange={handleChange} />
        <br />
      </Modal.Section>
    </Modal>
  );
}
