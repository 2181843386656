import { BlockStack, InlineStack, Text } from '@shopify/polaris';
import { useRef, useState } from 'react';
import Select, { components } from 'react-select';

const Input = (props) => <components.Input {...props} isHidden={false} />;

export const ContactReactSelect = ({ value, id, onChange, label, contacts, disabled }) => {
  const [inputValue, setInputValue] = useState(value);
  const [selectedOption, setSelectedOption] = useState({ label: value, value: value });

  const contactArr = contacts.map((c) => ({ value: c.CONNR, label: c.NAAM }));
  const options = useRef(contactArr).current;
  const selectRef = useRef();

  const onInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      setInputValue(inputValue);
      onChange(inputValue, id);
    }
  };

  const handleChange = (option) => {
    onChange(option ? option.label : '', id);
    setInputValue(option ? option.label : '');
    setSelectedOption(option);
  };

  const onFocus = () => value && selectRef.current?.select?.inputRef.select();

  return (
    <div style={{ width: '100%' }}>
      <BlockStack>
        {label && (
          <div style={{ marginBottom: '4px' }}>
            <InlineStack align="space-between">
              <Text>{label}</Text>
            </InlineStack>
          </div>
        )}

        <Select
          id={id}
          ref={selectRef}
          options={options}
          isClearable
          value={selectedOption}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onChange={handleChange}
          onFocus={onFocus}
          controlShouldRenderValue={false}
          components={{ Input }}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          styles={{
            valueContainer: (base) => ({ ...base, height: '32px' }),
            indicatorSeperator: (base) => ({ ...base, height: '32px' }),
            indicatorsContainer: (base) => ({ ...base, height: '32px' }),
            control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: disabled ? '#ebebeb' : '#919191' }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 })
          }}
        />
      </BlockStack>
    </div>
  );
};
