import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { extendTheme, ChakraProvider, useColorModeValue, Container, Stack, StackDivider, Box, FormControl, Flex, Button, FormLabel } from '@chakra-ui/react';
import Select from 'react-select';
import { useLookupStore } from '../../context/useStore';
import Helmet from 'react-helmet';
import { pubsToApiUrl } from '../../shared/components/functions';

export default function FairsEntry() {
  const { pubcodes } = useLookupStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [pubs, setPubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(props) {
    setPubs(props, navigate(`${location.pathname}?${pubsToApiUrl({ ...props })}`, { replace: true }));
  }

  function handleSave() {
    setIsLoading(true);
    navigate(`/fairs/form${location.search}`);
  }

  const theme = extendTheme({
    components: {
      Modal: {
        baseStyle: (props) => ({
          dialog: {
            maxWidth: ['95%', '95%', '95%'],
            minWidth: '95%',
            bg: '#00ff00',
            color: 'red'
          }
        })
      }
    }
  });

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <link
          rel="icon"
          href={window.location.href.includes('perfectmoose') ? 'https://perfectmoose.com/favicon.ico' : 'https://schuilenburg.com/wp-content/uploads/2019/03/cropped-schuilenburg-ico.png'}
        />
        <title>{'Fairs'}</title>
      </Helmet>
      <Container
        py={{
          base: '4',
          md: '8'
        }}
        color="white"
        bgColor={useColorModeValue('sm', 'sm-dark')}
        minH="100vh"
      >
        <Stack spacing="5" divider={<StackDivider />}>
          <Stack
            direction={{
              base: 'column',
              lg: 'row'
            }}
            spacing={{
              base: '5',
              lg: '8'
            }}
            justify="space-between"
          >
            <Box bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')} borderRadius="lg" flex="1">
              <Stack
                spacing="5"
                px={{
                  base: '4',
                  md: '6'
                }}
                py={{
                  base: '5',
                  md: '6'
                }}
              >
                <FormControl id="pubcodes">
                  <FormLabel>Select Pubcodes for this event</FormLabel>
                  <div style={{ color: 'black' }}>
                    <Select
                      isMulti
                      name="pubcodes"
                      options={pubcodes.map((pub) => {
                        return {
                          value: pub.counter,
                          label: `${pub.kode} (${pub.omschrijving})`
                        };
                      })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={pubs}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </FormControl>
              </Stack>
              <Flex
                direction="row-reverse"
                py="4"
                px={{
                  base: '4',
                  md: '6'
                }}
              >
                <Button onClick={() => handleSave()} disabled={pubs.length === 0} type="submit" isLoading={isLoading} color="black" backgroundColor="white">
                  Go to Form
                </Button>
              </Flex>
            </Box>
          </Stack>
          <Flex
            direction="row-reverse"
            py="4"
            px={{
              base: '4',
              md: '6'
            }}
          >
            <Button onClick={() => (window.location = '/')} type="submit" isLoading={isLoading} color="black" backgroundColor="white">
              Back to the admin
            </Button>
          </Flex>
        </Stack>
      </Container>
    </ChakraProvider>
  );
}
