function handleEntryValues(e, entry, setEntry) {
  const entryKeys = Object.keys(entry);
  for (var i = 0; i < entryKeys.length; i++) {
    let prevEntryKey = entry[entryKeys[i]];
    let newEntryKey = {
      type: prevEntryKey?.type,
      label: prevEntryKey?.label,
      max: prevEntryKey?.max,
      multi: prevEntryKey?.multi,
      disabled: prevEntryKey?.disabled,
      required: prevEntryKey?.required,
      value: prevEntryKey?.type === 'active' ? prevEntryKey?.value : e
    };
    const key = entryKeys[i];
    setEntry((entry) => ({ ...entry, [key]: newEntryKey }));
  }
}

function openAddModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId) {
  handleEntryValues('', entry, setEntry);
  setIsAdd(true);
  setEntryId(null);
  setModalOpen();
}

function openEditModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, itemID) {
  setModalOpen();
  setIsAdd(false);
  setEntryId(itemID);
  const entryKeys = Object.keys(entry);
  for (var i = 0; i < entryKeys.length; i++) {
    let prevEntryKey = entry[entryKeys[i]];
    let newEntryKey = {
      type: prevEntryKey?.type,
      label: prevEntryKey?.label,
      max: prevEntryKey?.max,
      multi: prevEntryKey?.multi,
      disabled: prevEntryKey?.disabled,
      required: prevEntryKey?.required,
      value: item[entryKeys[i]]
    };
    const key = entryKeys[i];
    setEntry((entry) => ({ ...entry, [key]: newEntryKey }));
  }
}

function handleInputChange(e, entry, setEntry, key) {
  let prevkey = entry[key];
  let newkey = {
    type: prevkey?.type,
    label: prevkey?.label,
    max: prevkey?.max,
    multi: prevkey?.multi,
    disabled: prevkey?.disabled,
    required: prevkey?.required,
    value: e
  };
  setEntry((entry) => ({ ...entry, [key]: newkey }));
}

function flattenEntry(entry) {
  let mapped = Object.keys(entry).map((key) => {
    return { [key]: entry[key].value };
  });
  return Object.assign({}, ...mapped);
}

export { openEditModal, openAddModal, handleInputChange, flattenEntry };
