export const updateMyData = async (rowIndex, columnId, value, setLines, setLinesDirty, resource, company, setIsBlurred) => {
  let valueChange = false;

  //updates the respective field
  setLines((old) =>
    old.map((row, index) => {
      if (index === rowIndex) {
        //set lines dirty if change in value
        if (row[columnId] !== value) {
          setLinesDirty(true);
          valueChange = true;
        }

        return {
          ...old[rowIndex],
          [columnId]: columnId === 'VP' || columnId === 'AP' || columnId === 'aantal' ? parseFloat(String(value).replace(',', '.')) : value,
          lineIsDirty: true
        };
      }
      return row;
    })
  );

  //if the columnid is the produktnr then call the api to get the product info
  if (columnId === 'produktnr' && valueChange) {
    if (!value) {
      return setLines((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return { ...old[rowIndex], produktnr: '', tekstlijn: '', notExisting: false, VP: '' };
          }
          return row;
        })
      );
    }

    //check if value is a number and an integer
    let isInteger = Number.isInteger(Number(value));
    if (isNaN(value) || value === '' || !isInteger) return;

    //call the api to get the product info
    const response = await fetch(`/api/products/${value}`);

    if (response.status === 404) {
      alert('Product does not exist');
      return setLines((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              produktnr: value,
              tekstlijn: '',
              notExisting: true
            };
          }
          return row;
        })
      );
    }

    const data = await response.json();
    if (!data) return;

    let preparedTekstlijn = '';
    //switch over taalID to set the preparedTekstlijn
    switch (parseInt(resource.TaalID)) {
      case 1:
        preparedTekstlijn = data.ProductNameNL;
        break;
      case 2:
        preparedTekstlijn = data.ProductNameFR;
        break;
      case 3:
        preparedTekstlijn = data.ProductNameUK;
        break;
      case 4:
        preparedTekstlijn = data.ProductNameDE;
        break;
      default:
        preparedTekstlijn = data.ProductNameNL;
        break;
    }

    //check if discontinued
    if (data.Discontinued) alert('Product is discontinued.');

    //check if datum bijw_VP is older than one year
    if (data['Datum  bijw_ VP']) {
      const date = new Date(data['Datum  bijw_ VP']);
      const currentDate = new Date();
      const oneYear = 1000 * 60 * 60 * 24 * 365;
      const difference = currentDate - date;

      if (difference > oneYear) {
        alert('Pay attention: Selling price of added product has not been updated for longer than one year.');
      }
    }

    setLines((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            produkthulpnr: data.ProductID, //set the hulp number equal to the product id to ensure proper working of access
            ProductID: data.ProductID,
            ProductName: data.name,
            ProductNameNL: data.name_nl,
            ProductNameFR: data.name_fr,
            Productref: data.ref,
            AP: data.Eenheidsaankoopprijs,
            VP: data['Eenheids verkoopprijs'],
            'Datum  bijw_ VP': data['Datum  bijw_ VP'],
            'Datum bijw_AP': data['Datum bijw_AP'],
            tekstlijn: preparedTekstlijn,
            BTW: parseInt(company.LandID) === 300 ? 21 : 0,
            aantal: parseInt(value) === 1 ? (row.aantal ? row.aantal : null) : row.aantal === null || row.aantal === '' ? 1 : row.aantal,
            Eenheid: data.EenheidID,
            ProductNameDE: data.ProductNameDE,
            ProductNameUK: data.ProductNameUK,
            EenheidID: data.unit_id,
            EenheidAantal: data.EenheidAantal,
            ParentProductID: data.parent_product_id,
            HasDetails: data.HasDetails,
            HSCode: data.HSCode,
            Kg: data.Kg,
            OnderdelenTypeID: data.OnderdelenTypeID,
            Discontinued: data.Discontinued,
            notExisting: false
          };
        }
        return row;
      })
    );
  }
  setIsBlurred(true);
};
