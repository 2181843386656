import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Layout, Toast, Banner, Tabs, Page, Card, Modal, TextField, Text, InlineStack, Box, BlockStack } from '@shopify/polaris';
import TechnicianOvertimeCalendar from './components/technicianOvertimeCalendarComponent';
import TechnicianSaldoComponent from './components/technicianSaldoComponent';
import TechnicianCorrectionsComponent from './components/technicianCorrectionComponent';
import { getRequest } from '../../shared/components/functions';
import { useToggle } from '../../shared/useToogle';

/*
    - wachtdienst
    - what with algo's that are ran at startup java programm
    - overuren saldo in technicians overview is not being displayed correctly
*/

export default function TechnicianPage() {
  let { id } = useParams();

  const [technician, setTechnician] = useState('');
  const [last_month_locked, setLastMonthLocked] = useState(null);
  const [planningMonthFilled, setPlanningMonthFilled] = useState(null);
  const [calenderItems, setCalenderItems] = useState('placeholder data'); //eslint-disable-line
  const [modalOpen, setModalOpen] = useState(false);
  const [overhoursToPayOut100Procent, setOverhoursToPayOut100Procent] = useState('0');
  const [lockingMonth, setLockingMonth] = useState(false);
  const [saldos, setSaldos] = useState([]);
  const [toastClosing, setToastClosing] = useState(false);
  const [toastPdf, setToastPdf] = useToggle();
  const [toastPdfError, setToastPdfError] = useToggle();

  /*
      STATUS BANNER LOGIC:
      if last month locked:
                  - Everything is ok 
            if last month not locked and lockable: 
                  - this technician needs to be locked and is lockable
            if last month not locked and not lockable
                  - planning is not complete, fill in planning before locking
  
  */
  var banner_ok = (
    <Box paddingBlockEnd={'400'}>
      <Banner title={`Last month is locked for ${technician.Gebruiker}. Everything up to date.`} tone="success">
        <Text>Last month is locked, lock this month at the beginning of next month.</Text>
      </Banner>
    </Box>
  );

  var banner_planning_ok_not_locked = (
    <Box paddingBlockEnd={'400'}>
      <Banner title={`Last month not locked, planning is ok.`} tone="warning">
        <Text>{`Planning from  ${technician.Gebruiker} is fully filled in but last month still needs to be locked.`}</Text>
      </Banner>
    </Box>
  );

  var banner_planning_nok_not_locked = (
    <Box paddingBlockEnd={'400'}>
      <Banner title={`Last month not locked & planning is not yet fully filled in by ${technician.Gebruiker}!`} tone="critical">
        <Text>Planning of last month needs to be completed before locking of last month can happen!</Text>
      </Banner>
    </Box>
  );

  const technician_info_banner =
    last_month_locked !== null && planningMonthFilled !== null
      ? last_month_locked
        ? banner_ok
        : planningMonthFilled
        ? !last_month_locked
          ? banner_planning_ok_not_locked
          : null
        : banner_planning_nok_not_locked
      : null;

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    //do health checks on timeslot table
    await fetch('/api/technicians/timeslots/healthchecks');

    const data = await getRequest(`/api/technicians/${id}`);

    setTechnician(data[0]);
    var d = new Date();
    d.setDate(0); //last day previous month
    var locked_check = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2); //check if last month is
    if (String(data[0].Datum).substring(0, 7) === locked_check) {
      setLastMonthLocked(true);
    } else {
      setLastMonthLocked(false);
    }

    const saldos = await getRequest(`/api/technicians/${id}/saldos`);
    setSaldos(saldos);
  };

  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const tabs = [
    {
      id: 'overtime-calendar',
      content: 'Overtime overview',
      comp: <TechnicianOvertimeCalendar technician_id={id} setPlanningMonthFilled={setPlanningMonthFilled} setCalenderItems={setCalenderItems} />,
      panelID: 'all-customers-content-1'
    },
    {
      id: 'accepts-marketing-1',
      content: 'Payouts and corrections',
      comp: <TechnicianCorrectionsComponent technician_id={id} />,
      panelID: 'accepts-marketing-content-1'
    }
  ];

  var page_subtitle =
    technician !== '' ? (
      <InlineStack gap="200">
        <Badge>{technician.Afkorting}</Badge>
        <Badge tone="success">{technician.Email}</Badge>
      </InlineStack>
    ) : null;
  var button_disabled_lock_last_month = !last_month_locked && planningMonthFilled ? false : true;
  var button_send_overtime_pdf = !last_month_locked && planningMonthFilled ? true : false;

  async function payOverhoursModal() {
    setModalOpen(true);
  }

  const lockMonth = async () => {
    setLockingMonth(true);

    var d = new Date();
    d.setDate(0); //last day previous month
    var year_last_month = d.getFullYear();
    var last_month = ('0' + (d.getMonth() + 1)).slice(-2);

    const result = await fetch('/api/technicians/closemonth', {
      method: 'POST',
      body: JSON.stringify({
        year: year_last_month,
        month: last_month,
        technicians_id: id,
        technician_name: technician.Gebruiker,
        overhours_to_pay_out_100_procent: overhoursToPayOut100Procent
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    await result.json();
    toggleToastClosing();
    fetchData();
    await generatePdf();
    setLockingMonth(false);
    setModalOpen(false);
  };

  const handleClose = useCallback(() => setModalOpen(!modalOpen), [modalOpen]);

  const generatePdf = async () => {
    var d = new Date();
    d.setDate(0); //last day previous month
    var year_last_month = d.getFullYear();
    var last_month = ('0' + (d.getMonth() + 1)).slice(-2);

    const result = await fetch('/api/technicians/pdf', {
      method: 'POST',
      body: JSON.stringify({
        year: year_last_month,
        month: last_month,
        technicians_id: id,
        technician_name: technician.Gebruiker
      }),
      headers: { 'Content-Type': 'application/json' }
    });
    if (!result.ok) return setToastPdfError();
    setToastPdf();
  };

  const toggleToastClosing = useCallback(() => setToastClosing((toast) => !toast), []);
  const toastMarkupClosing = toastClosing ? <Toast content="Month sucessfully locked" onDismiss={toggleToastClosing} /> : null;

  const toastMarkupPdf = toastPdf ? (
    <Toast content="PDF succesfully created and email sent" onDismiss={setToastPdf} />
  ) : toastPdfError ? (
    <Toast error content="Error creating and sending PDF!" onDismiss={setToastPdfError} />
  ) : null;

  return (
    <Page
      title={technician.Gebruiker}
      titleMetadata={page_subtitle}
      subtitle="Saldos are calculated and updated every month when the month is locked."
      primaryAction={{ content: 'Lock last month', disabled: button_disabled_lock_last_month, onAction: payOverhoursModal }}
      secondaryActions={[{ content: 'Send overtime PDF', loading: false, onAction: generatePdf, disabled: button_send_overtime_pdf }]}
      fullWidth
    >
      {technician_info_banner}
      {toastMarkupClosing}
      {toastMarkupPdf}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        title={`Close last month for technician ${technician.Gebruiker}`}
        primaryAction={{ content: 'LOCK MONTH AND MAIL PDF', onAction: lockMonth, loading: lockingMonth }}
      >
        <Modal.Section>
          <BlockStack gap="300">
            <Text>
              This is going to lock the last month for technician {technician.Gebruiker} and send both the overtime and holiday PDF to {technician.Email}.
            </Text>
            <Text>Optionally pay out overhours to {technician.Gebruiker}:</Text>
            <TextField label="Overhours to pay out (@100%)" value={overhoursToPayOut100Procent} onChange={(value) => setOverhoursToPayOut100Procent(value)} type="number" />
          </BlockStack>
        </Modal.Section>
      </Modal>

      <Layout>
        <Layout.Section>
          <Card>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
              {tabs[selected].comp}
            </Tabs>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <TechnicianSaldoComponent technician_id={id} saldos={saldos} fetchData={fetchData} last_month_locked={last_month_locked} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
