// User roles enum
export default {
  AdminFull: 1,
  User: 2,
  Warehouse: 3,
  Technician: 4,
  Planning: 5,
  AdminData: 6,
  Inactive: 7
};
