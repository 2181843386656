import { useEffect, useState } from 'react';
import { postRequest } from '../../../shared/components/functions';
import { Button, Modal, Spinner } from '@shopify/polaris';
import { LayoutBlockIcon } from '@shopify/polaris-icons';

export const ReceiptQuickview = ({ expense }) => {
  const s3Key = expense.s3_key;
  const [modalOpen, setModalOpen] = useState(false);
  const [S3ObjectUrl, setS3ObjectUrl] = useState();
  const [loading, setLoading] = useState(false);

  if (!s3Key) return;

  useEffect(() => {
    async function fetchS3ObjectUrl() {
      if (!s3Key || s3Key.length === 0) return setS3ObjectUrl(null);
      setLoading(true);
      const data = await postRequest(`/api/s3-expenses`, { key: s3Key });
      setS3ObjectUrl(data);
      setLoading(false);
    }
    fetchS3ObjectUrl();
  }, [modalOpen]);

  const image = S3ObjectUrl && S3ObjectUrl.url ? <img src={S3ObjectUrl.url} alt="receipt" /> : '';

  return (
    <div style={{ margin: '-4px 0' }}>
      <Modal activator={<Button icon={LayoutBlockIcon} onClick={() => setModalOpen(true)} />} open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Section>{loading ? <Spinner size="small" /> : image}</Modal.Section>
      </Modal>
    </div>
  );
};
