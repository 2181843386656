import { Button, Card, Modal } from '@shopify/polaris';
import { useCallback, useState } from 'react';

export default function PdfViewer({ pdfLink }) {
  if (!pdfLink) return;

  return (
    <Card>
      {/* <PdfViewModal pdfLink={pdfLink} /> */}
      <div style={{ height: '800px', cursor: 'help' }}>
        <iframe title="PDF Viewer" style={{ width: '100%', height: '100%' }} src={pdfLink} />
      </div>
    </Card>
  );
}

// const PdfViewModal = ({ pdfLink }) => {
//     const [active, setActive] = useState(false);

//     const handleModalChange = useCallback(() => setActive(!active), [active]);
//     return (
//         <>
//             <Button onClick={handleModalChange}>🔎</Button>
//             <Modal large open={active} onClose={handleModalChange} >
//                 <Modal.Section>
//                     <div style={{ width: "900px", height: "1400px" }}>
//                         <object style={{ cursor: "help" }} data={pdfLink} type="application/pdf" width="100%" height="100%">
//                             <p>It appears you don't have a PDF plugin for this browser. You can
//                                 <a href={pdfLink}>click here to download the PDF file.</a>
//                             </p>
//                         </object>
//                     </div>
//                 </Modal.Section>
//             </Modal>
//         </>
//     )
// }
