import { useLookupStore } from '../../context/useStore';
import CreatableSelect from 'react-select/creatable';

export default function IncasserenSelect({ id, value, handleChange, disabled, isClearable }) {
  const { incasseren } = useLookupStore();

  const options = incasseren.map((inc) => {
    return { value: inc.Incasseren, label: inc.Incasseren };
  });
  if (!incasseren.find((i) => i.Incasseren === value)) options.push({ value: value, label: value });

  return (
    <>
      <div style={{ marginBottom: '4px' }}>
        <div style={{ color: disabled ? 'rgba(181, 181, 181, 1)' : 'black' }}>Incasseren</div>
      </div>
      <CreatableSelect
        id={id}
        value={options.find((o) => o.value === value)}
        autoComplete="off"
        label="Incasseren"
        placeholder="Select payment collection method"
        isClearable={isClearable}
        onChange={(props) => {
          if (props) {
            handleChange(props.value, id);
          } else {
            handleChange(null, id);
          }
        }}
        options={options}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: '32px' }),
          indicatorSeperator: (base) => ({ ...base, height: '32px' }),
          indicatorsContainer: (base) => ({ ...base, height: '32px' }),
          control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: disabled ? '#ebebeb' : '#919191' }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 })
        }}
      />
    </>
  );
}
