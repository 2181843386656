export const logo = {
  'PM NY': {
    logo: 'https://i.imgur.com/wJ4qCvv.jpeg',
    sizewidth: 150,
    ico: 'https://perfectmoose.com/favicon.ico'
  },
  SCHUILENBURG: {
    logo: 'https://schuilenburg.com/img/schuilenburg.svg',
    sizewidth: 200,
    ico: 'https://schuilenburg.com/wp-content/uploads/2019/03/cropped-schuilenburg-ico.png'
  },
  AHA: {
    logo: 'ahaDocLogo.png',
    sizewidth: 300,
    ico: 'ahaIco.ico'
  },
  JADIBO: {
    logo: 'jadiboDocLogo.png',
    sizewidth: 150,
    ico: 'jadiboIco.ico'
  },
  ADRIAENS: {
    logo: 'adriaensDocLogo.png',
    sizewidth: 150,
    ico: 'adriaensIco.ico'
  }
};

export const theme = (user) => {
  return {
    colors: {
      surface: '#111213',
      onSurface: '#111213',
      interactive: '#2e72d2',
      secondary: '#111213',
      primary: '#3b5998',
      critical: '#d82c0d',
      warning: '#ffc453',
      highlight: '#5bcdda',
      success: '#008060',
      decorative: '#ffc96b'
    },
    colorScheme: 'light',
    logo: {
      width: logo[user?.ORGANISATION_NAME]?.sizewidth,
      topBarSource: logo[user?.ORGANISATION_NAME]?.logo,
      url: '/home'
    }
  };
};
