import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Link, Button, Tooltip, Box, Text } from '@shopify/polaris';
import { useUrl } from '../../../shared/util/hanldesave';
import { stripHtml, getTableFooter, alphabetize, fetchTableData, getUserOrg, putRequest, getRequest, filtersToApiUrl, truncate } from '../../../shared/components/functions';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../../shared/components/listFilter.jsx';
import ExcelExporterModal from '../../../shared/components/modals/excelExporterModal';
import { OrderFulfilledIcon, CreditCardIcon } from '@shopify/polaris-icons';
import { toggleJobClosed, toggleJobInvoiced } from '../../job/helpers';
import { useAuthStore, useLookupStore } from '../../../context/useStore';
import { useDebounce } from '../../../shared/components/inputs/useDebounce.js';
import Select from 'react-select';
import Toasts from '../../../shared/components/toasts/toasts';

export default function JobsTable({ mode }) {
  const url = useUrl();
  const navigate = useNavigate();
  const machinebrands = useLookupStore((state) => state.machinebrands);
  const users = useLookupStore((state) => state.users);
  const organisations = useLookupStore((state) => state.organisations);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const typeChoices = [
    { label: 'Regie', value: 'Regie' },
    { label: 'Project', value: 'Project' },
    { label: 'Contract', value: 'Contract' }
  ];

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isSaving, setIsSaving] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    description: { type: 'text', name: 'Description', value: url.get('description') || '' },
    dateFrom: { type: 'date', name: 'Date from', value: url.get('dateFrom') || '' },
    dateTo: { type: 'date', name: 'Date to', value: url.get('dateTo') || '' },
    invoiced: { type: 'status', name: 'Invoiced', value: url.get('invoiced') || '' },
    jobsClosed: { type: 'status', name: 'Closed', value: url.get('jobsClosed') || '' },
    type: { type: 'multiple', name: 'Type', value: url.get('type') ? JSON.parse(url.get('type')) : null, choices: typeChoices },
    brand: {
      type: 'multiple',
      name: 'Brand',
      value: url.get('brand') ? JSON.parse(url.get('brand')) : null,
      choices: machinebrands.sort((a, b) => alphabetize(a, b, 'merk')).map((brand) => ({ value: brand.counter, label: brand.merk }))
    },
    mode: { type: 'none', name: 'Mode', value: mode },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/jobs', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  async function fetchJobs() {
    const data = await getRequest(`/api/jobs?page=${page}&limit=100&${filtersToApiUrl(filters)}`);
    setItems(data);
  }

  const getBrandBadges = (brands, idx1) => (brands ? brands.map((brand, idx2) => <Badge key={`badge-${idx1}-${idx2}`}>{brand.merk}</Badge>) : null);

  const handleOwnerChange = async (value, jobID) => {
    setIsSaving(true);
    await putRequest(`/api/jobs/${jobID}`, { OwnerUserID: value?.value || null }, setToast, setToastError);
    fetchJobs();
    setIsSaving(false);
  };

  const rows = Array.isArray(items)
    ? items.map((item, idx) => {
        const status = item.Closed ? (
          <Badge key={`clse-bdage-yes-${item.JobID}`} tone="success">
            Yes
          </Badge>
        ) : (
          <Badge key={`open-badge-no-${item.JobID}`}>No</Badge>
        );
        const invoiced = item.Invoiced ? (
          <Badge key={`invoice-badge-yes-${item.JobID}`} tone="success">
            Yes
          </Badge>
        ) : (
          <Badge key={`invoice-badge-yes-${item.JobID}`} tone="warning">
            No
          </Badge>
        );

        return [
          <Link removeUnderline url={`/jobs/${item.JobID}`}>
            {item.JobNumber}
          </Link>,
          <Link removeUnderline url={`https://tech.schuilenburg.be/Jobscherm/${item.JobID}`}>
            In planning
          </Link>,
          String(item.OrderDate).substring(0, 10),
          <Link removeUnderline url={`/companies/${item.CustomerId}`}>
            {item.customer_name.substring(0, 30)}
          </Link>,
          <Badge>{item.job_type}</Badge>,
          stripHtml(item.Description).substring(0, 50),
          getBrandBadges(JSON.parse(item.brands), idx),
          <Select
            id="OwnerUserID"
            options={filteredUsers}
            onChange={(e) => handleOwnerChange(e, item.JobID)}
            value={filteredUsers.find((c) => parseInt(c.value) === parseInt(item.OwnerUserID)) || ''}
            isDisabled={isSaving}
            menuPortalTarget={document.body}
            isClearable
            styles={{
              valueContainer: (base) => ({ ...base, marginTop: -4 }),
              indicatorSeperator: (base) => ({ ...base, height: '26px' }),
              indicatorsContainer: (base) => ({ ...base, height: '26px' }),
              control: (base) => ({ ...base, height: '26px', minHeight: '26px', borderRadius: '8px', borderColor: '#919191' }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 })
            }}
          />,
          String(item.ChangedOn).substring(0, 10),
          item.ChangedBy,
          invoiced,
          <Tooltip content={item.InvoiceStatusDescription + ''}>
            <Text>{truncate(item.InvoiceStatusDescription, 15)}</Text>
          </Tooltip>,
          status,
          <div style={{ margin: '-4px 0' }}>
            {!item.Invoiced ? (
              <Tooltip content="Mark as invoiced">
                <Button
                  key={`invoice-button-${item.JobID}`}
                  size="slim"
                  icon={CreditCardIcon}
                  onClick={async () => {
                    await toggleJobInvoiced(item.JobID, !item.Invoiced);
                    fetchJobs();
                  }}
                />
              </Tooltip>
            ) : null}
            {!item.Closed ? (
              <Tooltip content="Mark as closed">
                <Button
                  key={`close-button-${item.JobID}`}
                  size="slim"
                  icon={OrderFulfilledIcon}
                  onClick={async () => {
                    await toggleJobClosed(item.JobID, !item.Closed);
                    fetchJobs();
                  }}
                />
              </Tooltip>
            ) : null}
          </div>
        ];
      })
    : [];

  return (
    <Box>
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Owner saved" />
      {mode === 'all_jobs' ? (
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter jobs by number, customer and description"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
      ) : null}
      <DataTable
        stickyHeader
        increasedTableDensity
        verticalAlign="middle"
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric']}
        headings={[
          <b>Number</b>,
          <b>Planning</b>,
          <b>Date</b>,
          <b>Customer</b>,
          <b>Type</b>,
          <b>Description</b>,
          <b>Brand</b>,
          <b>Owner</b>,
          <b>Changed on</b>,
          <b>Changed by</b>,
          <b>Invoiced</b>,
          <b>Invoice status</b>,
          <b>Closed</b>,
          <b>Actions</b>
        ]}
        rows={rows}
        footerContent={getTableFooter(rows, page, setPage, 'jobs')}
      />
    </Box>
  );
}
