import { useState } from 'react';
import { Modal, TextField, Checkbox, Link, BlockStack } from '@shopify/polaris';
import { handleInputChange, flattenEntry, openEditModal } from '../../shared/components/settingsFunctions';
import { DropdownSelect } from '../../shared/components/settingsSelect';
import { postRequest, putRequest } from '../../shared/components/functions';
import { performTracking } from '../../analytics/segment';

export const getSettingsEditLink = (setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, itemID) => {
  return (
    <Link removeUnderline onClick={async () => openEditModal(setModalOpen, entry, setEntry, setIsAdd, setEntryId, item, itemID)}>
      Edit
    </Link>
  );
};

export default function SettingsAddEditModal({ modalTitle, modalOpen, setModalOpen, isAdd, entry, setEntry, entryId, url, setToast, setErrorToast, setItems }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const validateAndSubmit = async () => {
    const invalidFields = Object.keys(entry).filter((itemKey) => entry[itemKey].required && !entry[itemKey].value && entry[itemKey].value.length < 1);
    if (invalidFields && invalidFields.length > 0) {
      setErrors(invalidFields);
    } else {
      setIsSubmitting(true);
      let payload = flattenEntry(entry);
      if (entryId || entryId === 0) {
        let entryToEditUrl = url + `/${entryId}`;
        await putRequest(entryToEditUrl, payload, setToast, setErrorToast);
      } else {
        await postRequest(url, payload, setToast, setErrorToast);
      }
      performTracking(`add_settings_${String(url).split('/')[1]}`, payload);
      setItems();
      setModalOpen(false);
      setErrors([]);
      setIsSubmitting(false);
    }
  };

  let action, title;
  if (isAdd) {
    action = { content: 'Add', onAction: async () => validateAndSubmit(true), loading: isSubmitting };
    title = modalTitle;
  } else {
    action = { content: 'Edit', onAction: () => validateAndSubmit(false), loading: isSubmitting };
    title = modalTitle + `: #${entryId}`;
  }

  return (
    <Modal
      open={modalOpen}
      onClose={() => {
        setModalOpen(), setIsSubmitting(false), setErrors([]);
      }}
      title={title}
      primaryAction={action}
    >
      <Modal.Section>
        <BlockStack gap="300">
          {Object.keys(entry).map((itemKey, idx) => {
            switch (entry[itemKey].type) {
              case 'active':
                return [
                  <div key={idx}>
                    <Checkbox label={entry[itemKey].label} checked={entry[itemKey].value} onChange={async (e) => handleInputChange(e, entry, setEntry, itemKey)} />
                  </div>
                ];

              case 'select':
                return [
                  <div key={idx}>
                    <DropdownSelect
                      toSelect={entry[itemKey].label}
                      isRequired={entry[itemKey].required}
                      error={errors.includes(itemKey)}
                      defaultValue={entry[itemKey].value}
                      onChange={async (e) => handleInputChange(e.value, entry, setEntry, itemKey)}
                    />
                  </div>
                ];

              default:
                return [
                  <div key={idx}>
                    <TextField
                      type={`${entry[itemKey].type}`}
                      label={`${entry[itemKey].label}`}
                      value={entry[itemKey].value}
                      maxLength={entry[itemKey].max}
                      showCharacterCount={entry[itemKey].type !== 'date'}
                      multiline={entry[itemKey].multi ? 2 : 0}
                      disabled={entry[itemKey].disabled}
                      requiredIndicator={entry[itemKey].required}
                      onChange={async (e) => handleInputChange(e, entry, setEntry, itemKey)}
                      autoComplete="off"
                      error={errors.includes(itemKey) ? 'Required' : false}
                    />
                  </div>
                ];
            }
          })}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
}
