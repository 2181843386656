import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { Page, Card, Link, Button, Banner, Text, Box, DataTable, Checkbox, BlockStack } from '@shopify/polaris';
import { formatToCurrency, putRequest, getRequest } from '../../shared/components/functions.jsx';
import { pad_with_zeroes } from '../../utils/sharepoint.js';
import { useAuthStore } from '../../context/useStore.js';
import ProjectSelect from '../../shared/components/projectSelect.jsx';
import userRoles from '../../utils/userRoles.js';
import MetricsCard from './metricsCard.jsx';
import HolidayRequestsComponent from '../holidays/components/holidayrequests.jsx';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';

export default function HomePage() {
  const { user, setUser } = useAuthStore();
  const [invoiceApprovals, setInvoiceApprovals] = useState([]);
  const [creditnoteApprovals, setCreditnoteApprovals] = useState([]);
  const [purchaseTypes, setPurchaseTypes] = useState([]);
  const [invoicesVervallen, setInvoicesVervallen] = useState([]);
  const [reports, setReports] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userSaml = params.get('userSaml') ? JSON.parse(decodeURIComponent(params.get('userSaml'))) : null;

  useEffect(() => {
    if (userSaml && !user) {
      localStorage.setItem('currentUser', JSON.stringify(userSaml.user));
      setUser(userSaml.user);
    }
  }, []);

  const fetchReports = useCallback(async () => setReports(await getRequest('/api/daily_reports')), []);

  useEffect(() => {
    async function fetchData() {
      if (user && user.id) {
        getInvoiceApprovals();
        getCreditnoteApprovals();
      }

      const expiredInvoices = await getRequest('/api/metrics/expired_invoices');
      if (expiredInvoices && expiredInvoices?.length > 0) setInvoicesVervallen(expiredInvoices);
      //setPlannedJobs(await getRequest(`/api/jobs`));
      //not possible to get purchase types out of store because store is still filling up after login so get it from api to be sure you have it here
      setPurchaseTypes(await getRequest('/api/purchasetypes'));
      fetchReports();
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getInvoiceApprovals = async () => {
    setInvoiceApprovals(await getRequest(`/api/invoice-approvals/awaitingapproval/${user?.id}`));
  };

  const getCreditnoteApprovals = async () => {
    setCreditnoteApprovals(await getRequest(`/api/creditnote-approvals/awaitingapproval/${user?.id}`));
  };

  const handleApprove = async (approvalID, resource) => {
    await putRequest(`/api/${resource}-approvals/${approvalID}`, { Goedgekeurd: true });
    resource === 'invoice' ? await getInvoiceApprovals() : await getCreditnoteApprovals();
  };

  const lineUpdate = async (value, approval) => {
    await putRequest(`/api/invoice-approvals/${approval.ProjectFactuurInID}`, { ProjectID: value });
    await getInvoiceApprovals();
  };

  const OrgOverview = () => {
    const comp =
      user?.role === userRoles.AdminFull || user?.role === userRoles.AdminData ? (
        <Box paddingBlockEnd="300">
          <Card>
            <CardHeading title="List of Admin applications" />
            <BlockStack>
              <div>
                Schuilenburg NV:{' '}
                <Link removeUnderline url="https://admin.schuilenburg.be">
                  admin.schuilenburg.be
                </Link>
              </div>
              <div>
                Aha innovations:{' '}
                <Link removeUnderline url="https://adminaha.schuilenburg.be">
                  adminaha.schuilenburg.be
                </Link>
              </div>
              <div>
                Adriaens NV:{' '}
                <Link removeUnderline url="https://adminadriaens.schuilenburg.be">
                  adminadriaens.schuilenburg.be
                </Link>
              </div>
              <div>
                Jadibo NV:{' '}
                <Link removeUnderline url="https://adminjadibo.schuilenburg.be">
                  adminjadibo.schuilenburg.be
                </Link>
              </div>
              <div>
                Perfect moose NY:{' '}
                <Link removeUnderline url="https://admin.perfectmoose.com">
                  admin.perfectmoose.com
                </Link>
              </div>
            </BlockStack>
          </Card>
        </Box>
      ) : null;
    return comp;
  };

  const invoicesTable = (
    <BlockStack>
      <CardHeading title="Invoices awaiting your approval" />
      <Text>
        Please open the PDF to check if the content of the incoming invoice correspondents with what was purchased. Always link it to a project if this applies, this will be used for the project cost
        calculation.
      </Text>
      <br />
      <DataTable
        verticalAlign="middle"
        increasedTableDensity
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric']}
        headings={[<b>Number</b>, <b>PDF</b>, <b>Date</b>, <b>Supplier</b>, <b>Invoice type</b>, <b>Value</b>, <b>Project</b>, <b>Actions</b>]}
        rows={
          Array.isArray(invoiceApprovals)
            ? invoiceApprovals.map((approval) => [
                <Link removeUnderline key={`link-invoice-in-${approval.ProjectFactuurInID}`} url={`/invoices_in/${approval.FactuurInID}`}>
                  {approval.FactuurInNummer}
                </Link>,
                <Link
                  removeUnderline
                  key={`link-sharepoint-${approval.ProjectFactuurInID}`}
                  url={`https://schuilenburg.sharepoint.com/Schuilenburg/${pad_with_zeroes(approval.Klantnummer, 5)}/Factuur In/${approval.FactuurInNummer}/${user.ACCOUNTANT_FILES_PREFIX}-FI${
                    approval.FactuurInNummer
                  }.pdf`}
                >
                  PDF
                </Link>,
                approval.datum?.substring(0, 10),
                <Link removeUnderline key={`link-company-${approval.ProjectFactuurInID}`} url={`/companies/${approval.FIRNR}`}>
                  {approval.NAAM}
                </Link>,
                approval.FactuurTypeID ? purchaseTypes.find((purchaseType) => purchaseType.BestellingTypeID === approval.FactuurTypeID)?.BestellingType : '',
                formatToCurrency(approval.Bedrag),
                <ProjectSelect index={approval.ProjectFactuurInID} value={approval.ProjectID} key={`projectselect-${approval.ProjectFactuurInID}`} onChange={(value) => lineUpdate(value, approval)} />,
                <Button variant="primary" key={`approve-button-${approval.ProjectFactuurInID}`} onClick={() => handleApprove(approval.ProjectFactuurInID, 'invoice')}>
                  Approve
                </Button>
              ])
            : []
        }
      />
    </BlockStack>
  );

  const creditnotesTable = (
    <BlockStack>
      <CardHeading title="Creditnotes awaiting your approval" />
      <Text>Please open the PDF to check the content of the incoming creditnote.</Text>
      <br />
      <div style={{ width: '50%' }}>
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric']}
          headings={[<b>Number</b>, <b>PDF</b>, <b>Date</b>, <b>Supplier</b>, <b>Value</b>, <b>Actions</b>]}
          rows={
            Array.isArray(creditnoteApprovals)
              ? creditnoteApprovals.map((approval) => [
                  <Link removeUnderline key={`link-invoice-in-${approval.ApprovalID}`} url={`/creditnotes_in/${approval.CreditnotaInID}`}>
                    {approval.CreditnotaInNummer}
                  </Link>,
                  <Link
                    removeUnderline
                    key={`link-sharepoint-${approval.ApprovalID}`}
                    url={`https://schuilenburg.sharepoint.com/Schuilenburg/${pad_with_zeroes(approval.Klantnummer, 5)}/Creditnota In/${approval.CreditnotaInNummer}/${user.ACCOUNTANT_FILES_PREFIX}-KI${
                      approval.CreditnotaInNummer
                    }.pdf`}
                  >
                    PDF
                  </Link>,
                  approval.datum?.substring(0, 10),
                  <Link removeUnderline key={`link-company-${approval.ApprovalID}`} url={`/companies/${approval.FIRNR}`}>
                    {approval.NAAM}
                  </Link>,
                  formatToCurrency(approval.Bedrag),
                  <Button variant="primary" key={`approve-button-${approval.ApprovalID}`} onClick={() => handleApprove(approval.ApprovalID, 'creditnote')}>
                    Approve
                  </Button>
                ])
              : []
          }
        />
      </div>
    </BlockStack>
  );

  return (
    <Page title={`Welcome home, ${user?.username}`} fullWidth>
      {user?.role === userRoles.AdminFull || user?.role === userRoles.AdminData ? <MetricsCard metrics={invoicesVervallen} /> : null}

      {reports?.length > 0 ? (
        <Box paddingBlockEnd="300">
          <Card>
            <CardHeading title="Daily report" />
            <DataTable
              verticalAlign="middle"
              increasedTableDensity
              columnContentTypes={['text']}
              headings={[<b>Report</b>]}
              rows={reports.map((r) => [
                <div style={{ margin: '-4px 0', display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={r.Behandeld}
                    onChange={async () => {
                      await putRequest(`/api/daily_reports/${r.ApplicatieMeldingID}`, { ...r, Behandeld: true, GebruikerID: user.id });
                      await fetchReports();
                    }}
                  />
                  <Text truncate>{r.Melding}</Text>
                </div>
              ])}
            />
          </Card>
        </Box>
      ) : null}

      <OrgOverview />

      {purchaseTypes?.length > 0 && (invoiceApprovals?.length > 0 || creditnoteApprovals?.length > 0) && (
        <Box paddingBlockEnd="300">
          <Card>
            <div style={{ display: 'flex', gap: '30px', flexDirection: 'column' }}>
              {invoiceApprovals?.length > 0 && invoicesTable}
              {creditnoteApprovals?.length > 0 && creditnotesTable}
            </div>
          </Card>
        </Box>
      )}

      {user?.role === userRoles.AdminFull ? <HolidayRequestsComponent /> : null}

      <Box paddingBlockEnd="400">
        <Banner
          title={
            <Text>
              Organising a fair? Easily collect company and contact information by using the <Link url={'/fairs'}>fairs app</Link>.
            </Text>
          }
        />
      </Box>
    </Page>
  );
}
