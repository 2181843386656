import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Link, Button, Tooltip, Box } from '@shopify/polaris';
import { useUrl } from '../../../shared/util/hanldesave';
import { fetchTableData, formatToCurrency, getTableFooter, truncate, getDocPdfLinkForListView, getRequest, getModalButton, getUserOrg } from '../../../shared/components/functions';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../../shared/components/listFilter.jsx';
import ExcelExporterModal from '../../../shared/components/modals/excelExporterModal';
import { emailSentBadge } from '../../../shared/components/badges/badges';
import { CreditCardIcon, CreditCardPercentIcon } from '@shopify/polaris-icons';
import InvoiceCollection from './invoiceCollection';
import { useAuthStore, useLookupStore } from '../../../context/useStore';
import QuickViewModal from '../../../shared/components/modals/quickViewModal';
import { ReminderMailButton } from './ReminderMailButton.jsx';
import { useDebounce } from '../../../shared/components/inputs/useDebounce.js';

export default function InvoicesTable({ factuurstatusID }) {
  const url = useUrl();
  const navigate = useNavigate();
  const users = useLookupStore((state) => state.users);
  const organisations = useLookupStore((state) => state.organisations);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);
  const choices = useLookupStore((state) => state.invoicestatuses).map((item) => ({ label: `${item.FactuurStatus}`, value: `${item.Sequence}` })) || [];

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [invoiceCollectionModalOpen, setInvoiceCollectionModalOpen] = useState(false);
  const [collectionInvoice, setCollectionIvoice] = useState({});
  const [fullInvoiceCollection, setFullInvoiceCollection] = useState(false); //full if fully paid
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    reference: { type: 'text', name: 'Reference', value: url.get('reference') || '' },
    date: { type: 'date', name: 'Date', value: url.get('date') || '' },
    dateEx: { type: 'date', name: 'Expiry date', value: url.get('dateEx') || '' },
    dateLastNotice: { type: 'date', name: 'Last notice date', value: url.get('dateLastNotice') || '' },
    total: { type: 'range', name: 'Total', value: url.get('total') ? JSON.parse(url.get('total')) : null, rangeBottom: 0, rangeTop: 500000, prefix: '€' },
    open: { type: 'range', name: 'Open', value: url.get('open') ? JSON.parse(url.get('open')) : null, rangeBottom: 0, rangeTop: 400000, prefix: '€' },
    datePayment: { type: 'date', name: 'Payment date', value: url.get('datePayment') || '' },
    mail: { type: 'status', name: 'PDF emailed', value: url.get('mail') || 'all' },
    status: { type: 'multiple', name: 'Status', value: url.get('status') ? JSON.parse(url.get('status')) : null, choices: choices },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => {
    if (factuurstatusID !== null && factuurstatusID !== 'vervallen') {
      await fetchTableData(page, `/api/invoices/type/${factuurstatusID}`, setItems, filters, abortController, setFilterLoading);
    } else if (factuurstatusID !== 'vervallen') {
      await fetchTableData(page, '/api/invoices', setItems, filters, abortController, setFilterLoading);
    } else {
      await fetchTableData(page, '/api/invoices/vervallen', setItems, filters, abortController, setFilterLoading);
    }
  };
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const fetchInvoices = async () => {
    let data = [];
    if (factuurstatusID !== null && factuurstatusID !== 'vervallen') {
      data = await getRequest(`/api/invoices/type/${factuurstatusID}?page=${page}&limit=100`);
    } else if (factuurstatusID !== 'vervallen') {
      data = await getRequest(`/api/invoices?page=${page}&limit=100`);
    } else {
      data = await getRequest(`/api/invoices/vervallen?page=${page}&limit=100`);
    }
    setItems(data);
  };

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const statusColor = item.status === 0 ? '' : item.status === 9 ? 'success' : item.status === 1 ? 'attention' : item.status === 2 ? 'warning' : 'critical';
        const fact_status = (
          <div style={{ display: 'flex', gap: 3 }}>
            <Badge tone={statusColor}>{item.FactuurStatus}</Badge>
            {item.Vervallen ? <Badge tone="critical">expired</Badge> : null}
          </div>
        );

        const isCurrentDate = String(item.datum).substring(0, 10) === new Date().toISOString().split('T')[0];
        const rowStyle = isCurrentDate ? { backgroundColor: '#FFF2CC' } : {};

        return [
          <div style={rowStyle}>
            <Link removeUnderline url={'/invoices/' + item.faktuurnr}>
              {item.Factuurnummer}
            </Link>
          </div>,
          <div>{getModalButton(item.faktuurnr, item.Factuurnummer, setModalOpen, setSelectedItem)}</div>,
          <div>{getDocPdfLinkForListView(item, 'invoice', item.companyNumber, item.Factuurnummer)}</div>,
          <div>
            <Link removeUnderline url={'/companies/' + item.klantnummer}>
              {truncate(item.klantnaam, 25, false)}
            </Link>
          </div>,
          <div>{truncate(item.refklant, 15, false)}</div>,
          <div>{String(item.datum).substring(0, 10)}</div>,
          <div>{String(item.Vervaldatum).substring(0, 10)}</div>,
          <div>{String(item.laatsteaanmaning).substring(0, 10)}</div>,
          <div>{formatToCurrency(item.totaalbedrag)}</div>,
          <div>{formatToCurrency(item.bedragopen)}</div>,
          <div>{item.datumbetaling != null ? String(item.datumbetaling).substring(0, 10) : ''}</div>,
          <div>{fact_status}</div>,
          <div>{item.Afkorting ? <Badge>{item.Afkorting}</Badge> : ''}</div>,
          <div>{emailSentBadge(item.Verstuurd)}</div>,
          item.status !== 9 ? (
            <div style={{ margin: '-4px 0' }}>
              <Tooltip content="Collect full payment">
                <Button
                  size="slim"
                  icon={CreditCardIcon}
                  onClick={() => {
                    setInvoiceCollectionModalOpen(true);
                    setCollectionIvoice(item);
                    setFullInvoiceCollection(true);
                  }}
                />
              </Tooltip>
              <Tooltip content="Collect partial payment">
                <Button
                  size="slim"
                  icon={CreditCardPercentIcon}
                  onClick={() => {
                    setInvoiceCollectionModalOpen(true);
                    setCollectionIvoice(item);
                    setFullInvoiceCollection(false);
                  }}
                />
              </Tooltip>
              {item.Vervallen ? <ReminderMailButton invoice={item} fetchInvoices={fetchInvoices} /> : null}
            </div>
          ) : (
            ''
          )
        ];
      })
    : [];

  const totals =
    items.length > 0
      ? ['', '', '', '', '', '', '', '', formatToCurrency(items.reduce((p, c) => p + c.totaalbedrag, 0)), formatToCurrency(items.reduce((p, c) => p + c.bedragopen, 0)), '', '', '', '', '']
      : null;

  return (
    <Box>
      <QuickViewModal modalOpen={modalOpen} setModalOpen={setModalOpen} id={selectedItem.id} type="financial-simple" source="invoices" title={`Invoice #${selectedItem.number}`} />
      <InvoiceCollection
        modalOpen={invoiceCollectionModalOpen}
        setModalOpen={setInvoiceCollectionModalOpen}
        collectionInvoice={collectionInvoice}
        fetchData={fetchInvoices}
        fullInvoiceCollection={fullInvoiceCollection}
      />
      {factuurstatusID === null ? (
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter invoices on number, reference, VAT number, customer name and customer number"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
      ) : null}
      <DataTable
        verticalAlign="middle"
        stickyHeader
        increasedTableDensity
        columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'text', 'text', 'text', 'numeric', 'numeric']}
        headings={[
          <b>Number</b>,
          <b>View</b>,
          <b>PDF</b>,
          <b>Customer</b>,
          <b>Reference</b>,
          <b>Date</b>,
          <b>Date expire</b>,
          <b>Last notice</b>,
          <b>Total</b>,
          <b>Open</b>,
          <b>Payment date</b>,
          <b>Status</b>,
          <b>Owner</b>,
          <b>PDF emailed</b>,
          <b>Payment</b>
        ]}
        showTotalsInFooter
        rows={rows}
        totals={totals}
        footerContent={getTableFooter(rows, page, setPage, 'invoices')}
      />
    </Box>
  );
}
