import React, { useEffect, useState } from 'react';
import { DataTable, Link } from '@shopify/polaris';
import { useLookupStore } from '../../../context/useStore';
import { getRequest } from '../../../shared/components/functions';

export default function MaintenanceContractsCompany({ FIRNR }) {
  const [items, setItems] = useState([]);
  const { servicecontracttypes, machinebrands } = useLookupStore();

  useEffect(() => {
    async function fetchItems() {
      const data = await getRequest(`/api/maintenance_contracts?limit=10000&FIRNR=${FIRNR}`);
      setItems(data);
    }

    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const brand = machinebrands.find((m) => m.counter === item.Machinemerk).merk;
        return [
          <Link removeUnderline url={'/maintenance_contracts/' + item.Kontraktnummer}>
            {item.Onderhoudscontractnummer}
          </Link>,
          item.aanvangsdatum ? item.aanvangsdatum.substring(0, 10) : '',
          item.OnderhoudscontractTypeID ? servicecontracttypes.find((contract) => contract.OnderhoudscontractTypeID === item.OnderhoudscontractTypeID).OnderhoudscontractType : '',
          item.Type,
          item.serienummer,
          <Link removeUnderline url={'/invoices/' + item.Faktuurnummer}>
            {item.invoice_number}
          </Link>,
          item.Faktuurdatum ? item.Faktuurdatum.substring(0, 10) : '',
          item.memo1,
          brand,
          item.Verstuurd ? 'Yes' : 'No'
        ];
      })
    : [];

  return items.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
      headings={[
        <b>{'Number'}</b>,
        <b>{'Start'}</b>,
        <b>{'Contract type'}</b>,
        <b>{'Machine type'}</b>,
        <b>{'Serial number'}</b>,
        <b>{'Invoice number'}</b>,
        <b>{'Invoice date'}</b>,
        <b>{'Remarks'}</b>,
        <b>{'Brand'}</b>,
        <b>{'Verstuurd'}</b>
      ]}
      rows={rows}
    />
  ) : (
    'No maintenance contracts'
  );
}
