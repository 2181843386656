import { useEffect, useState } from 'react';
import { FormLayout, Modal, Select, Spinner } from '@shopify/polaris';
import { formatToCurrency, getRequest } from '../../../shared/components/functions';
import CompanySelect from '../../../shared/components/companySelect';

export function JobTransformerModal({ setJob, active, setActive, handleJobTransform, company, resource }) {
  const [selectedResource, setSelectedResource] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function getResources() {
      //this will get all the invoices/quotations from the invoicing address company and set it to the invoices/quotations
      const data = await getRequest(`/api/${resource}s?limit=20&FIRNR=${company.FirNrFactuur || company.FIRNR}`);
      if (Array.isArray(data)) {
        let optionsArr = [];
        if (resource === 'invoice') optionsArr = data.filter((r) => r.status === 0).map((r) => ({ label: `${r.Factuurnummer} - ${formatToCurrency(r.totaalbedrag)}`, value: r.faktuurnr }));
        if (resource === 'quotation') optionsArr = data.map((r, idx) => ({ key: idx, label: `${r.Offertenummer} - ${formatToCurrency(r.price)}`, value: r.offertenr }));
        setOptions([{ label: `Select ${resource}...`, value: null }, ...optionsArr]);
      }
      setLoaded(true);
    }
    if (active) getResources();
  }, [company, active]);

  const handleCompanyChange = async (FIRNR, id) => {
    setJob((job) => ({ ...job, [id]: FIRNR }));
  };

  return (
    <Modal
      open={active}
      onClose={() => {
        setActive(false);
        setLoaded(false);
        setOptions([]);
      }}
      title={resource === 'invoice' ? 'Invoice job' : 'Make quotation to create pro forma invoice'}
      primaryAction={{ content: `Make new ${resource}`, onAction: () => handleJobTransform(false, null, `${resource}s`) }}
      secondaryActions={{ content: `Append to selected ${resource}`, onAction: () => handleJobTransform(true, selectedResource, `${resource}s`), disabled: !selectedResource }}
    >
      <Modal.Section>
        {!loaded ? (
          <Spinner size="small" />
        ) : (
          <FormLayout>
            <CompanySelect value={company.FIRNR} id="CustomerId" onChange={handleCompanyChange} label={`Select customer for ${resource}:`} isDisabled={false} isClearable />
            <Select label={`Append job to existing ${resource}:`} value={parseInt(selectedResource) || ''} onChange={setSelectedResource} options={options} />
          </FormLayout>
        )}
      </Modal.Section>
    </Modal>
  );
}
