import { useEffect, useState } from 'react';
import { TextField } from '@shopify/polaris';

export default function VATinput({ id, vat, onChange, disabled, isEU, companyLand }) {
  const [valid, setValid] = useState(false);
  const [error, setError] = useState(false);
  const [preciseError, setPreciseError] = useState('');

  useEffect(() => {
    async function getVatCheck() {
      const res = await fetch(`/api/vatcheck/${vat}`);
      const data = await res.json();
      if (data.valid) {
        setValid(true);
      } else {
        setError(true);
        data.message ? setPreciseError(data.message) : setPreciseError('Invalid VAT');
      }
    }
    if (vat && isEU && companyLand !== 300) getVatCheck();
  }, [isEU, vat, companyLand]);

  const labelAction =
    isEU && companyLand !== 300 ? { content: 'Official VIES Website', onAction: () => window.open(`https://ec.europa.eu/taxation_customs/vies/vatRequest.html`, '_blank').focus() } : null;

  return (
    <TextField
      id={id}
      maxLength={50}
      showCharacterCount
      autoComplete="off"
      label="VAT number"
      value={vat || ''}
      onChange={onChange}
      disabled={disabled}
      prefix={valid && !error ? '✅' : ''}
      error={error ? preciseError : ''}
      labelAction={labelAction}
    />
  );
}
