import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Form, Select, FormLayout, Layout, Page, Card, TextField, Checkbox, Link, BlockStack, InlineGrid, InlineStack, Box, Spinner } from '@shopify/polaris';
import LinesTable from '../../shared/components/linesTable';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { updateMyData } from '../../shared/util/updateLines';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { getBTWVerlegdID, TAV, alphabetize, getRequest, formatToCurrency, simplePostRequest } from '../../shared/components/functions.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import LinesTotalCard from '../../shared/components/cards/LinesTotalsCard.jsx';
import InvoiceSelect from '../../shared/components/invoiceSelect.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { round } from 'lodash';
import { getSchema } from '../../shared/formSchemas';
import EventTimeLine from '../../shared/components/events/eventTimeLine';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';
import { ContactReactSelect } from '../../shared/components/contactReactSelect.jsx';

export default function CreditnotePage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const docTypeID = 17;
  const docType = 'credit_note';
  const location = useLocation();
  const countries = useLookupStore((state) => state.countries);
  const languages = useLookupStore((state) => state.languages);
  const vatshifts = useLookupStore((state) => state.vatshifts);
  const { user } = useAuthStore();
  const [savingResult, setSavingResult] = useState(false);
  const [creditnote, setCreditnote] = useState({});
  const [initialCreditnote, setInitialCreditnote] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [linesDirty, setLinesDirty] = useState(url.get('linesDirty') || false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [company, setCompany] = useState({});
  const [invoice, setInvoice] = useState([]);
  const [isBlurred, setIsBlurred] = useState(true);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const pageTitle = getPageTitle(creditnote.Creditnotanummer, 'Creditnote');
  const creditnoteSchema = getSchema('creditnote', url, TAV, user);

  const lineInterface = {
    lineIsDirty: false,
    CreditnotaLijnID: null,
    CreditnotaID: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: '',
    VP: null,
    aantal: 1,
    BTW: company.LandID === 300 ? 21 : null,
    MuntVP: null,
    AP: null,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': null,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const handleChange = (value, id) => {
    let cn = creditnote;
    if (id === 'Betaald' && value) cn = { ...cn, Betaald: value, BedragOpen: 0 };
    setCreditnote({ ...cn, [id]: value });
    setIsDirty(true);
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setCreditnote(creditnoteSchema.default());
        setDataLoaded(true);
        if (location.state) {
          setLines(location.state);
          setLinesDirty(true);
        }
      } else {
        const data = await getRequest(`/api/creditnotes/${id}`);
        if (!data) {
          setCreditnote(creditnoteSchema.default());
          setDataLoaded(true);
          if (location.state) {
            setLines(location.state);
            setLinesDirty(true);
          }
          return;
        }
        setCreditnote(data);
        setInitialCreditnote(data);

        const data2 = await getRequest(`/api/creditnotes/${id}/lines`);
        setLines(data2);
        setInitialLines(data2);

        setDataLoaded(true);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/companies/${creditnote.FIRNR}`);
      if (data) {
        setCompany(data);
        setCreditnote((creditnote) => ({ ...creditnote, BTWVerlegdID: getBTWVerlegdID(data.LandID, countries.find((c) => c.LandId === data.LandID).EU) }));
      }
      const data2 = await getRequest(`/api/contacts?limit=10000&FIRNR=${creditnote.FIRNR}&status=0`);
      if (data2) setContacts(data2);
      setContactsLoaded(true);
    }
    if (creditnote.FIRNR) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditnote.FIRNR]);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/invoices/${creditnote.FactuurID}`);
      setInvoice(data);
    }
    if (creditnote.FactuurID) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditnote.FactuurID]);

  const updateTotals = () => {
    const totalVatExclusive = lines.reduce((prev, curr, index) => {
      const { aantal, VP, BTW } = lines[index];
      return prev + (parseFloat(aantal) || 0) * (parseFloat(VP) || 0) * (1 + BTW / 100);
    }, 0);

    if (round(creditnote.BedragTotaal, 2) !== round(totalVatExclusive, 2)) {
      setCreditnote(
        (cn) => ({
          ...cn,
          BedragOpen: Math.round(totalVatExclusive * 100) / 100,
          BedragTotaal: Math.round(totalVatExclusive * 100) / 100
        }),
        setIsDirty(true)
      );
    }
  };

  useEffect(() => {
    if (dataLoaded) updateTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines]);

  const generateDocument = async () => {
    const document = {
      addressee: {
        FIRNR: creditnote.FIRNR,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode,
        co: creditnote.TAV
      },
      eu: false,
      language: creditnote.TaalID ? languages.find((l) => l.TaalID === parseInt(creditnote.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      id: creditnote.CreditnotaID,
      typeID: docTypeID,
      number: creditnote.Creditnotanummer,
      reference: creditnote.Referentie,
      reference_date: creditnote.Datum ? String(creditnote.Datum).substring(0, 10) : '',
      date: String(creditnote.Datum).substring(0, 10),
      payment_condition: null,
      credit_invoice: invoice.Factuurnummer
    };

    //check if it is dirty if not tell user to save first
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  async function saveForm() {
    const errorInSchema = await validateSchema(creditnoteSchema, creditnoteSchema.cast(creditnote));
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        await handleSave(
          id,
          'creditnotes',
          creditnote.CreditnotaID,
          creditnoteSchema.cast(creditnote),
          setCreditnote,
          setInitialCreditnote,
          setToast,
          setToastError,
          isDirty,
          setIsDirty,
          linesDirty,
          lines,
          setLines,
          setInitialLines,
          setLinesDirty,
          setSavingResult,
          navigate
        );

        // auto generate pdf so latest changes are reflected in pdf
        if (id && id !== 'new') await generateDocument();
        setIsBlurred(false);
      } else {
        alert(errorInSchema);
      }
    }
  }

  if (!dataLoaded || !contactsLoaded)
    return (
      <Box padding="400">
        <Spinner size="small" />
      </Box>
    );

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Creditnote saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        savingResult={savingResult}
        handleDiscard={() => handleDiscard(initialCreditnote, initialLines, setCreditnote, setLines, setIsDirty, setLinesDirty)}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={saveForm}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="Creditnotanummer" disabled label="Number" value={String(creditnote.Creditnotanummer)} />
                  <TextField id="BedragOpen" label="Amount open" value={formatToCurrency(String(Math.round(creditnote.BedragOpen * 100) / 100))} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="FIRNR" label="Customer" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${creditnote.FIRNR}`) }} />
                  <TextField id="BedragTotaal" disabled label="Amount total" value={formatToCurrency(String(Math.round(creditnote.BedragTotaal * 100) / 100))} onChange={handleChange} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="Datum" maxLength={60} type="date" autoComplete="off" label="Date" value={String(creditnote.Datum).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                  <Select
                    id="TaalID"
                    maxLength={60}
                    options={languages?.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                    label="Language"
                    value={parseInt(creditnote.TaalID) || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <InlineStack wrap={false} gap="300">
                    <ContactReactSelect value={creditnote.TAV} id="tav" onChange={handleChange} label="TAV" contacts={contacts} disabled={savingResult} />
                    <Select
                      id="BTWVerlegdID"
                      options={vatshifts?.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((vat) => ({ value: vat.BTWVerlegdID, label: vat.Omschrijving }))}
                      placeholder="Select VAT shifted"
                      label="VAT Shifted"
                      value={parseInt(creditnote.BTWVerlegdID) || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </InlineStack>
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id="Referentie"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Reference customer"
                    value={creditnote.Referentie || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                  <InvoiceSelect id="FactuurID" label="Linked invoice" value={creditnote.FactuurID} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <InlineGrid columns={2}>
                    <BlockStack>
                      <Checkbox id="Betaald" label="Betaald" checked={creditnote.Betaald} onChange={handleChange} disabled={savingResult} />
                      <Checkbox id="Verstuurd" label="PDF document emailed" checked={creditnote.Verstuurd} onChange={handleChange} disabled={savingResult} />
                    </BlockStack>
                    <OwnerSelect id="GebruikerID" label="Owner" value={creditnote.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                  </InlineGrid>
                </FormLayout.Group>
                <TextField id="Opmerking" label="Note" value={creditnote.Opmerking || ''} multiline={4} onChange={handleChange} disabled={savingResult} />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <LinesTotalCard summary="credit" lines={lines} />
            <DocumentCard
              resource="CREDIT_NOTE"
              id={id}
              title="Credit note document"
              company={company}
              docNumber={creditnote.Creditnotanummer}
              isDirty={isDirty}
              linesDirty={linesDirty}
              generateDocument={generateDocument}
              docTypeID={docTypeID}
              docType={docType}
              setResource={setCreditnote}
              docLang={creditnote.TaalID}
              documentButtonLoading={savingResult || documentInProgress}
              setDocumentButtonLoading={setSavingResult}
            />
            <Card>
              <CardHeading title="Linked invoice" />
              {creditnote.FactuurID ? (
                <Link removeUnderline url={`/invoices/${creditnote.FactuurID}`}>
                  open invoice
                </Link>
              ) : (
                'No linked invoice'
              )}
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Content" />
            <LinesTable
              id={id}
              data={lines}
              updateMyData={updateMyData}
              setLines={setLines}
              setLinesDirty={setLinesDirty}
              onSubmit={saveForm}
              lineInterface={lineInterface}
              type="financial-simple"
              resource={creditnote}
              company={company}
              disabled={savingResult}
              setIsBlurred={setIsBlurred}
            />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <EventTimeLine resourceName="CREDIT_NOTE" resourceId={id} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
