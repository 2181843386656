import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Select, BlockStack, Banner, Text, Button, InlineStack, Box, InlineGrid } from '@shopify/polaris';
import { useUrl, handleSaveWithSecondPayload } from '../../shared/util/hanldesave';
import CompanySelect from '../../shared/components/companySelect.jsx';
import { getCurrenctDatetimeYYYMMDD, getExpiryDate, alphabetize, getRequest } from '../../shared/components/functions.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import IncasserenSelect from '../../shared/components/incasserenSelect.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas';
import EventTimeLine from '../../shared/components/events/eventTimeLine';
import { PdfCard } from '../invoice_in/components/PdfCard.jsx';
import { ApprovalCard } from '../invoice_in/components/ApprovalCard.jsx';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';

export default function CreditnoteInPage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const docTypeID = 20;
  const docType = 'credit_note_in';
  const currencies = useLookupStore((state) => state.currencies);
  const paymentconditions = useLookupStore((state) => state.paymentconditions);
  const { user } = useAuthStore();
  const [initialCreditnoteIn, setInitialCreditnoteIn] = useState('');
  const [creditnoteIn, setCreditnoteIn] = useState('');
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [company, setCompany] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [savingResult, setSavingResult] = useState(false);
  const [selectedS3Key, setSelectedS3Key] = useState();
  const [hasSysDoc, setHasSysDoc] = useState(false);
  const [approvals, setApprovals] = useState([]);
  const [initialApprovals, setInitialApprovals] = useState([]);
  const [approvalsDirty, setApprovalsDirty] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const pageTitle = getPageTitle(creditnoteIn?.CreditnotaInNummer, 'Creditnote IN');
  const creditnoteInSchema = getSchema('creditnote_in', url, null, user);

  const handleChange = (value, id) => {
    let creditnote = creditnoteIn;
    if (id === 'betalingID') creditnote.datumbetaling = getExpiryDate(value || undefined, creditnoteIn?.datum || getCurrenctDatetimeYYYMMDD()) || initialCreditnoteIn.datumbetaling;
    if (id === 'Bedragfaktuur') creditnote = { ...creditnote, Bedragfaktuur: value, bedragopen: value };
    setCreditnoteIn({ ...creditnote, [id]: value });
    setIsDirty(true);
  };

  const handleDiscard = () => {
    setCreditnoteIn(initialCreditnoteIn);
    setIsDirty(false);
    setApprovals(initialApprovals);
    setApprovalsDirty(false);
    setSavingResult(false);
  };

  async function saveForm() {
    setSavingResult(true);
    if (creditnoteIn.datum === '') creditnoteIn.datum = null;
    if (creditnoteIn.datumin === '') creditnoteIn.datumin = null;
    if (creditnoteIn.datumbetaling === '') creditnoteIn.datumbetaling = null;
    if (creditnoteIn.datumbetalingout === '') creditnoteIn.datumbetalingout = null;

    const errorInSchema = await validateSchema(creditnoteInSchema, creditnoteInSchema.cast(creditnoteIn));

    if (!errorInSchema) {
      //check if combo exists
      const doubling = await getRequest(`/api/creditnotes_in?supplier=${company.NAAM}&reference=${creditnoteIn.faktuurref}`);
      const filteredDoublings = doubling.filter((d) => d.CreditnotaINvolgnummer !== creditnoteIn.CreditnotaINvolgnummer);
      if (
        filteredDoublings &&
        filteredDoublings.length > 0 &&
        !confirm(`One or more incoming creditnotes for this customer with the reference already exists (${filteredDoublings.map((d) => d.CreditnotaInNummer).join(', ')}). Continue?`)
      ) {
        setApprovalsDirty(false);
        setIsDirty(false);
        return setSavingResult(false);
      }

      if (!creditnoteIn.processed && creditnoteIn.S3ObjectKeys) {
        // get PDF
        const response = await fetch(`/api/creditnotes_in/download?fileKey=${encodeURIComponent(selectedS3Key)}`);
        const blob = await response.blob();
        const pathArr = selectedS3Key.split('/');
        const downloadedFile = { blob, name: pathArr[pathArr.length - 1], type: 'application/pdf' };

        // create FormData object for sharepoint
        const formData = new FormData();
        formData.append('file', downloadedFile.blob, downloadedFile.name);
        formData.append('customerNumber', company.Klantnummer);
        formData.append('docType', 'credit_note_in');
        formData.append('docNumber', creditnoteIn.CreditnotaInNummer);
        formData.append('FIRNR', company.FIRNR);
        formData.append('resourceId', creditnoteIn.CreditnotaINvolgnummer);
        formData.append('docTypeId', 20);
        formData.append('isSystemDoc', '1');
        await fetch(`/api/docgen/uploadfile`, { method: 'POST', body: formData });
      }

      creditnoteIn.processed = true;

      if (approvalsDirty) {
        for (let i = 0; i < approvals.length; i++) {
          let valueOpen = parseFloat(approvals[i].Bedrag);
          if (!valueOpen || isNaN(valueOpen)) {
            setSavingResult(false);
            setApprovalsDirty(false);
            setIsDirty(false);
            return alert('❌ Saving not possible: Please check if the values of the approval lines are correct.');
          }
        }
      }

      const payloadTwo = { payload: approvals, link: window.location.href };

      await handleSaveWithSecondPayload(
        id,
        'creditnotes_in',
        creditnoteIn.CreditnotaINvolgnummer,
        'CreditnotaINvolgnummer',
        creditnoteInSchema.cast(creditnoteIn), //payloadOne
        payloadTwo,
        setCreditnoteIn,
        setInitialCreditnoteIn,
        `creditnote-approvals/dump/lines/${id}`,
        setApprovals,
        setInitialApprovals,
        isDirty,
        setIsDirty,
        approvalsDirty,
        setApprovalsDirty,
        setToast,
        setToastError,
        navigate
      );
    } else {
      console.log('Creditnote In:', creditnoteIn);
      alert(errorInSchema);
    }
    setSavingResult(false);
  }

  const getApprovals = async () => {
    const data = await getRequest(`/api/creditnote-approvals/${id}`);
    setInitialApprovals(data);
    setApprovals(data);
  };

  useEffect(() => {
    //if url contains /new then set credit note in to its interface
    async function fetchData() {
      if (id === 'new') {
        setCreditnoteIn(creditnoteInSchema.default());
        setInitialCreditnoteIn(creditnoteInSchema.default());
      } else {
        //else get the credit note in resource via api get request
        const data = await getRequest(`/api/creditnotes_in/${id}`);
        if (data && data.length > 0) {
          const creditnote = data[0];
          setCreditnoteIn(creditnote);
          setInitialCreditnoteIn(creditnote);
          await getApprovals();
        } else {
          setCreditnoteIn(creditnoteInSchema.default());
          setInitialCreditnoteIn(creditnoteInSchema.default());
        }
      }
      setDataLoaded(true);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (creditnoteIn.LeverancierNummer) {
        const data3 = await getRequest(`/api/companies/${creditnoteIn.LeverancierNummer}`);
        setCompany(data3);

        if (!data3.Leverancier) alert('This company is not marked as supplier, so not possible to set the correct payment conditions!');

        const creditnoteCompanyData = { Leveranciernaam: data3.NAAM };
        if (!creditnoteIn.processed && !hasSysDoc) creditnoteCompanyData.betalingID = data3.LevBetalingID || undefined;

        setCreditnoteIn((creditNoteIn) => ({ ...creditNoteIn, ...creditnoteCompanyData }));
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditnoteIn.LeverancierNummer]);

  const handleDelete = async () => {
    if (confirm('Are you sure you want to delete this creditnote?')) {
      setIsDirty(false);
      const result = await fetch(`/api/creditnotes_in/${id}`, { method: 'DELETE' });
      if (!result.ok) return setToastError(true);
      navigate(`/creditnotes_in`, { replace: true });
    }
  };

  return (
    dataLoaded && (
      <Page title={pageTitle} fullWidth>
        <TabTitle title={pageTitle} />
        <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Incoming creditnote saved" />
        <TheSaveBar isDirty={isDirty || approvalsDirty} savingResult={savingResult} handleDiscard={handleDiscard} handleSave={saveForm} />
        {creditnoteIn && id !== 'new' && !creditnoteIn.processed && !hasSysDoc ? (
          <div style={{ marginBottom: '20px' }}>
            <Banner
              tone="warning"
              title={
                <BlockStack inlineAlign="start">
                  <Text variant="headingMd">This is a new incoming creditnote. Please add information on supplier, reference and value to save this creditnote.</Text>
                  <InlineStack gap="200">
                    <Text variant="bodyMd" as="h5">
                      Mistakenly uploaded the document for this creditnote?
                    </Text>
                    <div style={{ marginTop: '1px' }}>
                      <Button variant="plain" tone="critical" onClick={handleDelete}>
                        Delete creditnote
                      </Button>
                    </div>
                  </InlineStack>
                </BlockStack>
              }
            />
          </div>
        ) : null}
        <Layout>
          <Layout.Section variant="oneHalf">
            <Card>
              <Form onSubmit={saveForm}>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField id="CreditnotaINvolgnummer" disabled label="Number" value={String(creditnoteIn.CreditnotaINvolgnummer)} />
                    <TextField
                      className="sentry-ignore"
                      id="Bedragfaktuur"
                      maxLength={60}
                      prefix="€"
                      type="number"
                      autoComplete="off"
                      label={
                        <InlineStack>
                          Total amount <p style={{ color: 'red' }}>*</p>
                        </InlineStack>
                      }
                      value={creditnoteIn.Bedragfaktuur ? String(creditnoteIn.Bedragfaktuur) : ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <CompanySelect
                      isClearable={false}
                      id="LeverancierNummer"
                      label={
                        <InlineStack>
                          Supplier <p style={{ color: 'red' }}>*</p>
                        </InlineStack>
                      }
                      value={creditnoteIn.LeverancierNummer}
                      onChange={handleChange}
                      isDisabled={savingResult}
                    />
                    <TextField
                      className="sentry-ignore"
                      id="bedragopen"
                      maxLength={60}
                      prefix="€"
                      type="number"
                      autoComplete="off"
                      label="Amount open"
                      value={creditnoteIn.bedragopen ? String(creditnoteIn.bedragopen) : ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="datumin"
                      maxLength={60}
                      type="date"
                      autoComplete="off"
                      label="Date in"
                      value={String(creditnoteIn.datumin).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                    <Select
                      id="ValutaID"
                      maxLength={60}
                      autoComplete="off"
                      label="Currency"
                      value={parseInt(creditnoteIn.ValutaID) || ''}
                      options={currencies.map((curr) => ({ value: curr.ValutaID, label: curr.Valuta }))}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="datum"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Date creditnote"
                      value={String(creditnoteIn.datum).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                    <IncasserenSelect id="Incasseren" value={creditnoteIn.Incasseren} handleChange={handleChange} disabled={savingResult} />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <TextField
                      id="faktuurref"
                      maxLength={50}
                      showCharacterCount
                      autoComplete="off"
                      label={
                        <InlineStack>
                          Reference creditnote <p style={{ color: 'red' }}>*</p>
                        </InlineStack>
                      }
                      value={creditnoteIn.faktuurref || ''}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                    <TextField
                      id="datumbetaling"
                      maxLength={50}
                      type="date"
                      autoComplete="off"
                      label="Expiry date"
                      value={String(creditnoteIn.datumbetaling).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <InlineGrid columns={2}>
                      <BlockStack>
                        <Checkbox id="status" label="Paid" checked={creditnoteIn.status} onChange={handleChange} disabled={savingResult} />
                        <Checkbox id="Vervallen" label="Expired" checked={creditnoteIn.Vervallen} onChange={handleChange} disabled={savingResult} />
                      </BlockStack>
                      <OwnerSelect id="GebruikerID" label="Owner" value={creditnoteIn.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                    </InlineGrid>
                    <TextField
                      id="datumbetalingout"
                      maxLength={50}
                      type="date"
                      label="Payment date"
                      value={String(creditnoteIn.datumbetalingout).substring(0, 10)}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      placeholder="Select payment"
                      id="betalingID"
                      label="Payment condition"
                      value={parseInt(creditnoteIn.betalingID) || ''}
                      options={paymentconditions.sort((a, b) => alphabetize(a, b, 'betalingNL')).map((cond) => ({ value: cond.betalingID, label: cond.betalingNL }))}
                      onChange={handleChange}
                      disabled={savingResult}
                    />
                    <TextField id="OGM" maxLength={20} showCharacterCount autoComplete="off" label="OGM" value={creditnoteIn.OGM || ''} onChange={handleChange} disabled={savingResult} />
                  </FormLayout.Group>
                  <TextField id="memo" label="Note" value={creditnoteIn.memo || ''} multiline={6} onChange={handleChange} disabled={savingResult} />
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            {id !== 'new' && !creditnoteIn.processed && creditnoteIn.S3ObjectKeys ? (
              <Box paddingBlockEnd="300">
                <PdfCard keys={JSON.parse(creditnoteIn.S3ObjectKeys) || []} selectedS3Key={selectedS3Key} setSelectedS3Key={setSelectedS3Key} setIsDirty={setIsDirty} resource="creditnotes_in" />
              </Box>
            ) : null}
            <DocumentCard
              resource="CREDIT_NOTE_IN"
              id={id}
              title="Credit note IN document"
              company={company}
              docNumber={creditnoteIn.CreditnotaInNummer}
              isDirty={isDirty}
              generateDocument={false}
              docTypeID={docTypeID}
              docType={docType}
              docLang={company.TaalID}
              documentButtonLoading={savingResult}
              setDocumentButtonLoading={setSavingResult}
              invoice={creditnoteIn}
              setInvoice={setCreditnoteIn}
              setIsDirty={setIsDirty}
              setHasSysDoc={setHasSysDoc}
            />
          </Layout.Section>
          <Layout.Section variant="fullWidth">
            <ApprovalCard
              handleSave={saveForm}
              id={id}
              approvals={approvals}
              setApprovals={setApprovals}
              resource={creditnoteIn}
              setApprovalsDirty={setApprovalsDirty}
              resourceName="Creditnote"
              resourceID="CreditnotaINvolgnummer"
            />
          </Layout.Section>
          <Layout.Section variant="fullWidth">
            <EventTimeLine resourceName="CREDIT_NOTE_IN" resourceId={id} />
          </Layout.Section>
        </Layout>
      </Page>
    )
  );
}
