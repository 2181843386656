import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, DataTable, Link, Page, Card, Filters } from '@shopify/polaris';
import { formatToCurrency, getTableFooter, fetchTableData } from '../../shared/components/functions.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function ProductsPage() {
  const url = useUrl();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    code: { type: 'text', name: 'Code', value: url.get('code') || '' },
    status: { type: 'status', name: 'Status', value: url.get('status') || 'all' },
    supplier: { type: 'text', name: 'Supplier', value: url.get('supplier') || '' },
    productRef: { type: 'text', name: 'Product reference', value: url.get('productRef') || '' },
    productName: { type: 'text', name: 'Product name', value: url.get('productName') || '' }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/products', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const productStatus = item.Discontinued ? <Badge tone="critical">Discontinued</Badge> : <Badge tone="success">Active</Badge>;
        const dateAP = item['Datum bijw_AP'] ? item['Datum bijw_AP'].substring(0, 10) : '';
        const dateVP = item['Datum  bijw_ VP'] ? item['Datum  bijw_ VP'].substring(0, 10) : '';
        return [
          <Link removeUnderline url={'/products/' + item.ProductID}>
            {item.ProductID}
          </Link>,
          item.Productref,
          item.ProductNameNL,
          <Link removeUnderline url={`/companies/${item.supplier.ID}`}>
            {item.supplier.name}
          </Link>,
          productStatus,
          formatToCurrency(item.Eenheidsaankoopprijs),
          dateAP,
          formatToCurrency(item['Eenheids verkoopprijs']),
          dateVP
        ];
      })
    : [];

  return (
    <Page title="Products" fullWidth primaryAction={{ content: 'New product', url: '/products/new' }}>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter products name, code and product reference"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'numeric', 'numeric']}
          headings={[
            <b>Code</b>,
            <b>Product reference</b>,
            <b>Product name</b>,
            <b>Supplier</b>,
            <b>Status</b>,
            <b>Purchase price</b>,
            <b>PP last updated</b>,
            <b>Selling price</b>,
            <b>SP last updated</b>
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'products')}
        />
      </Card>
    </Page>
  );
}
