import { useEffect, useState } from 'react';
import { DataTable, Link } from '@shopify/polaris';
import { formatToCurrency, getDocPdfLinkForListView, getRequest } from '../../../shared/components/functions';

export default function PurchasesCompany({ FIRNR }) {
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      const data = await getRequest(`/api/purchases?limit=1000&FIRNR=${FIRNR}`);
      setPurchases(data);
    };
    fetchItems();
  }, [FIRNR]);

  const rows = Array.isArray(purchases)
    ? purchases.map((item) => {
        const customer_ref_date = item.RefKlantDatum ? item.RefKlantDatum : '';
        return [
          <Link removeUnderline url={'/purchases/' + item.BestellingID}>
            {item.Bestellingnummer}
          </Link>,
          getDocPdfLinkForListView(item, 'order', item.Klantnummer, item.Bestellingnummer),
          String(item.Datum).substring(0, 10),
          item.customer_name,
          item.RefKlant,
          String(customer_ref_date).substring(0, 10),
          formatToCurrency(item.total_value)
        ];
      })
    : [];

  return purchases.length > 0 ? (
    <DataTable
      verticalAlign="middle"
      stickyHeader={true}
      increasedTableDensity={true}
      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'numeric']}
      headings={[<b>{'Number'}</b>, <b>{'PDF'}</b>, <b>{'Date'}</b>, <b>{'Customer'}</b>, <b>{'Reference customer'}</b>, <b>{'Date customer'}</b>, <b>{'Price'}</b>]}
      rows={rows}
    />
  ) : (
    'No purchase orders'
  );
}
