import { useState, useEffect } from 'react';
import { Badge, DataTable, Toast, Link, Button, Card, Checkbox, Box, ButtonGroup, Scrollable } from '@shopify/polaris';
import { useToggle } from '../../../shared/useToogle';
import { getRequest } from '../../../shared/components/functions';
import { CardHeading } from '../../../shared/components/cards/CardHeading';

export default function HolidayRequestsComponent() {
  const [items, setItems] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [multipleSelected, setMultipleSelected] = useState(false);
  const [toastActive, setToastActive] = useToggle();
  const [toastActiveError, setToastActiveError] = useToggle();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const requests = await getRequest('/api/holidays/requests');
    if (requests && requests.length > 0) {
      setItems(requests);
      setCheckboxes(new Array(requests.length).fill(false));
    }
    setDataLoaded(true);
  };

  const toastMarkup = toastActive ? (
    <Toast content="Email sent to technician(s)" onDismiss={setToastActive} />
  ) : toastActiveError ? (
    <Toast error content="Error sending email to technician(s)!" onDismiss={setToastActiveError} />
  ) : null;

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkboxes.map((item, index) => (index === position ? !item : item));

    //count true values in the checkboxes array
    const count = updatedCheckedState.filter((item) => item).length;

    //set multipleselected to true if count is more than 0 else set to false
    setMultipleSelected(count > 0);
    setCheckboxes(updatedCheckedState);
  };

  const setAllCheckBoxValuesFalse = () => {
    setCheckboxes(new Array(items.length).fill(false));
    setMultipleSelected(false);
  };

  const approveSelection = async () => {
    const selectedItems = items.filter((item, index) => checkboxes[index]);
    const res = await fetch('/api/holidays/requests/approve-selection', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(selectedItems)
    });
    if (!res.ok) return setToastActiveError();
    getData();
    setToastActive();
    setMultipleSelected(false);
  };

  const rejectSelection = async () => {
    const selectedItems = items.filter((item, index) => checkboxes[index]);
    const res = await fetch('/api/holidays/requests/reject-selection', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(selectedItems)
    });
    if (!res.ok) return setToastActiveError();
    getData();
    setToastActive();
    setMultipleSelected(false);
  };

  const rows = Array.isArray(items)
    ? items.map((item, index) => {
        var request_length = item.hrs > 6 ? <Badge>Full day</Badge> : <Badge tone="info">Half Day</Badge>;
        var req_date = String(item.Date);
        let now = new Date(req_date.substring(0, 4), req_date.substring(5, 7) - 1, req_date.substring(8, 10));
        let onejan = new Date(now.getFullYear(), 0, 1);
        let week = Math.ceil(((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7);

        return [
          <div style={{ margin: '-5px 0' }}>
            <Checkbox checked={checkboxes[index]} onChange={() => handleCheckboxChange(index)} />
          </div>,
          item.Date,
          String(item.StartTechnician).substring(11, 16),
          String(item.EndTechnician).substring(11, 16),
          <Link removeUnderline url={`https://tech.schuilenburg.be/Weekoverzicht/${String(item.Date).substring(0, 4)}/${week}`}>
            In Planning
          </Link>,
          item.Gebruiker,
          item.Remarks,
          request_length,
          <Badge>{item.Name}</Badge>
        ];
      })
    : [];

  const display_selection_buttons = multipleSelected ? 'inline' : 'none';

  return (
    <Box paddingBlockEnd="400">
      <Card>
        <CardHeading title="Holiday requests needing approval" />
        <Box paddingBlockEnd="300">
          <div style={{ display: display_selection_buttons, float: 'right', marginTop: '-25px' }}>
            <ButtonGroup>
              <Button size="slim" variant="tertiary" onClick={() => setAllCheckBoxValuesFalse()}>
                Remove selection
              </Button>
              <Button size="slim" variant="primary" key="asdfasfdf" onClick={() => approveSelection()}>
                Approve selection
              </Button>
              <Button size="slim" variant="primary" tone="critical" onClick={() => rejectSelection()}>
                Reject selection
              </Button>
            </ButtonGroup>
          </div>
        </Box>

        {toastMarkup}

        {dataLoaded ? (
          <Scrollable shadow style={{ maxHeight: '600px' }}>
            <DataTable
              verticalAlign="middle"
              increasedTableDensity={true}
              columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
              headings={['Mark', 'Date', 'From', 'To', 'Planning', 'Name', 'Remarks', 'Length', 'Type']}
              rows={rows}
            />
          </Scrollable>
        ) : null}
      </Card>
    </Box>
  );
}
