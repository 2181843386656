import React from 'react';
import { useLookupStore } from '../../context/useStore.js';
import Select from 'react-select';

function DropdownSelect({ toSelect, isRequired, error, defaultValue, onChange }) {
  let options, defaultLabel;
  const { documenttypes, languages, machinebrands, usertypes } = useLookupStore();

  function getDropdown(options) {
    if (defaultValue > 0) {
      const defaultArr = options.filter((option) => option.value === defaultValue);
      defaultLabel = defaultArr[0].label;
    } else {
      defaultLabel = '';
      defaultValue = null;
    }

    return (
      <Select
        options={options}
        placeholder={`Select ${toSelect.toLowerCase()}...`}
        closeMenuOnSelect
        isClearable={!isRequired}
        onChange={onChange}
        value={defaultValue ? { label: defaultLabel, value: defaultValue } : null}
        required={isRequired}
        menuPortalTarget={document.body}
        styles={
          error
            ? {
                valueContainer: (base) => ({ ...base, height: '32px' }),
                indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', border: '2px solid red', background: '#FFF2F2' }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 })
              }
            : {
                valueContainer: (base) => ({ ...base, height: '32px' }),
                indicatorSeperator: (base) => ({ ...base, height: '32px' }),
                indicatorsContainer: (base) => ({ ...base, height: '32px' }),
                control: (base) => ({ ...base, height: '32px', minHeight: '32px', borderRadius: '8px', borderColor: '#919191' }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 })
              }
        }
      />
    );
  }

  switch (toSelect) {
    case 'Machine brand':
      options = machinebrands.map((item) => {
        return { label: `${item.merk}`, value: item.counter };
      });
      return getDropdown(options);

    case 'Document type':
      options = documenttypes.map((item) => {
        return { label: `${item.DocumentTypeUK} | ${item.DocumentTypeNL}`, value: item.DocumentTypeID };
      });
      return getDropdown(options);

    case 'User type':
      options = usertypes.map((item) => {
        return { label: `${item.Gebruikertype}`, value: item.GebruikertypeID };
      });
      return getDropdown(options);

    default:
      options = languages.map((item) => {
        return { label: `${item.Omschrijving}`, value: item.TaalID };
      });
      return getDropdown(options);
  }
}

export { DropdownSelect };
