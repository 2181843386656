import React, { useEffect, useState } from 'react';
import { Badge, BlockStack, Box, Card, InlineStack, Layout, Link, Page, Text } from '@shopify/polaris';
import MetricMonthYearLine from './components/metrics/MetricMonthYearLine';
import userRoles from '../../utils/userRoles';
import { useAuthStore, useLookupStore } from '../../context/useStore';
import { getNiceDate } from '../../shared/components/events/eventTimeLine';
import { CardHeading } from '../../shared/components/cards/CardHeading';

export default function AnalyticsPage() {
  const { user } = useAuthStore();
  const [events, setEvents] = useState([]);
  const users = useLookupStore((state) => state.users);

  if (user?.role !== userRoles.AdminFull) return null;

  useEffect(() => {
    async function fetchEvents() {
      const res = await fetch('/api/events');
      const data = await res.json();
      setEvents(data);
    }
    fetchEvents();
  }, []);

  return (
    <Page fullWidth>
      <BlockStack gap="400">
        <MetricMonthYearLine type={'invoicing_out'} />
        <Layout>
          <Layout.Section variant="oneHalf">
            <Card>
              <CardHeading title="Last 250 events" />
              {Array.isArray(events) &&
                events.map((event, idx) => {
                  return (
                    <Box key={'event-' + idx} paddingBlockEnd={'100'}>
                      <InlineStack gap="400">
                        <Text>{event.type === 'CREATE' ? '🎉' : event.type === 'UPDATE' ? '🛠' : event.type === 'DELETE' ? '❌' : event.type === 'EMAIL_SENT' ? '✉️' : '📣'}</Text>
                        <Text>{users.find((user) => user.GebruikerID === event.user_id)?.Afkorting}</Text>
                        <Text>
                          <Badge tone="success">
                            {event.type === 'CREATE' ? 'create' : event.type === 'UPDATE' ? 'update' : event.type === 'DELETE' ? 'delete' : event.type === 'MAIL' ? 'mail sent' : event.type}
                          </Badge>
                        </Text>
                        <Text>
                          <Badge>{event.resource}</Badge>
                        </Text>
                        <Text>{getNiceDate(event.timestamp * 1)}</Text>
                        <Link url={`/${linksForResource[event.resource]}/${event.resource_id}`}>Link</Link>
                      </InlineStack>
                    </Box>
                  );
                })}
            </Card>
          </Layout.Section>
          <Layout.Section variant="oneHalf"></Layout.Section>
        </Layout>
      </BlockStack>
    </Page>
  );
}

/*
  COMPANY: 'COMPANY',
  COMPANY_CONTACT: 'COMPANY_CONTACT',
  INVOICE: 'INVOICE',
  SALE: 'SALE',
  QUOTATION: 'QUOTATION',
  INQUIRY: 'INQUIRY',
  PURCHASE: 'PURCHASE',
  CREDIT_NOTE: 'CREDIT_NOTE',
  INVOICE_IN: 'INVOICE_IN',
  DELIVERY_ORDER: 'DELIVERY_ORDER',
  CREDIT_NOTE_IN: 'CREDIT_NOTE_IN',
  MAINTENANCE_CONTRACT: 'MAINTENANCE_CONTRACT',
  INSTALLATION_NOTE: 'INSTALLATION_NOTE',
  PRODUCT: 'PRODUCT'

*/
const linksForResource = {
  SALE: 'sales',
  INVOICE: 'invoices',
  INVOICE_IN: 'invoices_in',
  PURCHASE: 'purchases',
  CREDIT_NOTE: 'creditnotes',
  CREDIT_NOTE_IN: 'creditnotes_in',
  COMPANY: 'companies',
  COMPANY_CONTACT: 'contacts',
  PRODUCT: 'products',
  INQUIRY: 'inquiries',
  QUOTATION: 'quotations',
  DELIVERY_ORDER: 'delivery_notes',
  INSTALLATION_NOTE: 'montage_bons',
  MAINTENANCE_CONTRACT: 'maintenance_contracts'
};
