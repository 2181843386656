import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Form, Select, FormLayout, Layout, Page, Card, TextField, Checkbox, BlockStack, InlineGrid } from '@shopify/polaris';
import CompanySelect from '../../shared/components/companySelect';
import LinesTable from '../../shared/components/linesTable';
import { TheSaveBar } from '../../shared/components/theSaveBar';
import { updateMyData } from '../../shared/util/updateLines';
import { handleSave, handleDiscard, useUrl } from '../../shared/util/hanldesave';
import { alphabetize, getRequest, simplePostRequest } from '../../shared/components/functions.jsx';
import DocumentCard from '../../shared/components/cards/DocumentCard';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import ProjectSelect from '../../shared/components/projectSelect';
import LinesTotalCard from '../../shared/components/cards/LinesTotalsCard.jsx';
import { validateLines, validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { getPageTitle, TabTitle } from '../../shell/helmet';
import { getSchema } from '../../shared/formSchemas';
import EventTimeLine from '../../shared/components/events/eventTimeLine';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import OwnerSelect from '../../shared/components/ownerSelect.jsx';

export default function PurchasePage() {
  let { id } = useParams();
  const navigate = useNavigate();
  let url = useUrl();
  const docTypeID = 13;
  const docType = 'order';
  const location = useLocation();
  const [savingResult, setSavingResult] = useState(false);
  const [purchase, setPurchase] = useState({});
  const [initialPurchase, setInitialPurchase] = useState({});
  const [lines, setLines] = useState([]);
  const [initialLines, setInitialLines] = useState([]);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false); //eslint-disable-line
  const [isDirty, setIsDirty] = useState(url.get('isDirty') || false);
  const [linesDirty, setLinesDirty] = useState(url.get('linesDirty') || false);
  const [company, setCompany] = useState({});
  const [customer, setCustomer] = useState({});
  const [isBlurred, setIsBlurred] = useState(true);
  const [documentInProgress, setDocumentInProgress] = useState(false);
  const { countries, languages, purchasetypes } = useLookupStore();
  const { user } = useAuthStore();
  const pageTitle = getPageTitle(purchase.Bestellingnummer, 'Purchase');
  const purchaseSchema = getSchema('purchase', url, null, user);

  const lineInterface = {
    lineIsDirty: false,
    BestellingLijnID: null,
    BestellingID: id,
    produktnr: null,
    produkthulpnr: null,
    tekstlijn: null,
    VP: null,
    aantal: 1,
    BTW: company.LandID === 300 ? 21 : null,
    MuntVP: null,
    PrijsaanvraagID: null,
    AP: null,
    ProductID: null,
    ProductName: null,
    ProductNameNL: '',
    ProductNameFR: '',
    Productref: '',
    SupplierID: null,
    UnitsOnOrder: null,
    'Eenheids verkoopprijs': null,
    'Munt verkoopprijs': null,
    'Datum  bijw_ VP': '',
    ReorderLevel: 0,
    Discontinued: false,
    Eenheidsaankoopprijs: 0,
    'Munt aankoopprijs': null,
    'Datum bijw_AP': '',
    Omschrijving: '',
    Eenheid: null,
    ProductNameDE: '',
    ProductNameUK: '',
    EenheidID: null,
    EenheidAantal: null,
    ParentProductID: null,
    HasDetails: false,
    HSCode: null,
    Kg: null,
    OnderdelenTypeID: null
  };

  const handleChange = (value, id) => {
    if (id === 'RefKlantDatum' && (!value || value === '')) value = null;
    setPurchase((purchase) => ({ ...purchase, [id]: value }), setIsDirty(true));
  };

  useEffect(() => {
    async function fetchData() {
      //if url contains /new then set machine to its interface
      if (id === 'new') {
        setPurchase(purchaseSchema.default());
      } else {
        const data = await getRequest(`/api/purchases/${id}`);
        if (!data) return setPurchase(purchaseSchema.default());
        setPurchase(data);
        setInitialPurchase(data);

        const data2 = await getRequest(`/api/purchases/${id}/lines`);
        setLines(data2);
        setInitialLines(data2);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (purchase.LeverancierID) {
        const data3 = await getRequest(`/api/companies/${purchase.LeverancierID}`);
        setCompany(data3);
      }
      if (purchase.KlantID) {
        const data4 = await getRequest(`/api/companies/${purchase.KlantID}`);
        setCustomer(data4);
      }
    }
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase.LeverancierID]);

  useEffect(() => {
    if (location.state) setLines(location.state);
  }, [location]);

  const generateDocument = async () => {
    const document = {
      addressee: {
        FIRNR: purchase.LeverancierID,
        name: company.NAAM,
        number: company.Klantnummer,
        address: company.STRAAT_NR,
        city: company.WOONPL,
        zip: company.POSTNR,
        country: countries.find((c) => c.LandId === company.LandID)?.Land,
        countryId: company.LandID,
        vat: company.BTW,
        postal_code: company.postkode
      },
      eu: false,
      language: purchase.TaalID ? languages.find((l) => l.TaalID === parseInt(purchase.TaalID))?.Taal : 'N',
      items: lines,
      type: docType, //{'invoice','service_report','delivery_note','quotation','service_report','order_confirmation','maintenance_contract','order','price_request','reminder','project','credit_note','proforma','invoice_in','credit_note_in','letter','fax','info_schuilenburg','part','customer'}
      typeID: docTypeID,
      id: purchase.BestellingID,
      number: purchase.Bestellingnummer,
      reference: customer.NAAM,
      reference_date: purchase.RefKlantDatum ? String(purchase.RefKlantDatum).substring(0, 10) : '',
      date: String(purchase.Datum).substring(0, 10),
      payment_condition: null,
      delivery_term: purchase.Leveringstermijn,
      aanbod: purchase.Aanbod,
      machine_type: purchase.MachineType,
      machine_number: purchase.MachineNummer
    };

    //check if it is dirty if not tell user to save first
    setDocumentInProgress(true);
    const res = await simplePostRequest(`/api/docgen`, document);
    if (res.ok) setDocumentInProgress(false);
  };

  async function saveForm() {
    const errorInSchema = await validateSchema(purchaseSchema, purchase);
    const errorInLines = await validateLines(lines);
    if (errorInLines) return alert(errorInLines);

    if (!documentInProgress) {
      if (!errorInSchema) {
        await handleSave(
          id,
          'purchases',
          purchase.BestellingID,
          purchase,
          setPurchase,
          setInitialPurchase,
          setToast,
          setToastError,
          isDirty,
          setIsDirty,
          linesDirty,
          lines,
          setLines,
          setInitialLines,
          setLinesDirty,
          setSavingResult,
          navigate
        );

        // auto generate pdf so latest changes are reflected in pdf
        if (id && id !== 'new') await generateDocument();
        setIsBlurred(false);
      } else {
        alert(errorInSchema);
      }
    }
  }

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Purchase saved" />
      <TheSaveBar
        isDirty={isDirty || linesDirty}
        savingResult={savingResult}
        handleDiscard={() => handleDiscard(initialPurchase, initialLines, setPurchase, setLines, setIsDirty, setLinesDirty)}
        handleSave={saveForm}
        disabled={documentInProgress || (!isBlurred && linesDirty)}
      />
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={saveForm}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="Bestellingnummer" disabled label="Number" value={purchase.Bestellingnummer ? String(purchase.Bestellingnummer) : 'created automatically'} />
                  <TextField id="LeverancierID" label="Supplier" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${purchase.LeverancierID}`) }} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField id="Datum" maxLength={50} type="date" autoComplete="off" label="Date" value={String(purchase.Datum).substring(0, 10)} onChange={handleChange} disabled={savingResult} />
                  <CompanySelect isClearable id="KlantID" label="Customer" value={purchase.KlantID} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    id="TaalID"
                    maxLength={50}
                    autoComplete="off"
                    label="Language"
                    value={parseInt(purchase.TaalID) || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                    options={languages.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                  />
                  <TextField
                    id="RefKlant"
                    maxLength={100}
                    showCharacterCount
                    type="text"
                    autoComplete="off"
                    label="Reference customer"
                    value={purchase.RefKlant || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <TextField
                    id="Leveringstermijn"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Delivery term"
                    value={purchase.Leveringstermijn || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                  <TextField
                    id="RefKlantDatum"
                    maxLength={50}
                    type="date"
                    autoComplete="off"
                    label="Customer date"
                    value={String(purchase.RefKlantDatum).substring(0, 10)}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <ProjectSelect id="ProjectID" label="Project" value={purchase.ProjectID} onChange={handleChange} isDisabled={savingResult} />
                  <TextField
                    id="MachineType"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Machine type"
                    value={purchase.MachineType || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select
                    id="BestellingTypeID"
                    label="Purchase type"
                    value={parseInt(purchase.BestellingTypeID) || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                    options={purchasetypes.sort((a, b) => alphabetize(a, b, 'BestellingType')).map((pt) => ({ value: pt.BestellingTypeID, label: pt.BestellingType }))}
                  />
                  <TextField
                    id="MachineNummer"
                    maxLength={50}
                    showCharacterCount
                    autoComplete="off"
                    label="Machine number"
                    value={purchase.MachineNummer || ''}
                    onChange={handleChange}
                    disabled={savingResult}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <InlineGrid columns={2}>
                    <BlockStack>
                      <Checkbox id="Verstuurd" label="PDF Document emailed" checked={purchase.Verstuurd} onChange={handleChange} disabled={savingResult} />
                      <Checkbox id="Geannuleerd" label="Cancelled" checked={purchase.Geannuleerd} onChange={handleChange} disabled={savingResult} />
                    </BlockStack>
                    <OwnerSelect id="GebruikerID" label="Owner" value={purchase.GebruikerID} handleChange={handleChange} disabled={savingResult} />
                  </InlineGrid>
                  <TextField id="Aanbod" maxLength={50} showCharacterCount autoComplete="off" label="Aanbod" value={purchase.Aanbod || ''} onChange={handleChange} disabled={savingResult} />
                </FormLayout.Group>
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <LinesTotalCard summary="purchase" lines={lines} />
            <DocumentCard
              resource="PURCHASE"
              id={id}
              title="Purchase document"
              docNumber={purchase.Bestellingnummer}
              isDirty={isDirty}
              linesDirty={linesDirty}
              generateDocument={generateDocument}
              docTypeID={docTypeID}
              docType={docType}
              company={company}
              setResource={setPurchase}
              docLang={purchase.TaalID}
              documentButtonLoading={savingResult || documentInProgress}
              setDocumentButtonLoading={setSavingResult}
            />
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <CardHeading title="Purchase content" />
            <LinesTable
              id={id}
              data={lines}
              updateMyData={updateMyData}
              setLines={setLines}
              setLinesDirty={setLinesDirty}
              onSubmit={saveForm}
              lineInterface={lineInterface}
              resource={purchase}
              company={company}
              disabled={savingResult}
              setIsBlurred={setIsBlurred}
            />
          </Card>
        </Layout.Section>
        <Layout.Section>
          <EventTimeLine resourceName="PURCHASE" resourceId={id} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
