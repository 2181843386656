import { Badge, Card, Page, Tabs } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ExpenseHistoryTable } from './components/ExpenseHistoryTable';
import { useUrl } from '../../shared/util/hanldesave';
import { OpenExpenses } from './components/OpenExpenses';
import { useLookupStore } from '../../context/useStore';
import { formatToCurrency, getRequest, putRequest } from '../../shared/components/functions';
import Toasts from '../../shared/components/toasts/toasts';

export const ExpenseForUserDetailPage = () => {
  let { userId } = useParams();
  const users = useLookupStore((state) => state.users);
  const username = users.find((u) => u.GebruikerID === parseInt(userId))?.Gebruikernaam;
  const pageTitle = `${username}`;
  let url = useUrl();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(url.get('tab') ? parseInt(url.get('tab')) : 0);
  const [totalAwaitingApproval, setTotalAwaitingApproval] = useState();
  const [totalAwaitingPayment, setTotalAwaitingPayment] = useState();
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [items, setItems] = useState([]);

  const fetchData = useCallback(async () => {
    const data = await getRequest(`/api/expenses/users/${userId}`);
    setTotalAwaitingApproval(data.filter((i) => i.approved === null).reduce((p, c) => p + c.Prijs, 0));
    setTotalAwaitingPayment(data.filter((i) => i.approved && !i.Uitbetaald).reduce((p, c) => p + c.Prijs, 0));
    setItems(data);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate(`${window.location.pathname}?tab=${selectedTabIndex}`, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'expense-history',
      content: 'Expense history',
      panelID: 'expense-history',
      comp: <ExpenseHistoryTable items={items} setItems={setItems} fetchUpdatedData={fetchData} />
    },
    {
      id: 'open-expenses',
      content: 'Open expenses',
      panelID: 'open-expenses',
      comp: <OpenExpenses />
    }
  ];

  const markAllApprovedExpensesAsPaid = async () => {
    await putRequest(`/api/expenses/paid?approved=1&paid=0&user=${userId}`, {}, setToast, setToastError);
    fetchData();
  };

  return (
    <Page
      title={pageTitle}
      fullWidth
      titleMetadata={
        <span style={{ display: 'flex', gap: '10px' }}>
          <Badge tone="info">{`Total value to approve: ${formatToCurrency(totalAwaitingApproval)}`} </Badge>
          <Badge tone="info">{`Total value to pay out: ${formatToCurrency(totalAwaitingPayment)}`}</Badge>
        </span>
      }
      primaryAction={{ content: 'Mark all approved expenses as paid', disabled: totalAwaitingPayment === 0, onAction: markAllApprovedExpensesAsPaid }}
    >
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Changes saved" />
      <Card>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].comp}
        </Tabs>
      </Card>
    </Page>
  );
};
