import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select as PSelect, Checkbox, Layout, TextField, FormLayout, Form, Page, Card, Banner, BlockStack, Box } from '@shopify/polaris';
import { useLookupStore } from '../../context/useStore.js';
import { useUrl, handleSaveWithSecondPayload } from '../../shared/util/hanldesave';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import { alphabetize, getRequest } from '../../shared/components/functions.jsx';
import { TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import CustomPhoneInput from '../../shared/components/inputs/phone.jsx';
import Select from 'react-select';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import EventTimeLine from '../../shared/components/events/eventTimeLine.jsx';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';
/*
  FIXME:
  - what is the field sendMail and Montagebon and Factuur used for?
*/

export default function ContactPage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const { contacttitels, countries, languages, pubcodes } = useLookupStore();
  const [initialContact, setInitialContact] = useState('');
  const [contact, setContact] = useState('');
  const [company, setCompany] = useState({});
  const [initialPubs, setInitialPubs] = useState([]);
  const [pubs, setPubs] = useState([]);
  const [pubsDirty, setPubsDirty] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [selectContacttiles, setSelectContacttiles] = useState([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const pageTitle = id === 'new' ? 'New contact' : contact.NAAM;
  const contactSchema = getSchema('contact', url);

  const handleChange = (value, id) => {
    setContact((contact) => ({ ...contact, [id]: value }), setIsDirty(true));
  };

  const handleSave = async () => {
    setFormSubmitting(true);
    const errorInSchema = await validateSchema(contactSchema, contact);
    if (!errorInSchema) {
      await handleSaveWithSecondPayload(
        id,
        'contacts',
        contact.CONNR,
        'CONNR',
        contact,
        pubs,
        setContact,
        setInitialContact,
        `contacts/${id}/pubs`,
        setPubs,
        setInitialPubs,
        isDirty,
        setIsDirty,
        pubsDirty,
        setPubsDirty,
        setToast,
        setToastError,
        navigate
      );
    } else {
      console.log('Contact:', contact);
      alert(errorInSchema);
    }
    setFormSubmitting(false);
  };

  const handleDiscard = () => {
    setContact(initialContact);
    setPubs(initialPubs);
    setIsDirty(false);
    setPubsDirty(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (id === 'new') {
        setContact(contactSchema.default());
      } else {
        const data = await getRequest(`/api/contacts/${id}`);
        if (!data || data.length === 0) return setContact(contactSchema.default());
        setContact(data[0]);
        setInitialContact(data[0]);

        const data2 = await getRequest(`/api/contacts/${id}/pubs`);
        const codes = data2.map((pub) => ({ value: pub.counter, label: `${pub.kode} (${pub.omschrijving})` }));
        setPubs(codes);
        setInitialPubs(codes);
      }
    }

    fetchData();
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      if (id === 'new' || contact.CONNR) {
        const data = await getRequest(`/api/companies/${url.get('KLANR') || contact.KLANR}`);
        setCompany(data);
      }
    }
    fetchData();
    //eslint-disable-next-line
  }, [contact.CONNR]);

  useEffect(() => {
    let proptitle = 'AanspreektitelUK';
    switch (contact.TaalID) {
      case 1:
        proptitle = 'AanspreektitelNL';
        break;
      case 2:
        proptitle = 'AanspreektitelFR';
        break;
      case 3:
        proptitle = 'AanspreektitelUK';
        break;
      case 4:
        proptitle = 'AanspreektitelDE';
        break;
      default:
        proptitle = 'AanspreektitelUK';
    }
    const contactTitles = contacttitels.map((title) => ({ value: title.AanspreektitelID, label: title[proptitle] }));
    setSelectContacttiles(contactTitles);
  }, [contact.TaalID, contacttitels]);

  const discontinued_banner = contact.DISCONTINUED ? (
    <Box paddingBlockEnd="400">
      <Banner tone="critical">This contact is discontinued!</Banner>
    </Box>
  ) : null;

  return (
    <Page title={pageTitle} fullWidth>
      <TabTitle title={pageTitle} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Contact saved" />
      <TheSaveBar isDirty={isDirty || pubsDirty} handleSave={handleSave} handleDiscard={handleDiscard} savingResult={formSubmitting} />
      {discontinued_banner}
      <Layout>
        <Layout.Section>
          <Card>
            <Form onSubmit={handleSave}>
              <FormLayout>
                <FormLayout.Group>
                  <TextField id="NAAM" label="Name" value={contact.NAAM} onChange={handleChange} maxLength={100} showCharacterCount={contact.NAAM} disabled={formSubmitting} />
                  <TextField label="Company" value={company.NAAM} disabled labelAction={{ content: 'open', onAction: () => navigate(`/companies/${contact.KLANR}`) }} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <PSelect id="AanspreektitelID" label="Title" value={parseInt(contact.AanspreektitelID) || ''} options={selectContacttiles} onChange={handleChange} disabled={formSubmitting} />
                  <TextField id="DEPT" label="Function" value={contact.DEPT || ''} onChange={handleChange} maxLength={50} showCharacterCount disabled={formSubmitting} />
                  <PSelect
                    id="TaalID"
                    label="Language"
                    value={parseInt(contact.TaalID) || ''}
                    disabled={formSubmitting}
                    options={languages.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
                    onChange={handleChange}
                  />
                </FormLayout.Group>
                <TextField
                  id="E-mail"
                  maxLength={100}
                  showCharacterCount
                  type="email"
                  autoComplete="off"
                  label="Email"
                  value={contact['E-mail'] || ''}
                  onChange={handleChange}
                  disabled={formSubmitting}
                />
                <FormLayout.Group>
                  <CustomPhoneInput
                    id="GSM"
                    label="GSM"
                    value={contact.GSM}
                    onChange={handleChange}
                    countryIso2={company.LandID ? countries.find((country) => country.LandId === parseInt(company.LandID))?.ISO2 : 'BE'}
                    disabled={formSubmitting}
                  />
                  <CustomPhoneInput
                    id="TEL"
                    label="TEL"
                    value={contact.TEL}
                    onChange={handleChange}
                    countryIso2={company.LandID ? countries.find((country) => country.LandId === parseInt(company.LandID))?.ISO2 : 'BE'}
                    disabled={formSubmitting}
                  />
                </FormLayout.Group>
                <TextField id="OPMERKING" value={contact.OPMERKING || ''} label="Note" multiline={25} onChange={handleChange} placeholder="Edit note ✍️" disabled={formSubmitting} />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Status" />
              <Form onSubmit={handleSave}>
                <Checkbox id="DISCONTINUED" label="Contact discontinued" checked={contact.DISCONTINUED} onChange={handleChange} disabled={formSubmitting} />
              </Form>
            </Card>
            <Card>
              <CardHeading title="Pub codes" />
              {id === 'new' ? (
                'Create contact before assigning pub codes'
              ) : (
                <Select
                  isMulti
                  options={pubcodes.sort((a, b) => alphabetize(a, b, 'kode')).map((pub) => ({ value: pub.counter, label: `${pub.kode} (${pub.omschrijving})` }))}
                  value={pubs}
                  isDisabled={formSubmitting}
                  menuPortalTarget={document.body}
                  onChange={(props) => {
                    setPubs(props);
                    setPubsDirty(true);
                  }}
                />
              )}
            </Card>
            <EventTimeLine resourceName="COMPANY_CONTACT" resourceId={id} />
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
